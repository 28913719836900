// generates array [0,1,..,33,34] from total
const generateArray = (total = 0) =>
  Array.apply(null, { length: total }).map(Number.call, Number);

// generate paignation for sort table
const generatePagination = (active, total) => {
  const limit = 7;
  const firstBoundary = 4;
  const finalBoundary = total - 5;
  const extraItems = 3;

  // -2 here because there is 2 extra buttons manually added
  if (total - 2 > limit) {
    if (active < firstBoundary) {
      // deals with 1...5
      return [0, ...[...generateArray(limit).map(item => item + 1)], total - 1];
    } else if (active >= finalBoundary) {
      // deals with x ... limit
      return [
        0,
        ...[...generateArray(limit).map(item => item - 1 + (total - limit))],
        total - 1,
      ];
    } else {
      // deals with 5 ... limit - 5
      return [
        0,
        ...[...generateArray(limit).map(item => item + (active - extraItems))],
        total - 1,
      ];
    }
  } else {
    return generateArray(total);
  }
};

export default generatePagination;
