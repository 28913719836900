import React, { Fragment, useRef } from 'react';
import { array, string, object, func } from 'prop-types';
import { connect } from 'react-redux';
import SortTableRow from '../SortTableRow/SortTableRow';
import { get } from 'lodash';
import SortTablePagination from '../SortTablePagination/SortTablePagination';
import SortTableHeader from '../SortTableHeader/SortTableHeader';
import { openMenu } from '../../actions/menuActions';
import { openModal } from '../../actions/modalActions';
import HeroIconViewGridAddOutline from '../HeroIcon/HeroIconViewGridAddOutline';

import './SortTable.scss';

const pdfModal = {
  contents: 'PdfModal',
  maxWidth: '480px',
};

// main sort table wrapper
const SortTable = ({
  sortTable,
  dashboard,
  translations,
  openModal,
  openM,
}) => {
  const { itemView__emptyBigArea_text } = translations.byKeyTranslations;
  const scrollContainer = useRef();
  const { sortedIssuesChunked } = dashboard;
  const correctRows = get(
    sortedIssuesChunked,
    [sortTable.paginationActive],
    []
  );

  const scrollPage = () => (scrollContainer.current.scrollTop = 0);

  return (
    <Fragment>
      <SortTableHeader />

      <div className="sort-table__scroll custom-scroll" ref={scrollContainer}>
        {correctRows.map((item) => (
          <SortTableRow key={item._id} item={item} />
        ))}

        {sortedIssuesChunked.length === 0 && (
          <div className="sort-table__empty">
            <div className="sort-table__empty--inner grid place-items-center grid-flow-row auto-rows-max">
              <HeroIconViewGridAddOutline size={80} />
              <p>{itemView__emptyBigArea_text}</p>
            </div>
          </div>
        )}

        <SortTablePagination scrollPage={scrollPage} />

        <div className="dashboard__mobileBottomNav">
          <div
            className="dashboard__mobileBottomNav--link"
            onClick={() => openM('workSpaceMenu')}
          >
            Workspaces
          </div>
          <div
            className="dashboard__mobileBottomNav--link"
            onClick={() => openModal(pdfModal)}
          >
            Export
          </div>
        </div>
      </div>
    </Fragment>
  );
};

SortTable.propTypes = {
  data: array,
  url: string,
  dashboard: object,
  sortTable: object,
  translations: object,
  openM: func,
  openModal: func,
};

const mapStateToProps = ({ sortTable, dashboard, translations }) => ({
  sortTable,
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  openM: (item) => dispatch(openMenu(item)),
  openModal: (data) => dispatch(openModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortTable);
