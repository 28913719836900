import downloadFile from '../helpers/downloadFile';
// forces file to download
const forceDownload = async (url = '', name = 'download') => {
  try {
    const res = await fetch(url);
    const blob = await res.blob();
    downloadFile(blob, name);
  } catch (e) {
    const tag = document.createElement('a');
    tag.href = url;
    tag.download = name;
    tag.target = '_blank';
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
};

export default forceDownload;
