import React, { useState, useEffect, Fragment } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import { setActivePagination } from '../../actions/sortTableActions';
import generatePagination from '../../helpers/generatePagination';
import { PAGINATION_LENGTH } from '../../constants';

import './SortTablePagination.scss';

// pagination used in main table
const SortTablePagination = ({
  dashboard,
  scrollPage,
  setActivePag,
  sortTable,
}) => {
  const [pageLabels, setPageLabels] = useState([]);
  const { activeIssue, sortedIssuesChunked, sortedIssues } = dashboard;
  const { paginationActive } = sortTable;
  const activeId = activeIssue._id;

  useEffect(() => {
    const newPage = generatePagination(
      paginationActive,
      sortedIssuesChunked.length
    );

    if (pageLabels.length !== newPage.length) {
      setActivePag(0);
      setPageLabels(newPage);
    }
  }, [sortedIssuesChunked]); // eslint-disable-line

  useEffect(() => {
    // ensure correct pag page is active on page load + item change
    // this dosnt need to happen on every click but load + create new
    const findActiveIndex = sortedIssues.findIndex(
      (item) => item._id === activeId
    );

    const activePageNo =
      sortedIssues.length > PAGINATION_LENGTH && findActiveIndex >= 0
        ? Math.floor(findActiveIndex / PAGINATION_LENGTH)
        : 0;

    changePagination(activePageNo);
  }, [activeId]); // eslint-disable-line

  const changePagination = (data) => {
    setActivePag(data);
    const newPage = generatePagination(data, sortedIssuesChunked.length);
    setPageLabels(newPage);
  };

  return (
    <Fragment>
      {sortedIssuesChunked.length > 1 && (
        <div className="sort-table__pagination-container">
          <div className="sort-table__pagination">
            <Button
              subtle
              onClick={
                paginationActive > 0
                  ? () => {
                      scrollPage();
                      changePagination(paginationActive - 1);
                    }
                  : () => {}
              }
            >
              &lt;
            </Button>
            {pageLabels.map((item) => (
              <Button
                subtle={paginationActive !== item}
                key={item}
                appearance
                onClick={() => {
                  scrollPage();
                  changePagination(item);
                }}
              >
                {String(item + 1)}
              </Button>
            ))}
            <Button
              subtle
              onClick={
                paginationActive + 1 < sortedIssuesChunked.length
                  ? () => {
                      scrollPage();
                      changePagination(paginationActive + 1);
                    }
                  : () => {}
              }
            >
              &gt;
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

SortTablePagination.propTypes = {
  dashboard: object,
  scrollPage: func,
  setActivePag: func,
  sortTable: object,
};

const mapStateToProps = ({ sortTable, dashboard }) => ({
  sortTable,
  dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  setActivePag: (data) => dispatch(setActivePagination(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SortTablePagination);
