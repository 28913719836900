import React from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Link from '../Link/Link';
import ThumbNail from '../ThumbNail/ThumbNail';
import { openModal } from '../../actions/modalActions';
import imageUrl from '../../helpers/imageUrl';
import { genericTimeYearDate } from '../../helpers/formatDates';
import getPermissions from '../../helpers/getPermissions';
import {
  updateActiveIssue,
  deleteItemAsset,
  removeCoverImage,
} from '../../actions/itemActions';

import './AssetCell.scss';

const modal = {
  contents: 'AssetViewer',
  maxWidth: 'full',
};

// main item asset inner cell
const AssetCell = ({
  data,
  openM,
  updateField,
  deleteAsset,
  removeCover,
  dashboard,
  noDelete,
  translations,
}) => {
  const {
    itemView__assetRemoveCover_link,
    itemView__assetSetCover_link,
    itemView__assetDelete_link,
  } = translations.byKeyTranslations;
  const { _id, url, key, creator, createdAt } = data;
  const { _id: issueId, coverImage } = dashboard.activeIssue;

  const correctLink = () =>
    coverImage && coverImage._id === _id ? (
      <Link onClick={() => removeCover(issueId)} dark inline>
        {itemView__assetRemoveCover_link}
      </Link>
    ) : (
      <Link onClick={() => updateField(issueId, 'coverImage', _id)} dark inline>
        {itemView__assetSetCover_link}
      </Link>
    );

  return (
    <div key={_id} className="issue__attachment">
      <div
        className="issue__attachment-left"
        onClick={() => openM({ ...modal, data: _id })}
      >
        <ThumbNail url={imageUrl(key, url)} name={url} noLink darkBorder />
        <div className="asset-cell__info-container">
          <p className="issue__attachment-name" title={url}>
            {url}
          </p>
          <p className="asset-cell__info">
            {creator.firstName && creator.firstName + ' - '}
            {genericTimeYearDate(createdAt)}
          </p>
        </div>
      </div>
      <span className="issue__attachment-right">
        <div>
          {getPermissions('item:attachment:changeCover') && correctLink()}

          <br />

          {!noDelete && getPermissions('item:attachment:delete') && (
            <Link onClick={() => deleteAsset(_id, issueId)} dark inline>
              {itemView__assetDelete_link}
            </Link>
          )}
        </div>
      </span>
    </div>
  );
};

AssetCell.propTypes = {
  openM: func,
  updateField: func,
  dashboard: object,
  deleteAsset: func,
  removeCover: func,
  noDelete: bool,
  data: object,
  translations: object,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  openM: (target) => dispatch(openModal(target)),
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  deleteAsset: (id, projectId) => dispatch(deleteItemAsset(id, projectId)),
  removeCover: (id) => dispatch(removeCoverImage(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetCell);
