import exportCreator, { exportFuzzyCreator } from '../helpers/exportCreator.js';
import config from '../config';

// reducers constants
const constants = {
  ...exportFuzzyCreator('ADMIN_USERS'),
  ...exportFuzzyCreator('ADMIN_PROJECTS'),
  ...exportFuzzyCreator('ADMIN_PROJECTS_VIEW'),
  ...exportFuzzyCreator('HISTORY'),
  ...exportCreator('ADMIN_DASHBOARD'),
  ...exportCreator('ADMIN_TRANSLATIONS'),
  ...exportCreator('ADMIN_PROJECTS'),
  ...exportCreator('ADMIN_PROJECTS_VIEW'),
  ...exportCreator('ADMIN_PROJECTS_ITEMS'),
  ...exportCreator('ADMIN_PROJECTS_EVENTS'),
  ...exportCreator('ADMIN_PROJECTS_STATS'),
  ...exportCreator('ADMIN_USERS'),
  ...exportCreator('ADMIN_USER_VIEW'),
  ...exportCreator('ADMIN_USER_PROJECTS'),
  ...exportCreator('ADMIN_USER_EVENTS'),
  ...exportCreator('LOGIN'),
  ...exportCreator('REGISTER'),
  ...exportCreator('CREATE_NEW_PROJECT'),
  ...exportCreator('PROJECT_NAV'),
  ...exportCreator('ISSUES'),
  ...exportCreator('CREATE_NEW_ISSUE'),
  ...exportCreator('ADMIN_FEEDBACK'),
  ...exportCreator('COMPANY'),
  ...exportCreator('NOTIFICATIONS'),
  ...exportCreator('TEAMS'),
  ...exportCreator('ADMIN_TEAMS'),
  ...exportCreator('REPORT'),
  ...exportCreator('ADMIN_REPORTS'),
  ...exportCreator('ITEM_HISTORY'),
  ...exportCreator('PROJECT_HISTORY'),
  ...exportCreator('TEAM_ARCHIVE'),
  ...exportCreator('TEAM_TEMPLATE'),
  ...exportCreator('ADMIN_TOKEN'),
  ISSUES_SET_ACTIVE: 'ISSUES_SET_ACTIVE',
  PROJECT_NAV_SET_ACTIVE: 'PROJECT_NAV_SET_ACTIVE',
  LOGOUT: 'LOGOUT',
  LOADING_REQUEST: 'LOADING_REQUEST',
  LOADING_FINISHED: 'LOADING_FINISHED',
  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',
  ADMIN_USERS_LABELS: 'ADMIN_USERS_LABELS',
  ADMIN_USERS_LIST: 'ADMIN_USERS_LIST',
  ISSUES_FILTER: 'ISSUES_FILTER',
  MENU_OPEN: 'MENU_OPEN',
  MENU_CLOSE: 'MENU_CLOSE',
  CLEAR_FILTER: 'CLEAR_FILTER',
  PROJECT_NAV_WORKSPACE: 'PROJECT_NAV_WORKSPACE',
  ALL_ISSUES: 'ALL_ISSUES',
  MY_ISSUES: 'MY_ISSUES',
  OPEN_ISSUES: 'OPEN_ISSUES',
  PROJECT_NAV_FUZZY_TERM: 'PROJECT_NAV_FUZZY_TERM',
  PROJECT_MENU_FUZZY_TERM: 'PROJECT_MENU_FUZZY_TERM',
  PROJECT_MENU_FUZZY_SEARCH: 'PROJECT_MENU_FUZZY_SEARCH',
  PROJECT_NAV_RESET: 'PROJECT_NAV_RESET',
  ISSUES_RESET: 'ISSUES_RESET',
  SORT_TABLE: 'SORT_TABLE',
  SORT_TABLE_RESULT: 'SORT_TABLE_RESULT',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  USER_PROFILE: 'USER_PROFILE',
  USER_SUBS: 'USER_SUBS',
  USER_SIDEBAROPEN: 'USER_SIDEBAROPEN',
  USER_SIDEBARWIDTH: 'USER_SIDEBARWIDTH',
  USER_ISSUEDOCWIDTH: 'USER_ISSUEDOCWIDTH',
  ISSUES_UPDATE_FILTER: 'ISSUES_UPDATE_FILTER',
  ISSUES_UPDATE_FILTERED_ISSUES: 'ISSUES_UPDATE_FILTERED_ISSUES',
  ISSUES_UPDATE_SEARCH_FILTERED_ISSUES: 'ISSUES_UPDATE_SEARCH_FILTERED_ISSUES',
  ISSUES_SORTED_ISSUES: 'ISSUES_SORTED_ISSUES',
  ISSUES_FUZZY_TERM: 'ISSUES_FUZZY_TERM',
  ISSUES_SORTED_CHUNKED: 'ISSUES_SORTED_CHUNKED',
  SET_PAGINATION: 'SET_PAGINATION',
  PROJECT_EDIT_UPDATE: 'PROJECT_EDIT_UPDATE',
  PROJECT_EDIT_CLEAR: 'PROJECT_EDIT_CLEAR',
  PROJECT_EDIT_BULK: 'PROJECT_EDIT_BULK',
  TEAMS_SET_ACTIVE: 'TEAMS_SET_ACTIVE',
  TEAM_PROJECTS: 'TEAM_PROJECTS',
  ADMIN_TEAMS_LABELS: 'ADMIN_TEAMS_LABELS',
  ADMIN_TEAMS_LIST: 'ADMIN_TEAMS_LIST',
  ADMIN_TEAMS_FUZZY_SEARCH: 'ADMIN_TEAMS_FUZZY_SEARCH',
  ADMIN_TEAMS_FUZZY_TERM: 'ADMIN_TEAMS_FUZZY_TERM',
  BULK_EDIT_MENU: 'BULK_EDIT_MENU',
  BULK_EDIT_ITEMS: 'BULK_EDIT_ITEMS',
  BULK_EDIT_EDIT: 'BULK_EDIT_EDIT',
  BULK_EDIT_TAGS: 'BULK_EDIT_TAGS',
  TEAMS_PROJECT_FUZZY_TERM: 'TEAMS_PROJECT_FUZZY_TERM',
  TEAMS_PROJECT_FUZZY_SERCH: 'TEAMS_PROJECT_FUZZY_SERCH',
  TEAMS_MEMBER_FUZZY_TERM: 'TEAMS_MEMBER_FUZZY_TERM',
  TEAMS_MEMBER_FUZZY_SEARCH: 'TEAMS_MEMBER_FUZZY_SEARCH',
  TEAMS_MEMBER_RESET_SEARCH: 'TEAMS_MEMBER_RESET_SEARCH',
  RESET_ALL_ISSUES: 'RESET_ALL_ISSUES',
  ALL_ISSUE_DATA_UPDATES: 'ALL_ISSUE_DATA_UPDATES',
  SUB_LOADING: 'SUB_LOADING',
  WORKSPACE_EDIT_UPDATE: 'WORKSPACE_EDIT_UPDATE',
  WORKSPACE_EDIT_CLEAR: 'WORKSPACE_EDIT_CLEAR',
  WORKSPACE_EDIT_BULK: 'WORKSPACE_EDIT_BULK',
  COMPACT_LIST: 'COMPACT_LIST',
  COMFORTABLE_LIST: 'COMFORTABLE_LIST',
  UPDATE_USER_PROJECT_SETTINGS: 'UPDATE_USER_PROJECT_SETTINGS',
};

export default constants;

// apis
export const api = {
  API_BASE_URL: config.API_BASE_URL,
  PROJECTS_API_URL: '/projects',
  LOGIN_API_URL: '/authentication',
  USERS_API_URL: '/users',
  ITEMS_API_URL: '/items',
  DUPLICATE_ITEM_API_URL: '/duplicate-item',
  USER_PROJECTS: '/user-projects',
  SUB_DOC_UPDATE: '/sub-doc-update',
  SUB_DOC_PATCH: '/sub-doc-patch',
  SUB_DOC_DELETE: '/sub-doc-delete',
  UPDATE_SEEN: '/update-seen',
  ASSET_URL: '/assets',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  GENERATE_PDF: '/generate-pdf',
  GENERATE_CSV: '/generate-csv',
  FEEDBACK_API_URL: '/feedback',
  DELETE_COMMENT: '/delete-comment',
  REGISTER_URL: '/register',
  COMPANY_URL: '/companies',
  NOTIFICATION_API_URL: '/user-notifications',
  USERS_SUBS_API_URL: '/subscriptions',
  CONFIRM_EMAIL: '/confirm-email',
  EDIT_COMMENT: '/edit-comment',
  NEW_TAG: '/new-tag',
  ADMIN_STATS_URL: '/admin-stats',
  WORKSPACE_SORT: '/workspace-sort',
  TEAMS_API: '/teams',
  MY_TEAMS_API: '/my-team',
  EVENTS_API_URL: '/event-stream',
  ADMIN_PROJECT_STATS_URL: '/admin-project-stats',
  BULK_EDIT_API: '/bulk-edit',
  TEAM_MEMBER_API: '/add-team-member',
  BULK_DELETE: '/bulk-delete',
  REPORT_API: '/project-report',
  ADMIN_REPORT_API: '/admin-reports',
  PDF_REPORT_API: '/report-pdf',
  ITEM_HISTORY_URL: '/item-history',
  PROJECT_HISTORY_URL: '/project-history',
  ADMIN_TRANSLATIONS_URL: '/translations',
  ADMIN_TRANSLATIONS_EDIT_URL: '/translation-edit',
  TEAM_ARCHIVE_URL: '/archived',
  ITEMS_SUBITEM_API_URL: '/sub-task',
  DELETE_SUBTASK_API_URL: '/delete-sub-task',
  TEAM_GET_TEMPLATES: '/template-projects',
  TOKEN_API: '/external-token',
};

// can move this into the reducer if we want to have custom pag size
export const PAGINATION_LENGTH = 50;
