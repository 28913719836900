import React from 'react';
import { object, func, array } from 'prop-types';
import Button from '../../components/Button/Button';
import { connect } from 'react-redux';
import { adminTranslationsUpdate } from '../../actions/adminTranslationsActions';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import { get } from 'lodash';

import './AdminTranslateConfirm.scss';

const AdminTranslateConfirm = ({
  data,
  update,
  translations = { byKeyTranslations: {} },
}) => {
  const editedData = data.filter((item) => item.changed);

  const body = (
    <div className="adminTranslation__body">
      <p>The following terms have changed :</p>

      <div className="adminTranslations__changed--row">
        <div className="adminTranslations__changed--left">
          <div>Term</div>
        </div>
        <div className="adminTranslations__changed--inner">
          <div>OLD</div>
          <div className="adminTranslations__changed--middle"> </div>
          <div>NEW</div>
        </div>
      </div>
      {editedData.map(({ name, text, _id }) => (
        <div className="adminTranslations__changed--row" key={_id}>
          <div className="adminTranslations__changed--left">
            <div>{name}</div>
          </div>
          <div className="adminTranslations__changed--inner">
            <div className="adminTranslations__changed--oldText">
              {get(translations, `byKeyTranslations[${name}]`)}
            </div>
            <div className="adminTranslations__changed--middle">{`->`}</div>
            <div className="adminTranslations__changed--newText">{text}</div>
          </div>
        </div>
      ))}
    </div>
  );

  const footer = <Button onClick={() => update(data)}>Confirm</Button>;

  return <ModalFormat body={body} footer={footer} />;
};

AdminTranslateConfirm.propTypes = {
  data: array,
  translations: object,
  update: func,
};

const mapStateToProps = ({ translations }) => ({
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(adminTranslationsUpdate(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTranslateConfirm);
