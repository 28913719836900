import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { push } from 'connected-react-router';

const { LOADING_FINISHED, LOADING_REQUEST } = constants;

export const resetPassword =
  ({ password }, id) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'resetPassword' });

    const fetchDataInfo = {
      url: api.RESET_PASSWORD,
      type: 'POST',
      body: {
        id,
        password,
      },
    };

    try {
      await fetchData(fetchDataInfo);
      dispatch(toast('success', 'Password successfully reset.'));
      dispatch({ type: LOADING_FINISHED, src: 'resetPassword' });
      dispatch(push(`/login`));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'resetPassword' });
      dispatch(toast('error', e));
    }
  };
