// removes empty values from object eg { empty: undefined }
const removeEmptyFromObj = obj =>
  Object.keys(obj).reduce((acc, key) => {
    if (
      Array.isArray(obj[key]) ||
      ((obj[key] === false || obj[key] || obj[key] === 0) &&
        String(obj[key]).length)
    ) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

export default removeEmptyFromObj;
