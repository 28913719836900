import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { object, bool, func } from 'prop-types';
import cx from 'classnames';

import './DropDown.scss';

// drop down wrapper
const DropDown = forwardRef(
  (
    {
      input,
      output,
      border,
      inline,
      right,
      action,
      blur,
      style,
      customCloseAction,
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      closeAction() {
        changeOpen(false);
      },
    }));

    const [open, changeOpen] = useState(false);
    const outputRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
      if (open) {
        document.addEventListener('click', handleClick);
        action && action();
      } else {
        document.removeEventListener('click', handleClick);
      }
      return function () {
        document.removeEventListener('click', handleClick);
      };
    }, [open]); // eslint-disable-line

    // useEffect(() => {
    // this is annoying but not sure if we need it
    //   changeOpen(false)
    // }, [input])

    const handleClick = ({ target }) => {
      if (inputRef.current.contains(target)) {
        changeOpen(true);
      } else if (!outputRef.current.contains(target)) {
        changeOpen(false);
        blur && blur();
      }
    };

    return (
      <div
        className={cx('drop-down__container', {
          'drop-down__container--inline': inline,
        })}
        style={style}
      >
        <div
          onClick={() => {
            action && action();
            changeOpen(!open);
          }}
          ref={inputRef}
        >
          {input}
        </div>
        <div
          className={cx('drop-down__menu', {
            'drop-down__menu--border': border,
            'drop-down__menu--right': right,
            'drop-down__menu--hidden': !open,
          })}
          ref={outputRef}
          onClick={() => (customCloseAction ? {} : changeOpen(!open))}
        >
          {output}
        </div>
      </div>
    );
  }
);

DropDown.propTypes = {
  input: object,
  dropDown: object,
  border: bool,
  inline: bool,
  right: bool,
  action: func,
  output: object,
  blur: func,
  style: object,
  customCloseAction: bool,
};

export default DropDown;
