import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { projectFetch } from '../actions/projectActions';
import { push } from 'connected-react-router';

const { NOTIFICATIONS_SUCCESS } = constants;

export const getUserNotifications = () => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.NOTIFICATION_API_URL}/${id}`,
    type: 'GET',
  };

  try {
    const notifications = await fetchData(fetchDataInfo);
    dispatch({ type: NOTIFICATIONS_SUCCESS, notifications });
    dispatch(updateSeen());
  } catch (e) {
    dispatch(toast('error', e));
  }
};

const updateSeen = () => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.NOTIFICATION_API_URL}/${id}`,
    type: 'PATCH',
  };

  try {
    await fetchData(fetchDataInfo);
  } catch (e) {
    dispatch(toast('error', e));
  }
};

export const openProjectAndItem = (pId, iId) => async (dispatch) => {
  // maybe do a am i currently active in here
  await dispatch(push(`/project/${pId}/item/${iId}`));
  await dispatch(projectFetch());
};
