import store from '../store.js';
import {
  projectAdminRole,
  projectCollaboratorRole,
  projectGuestRole,
} from '../constants/roles';

// global admins and team leaders can do anything
// none team permissions, generic user actions
const getPermission = (action) => {
  const allRoles = {
    admin: projectAdminRole,
    collaborator: projectCollaboratorRole,
    guest: projectGuestRole,
  };

  const { login, project, teams } = store.getState();

  const { globalAdmin, _id: id } = login.userProfile || {};
  const { members = [] } = project.activeProject;
  const { teamMembers = [], teamLeader = {} } = teams.activeTeam || {};

  // if global can do everything
  if (globalAdmin) {
    return true;
  }

  // if they are the team leader
  if (id === teamLeader._id) {
    return true;
  }

  const teamMember = teamMembers.find((item) => item.user._id === id) || {};

  // if user is a team admin
  const teamMemberLevel = teamMember.teamLevel;

  if (teamMemberLevel === 'teamAdmin') {
    return true;
  }

  const user = members.find((item) => item.user._id === id);

  if (!user) {
    return false;
  }

  // get users member level on project
  const userMemberLevel = user.memberLevel;

  // check their member level exists within the correct schema
  if (allRoles[userMemberLevel].includes(action)) {
    return true;
  }

  return false;
};

export default getPermission;
