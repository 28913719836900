import React, { Fragment, useRef } from 'react';
import { func, object } from 'prop-types';
import DropDown from '../DropDown/DropDown';
import Tag from '../Tag/Tag';
import { tagify } from '../../helpers/tagify';
import { connect } from 'react-redux';
import getPermissions from '../../helpers/getPermissions';
import {
  updateNewTag,
  deleteActiveTag,
  addSingleCurrentTag,
} from '../../actions/itemActions';

import CustomReactSelect from 'react-select/creatable';

import './Tags.scss';

// rounded tags used on filter and tags
// also a create new is avaible
const Tags = ({
  newTag,
  deleteTag,
  dashboard,
  project,
  newSingleTag,
  translations,
}) => {
  const { field__tags_tag } = translations.byKeyTranslations;
  const tagRef = useRef();
  const { _id, tags = [], projectId } = dashboard.activeIssue;
  const canUpdate = getPermissions('item:edit:tags');

  return (
    <Fragment>
      <h5>{field__tags_tag}</h5>

      {canUpdate && (
        <DropDown
          action={() => tagRef.current.focus()}
          inline={true}
          input={<Tag text="+" />}
          output={
            <CustomReactSelect
              options={tagify(project.activeProject.tags)}
              value={tagify(tags)}
              onChange={(value) => newSingleTag(_id, value)}
              isClearable
              isMulti
              menuIsOpen
              onCreateOption={(value) =>
                newTag({
                  id: _id,
                  projectId,
                  newTag: value,
                })
              }
              ref={tagRef}
              styles={{
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          }
        />
      )}

      {tags &&
        tags.map((tag) => (
          <Tag
            key={tag}
            text={tag}
            onClick={canUpdate ? () => deleteTag(_id, tag) : false}
            remove={canUpdate}
          />
        ))}
    </Fragment>
  );
};

Tags.propTypes = {
  dashboard: object,
  project: object,
  newTag: func,
  deleteTag: func,
  newSingleTag: func,
  translations: object,
};

const mapStateToProps = ({ dashboard, project, translations }) => ({
  dashboard,
  project,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  newTag: (data) => dispatch(updateNewTag(data)),
  deleteTag: (id, tag) => dispatch(deleteActiveTag(id, tag)),
  newSingleTag: (id, tags) => dispatch(addSingleCurrentTag(id, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
