import React from 'react';
import RoundBadge from '../../components/UI/Badge/RoundBadge';
import { roles as roleLabels } from '../../constants/labels';
import genUrl from '../../helpers/genUrl';
import { userUrl } from '../../helpers/adminUrls';
import { memberLabels } from '../../constants/labels';
import { get } from 'lodash';
import ReactJson from 'react-json-view';

export const tableHeadIssues = (URL_ID) => [
  {
    accessorKey: '_id',
    header: 'ID',
    cell: (props) => (
      <a href={genUrl(URL_ID, props.row.original._id)}>
        #{props.row.original.itemId}
      </a>
    ),
  },
  {
    accessorKey: 'name',
    cell: (props) => props.getValue(),
    header: 'Name',
  },
  {
    id: 'creator',
    accessorFn: (row) =>
      `${row.creator.firstName} ${row.creator.lastName}` || '-',
    cell: (props) =>
      props.row.original.creator.firstName ? (
        <a href={userUrl(props.row.original.creator._id)}>{props.getValue()}</a>
      ) : (
        '-'
      ),
    header: 'Creator',
  },
  {
    accessorKey: 'status',
    cell: (props) => props.getValue(),
    header: 'Status',
  },
  {
    id: 'assignee',
    accessorFn: (row) =>
      row.assignee ? `${row.assignee.firstName} ${row.assignee.lastName}` : '-',
    header: 'Assignee',
  },
];

export const tableHeadMembers = () => [
  {
    id: 'name',
    accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}` || '-',
    cell: (props) =>
      props.row.original.user.firstName ? (
        <a href={userUrl(props.row.original.user._id)}>{props.getValue()}</a>
      ) : (
        '-'
      ),
    header: 'Name',
  },
  {
    id: 'company',
    accessorFn: (row) => `${row.user.company}` || '-',
    cell: (props) => (props.row.original.user.company ? props.getValue() : '-'),
    header: 'Company',
  },
  {
    id: 'discipline',
    accessorFn: (row) => `${row.user.discipline}` || '-',
    cell: (props) =>
      props.row.original.user.discipline ? roleLabels[props.getValue()] : '-',
    header: 'Discipline',
  },
  {
    id: 'email',
    accessorFn: (row) => `${row.user.email}` || '-',
    cell: (props) => (props.row.original.user.email ? props.getValue() : '-'),
    header: 'Email',
  },
  {
    id: 'role',
    accessorFn: (row) => `${row.memberLevel}` || '-',
    cell: (props) =>
      props.row.original.memberLevel ? (
        <RoundBadge
          value={get(
            memberLabels.find((label) => label.value === props.getValue()),
            'label'
          )}
        />
      ) : (
        '-'
      ),
    header: 'Role',
  },
];

export const tableHeadEvents = () => [
  {
    accessorKey: 'createdAt',
    cell: (props) => props.getValue(),
    header: 'Date',
  },
  {
    accessorKey: 'table',
    cell: (props) => props.getValue(),
    header: 'Table',
  },
  {
    accessorKey: 'method',
    cell: (props) => props.getValue(),
    header: 'Method',
  },
  {
    id: 'user',
    accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}` || '-',
    cell: (props) =>
      props.row.original.user.firstName ? (
        <a href={userUrl(props.row.original.user._id)}>{props.getValue()}</a>
      ) : (
        '-'
      ),
    header: 'Name',
  },
  {
    accessorKey: 'oldVal',
    cell: (props) => (
      <ReactJson
        src={props.getValue()}
        name={false}
        indentWidth={2}
        collapsed
      />
    ),
    header: 'Old Value',
    enableSorting: false,
  },
  {
    accessorKey: 'newVal',
    cell: (props) => (
      <ReactJson
        src={props.getValue()}
        name={false}
        indentWidth={2}
        collapsed
      />
    ),
    header: 'New Value',
    enableSorting: false,
    width: 150,
  },
];
