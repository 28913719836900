import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import TeamSettings from '../../pages/TeamSettings/TeamSettings';
import TeamProjects from '../../pages/TeamProjects/TeamProjects';
import { getTeamDetails, setActiveTeam } from '../../actions/teamsActions';
import TeamMembers from '../../pages/TeamMembers/TeamMembers';
import TeamArchive from '../../pages/TeamArchive/TeamArchive';
import AccountNav from '../../components/AccountNav/AccountNav';
import { flatMap } from 'lodash';
import TeamTemplates from '../../pages/TeamTemplates/TeamTemplates';

import HeroIconInformationCircle from '../../components/HeroIcon/HeroIconInformationCircle';
import HeroIconUserGroup from '../../components/HeroIcon/HeroIconUserGroup';
import HeroIconTemplate from '../../components/HeroIcon/HeroIconTemplate';
import HeroIconCollection from '../../components/HeroIcon/HeroIconCollection';
import HeroIconArchive from '../../components/HeroIcon/HeroIconArchive';

import './Teams.scss';

const defaultTeamMenu = (item, setActive) => [
  {
    url: `#settings_${item._id}`,
    title: item.name,
    component: <TeamSettings />,
    onClick: () => setActive(item),
    isHeader: true,
  },
  {
    url: `#settings_${item._id}`,
    title: 'Details',
    component: <TeamSettings />,
    onClick: () => setActive(item),
    padLeft: true,
    icon: <HeroIconInformationCircle marginRight={10} />,
  },
  {
    url: `#projects_${item._id}`,
    title: 'Projects',
    component: <TeamProjects />,
    onClick: () => setActive(item),
    padLeft: true,
    icon: <HeroIconCollection marginRight={10} />,
  },
  {
    url: `#members_${item._id}`,
    title: 'Members',
    component: <TeamMembers />,
    onClick: () => setActive(item),
    padLeft: true,
    icon: <HeroIconUserGroup marginRight={10} />,
  },
  {
    url: `#archive_${item._id}`,
    title: 'Archived',
    component: <TeamArchive />,
    onClick: () => setActive(item),
    padLeft: true,
    icon: <HeroIconArchive marginRight={10} />,
    permissions: 'team:view:archive',
  },
  {
    url: `#template_${item._id}`,
    title: 'Templates',
    component: <TeamTemplates />,
    onClick: () => setActive(item),
    padLeft: true,
    icon: <HeroIconTemplate marginRight={10} />,
    permissions: 'team:view:archive',
  },
];

// team index route /teams
const Teams = ({ getData, teams, setActive }) => {
  const { myTeams = [], myTeam } = teams;

  // Filter out teams where I am an owner of
  const myTeamsFiltered = myTeams.filter((x) => x.name !== myTeam.name);

  const projectId = window.location.hash
    ? window.location.hash.split('_')[1]
    : '';

  const validId = projectId.length === 24 ? projectId : '';

  useEffect(() => {
    getData(validId);
  }, [validId]); // eslint-disable-line

  // Team I am owner of - there can only ever be 1
  const myTeamArr = defaultTeamMenu(myTeam, setActive);
  // Flatten all teams I am a member of - this should not include if I am owner
  const teamArr = flatMap(myTeamsFiltered, (item) =>
    defaultTeamMenu(item, setActive)
  );
  const mergeTeamData = myTeam._id ? [...myTeamArr, ...teamArr] : teamArr;

  return <AccountNav links={mergeTeamData} />;
};

Teams.propTypes = { teams: object, getData: func, setActive: func };

const mapStateToProps = ({ teams }) => ({
  teams,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (id) => dispatch(getTeamDetails(id)),
  setActive: (data) => dispatch(setActiveTeam(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
