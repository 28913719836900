import NewProjectModal from './NewProjectModal/NewProjectModal';
import NewIssueModal from './NewIssueModal/NewIssueModal';
import NewWorkSpaceModal from './NewWorkSpaceModal/NewWorkSpaceModal';
import FeedbackModal from './FeedbackModal/FeedbackModal';
import PowerBIModal from './PowerBIModal/PowerBIModal';
import AssetViewer from './AssetViewer/AssetViewer';
import DeleteProjectModal from './DeleteProjectModal/DeleteProjectModal';
import PdfModal from './PdfModal/PdfModal';
import CompanySuggestionModal from './CompanySuggestionModal/CompanySuggestionModal';
import ProfilePictureModal from './ProfilePictureModal/ProfilePictureModal';
import AdminTeamCreate from './AdminTeamCreate/AdminTeamCreate';
import NewTeamMembersModal from './NewTeamMembersModal/NewTeamMembersModal';
import DeleteItemsBulkModal from './DeleteItemsBulkModal/DeleteItemsBulkModal';
import ReportModal from './ReportModal/ReportModal';
import ProjectHistoryModal from './ProjectHistoryModal/ProjectHistoryModal';
import AdminTranslateConfirm from './AdminTranslateConfirm/AdminTranslateConfirm';
import UploadsModal from './UploadsModal/UploadsModal';
import AdminTokenCreate from './AdminTokenCreate/AdminTokenCreate';
import UserProjectsModal from './UserProjectsModal/UserProjectsModal';

const modals = {
  NewProjectModal,
  NewIssueModal,
  NewWorkSpaceModal,
  FeedbackModal,
  PowerBIModal,
  AssetViewer,
  DeleteProjectModal,
  PdfModal,
  CompanySuggestionModal,
  ProfilePictureModal,
  AdminTeamCreate,
  NewTeamMembersModal,
  DeleteItemsBulkModal,
  ReportModal,
  ProjectHistoryModal,
  AdminTranslateConfirm,
  UploadsModal,
  AdminTokenCreate,
  UserProjectsModal,
};

export default modals;
