import constants from '../constants';
import userLabel from '../helpers/userLabel';

const {
  TEAMS_SUCCESS,
  TEAMS_FAILURE,
  TEAMS_SET_ACTIVE,
  TEAM_PROJECTS,
  TEAMS_PROJECT_FUZZY_TERM,
  TEAMS_PROJECT_FUZZY_SERCH,
  TEAMS_MEMBER_FUZZY_TERM,
  TEAMS_MEMBER_FUZZY_SEARCH,
  TEAMS_MEMBER_RESET_SEARCH,
  LOGOUT,
  TEAM_ARCHIVE_SUCCESS,
  TEAM_TEMPLATE_SUCCESS,
} = constants;

const initState = {
  activeTeam: {},
  myTeam: {},
  myTeams: [],
  teamProjects: [],
  error: '',
  activeTeamLabels: [],
  fuzzyTerm: '',
  fuzzyTeams: [],
  fuzzyMemberTerm: '',
  fuzzyMembers: [],
  teamArchive: [],
  teamTemplate: [],
};

export const teams = (state = initState, action) => {
  switch (action.type) {
    case TEAMS_SUCCESS:
      return {
        ...state,
        myTeam: action.myTeam,
        myTeams: action.myTeams,
      };
    case TEAMS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case TEAMS_SET_ACTIVE:
      return {
        ...state,
        activeTeam: action.activeTeam,
        activeTeamLabels:
          // move this to the action, shouldnt really be here
          action.activeTeam && action.activeTeam.teamMembers
            ? [
                ...action.activeTeam.teamMembers,
                { user: action.activeTeam.teamLeader },
              ].map(
                ({
                  user: {
                    discipline,
                    company,
                    emailVerified,
                    picture,
                    firstName,
                    lastName,
                    email,
                  },
                  user,
                }) => ({
                  ...userLabel(user),
                  description: `${
                    (discipline && discipline.toUpperCase()) || ' '
                  } - ${company || ' '}`,
                  emailVerified,
                  picture,
                  filterValues: [firstName, lastName, email],
                })
              )
            : [],
      };
    case TEAM_PROJECTS:
      return {
        ...state,
        teamProjects: action.teamProjects,
      };

    case TEAMS_PROJECT_FUZZY_TERM:
      return {
        ...state,
        fuzzyTerm: action.fuzzyTerm,
      };

    case TEAMS_PROJECT_FUZZY_SERCH:
      return {
        ...state,
        fuzzyTeams: action.fuzzyTeams,
      };

    case TEAMS_MEMBER_FUZZY_TERM:
      return {
        ...state,
        fuzzyMemberTerm: action.fuzzyMemberTerm,
      };
    case TEAMS_MEMBER_FUZZY_SEARCH:
      return {
        ...state,
        fuzzyMembers: action.fuzzyMembers,
      };
    case TEAMS_MEMBER_RESET_SEARCH: {
      return {
        ...state,
        fuzzyMemberTerm: '',
        fuzzyMembers: [],
        fuzzyTerm: '',
        fuzzyTeams: [],
      };
    }

    case TEAM_ARCHIVE_SUCCESS: {
      return {
        ...state,
        teamArchive: action.teamArchive,
      };
    }
    case TEAM_TEMPLATE_SUCCESS: {
      return {
        ...state,
        teamTemplate: action.teamTemplate,
      };
    }

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
