import React from 'react';
import { object, func } from 'prop-types';
import Link from '../Link/Link';
import { sendConfirmEmail } from '../../actions/registerActions';
import { connect } from 'react-redux';
import template from 'string-template';

import './VerifyEmail.scss';

// this file blocks other stuff if email isnt verified
const VerifyEmail = ({ confirmEmail, login, translations }) => {
  const {
    verifyEmail_pleaseConfirm_text,
    verifyEmail__currentEmail_text,
    verifyEmail__noEmail_text,
    verifyEmail__resendEmail_link,
  } = translations.byKeyTranslations;
  const { _id, email } = login.userProfile || {};

  return (
    <div className="container__confirm-account">
      <h2>{verifyEmail_pleaseConfirm_text}</h2>

      <p>
        {template(verifyEmail__currentEmail_text, { email })}{' '}
        <Link to="/profile#account" inline bright>
          My Account
        </Link>
        .
      </p>

      <p>
        {verifyEmail__noEmail_text}{' '}
        <Link inline bright onClick={() => confirmEmail(_id)}>
          {verifyEmail__resendEmail_link}
        </Link>
        .
      </p>
    </div>
  );
};

VerifyEmail.propTypes = {
  login: object,
  confirmEmail: func,
  translations: object,
};

const mapStateToProps = ({ login, translations }) => ({
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  confirmEmail: (id) => dispatch(sendConfirmEmail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
