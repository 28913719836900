import React from 'react';
import { string, bool, func } from 'prop-types';
import cx from 'classnames';
import correctIcon from '../../helpers/correctIcon';

import './ThumbNail.scss';

// image thumb nail, used on comments and other items
const ThumbNail = ({
  directUrl,
  url,
  name,
  noLink,
  flex,
  noPadding,
  onClick,
  darkBorder,
  large,
  deleteIcon,
  active,
}) => (
  /* eslint-disable */
  <a
    className={cx('thumb-nail__container', {
      'thumb-nail__container--flex': flex,
      'thumb-nail__container--no-padding': noPadding,
      'thumb-nail__container--click': onClick,
      'thumb-nail__container--dark-border': darkBorder,
      'thumb-nail__container--large': large,
      'thumb-nail__container--delete-icon': deleteIcon,
      'thumb-nail__container--active': active,
    })}
    href={!noLink ? url : null}
    target="_blank"
    rel="noopener noreferrer"
    title={name}
    onClick={onClick ? onClick : null}
  >
    <div
      style={{
        backgroundImage: `url("${directUrl ? directUrl : correctIcon(url)}")`,
      }}
      className="thumb-nail__item"
    />
  </a>
  /* eslint-enable */
);

ThumbNail.propTypes = {
  directUrl: string,
  url: string,
  name: string,
  noLink: bool,
  noPadding: bool,
  onClick: func,
  darkBorder: bool,
  flex: bool,
  large: bool,
  deleteIcon: bool,
  active: bool,
};

export default ThumbNail;
