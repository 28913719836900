import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button from '../../components/Button/Button';
import { getTeamProjects } from '../../actions/teamsActions';
import { openModal } from '../../actions/modalActions';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import { setActiveProject, resetProject } from '../../actions/projectActions';
import format from 'date-fns/format';

import FilterTable from '../../components/UI/Table/FilterTable';

import './TeamProjects.scss';

const modal = {
  heading: 'Create New Project',
  contents: 'NewProjectModal',
};

// team projects route /teams
const TeamProjects = ({ teams, getProjects, open, loading, reset }) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <a href={`/project/${props.row.original._id}`} onClick={() => reset()}>
          {props.getValue()}
        </a>
      ),
      header: 'Name',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'description',
      cell: (props) => props.getValue(),
      header: 'Description',
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'memberCount',
      cell: (props) => props.getValue(),
      header: 'Members',
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'itemCount',
      cell: (props) => (props.getValue() === -1 ? 0 : props.getValue()),
      header: 'Items',
      enableGlobalFilter: false,
    },
    {
      accessorKey: 'projectLastModifed',
      cell: (props) =>
        props.getValue() == null
          ? '-'
          : format(Date.parse(props.getValue()), 'dd-MM-yyyy'),
      header: 'Last Modified',
      enableGlobalFilter: false,
    },
  ];

  const { activeTeam = {} } = teams;
  const projects = !teams.fuzzyTeams
    ? []
    : teams.fuzzyTeams.length === 0
    ? teams.teamProjects
    : teams.fuzzyTeams;

  useEffect(() => {
    if (activeTeam._id) {
      getProjects(activeTeam._id);
    }
  }, [activeTeam._id]); // eslint-disable-line

  return (
    <div>
      <div className="team-project__title">
        <h1>{activeTeam.name} Projects</h1>

        {getTeamPermissions('team:project:new') && (
          <Button onClick={() => open(modal)}>Create Project</Button>
        )}
      </div>

      <FilterTable
        defaultData={projects}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search projects..."
        loading={loading.loading}
        filterColumn="name"
      />
    </div>
  );
};

TeamProjects.propTypes = {
  teams: object,
  getProjects: func,
  open: func,
  loading: object,
  setActive: func,
  reset: func,
};

const mapStateToProps = ({ teams, loading }) => ({ teams, loading });

const mapDispatchToProps = (dispatch) => ({
  getProjects: (id) => dispatch(getTeamProjects(id)),
  open: (data) => dispatch(openModal(data)),
  setActive: (project) => dispatch(setActiveProject(project)),
  reset: () => dispatch(resetProject()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamProjects);
