import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { object, func, bool } from 'prop-types';
import { formatOptionLabelNoPicture } from '../../helpers/selectProfile';
import { memberLabels } from '../../constants/labels';
import { updateSingleRecord } from '../../actions/editProjectActions';
import { orderBy } from 'lodash';
import MemberEditTable from '../MemberEditTable/MemberEditTable';
import getPermissions from '../../helpers/getPermissions';
import Button from '../Button/Button';
import Link from '../Link/Link';
import { closeModal } from '../../actions/modalActions';

import ReactSelect, { createFilter } from 'react-select';

import './EditProjectSectionMembers.scss';
import { useEffect } from 'react';

const getOptionValue = (option) => {
  if (option.filterValues && option.filterValues.length) {
    return option.filterValues.join(', ');
  }
  return option.value;
};

// project members section
const EditProjectSectionMembers = ({
  editProject,
  update: memberUpdate,
  loading,
  teams,
  close,
  translations,
}) => {
  const {
    editProject__addTeamMember_input,
    editProject__addTeamMemberRole_input,
    editProject__teamHelper_text,
    editProject__teamAdd_button,
  } = translations.byKeyTranslations;
  const guest = { value: 'guest', label: 'Guest' };
  const selectRef = useRef();
  const roleRef = useRef();
  const [newMember, setNewMember] = useState(null);
  const [newRole, setNewRole] = useState(guest);

  const { activeTeamLabels, activeTeam = {} } = teams;

  const addMemberOptons = [...activeTeamLabels];

  const { members = [] } = editProject;

  const people = members || [];
  const selectedMembersIds = people ? people.map((item) => item.user._id) : [];

  const [projMembers, setProjMembers] = useState(people);
  const [editUser, setEditUser] = useState({});

  // const adminList = people.filter(
  //   (item) => item.memberLevel === membersLevels.admin
  // );

  // const collabList = people.filter(
  //   (item) => item.memberLevel === membersLevels.collaborator
  // );

  // const guestList = people.filter(
  //   (item) => item.memberLevel === membersLevels.guest
  // );

  useEffect(() => {
    if (Object.keys(editUser).length > 0) {
      // console.log({ editUser });
      if (editUser.action === 'update') {
        tableAction(editUser.value, editUser.id, projMembers);
      }
      if (editUser.action === 'delete') {
        deleteAction(editUser.id, projMembers);
      }
    }
  }, [editUser]); // eslint-disable-line

  const update = memberUpdate;

  const tableAction = (value, userId, projectMembers) => {
    // console.log(1, projectMembers);
    const index = projectMembers.findIndex((item) => item.user._id === userId);
    projectMembers[index].memberLevel = value;
    setProjMembers(projectMembers);
    // console.log(2, projectMembers);
    update({
      field: 'members',
      value: orderBy(projectMembers, 'user.firstName'),
    });
  };

  const deleteAction = (id, projectMembers) => {
    // console.log(projectMembers);
    const projMembers = projectMembers.filter((item) => item.user._id !== id);
    setProjMembers(projMembers);
    update({
      field: 'members',
      value: projMembers,
    });
  };

  const onMemberAdd = () => {
    const { value, filterValues, description, emailVerified } = newMember;
    const newMemberList = [
      ...people,
      {
        user: {
          _id: value,
          firstName: filterValues[0],
          lastName: filterValues[1],
          discipline: description.split(' - ')[0].trim(),
          company: description.split(' - ')[1].trim(),
          email: filterValues[2],
          emailVerified,
        },
        memberLevel: newRole.value,
        emailVerified,
      },
    ];

    setProjMembers(newMemberList);

    update({
      field: 'members',
      value: newMemberList,
    });

    setNewMember(null);
    setNewRole(guest);
    // selectRef.current.select.state.value = null;
    // roleRef.current.select.state.value = guest;
  };

  return (
    <div className="project-edit__member-container px-0.5">
      {getPermissions('project:edit:members') && (
        <div>
          <div className="edit-members__add-new">
            <div className="edit-members__add-new--member">
              <h5>{editProject__addTeamMember_input}</h5>
              <div className="pt-4">
                <ReactSelect
                  value={newMember}
                  defaultValue={undefined}
                  getOptionValue={getOptionValue}
                  placeholder="Select members"
                  options={addMemberOptons}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      background: '#fafbfc',
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      outline: state.isFocused
                        ? '#66ccff solid 2px !important'
                        : 'none',
                      '*': {
                        boxShadow: 'none !important',
                      },
                      '&:hover': {
                        borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      },
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected
                        ? '#9a9fa7'
                        : state.isFocused
                        ? '#f1f5f9'
                        : base.backgroundColor,
                      '&:hover': {
                        backgroundColor: state.isSelected
                          ? '#9a9fa7'
                          : '#f1f5f9',
                      },
                    }),
                  }}
                  formatOptionLabel={(vals, context) =>
                    formatOptionLabelNoPicture(
                      vals,
                      context,
                      selectedMembersIds
                    )
                  }
                  filterOption={createFilter({
                    ignoreAccents: false,
                  })} // meant to improve perf
                  isOptionDisabled={({ value }) =>
                    selectedMembersIds.includes(value)
                  }
                  onChange={(val) => setNewMember(val)}
                  ref={selectRef}
                />
              </div>
            </div>
            <div className="edit-members__add-new--role">
              <h5>{editProject__addTeamMemberRole_input}</h5>
              <div className="pt-4">
                <ReactSelect
                  isSearchable={false}
                  options={memberLabels}
                  defaultValue={newRole}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      background: '#fafbfc',
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      outline: state.isFocused
                        ? '#66ccff solid 2px !important'
                        : 'none',
                      '*': {
                        boxShadow: 'none !important',
                      },
                      '&:hover': {
                        borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      },
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected
                        ? '#9a9fa7'
                        : state.isFocused
                        ? '#f1f5f9'
                        : base.backgroundColor,
                      '&:hover': {
                        backgroundColor: state.isSelected
                          ? '#9a9fa7'
                          : '#f1f5f9',
                      },
                    }),
                  }}
                  onChange={(val) => setNewRole(val)}
                  ref={roleRef}
                />
              </div>
            </div>
            <div className="edit-members__add-new--button">
              <Button
                onClick={onMemberAdd}
                disabled={!newMember || !(newMember && newMember.value)}
              >
                {editProject__teamAdd_button || ''}
              </Button>
            </div>
          </div>
          <p>
            {editProject__teamHelper_text}{' '}
            <Link
              to={`/teams#members_${activeTeam._id}`}
              inline
              bright
              onClick={close}
            >
              Team Settings
            </Link>
            .
          </p>
        </div>
      )}

      <MemberEditTable
        // data={people}
        data={projMembers || []}
        setEditUser={setEditUser}
        action={tableAction}
        deleteAction={
          getPermissions('project:edit:members') ? deleteAction : false
        }
        title="Project Members"
        loading={loading.loading}
        showUserProjects={false}
      />

      <br />
    </div>
  );
};

EditProjectSectionMembers.propTypes = {
  update: func,
  project: object,
  error: func,
  team: bool,
  editProject: object,
  loading: object,
  teams: object,
  close: func,
  translations: object,
};

const mapStateToProps = (props) => {
  // console.log({ props });
  return {
    editProject: props.editProject,
    teams: props.teams,
    loading: props.loading,
    translations: props.translations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateSingleRecord(data)),
  close: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProjectSectionMembers);
