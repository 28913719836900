import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import {
  adminProjectsFetch,
  projectsFuzzy,
} from '../../actions/adminProjectsActions';
import AdminContainer from '../../components/AdminContainer/AdminContainer';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminProjects.scss';

// admin route /admin/projects
const AdminProjects = ({
  adminProjects,
  fetchProjects,
  // resetSearch,
  loading,
}) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <a href={`/admin/projects/${props.row.original._id}`}>
          {props.getValue()}
        </a>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'description',
      cell: (props) => props.getValue(),
      header: 'Description',
    },
  ];

  useEffect(() => {
    fetchProjects();

    // return () => {
    //   // on unmount reset the search
    //   resetSearch();
    // };
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <h1>Admin Projects</h1>

      <FilterTable
        defaultData={adminProjects.projects}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search projects..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminProjects.propTypes = {
  fetchProjects: func,
  adminProjects: object,
  resetSearch: func,
  loading: object,
};

const mapStateToProps = ({ adminProjects, loading }) => ({
  adminProjects,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(adminProjectsFetch()),
  resetSearch: () => dispatch(projectsFuzzy('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjects);
