import React, { Fragment, useState, useEffect } from 'react';
import { array } from 'prop-types';
import TopNav from '../TopNav/TopNav';
import Modal from '../Modal/Modal';
import cx from 'classnames';
import { get } from 'lodash';
import getTeamPermissions from '../../helpers/getTeamPermissions';

import './AccountNav.scss';

const AccountNav = ({ links }) => {
  const getPage = () =>
    window.location.hash ? window.location.hash : get(links, '[0].url');

  const [open, setOpen] = useState(getPage());

  useEffect(() => {
    setOpen(getPage());
  }, [links]); // eslint-disable-line

  const contentToRender = links.find((item) => item.url === open) || {};

  return (
    <Fragment>
      <TopNav />
      <Modal />
      <div className="account-nav__container">
        <div className="account-nav__left">
          {links.map(
            ({
              url,
              title,
              isHeader,
              onClick,
              padLeft,
              icon,
              permissions = false,
            }) =>
              (!permissions || getTeamPermissions(permissions)) && (
                <div
                  key={title + url}
                  onClick={() => {
                    if (!isHeader) {
                      window.location.hash = url;
                      setOpen(url);
                      onClick && onClick();
                    }
                  }}
                  className={cx('account-nav__item', {
                    'account-nav__item--active': open === url && !isHeader,
                    'account-nav__item--header': isHeader,
                    'account-nav__item--pad-left': padLeft,
                  })}
                >
                  {icon && icon}
                  {title}
                </div>
              )
          )}
        </div>
        <div className="account-nav-right">{contentToRender.component}</div>
      </div>
    </Fragment>
  );
};

AccountNav.propTypes = {
  links: array,
};

export default AccountNav;
