import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { createNewIssue } from '../../actions/createNewIssuesAction';
import { priorityLabel, statusLabel } from '../../constants/labels';
import { imageAttachments } from '../../constants/labels';
import { selectProfile } from '../../helpers/selectProfile';
import userLabel from '../../helpers/userLabel';
import Description from '../../components/Description/Description';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect, { createFilter } from 'react-select';

import DatePicker from '../../components/UI/DateInput/DatePicker';

import './NewIssueModal.scss';

const NewIssueModal = ({ postNew, project, translations }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [submittingForm, setSubmittingForm] = useState(false);
  const [formData, setFormData] = useState(null);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [priority, setPriority] = useState();
  const [assignee, setAssignee] = useState();
  const [status, setStatus] = useState();

  const [attachmentFile, setAttachmentFile] = useState(null);
  let [dueDate, setDueDate] = useState(null);

  const {
    field__title_tag,
    field__priority_tag,
    field__status_tag,
    field__assignee_tag,
  } = translations.byKeyTranslations;
  const { members = [] } = project.activeProject;

  const projectUsers = members.map(
    ({ user: { discipline, company, emailVerified, picture }, user }) => ({
      ...userLabel(user),
      description: `${(discipline && discipline.toUpperCase()) || ' '} - ${
        company || ' '
      }`,
      emailVerified,
      picture,
    })
  );

  const handleFileInput = (e) => {
    // handle validations
    setAttachmentFile(e.target);
    // file = e.target.files[0];
  };

  useEffect(() => {
    setFormData({
      name: name || '',
      description: description || '',
      dueDate: dueDate || '',
      priority: priority || { value: 'Normal', label: 'Normal' },
      assignee: assignee || {},
      status: status || { value: 'Open', label: 'Open' },
    });
  }, [setFormData, name, description, dueDate, assignee, status, priority]);

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <div className="max-h-[70vh] overflow-y-auto custom-scroll pr-2 pl-0.5">
      <form
        onSubmit={handleSubmit((x) => {
          postNew(formData, attachmentFile);
        })}
        className="pt-5"
      >
        <h5>
          {field__title_tag}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('name', {
            required: ' Title is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
            onChange: (e) => {
              setName(e.target.value);
            },
          })}
          defaultValue=""
          type="text"
        />
        <div>
          <Description
            callback={(txt) => setDescription(txt)}
            formLabel={true}
            blankStart={true}
          />
        </div>
        <h5>
          Due Date
          <ErrorMessage
            errors={errors}
            name="dueDate"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>

        <DatePicker
          value={dueDate}
          setValue={setDueDate}
          formatString="yyyy-MM-dd"
        />

        <h5>
          {field__priority_tag}
          <ErrorMessage
            errors={errors}
            name="priority"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="priority"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...register('priority', {
                onChange: (e) => {
                  setPriority(e.target.value);
                },
              })}
              {...field}
              value={priority || null}
              options={priorityLabel}
              isClearable={false}
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              {...selectProfile}
              filterOption={createFilter({
                ignoreAccents: false,
                ignoreCase: true,
                trim: true,
              })} // meant to improve perf
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        <h5>
          {field__assignee_tag}
          <ErrorMessage
            errors={errors}
            name="assignee"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="assignee"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...register('assignee', {
                onChange: (e) => {
                  setAssignee(e.target.value);
                },
              })}
              {...field}
              value={assignee || null}
              options={projectUsers}
              isClearable={false}
              isSearchable={true}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              {...selectProfile}
              filterOption={createFilter({
                ignoreAccents: false,
                ignoreCase: true,
                trim: true,
              })} // meant to improve perf
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
              {...selectProfile()}
            />
          )}
        />

        <h5>
          {field__status_tag}
          <ErrorMessage
            errors={errors}
            name="status"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...register('status', {
                onChange: (e) => {
                  setStatus(e.target.value);
                },
              })}
              {...field}
              value={status || null}
              options={statusLabel}
              // defaultValue={{ value: 'Open', label: 'Open' }}
              isClearable={false}
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              {...selectProfile}
              filterOption={createFilter({
                ignoreAccents: false,
                ignoreCase: true,
                trim: true,
              })} // meant to improve perf
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        <h5>
          Cover Image
          <ErrorMessage
            errors={errors}
            name="coverImage"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <div>
          <input
            type="file"
            accept={imageAttachments}
            onChange={handleFileInput}
            className="block w-full my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
          />
        </div>

        <div className="mt-6">
          <ButtonGroup>
            <Button loading={submittingForm}>Save</Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
};

NewIssueModal.propTypes = {
  login: object,
  postNew: func,
  projectId: string,
  project: object,
  translations: object,
};

const mapStateToProps = ({ login, project, translations }) => ({
  login,
  project,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postNew: (data, ref) => dispatch(createNewIssue(data, ref)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewIssueModal);
