import { format } from 'date-fns';

// 21/09/1990 date format
const genericYearDate = (date) =>
  date && format(Date.parse(date), 'dd/MM/yyyy');

// 18:15 21/09/1990 date format
const genericTimeYearDate = (date) =>
  date && format(Date.parse(date), 'HH:mm dd/MM/yyyy');

export { genericYearDate, genericTimeYearDate };
