// generate user labels and ensures they have a full name or use email
const userLabel = data => {
  const notNullData = data ? data : {};
  const { firstName, lastName, email, _id } = notNullData;
  return {
    label: firstName && lastName ? `${firstName} ${lastName}` : email || '-',
    value: _id,
  };
};

// same as above but text only
export const userLabelText = data => {
  const notNullData = data ? data : {};
  const { firstName, lastName, email } = notNullData;
  return firstName && lastName ? `${firstName} ${lastName}` : email || '-';
};

export default userLabel;
