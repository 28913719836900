// ENV config object

const dev = {
  API_BASE_URL: 'http://localhost:3030',
  IMAGE_PATH: 'http://localhost:9000/bcapp-dev-assets',
  MSAL_AUTH_CLIENTID: 'f75cdc09-3697-49e1-94c8-1fd6e2c9c7fb',
  MSAL_AUTH_REDIRECT: 'http://localhost:3000/login',
  MSAL_AUTH_AUTHORITY:
    'https://login.microsoftonline.com/8d9d8150-428e-419b-b2f7-ffcc9cb542a3',
  FAST_REFRESH: true,
};

const staging = {
  API_BASE_URL: 'https://bcapp-server-test.azurewebsites.net',
  IMAGE_PATH: 'https://s3-ap-southeast-2.amazonaws.com/bcapp-staging-assets',
  MSAL_AUTH_CLIENTID: '38a44232-4f13-4f19-91b6-84e4d8e79d88',
  MSAL_AUTH_REDIRECT: 'https://zealous-sea-05845aa00.azurestaticapps.net/login',
  MSAL_AUTH_AUTHORITY:
    'https://login.microsoftonline.com/8d9d8150-428e-419b-b2f7-ffcc9cb542a3',
};

const prod = {
  API_BASE_URL: 'https://bcapp-server-production.azurewebsites.net',
  IMAGE_PATH: 'https://s3-ap-southeast-2.amazonaws.com/bcapp-production-assets',
  MSAL_AUTH_CLIENTID: 'fb483373-2d31-4fe5-ba28-e8a847211841',
  MSAL_AUTH_REDIRECT: 'https://bcapp.com.au/login',
  MSAL_AUTH_AUTHORITY:
    'https://login.microsoftonline.com/8d9d8150-428e-419b-b2f7-ffcc9cb542a3',
};

const getConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return prod;
    case 'staging':
      return staging;
    default:
      return dev;
  }
};

const config = getConfig();

export default config;
