import React, { Fragment, useState, useRef, useEffect } from 'react';
import Link from '../Link/Link';
import CheckBox from 'react-animated-checkbox';
import IssueDocumentInfoAssignee from '../IssueDocumentInfo/IssueDocumentInfoAssignee';
import IssueDocumentInfoDueDate from '../IssueDocumentInfo/IssueDocumentInfoDueDate';
import { connect } from 'react-redux';
import { func, object, string, number } from 'prop-types';
import {
  addNewTask,
  updateTask,
  deleteSubTask,
  sortTasks,
} from '../../actions/itemActions';
import getPermissions from '../../helpers/getPermissions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { orderBy } from 'lodash';

import HeroIconTrash from '../HeroIcon/HeroIconTrash';

import './SubTasks.scss';

const ChildInput = ({
  index,
  itemOpen,
  setItemOpen,
  item,
  updateDone,
  _id,
  translations,
}) => {
  const [editValue, setEditValue] = useState(item.title);

  const { subTask__newTaskEmpty__text } = translations;
  const [defaultVal, setDefaultVal] = useState('');
  const ref = useRef();

  useEffect(() => {
    ref.current && ref.current.focus();
  }, [ref.current, itemOpen]); // eslint-disable-line

  return (
    <div
      onClick={() => {
        if (getPermissions('subtask:edit')) {
          setItemOpen(index);
          setEditValue(item.title);
          setDefaultVal(item.title);
        }
      }}
      style={{ flex: 1 }}
    >
      {itemOpen === index ? (
        <input
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="subtasks__child--input"
          onBlur={() => {
            if (defaultVal !== editValue) {
              updateDone(_id, item._id, { title: editValue });
            }
            setEditValue('');
            setItemOpen(-1);
          }}
          onClick={(e) => e.stopPropagation()} // stop parent click closing it
          ref={ref}
        />
      ) : (
        <p className="subtasks__child--title">
          {item.title.length > 0 ? item.title : subTask__newTaskEmpty__text}
        </p>
      )}
    </div>
  );
};

ChildInput.propTypes = {
  index: number,
  itemOpen: number,
  editValue: string,
  setEditValue: func,
  setItemOpen: func,
  item: object,
  updateDone: func,
  _id: string,
  translations: object,
};

const SubTasks = ({
  newTask,
  dashboard,
  updateDone,
  deleteTask,
  saveSortOrder,
  translations,
}) => {
  const { subTask__newTask__button, subTask__title__text } =
    translations.byKeyTranslations;
  const [itemOpen, setItemOpen] = useState(-1);
  const { _id, subTasks = [] } = dashboard.activeIssue;

  const [sortTasks, setSortTasks] = useState([]);

  useEffect(() => {
    setSortTasks(orderBy(subTasks, 'order'));
  }, [subTasks]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = ({ destination, source }, data) => {
    if (!destination) {
      return false;
    }
    const sort = reorder(data, source.index, destination.index);
    setSortTasks(sort);
    saveSortOrder(_id, sort);
  };

  return (
    <Fragment>
      <div className="issue__attachment--add">
        <h5>{subTask__title__text}</h5>

        {getPermissions('subtask:create') && (
          <Link onClick={() => newTask(_id)} bright>
            {subTask__newTask__button}
          </Link>
        )}
      </div>

      <div className="subtasks__container">
        <DragDropContext onDragEnd={(data) => onDragEnd(data, sortTasks)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortTasks.map((item, index) => (
                  <Draggable
                    key={'subs' + item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="subtasks__child" key={'subs' + index}>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <CheckBox
                                checked={item.done}
                                checkBoxStyle={{
                                  checkedColor: '#2abb59',
                                  size: 18,
                                  unCheckedColor: '#b8b8b8',
                                }}
                                duration={400}
                                onClick={() =>
                                  getPermissions('subtask:edit')
                                    ? updateDone(_id, item._id, {
                                        done: !item.done,
                                      })
                                    : {}
                                }
                              />
                            </div>
                            <ChildInput
                              index={index}
                              itemOpen={itemOpen}
                              setItemOpen={setItemOpen}
                              item={item}
                              updateDone={updateDone}
                              _id={_id}
                              translations={translations.byKeyTranslations}
                            />
                          </div>
                          <div style={{ maxWidth: '180px' }}>
                            <div className="subtasks__child--cell">
                              <IssueDocumentInfoDueDate
                                hideLabel={true}
                                dueTask={{
                                  _id,
                                  childId: item._id,
                                  dueDate: item.dueDate,
                                }}
                              />
                            </div>
                            <div className="subtasks__child--cell">
                              <IssueDocumentInfoAssignee
                                hideLabel={true}
                                dueAssignee={{
                                  _id,
                                  childId: item._id,
                                  assignee: item.assignee,
                                }}
                              />
                            </div>
                          </div>
                          {getPermissions('subtask:delete') && (
                            <div
                              className="subTask__delete"
                              onClick={() => deleteTask(_id, item._id)}
                            >
                              <HeroIconTrash />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Fragment>
  );
};

SubTasks.propTypes = {
  newTask: func,
  dashboard: object,
  updateDone: func,
  deleteTask: func,
  saveSortOrder: func,
  translations: object,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  newTask: (_id) => dispatch(addNewTask(_id)),
  updateDone: (parent, child, value) =>
    dispatch(updateTask(parent, child, value)),
  deleteTask: (parent, child) => dispatch(deleteSubTask(parent, child)),
  saveSortOrder: (id, data) => dispatch(sortTasks(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubTasks);
