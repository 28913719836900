import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { push } from 'connected-react-router';
import genUrl from '../helpers/genUrl';
import { filterDashboardMany } from '../actions/dashboardActions';

const {
  LOADING_REQUEST,
  LOADING_FINISHED,
  PROJECT_NAV_SET_ACTIVE,
  MODAL_CLOSE,
  PROJECT_NAV_WORKSPACE,
  ISSUES_UPDATE_FILTER,
  WORKSPACE_EDIT_CLEAR,
} = constants;

const { SUB_DOC_UPDATE, SUB_DOC_PATCH, SUB_DOC_DELETE, WORKSPACE_SORT } = api;

export const setActiveWorkspace =
  (id, activeWorkspaceIssues = null) =>
  async (dispatch) => {
    dispatch({ type: PROJECT_NAV_WORKSPACE, id, activeWorkspaceIssues });
  };

export const setActiveWorkspaceOnly =
  (id, filters, filteredIssues = [], clearUrl = false) =>
  async (dispatch, getState) => {
    if (clearUrl) {
      const { activeProject } = getState().project;
      dispatch(push(genUrl(activeProject._id)));
    }
    dispatch(setActiveWorkspace(id, filteredIssues));
    dispatch({ type: ISSUES_UPDATE_FILTER, filters });
  };

export const setActiveWorkspaceAndFilter =
  (id, filters, filteredIssues = [], clearUrl = false) =>
  async (dispatch, getState) => {
    if (clearUrl) {
      const { activeProject } = getState().project;
      dispatch(push(genUrl(activeProject._id)));
    }
    dispatch(setActiveWorkspace(id, filteredIssues));
    dispatch({ type: ISSUES_UPDATE_FILTER, filters });
    dispatch(filterDashboardMany());
  };

export const addNewWorkspace = () => async (dispatch, getState) => {
  const { login, project, editWorkspace } = getState();
  const { id: uId } = login.user;
  const projectId = project.activeProject._id;
  const { name, publicToggle, filters, items } = editWorkspace;

  try {
    dispatch({ type: LOADING_REQUEST, src: 'addNewWorkspace' });

    const data = {
      name,
      items,
      filters,
      owner: publicToggle ? null : uId,
    };

    if (!name) {
      dispatch({ type: LOADING_FINISHED, src: 'addNewWorkspace' });
      return dispatch(toast('error', 'Workspace needs a name'));
    }

    const newWorkSpaceConfig = {
      url: `${SUB_DOC_UPDATE}/${projectId}`,
      type: 'PATCH',
      body: {
        data,
        target: 'workSpaces',
        table: 'projects',
      },
    };

    const project = await fetchData(newWorkSpaceConfig);
    dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject: project });
    dispatch({ type: LOADING_FINISHED, src: 'addNewWorkspace' });
    dispatch({ type: WORKSPACE_EDIT_CLEAR });
    dispatch({ type: MODAL_CLOSE });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'addNewWorkspace' });
    dispatch(toast('error', e));
  }
};

export const updateSingleWorkspace =
  (childId) => async (dispatch, getState) => {
    const { login, project, editWorkspace } = getState();
    const { id } = login.user;
    const projectId = project.activeProject._id;
    const { name, publicToggle, filters, items } = editWorkspace;

    try {
      dispatch({ type: LOADING_REQUEST, src: 'updateSingleWorkspace' });

      const data = {
        name,
        items,
        filters,
        owner: publicToggle ? null : id,
      };

      if (!name) {
        delete data.name;
      }

      if (!items) {
        delete data.items;
      }

      if (publicToggle === null) {
        delete data.owner;
      }

      const updateWorkSpace = {
        url: `${SUB_DOC_PATCH}/${projectId}`,
        type: 'PATCH',
        body: {
          data,
          target: 'workSpaces',
          childId,
          table: 'projects',
        },
      };

      const project = await fetchData(updateWorkSpace);
      dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject: project });
      dispatch(setActiveWorkspaceAndFilter(childId, filters, items));
      dispatch({ type: LOADING_FINISHED, src: 'updateSingleWorkspace' });
      dispatch({ type: WORKSPACE_EDIT_CLEAR });

      dispatch({ type: MODAL_CLOSE });
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'updateSingleWorkspace' });
      dispatch(toast('error', e));
    }
  };

export const deleteWorkSpace = (childTarget) => async (dispatch, getState) => {
  try {
    const projectId = getState().project.activeProject._id;

    dispatch({ type: LOADING_REQUEST, src: 'deleteWorkSpace' });

    const newWorkSpaceConfig = {
      url: `${SUB_DOC_DELETE}/${projectId}`,
      type: 'PATCH',
      body: {
        childTarget,
        table: 'projects',
        target: 'workSpaces',
        idTarget: true,
      },
    };

    const project = await fetchData(newWorkSpaceConfig);
    dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject: project });
    dispatch({ type: LOADING_FINISHED, src: 'deleteWorkSpace' });
    dispatch({ type: MODAL_CLOSE });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteWorkSpace' });
    dispatch(toast('error', e));
  }
};

export const sortWorkSpace = (data) => async (dispatch, getState) => {
  try {
    const projectId = getState().project.activeProject._id;

    const newWorkSpaceConfig = {
      url: `${WORKSPACE_SORT}/${projectId}`,
      type: 'PATCH',
      body: {
        data,
      },
    };

    const project = await fetchData(newWorkSpaceConfig);
    dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject: project });
  } catch (e) {
    dispatch(toast('error', e));
  }
};
