import React from 'react';
import { string, oneOfType, arrayOf, node, func, bool } from 'prop-types';
import cx from 'classnames';

import './Label.scss';

const getLabelColor = (type) => {
  switch (type) {
    case 'Low':
      return 'label__container--ok';
    case 'Normal':
      return 'label__container--warn';
    case 'High':
      return 'label__container--danger';
    default:
      return 'label__container--ok';
  }
};

// small label
const Label = ({ text, children, onClick, hover, danger, warn }) => (
  <div
    className={cx('label__container', getLabelColor(children || text), {
      'label__container--click': onClick || hover,
      'label__container--warnMessage': warn,
      'label__container--danger': danger,
    })}
    onClick={onClick}
  >
    {children ? children : text}
  </div>
);

Label.propTypes = {
  text: string,
  children: oneOfType([arrayOf(node), node]),
  onClick: func,
  hover: bool,
  danger: bool,
  warn: bool,
};

export default Label;
