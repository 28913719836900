import React from 'react';
import { object } from 'prop-types';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import { connect } from 'react-redux';
import template from 'string-template';

import './CompanySuggestionModal.scss';

// ccompany suggestion model, this could be nulled if there is 0 reccomdned
const CompanySuggestionModal = ({ data, translations }) => {
  const { companySuggest__info_text, companySuggest__chooseCorrect_button } =
    translations.byKeyTranslations;
  const { action, list, newCompany } = data;

  return (
    <div>
      <p>{companySuggest__info_text}</p>

      <div className="company-suggestion__list">
        {list.map((item) => (
          <Link dark key={item} onClick={() => action(item)}>
            {item}
          </Link>
        ))}
      </div>

      <Button onClick={() => action()}>
        {template(companySuggest__chooseCorrect_button, { newCompany })}
      </Button>

      <br />
      <br />
    </div>
  );
};

CompanySuggestionModal.propTypes = {
  data: object,
  translations: object,
};

const mapStateToProps = ({ translations }) => ({
  translations,
});

export default connect(mapStateToProps, null)(CompanySuggestionModal);
