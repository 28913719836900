import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func, object, oneOfType } from 'prop-types';
import EditProjectSection from '../../components/EditProjectSection/EditProjectSection';
import EditProjectSectionMembers from '../../components/EditProjectSectionMembers/EditProjectSectionMembers';
import Button from '../../components/Button/Button';
import {
  loadCurrentProject,
  clearProjectEdit,
} from '../../actions/editProjectActions';
import { updateProject } from '../../actions/createNewProjectActions';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import { fetchTemplates } from '../../actions/teamsActions';

import Tabs from '../../components/UI/Tabs/Tabs';

import './NewProjectModal.scss';

// new project modal from multipul places
const NewProjectModal = ({
  editMode,
  newProject,
  loadProject,
  saveProject,
  loading,
  data,
  loadTemplates,
  project,
}) => {
  const { template } = data;
  const [selectTab, setSelectTab] = useState(0);

  useEffect(() => {
    if (editMode) {
      loadProject();
    } else {
      newProject();
      loadTemplates();
    }
  }, []); // eslint-disable-line

  const settings = {
    label: 'Project Settings',
    content: <EditProjectSection editMode={editMode} />,
  };

  const members = {
    label: 'Project Members',
    content: <EditProjectSectionMembers editMode={editMode} />,
  };

  const modalBody =
    template || project.activeProject.template ? (
      <EditProjectSection editMode={editMode} template={true} />
    ) : (
      <Tabs
        tabs={[settings, members]}
        selected={selectTab}
        onSelect={(tab, index) => setSelectTab(index)}
      />
    );

  const modalFooter = (
    <Button onClick={() => saveProject(template)} loading={loading.loading}>
      Save Project
    </Button>
  );

  return <ModalFormat body={modalBody} footer={modalFooter} />;
};

NewProjectModal.propTypes = {
  editMode: bool,
  newProject: func,
  loadProject: func,
  loading: object,
  saveProject: func,
  data: oneOfType([object, bool]),
  loadTemplates: func,
  project: object,
};

const mapStateToProps = ({ loading, project }) => ({ loading, project });

const mapDispatchToProps = (dispatch) => ({
  newProject: () => dispatch(clearProjectEdit()),
  loadProject: () => dispatch(loadCurrentProject()),
  saveProject: (template) => dispatch(updateProject(template)),
  loadTemplates: () => dispatch(fetchTemplates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProjectModal);
