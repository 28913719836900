import constants from '../constants';
import { sortAndChunk } from '../actions/dashboardActions';

const { SORT_TABLE, SET_PAGINATION } = constants;

export const sortClick = (newTarget, reset) => async (dispatch, getState) => {
  const { target, aToZ } = getState().sortTable;
  const { filterBySearch } = getState().dashboard;

  if (reset) {
    dispatch(sortTableAction({ target: '', aToZ: false }));
  }

  if (target === newTarget) {
    if (aToZ) {
      dispatch(sortTableAction({ target: newTarget, aToZ: false }));
    } else {
      dispatch(sortTableAction({ target: '', aToZ: false }));
    }
  } else {
    dispatch(sortTableAction({ target: newTarget, aToZ: true }));
  }
  dispatch(sortAndChunk(filterBySearch));
};

export const sortTableAction =
  ({ target, aToZ }) =>
  async (dispatch) =>
    dispatch({ type: SORT_TABLE, target, aToZ });

export const setActivePagination = (paginationActive) => async (dispatch) =>
  dispatch({ type: SET_PAGINATION, paginationActive });
