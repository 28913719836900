import React, { Fragment } from 'react';
import { object } from 'prop-types';
import cx from 'classnames';

import './ModalFormat.scss';

// stat panel used on admin section
const ModalFormat = ({ body, footer }) => {
  return (
    <Fragment>
      <div
        className={cx('modal-format__body custom-scroll pr-2', {
          'modal-format__body--no-footer': !footer,
        })}
      >
        {body}
      </div>
      {footer && <div className="modal-format__footer">{footer}</div>}
    </Fragment>
  );
};

ModalFormat.propTypes = {
  body: object,
  footer: object,
};

export default ModalFormat;
