import React from 'react';
import { bool } from 'prop-types';
import cx from 'classnames';

import './Chevvy.scss';

// chevron wrapper
const Chevvy = ({ activeRotate, activeColor, active }) => (
  <div
    className={cx('chevvy__container', {
      'chevvy__container--active-rotate': activeRotate || active,
      'chevvy__container--active-color': activeColor || active,
    })}
  />
);

Chevvy.propTypes = {
  activeRotate: bool,
  activeColor: bool,
  active: bool,
};

export default Chevvy;
