import React, { useState, useEffect } from 'react';
import { string, object } from 'prop-types';
import { connect } from 'react-redux';
import { genericTimeYearDate } from '../../helpers/formatDates';
import Link from '../../components/Link/Link';
import copyToClip from '../../helpers/copyToClip';
import forceDownload from '../../helpers/forceDownload';
import { get, flatMap } from 'lodash';
import imageUrl from '../../helpers/imageUrl';
import ThumbNail from '../../components/ThumbNail/ThumbNail';
import { correctHtml } from '../../helpers/correctIcon';

import cx from 'classnames';

import './AssetViewer.scss';

// asset viwer
const AssetViewer = ({ data, dashboard, translations }) => {
  const {
    assetView__downloadAsset_link,
    assetView__copyLink_link,
    assetView__uploadedDate_text,
  } = translations.byKeyTranslations;
  const { assets, comments } = dashboard.activeIssue;
  const [activeItem, setActiveItem] = useState({});

  const imagePath = imageUrl(activeItem.key, activeItem.url);

  const commentAssets = flatMap(comments, (comment) => comment.assets);

  const allAssets = [...assets, ...commentAssets];

  useEffect(() => {
    setActiveItem(allAssets.find((item) => item._id === data) || {});
  }, [data]); // eslint-disable-line

  return (
    <div className="asset-viewer__container">
      <div className="asset-viewer__body">
        <div className="asset-viewer__body-window">
          {correctHtml(imagePath, activeItem.name)}
        </div>
        <div className="asset-viewer__info">
          <div>
            <p>{activeItem.url}</p>
            <p>
              {assetView__uploadedDate_text}{' '}
              {genericTimeYearDate(activeItem.createdAt)}
            </p>
            <p>
              By: {get(activeItem, 'creator.firstName')}{' '}
              {get(activeItem, 'creator.lastName')}
            </p>
          </div>
          <div className="asset-viewer__buttons">
            <Link bright onClick={() => copyToClip(imagePath)}>
              {assetView__copyLink_link}
            </Link>
            <Link
              bright
              onClick={() => forceDownload(imagePath, activeItem.url)}
            >
              {assetView__downloadAsset_link}
            </Link>
          </div>
        </div>
      </div>
      <div className="asset-viewer__bottom">
        {allAssets.map((item) => (
          <div key={item._id}>
            <ThumbNail
              onClick={(e) => {
                e.preventDefault();
                setActiveItem(item);
              }}
              title={item.url}
              url={imageUrl(item.key, item.url)}
              large
              active={item._id === activeItem._id}
            />
            <p
              className={cx('asset-viewer__thumb-text', {
                'asset-viewer__thumb-text--active': item._id === activeItem._id,
              })}
            >
              {item.url.slice(0, 30)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

AssetViewer.propTypes = {
  data: string,
  dashboard: object,
  translations: object,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

export default connect(mapStateToProps, null)(AssetViewer);
