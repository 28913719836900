import React from 'react';
import { string, func, bool } from 'prop-types';
import cx from 'classnames';

import './Tag.scss';

// base tag used in tags and other places
const Tag = ({
  text,
  onClick,
  active,
  right,
  remove,
  noBottomPad,
  title,
  isDisabled,
}) => (
  <div
    onClick={onClick}
    className={cx('tag__container', {
      'tag__container--active': active,
      'tag__container--right-pad': right,
      'tag__container--click': onClick,
      'tag__container--remove': remove,
      'tag__container--no-bottom': noBottomPad,
      'tag__container--disabled': isDisabled,
    })}
    title={title}
  >
    {text || '-'}
  </div>
);

Tag.propTypes = {
  text: string,
  onClick: func,
  active: bool,
  right: bool,
  remove: bool,
  noBottomPad: bool,
  title: string,
};

export default Tag;
