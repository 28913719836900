import constants from '../constants';
import { matchSorter } from 'match-sorter';

const {
  MENU_OPEN,
  MENU_CLOSE,
  PROJECT_MENU_FUZZY_TERM,
  PROJECT_MENU_FUZZY_SEARCH,
} = constants;

export const openMenu = (target) => async (dispatch) => {
  dispatch({ type: MENU_CLOSE, src: 'openMenu' });
  dispatch({ type: MENU_OPEN, target, src: 'openMenu' });
};

export const closeMenu = () => async (dispatch) => {
  dispatch({ type: MENU_CLOSE, src: 'closeMenu' });
};

export const projectMenuSearch = (projectFuzzyTerm) => async (dispatch) => {
  dispatch({ type: PROJECT_MENU_FUZZY_TERM, projectFuzzyTerm });
  dispatch(projectMenuFuzzySearch());
};

export const projectMenuFuzzySearch = () => (dispatch, getState) => {
  const { projectFuzzyTerm } = getState().menu;
  const { projects } = getState().project;

  const fuzzyProjects = matchSorter(projects, projectFuzzyTerm, {
    keys: ['name', 'description'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  dispatch({ type: PROJECT_MENU_FUZZY_SEARCH, fuzzyProjects });
};
