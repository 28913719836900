import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';

const {
  ADMIN_DASHBOARD_SUCCESS,
  ADMIN_DASHBOARD_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

export const adminDashboardFetch = () => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminDashboardFetch' });

  const fetchDataInfo = {
    url: api.ADMIN_STATS_URL,
  };

  try {
    const stats = await fetchData(fetchDataInfo);

    dispatch({ type: ADMIN_DASHBOARD_SUCCESS, stats });
    dispatch({ type: LOADING_FINISHED, src: 'adminDashboardFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_DASHBOARD_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminDashboardFetch' });
    dispatch(toast('error', e));
  }
};
