import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { updateAcccount } from '../../actions/registerActions';
import { fetchCurrentUser } from '../../actions/loginActions';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './ProfilePassword.scss';

// profile password route /profile#password
const ProfilePassword = ({ updateUser, login = {}, getUser, translations }) => {
  const {
    passwordDetailsPage__confirmNew_input,
    passwordDetailsPage__currentPassword_input,
    passwordDetailsPage__newPassword_input,
    passwordDetailsPage__password_header,
    passwordDetailsPage__passwordHelper_text,
    passwordDetailsPage__savePassword_button,
  } = translations.byKeyTranslations;

  const { id } = login.user;
  const { strategy } = login.userProfile;
  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const [submittingForm, setSubmittingForm] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
        setTimeout(() => setSubmittingForm(false), 3000);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <div>
      <h1>{passwordDetailsPage__password_header}</h1>

      <form onSubmit={handleSubmit((data) => updateUser(id, data))}>
        <h5>
          {passwordDetailsPage__currentPassword_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="currentPassword"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>

        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('currentPassword', {
            required: ' Current password is required',
          })}
          defaultValue=""
          type="password"
          disabled={strategy && strategy !== 'local' ? true : false}
        />

        <h5>
          {passwordDetailsPage__newPassword_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>

        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('password', {
            required: ' Password is required',
            validate: {
              moreThanEight: (v) =>
                v.length >= 8 || `${passwordDetailsPage__passwordHelper_text}`,
              hasLetters: (v) =>
                v.match(/[a-z]/) != null || 'Invalid password, no letters',
              hasNumbers: (v) =>
                v.match(/\d/) != null || 'Invalid password, no numbers',
            },
          })}
          defaultValue=""
          type="password"
          disabled={strategy && strategy !== 'local' ? true : false}
        />

        <h5>
          {passwordDetailsPage__confirmNew_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="confirmPassword"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>

        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('confirmPassword', {
            required: ' Confirmation password is required',
            validate: {
              matchPassword: (v) =>
                v === getValues().password || 'Passwords dont match',
            },
          })}
          defaultValue=""
          type="password"
          disabled={strategy && strategy !== 'local' ? true : false}
        />

        <div className="mt-6">
          <ButtonGroup>
            <Button
              loading={submittingForm}
              disabled={strategy && strategy !== 'local' ? true : false}
            >
              {passwordDetailsPage__savePassword_button || ''}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
};

ProfilePassword.propTypes = {
  register: object,
  getUser: func,
  login: object,
  updateUser: func,
  translations: object,
};

const mapStateToProps = ({ register, login, translations }) => ({
  register,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data) => dispatch(updateAcccount(id, data)),
  getUser: () => dispatch(fetchCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
