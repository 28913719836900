import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, bool } from 'prop-types';
import { updateSingleRecord } from '../../actions/editProjectActions';
import Button from '../Button/Button';
import { get } from 'lodash';
import Dragger from '../Dragger/Dragger';
import toast from '../../helpers/toast';
import ReactSelect, { createFilter } from 'react-select';

import CustomReactSelect from 'react-select/creatable';

import './EditProjectSection.scss';

// project details edit sections
const EditProjectSection = ({
  editProject,
  update,
  project,
  throwError,
  editMode,
  translations,
  teams,
  template,
}) => {
  const { editProject__uploadCover_input, editProject__uploadNavisZip_input } =
    translations.byKeyTranslations;
  const coverRef = useRef();
  const navisRef = useRef();

  const { description, name, powerBI, locations = [] } = editProject;
  const { locations: oldLocations = [] } = project.activeProject;

  // i dont like this, but not sure how else to do it ?
  const defaultLocations = oldLocations.map((value) => ({
    label: value,
    value: value,
  }));
  const locationLabels = locations.map((value) => ({
    label: value,
    value: value,
  }));

  const templateLabels = teams.teamTemplate.map((value) => ({
    label: value.name,
    value: value._id,
  }));

  useEffect(() => {
    // the state is saved so reset on mount, rest is OK
    update({
      field: 'coverRef',
      value: null,
    });
    update({
      field: 'navisRef',
      value: null,
    });
  }, []); // eslint-disable-line

  return (
    <div style={{ flexDirection: 'column', flex: 1 }} className="pt-5 px-0.5">
      <h5>
        Title
        <span className="text-red-600"> *</span>
      </h5>
      <input
        className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
        type="text"
        value={name}
        onChange={({ target: { value } }) =>
          update({ field: 'name', value: value })
        }
      />

      <h5>Description</h5>
      <input
        className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
        type="text"
        value={description}
        onChange={({ target: { value } }) => {
          update({ field: 'description', value: value });
        }}
      />

      {!editMode && !template && !project.activeProject.template && (
        // Template assignment will break if the template itself
        // doesn't have any custom items in it
        <div>
          <h5>Project Template</h5>
          <ReactSelect
            placeholder="No Template"
            onChange={(data) => {
              console.log(data);
              update({
                field: 'template_id',
                value: data.value,
              });
            }}
            options={templateLabels}
            isClearable={false}
            isSearchable={false}
            className="pt-[10px] mb-[10px]"
            menuPortalTarget={document.body}
            filterOption={createFilter({
              ignoreAccents: false,
              ignoreCase: true,
              trim: true,
            })} // meant to improve perf
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: '300px',
              }),
              control: (base, state) => ({
                ...base,
                background: '#fafbfc',
                borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                outline: state.isFocused
                  ? '#66ccff solid 2px !important'
                  : 'none',
                '*': {
                  boxShadow: 'none !important',
                },
                '&:hover': {
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? '#9a9fa7'
                  : state.isFocused
                  ? '#f1f5f9'
                  : base.backgroundColor,
                '&:hover': {
                  backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                },
              }),
            }}
          />
        </div>
      )}
      {!template && !project.activeProject.template && (
        <div>
          <h5>Location Tags</h5>
          <CustomReactSelect
            placeholder="Add Tags"
            onChange={(data) =>
              update({
                field: 'locations',
                value: data.map((item) => item.value),
              })
            }
            defaultValue={editMode ? defaultLocations : []}
            options={locationLabels}
            isClearable={false}
            isMulti
            className="pt-[10px] mb-[10px]"
            menuPortalTarget={document.body}
            filterOption={createFilter({
              ignoreAccents: false,
              ignoreCase: true,
              trim: true,
            })} // meant to improve perf
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: '300px',
              }),
              // Colour your options
              // https://github.com/JedWatson/react-select/issues/2728
              control: (base, state) => ({
                ...base,
                background: '#fafbfc',
                borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                outline: state.isFocused
                  ? '#66ccff solid 2px !important'
                  : 'none',
                '*': {
                  boxShadow: 'none !important',
                },
                '&:hover': {
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                },
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isSelected
                  ? '#9a9fa7'
                  : state.isFocused
                  ? '#f1f5f9'
                  : base.backgroundColor,
                '&:hover': {
                  backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                },
              }),
            }}
          />
        </div>
      )}
      {!template && !project.activeProject.template && (
        <div>
          <h5>Power BI (URL only, not embed code)</h5>
          <input
            placeholder="Power BI URL (not embed code)"
            className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
            type="text"
            value={powerBI}
            onChange={({ target: { value } }) =>
              update({ field: 'powerBI', value: value })
            }
          />
        </div>
      )}
      {!template && !project.activeProject.template && (
        <div className="edit-project__upload-section">
          <Dragger
            action={(e) => {
              e.persist();
              coverRef.current.files = e.dataTransfer.files;
              update({
                field: 'coverRef',
                value: coverRef.current,
              });
            }}
          >
            <Button onClick={() => coverRef.current.click()}>
              {editProject__uploadCover_input || ''}
            </Button>
            <p>
              {get(coverRef, 'current.files[0].name')
                ? get(coverRef, 'current.files[0].name')
                : 'No File Selected'}
            </p>
          </Dragger>

          <Dragger
            action={(e) => {
              e.persist();
              if (!e.dataTransfer.files[0].name.includes('.zip')) {
                return throwError('Needs to be a .zip file');
              }
              navisRef.current.files = e.dataTransfer.files;
              update({
                field: 'navisRef',
                value: navisRef.current,
              });
            }}
          >
            <Button onClick={() => navisRef.current.click()}>
              {editProject__uploadNavisZip_input || ''}
            </Button>
            <p>
              {get(navisRef, 'current.files[0].name')
                ? get(navisRef, 'current.files[0].name')
                : 'No File Selected'}
            </p>
          </Dragger>
        </div>
      )}
      <input
        type="file"
        ref={coverRef}
        onChange={() =>
          update({
            field: 'coverRef',
            value: coverRef.current,
          })
        }
        accept=".jpg, .jpeg, .png, .gif"
        style={{ display: 'none' }}
      />

      <input
        type="file"
        ref={navisRef}
        onChange={() =>
          update({
            field: 'navisRef',
            value: navisRef.current,
          })
        }
        accept=".zip"
        style={{ display: 'none' }}
      />

      <br />
    </div>
  );
};

EditProjectSection.propTypes = {
  postForm: func,
  editMode: bool,
  project: object,
  throwError: func,
  editProject: object,
  update: func,
  translations: object,
  teams: object,
  template: bool,
};

const mapStateToProps = ({ editProject, project, translations, teams }) => ({
  editProject,
  project,
  translations,
  teams,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateSingleRecord(data)),
  throwError: (e) => dispatch(toast('error', e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProjectSection);
