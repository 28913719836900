import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import {
  adminUserFetch,
  adminUserProjectsFetch,
  adminUserEventsFetch,
} from '../../actions/adminUserViewActions';
import { formatDistanceToNow } from 'date-fns';

import AdminContainer from '../../components/AdminContainer/AdminContainer';
import { roles } from '../../constants/labels';
import Stat from '../../components/Stat/Stat';
import {
  // projectsHead,
  // projectsTable,
  // eventsHead,
  // eventsTable,
  tableHeadUserProjects,
  tableHeadUserEvents,
} from './AdminUserTables.jsx';
import { genericTimeYearDate } from '../../helpers/formatDates';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminUserView.scss';

const AdminUserView = ({
  adminUserView,
  fetchUser,
  fetchProjects,
  fetchEvents,
  match,
  loading,
}) => {
  const URL_ID = match.params.id;

  useEffect(() => {
    fetchUser(URL_ID);
    fetchProjects(URL_ID);
    fetchEvents(URL_ID);
  }, []); // eslint-disable-line

  const { user, projects, events } = adminUserView;

  const {
    firstName,
    lastName,
    email,
    createdAt,
    company,
    discipline,
    interval,
    globalAdmin,
    emailVerified,
    failedLoginAttempts,
  } = user || {};

  const userProjectsTable = (URL_ID) => {
    return tableHeadUserProjects(URL_ID);
  };

  const userEventsTable = () => {
    return tableHeadUserEvents();
  };

  return (
    <AdminContainer>
      <h1>{`${firstName} ${lastName}`}</h1>
      <p>{email}</p>
      <p title={createdAt}>
        {createdAt && `Created ${formatDistanceToNow(Date.parse(createdAt))}`}{' '}
        ago
      </p>

      <h2>Info</h2>
      <article className="admin__stats-container">
        <Stat name="Email Verified" value={emailVerified ? 'True' : 'False'} />
        <Stat name="Company" value={company} />
        <Stat name="Discipline" value={roles[discipline]} />
        <Stat name="Global Admin" value={globalAdmin ? 'True' : 'False'} />

        <Stat name="Notifications" value={String(user.notifications || 0)} />
        <Stat
          name="Last Login Date"
          value={genericTimeYearDate(user.lastLoginDate) || 'None'}
        />
        <Stat
          name="Notification Interval"
          value={interval === 1 ? 'Every hour' : `Every ${interval} hours`}
        />
        <Stat
          name="Failed Login Attempts"
          value={String(
            (failedLoginAttempts && failedLoginAttempts.length) || 0
          )}
        />
        <Stat
          name="Password Reset Date"
          value={genericTimeYearDate(user.passwordResetDate) || 'None'}
        />
      </article>

      <h2>Projects</h2>

      <FilterTable
        defaultData={projects}
        defaultColumns={userProjectsTable(URL_ID)}
        showPagination={true}
        placeholder="Search projects..."
        loading={loading.loading}
      />

      <h2>Recent Activity</h2>

      <FilterTable
        defaultData={events}
        defaultColumns={userEventsTable(URL_ID)}
        showPagination={true}
        placeholder="Search recent activity..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminUserView.propTypes = {
  fetchUser: func,
  fetchProjects: func,
  fetchEvents: func,
  adminUserView: object,
  match: object,
};

const mapStateToProps = ({ adminUserView, loading }) => ({
  adminUserView,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (id) => dispatch(adminUserFetch(id)),
  fetchProjects: (id) => dispatch(adminUserProjectsFetch(id)),
  fetchEvents: (id) => dispatch(adminUserEventsFetch(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserView);
