import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { postNewFeedback } from '../../actions/feedbackActions';
import { imageAttachments } from '../../constants/labels';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect, { createFilter } from 'react-select';
import { useEffect } from 'react';

// feed back modal from bottom left nav
const FeedbackModal = ({ post }) => {
  const question = [
    { label: 'Ask a question', value: 'question' },
    { label: 'General feedback', value: 'feedback' },
    { label: 'Suggest an improvement', value: 'improvement' },
    { label: 'Report a bug', value: 'bug' },
  ];

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [submittingForm, setSubmittingForm] = useState(false);

  const [formData, setFormData] = useState(null);

  const [formType, setFormType] = useState(null);

  const [attachmentFile, setAttachmentFile] = useState(null);

  const [comment, setComment] = useState('');

  const handleFileInput = (e) => {
    // handle validations
    setAttachmentFile(e.target);
    // file = e.target.files[0];
  };

  useEffect(() => {
    if (formType !== null) {
      setFormData({
        type: formType || null,
        comment: comment || '',
      });
      console.log(getValues());
    }
  }, [formType, comment, getValues]);

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          post(data, attachmentFile);
          // console.log({ data, file: attachmentFile });
        })}
      >
        <label>
          Query Type
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="type"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <Controller
          name="type"
          control={control}
          rules={{ required: ' Form type is required' }}
          render={({ field }) => (
            <ReactSelect
              {...register('type')}
              {...field}
              placeholder="I want to ..."
              onChange={(e) => {
                setFormType(e);
              }}
              options={question}
              isClearable={false}
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              filterOption={createFilter({
                ignoreAccents: false,
                ignoreCase: true,
                trim: true,
              })} // meant to improve perf
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        <label>
          Add attachment
          <ErrorMessage
            errors={errors}
            name="attachment"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>

        <div>
          <input
            type="file"
            accept={imageAttachments}
            onChange={handleFileInput}
            className="block w-full my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
          />
        </div>

        <label>
          Comment
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="comment"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <textarea
          className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('comment', {
            required: ' Comment is required',
          })}
          type="text"
          onChange={(e) => setComment(e.target.value)}
        />

        <div className="mt-6">
          <ButtonGroup>
            <Button loading={submittingForm}>Send</Button>
          </ButtonGroup>
        </div>
      </form>
    </>
  );
};

FeedbackModal.propTypes = {
  post: func,
};

const mapDispatchToProps = (dispatch) => ({
  post: (data, ref) => dispatch(postNewFeedback(data, ref)),
});

export default connect(null, mapDispatchToProps)(FeedbackModal);
