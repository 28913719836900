import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func, bool, oneOfType } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import Filters from '../../components/Filters/Filters';
import {
  updateFilters,
  updateMultiFilters,
  filterData as runFilter,
} from '../../helpers/updateFilters';
import getPermissions from '../../helpers/getPermissions';
import {
  addNewWorkspace,
  deleteWorkSpace,
  updateSingleWorkspace,
} from '../../actions/workspaceActions';
import { orderBy } from 'lodash';
import Checkbox from '../../components/CheckBox/CheckBox';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import { genericTimeYearDate } from '../../helpers/formatDates';
import {
  updateWorkspaceAction,
  loadCurrentWorkspace,
} from '../../actions/editWorkspaceActions';
import filterList from '../../constants/filters';

import Tabs from '../../components/UI/Tabs/Tabs';

import './NewWorkSpaceModal.scss';

// new workspace modal
const NewWorkSpaceModal = ({
  postNew,
  project,
  dashboard,
  data,
  update,
  deleteItem,
  updateWorkspace,
  editMode,
  editWorkspace,
  loading,
  loadWorkspace,
  translations,
}) => {
  const { workspaceModal__itemInfo_text } = translations.byKeyTranslations;
  const [selectedTab, setSelectedTab] = useState(0);
  const filterData = editWorkspace.filters;
  const selectedItems = editWorkspace.items || [];

  const updateState = (target, value) => {
    const newFilterData = updateFilters(filterData, target, value);
    updateWorkspace({ field: 'filters', value: newFilterData });
  };

  const updateStateMulti = (data) => {
    const newFilterData = updateMultiFilters(filterData, data);
    updateWorkspace({ field: 'filters', value: newFilterData });
  };

  useEffect(() => {
    const { items, filters, public: publicToggle = null } = data;
    loadWorkspace({
      items: items,
      filters: filters ? filters : filterList(),
      publicToggle,
    });
    if (project.activeProject.template) {
      updateWorkspace({ field: 'publicToggle', value: true });
    }
  }, []); // eslint-disable-line

  const sortedIssues = orderBy(dashboard.issues, 'name');

  const filteredIssues = sortedIssues.length
    ? runFilter(filterData, sortedIssues)
    : [];

  const modalBody = (
    <div className="workspace__setting-section pt-5 px-0.5">
      <h5>
        Name
        <span className="text-red-600"> *</span>
      </h5>

      <input
        className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
        type="text"
        defaultValue={data.name}
        onChange={({ target: { value } }) =>
          updateWorkspace({ field: 'name', value })
        }
      />

      {!project.activeProject.template &&
        getPermissions('project:workspace:public:create') && (
          <div className="py-4 grid grid-cols-[auto_1fr] gap-2 place-items-center">
            <Checkbox
              defaultIsChecked={Object.keys(data).length > 0 && !data.owner}
              onChange={(value) => {
                updateWorkspace({ field: 'publicToggle', value });
              }}
            />
            <h5 className="justify-self-start mt-0" name="public">
              Shared Workspace
            </h5>
          </div>
        )}

      <Filters
        filters={filterData}
        updateFilter={updateState}
        updateMultiFilter={updateStateMulti}
        issues={dashboard.issues}
        project={project.activeProject}
        allData={true}
        translations={translations}
      />
    </div>
  );

  const modalIssues = (
    <div className="workspace__issues-section">
      <div className="workspace__text-title">
        <h4>Total Items: {sortedIssues.length}</h4>

        <h4>Filtered Items: {filteredIssues.length}</h4>

        <h4>Selected Items: {selectedItems.length}</h4>
      </div>

      <p>{workspaceModal__itemInfo_text}</p>

      <br />

      {filteredIssues.map(
        ({ _id, name, descriptionText, creator, createdAt }) => (
          <div
            key={_id}
            className="profile__sub--item"
            onClick={() =>
              updateWorkspace({
                field: 'items',
                value: selectedItems.includes(_id)
                  ? selectedItems.filter((item) => item !== _id)
                  : [...selectedItems, _id],
              })
            }
          >
            <div>
              <Checkbox defaultIsChecked={selectedItems.includes(_id)} />
            </div>

            <div className="big-flex">
              <div className="profile__sub--title">
                <h5>{name}</h5>
                <p>
                  {creator.firstName} {creator.lastName} on{' '}
                  {genericTimeYearDate(createdAt)}
                </p>
              </div>
              <p>{descriptionText}</p>
            </div>
          </div>
        )
      )}
    </div>
  );

  const modalFooter = (
    <Fragment>
      <ButtonGroup between={editMode}>
        {editMode && (
          <Button
            danger
            onClick={() => deleteItem(data._id)}
            isLoading={loading.loading}
          >
            Delete
          </Button>
        )}

        <Button
          onClick={() => {
            // console.log(data);
            data._id ? update(data._id) : postNew();
          }}
          isLoading={loading.loading}
        >
          Save
        </Button>
      </ButtonGroup>
    </Fragment>
  );

  const modalTabs = (
    <Tabs
      tabs={[
        {
          label: 'Settings',
          content: modalBody,
        },
        {
          label: `Items ${filteredIssues.length}/${sortedIssues.length}`,
          content: modalIssues,
        },
      ]}
      selected={selectedTab}
      onSelect={(tab, index) => setSelectedTab(index)}
    />
  );

  return <ModalFormat body={modalTabs} footer={modalFooter} />;
};

NewWorkSpaceModal.propTypes = {
  project: object,
  postNew: func,
  dashboard: object,
  data: oneOfType([object, bool]),
  editMode: bool,
  update: func,
  deleteItem: func,
  updateWorkspace: func,
  editWorkspace: object,
  loading: object,
  loadWorkspace: func,
  translations: object,
};

const mapStateToProps = ({
  project,
  dashboard,
  editWorkspace,
  loading,
  translations,
}) => ({
  project,
  dashboard,
  editWorkspace,
  loading,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postNew: () => dispatch(addNewWorkspace()),
  update: (childId) => dispatch(updateSingleWorkspace(childId)),
  deleteItem: (childId) => dispatch(deleteWorkSpace(childId)),
  updateWorkspace: (data) => dispatch(updateWorkspaceAction(data)),
  loadWorkspace: (data) => dispatch(loadCurrentWorkspace(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewWorkSpaceModal);
