// forces browser to download a file
const downloadFile = (blob, name = 'file') => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: blob.type });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const urlCreator = window.URL || window.webkitURL;

  const data = urlCreator.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = name;
  link.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    urlCreator.revokeObjectURL(data);
  }, 100);
};

export default downloadFile;
