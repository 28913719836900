import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import postImage from '../helpers/postImage';

const {
  LOADING_REQUEST,
  LOADING_FINISHED,
  MODAL_CLOSE,
  ADMIN_FEEDBACK_SUCCESS,
  ADMIN_FEEDBACK_FAILURE,
} = constants;

export const fetchFeedback = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_REQUEST, src: 'fetchFeedback' });

    const fetchDataInfo = {
      url: api.FEEDBACK_API_URL,
    };

    const feedback = await fetchData(fetchDataInfo);
    dispatch({ type: LOADING_FINISHED, src: 'fetchFeedback' });
    dispatch({ type: ADMIN_FEEDBACK_SUCCESS, feedback });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'fetchFeedback' });
    dispatch({ type: ADMIN_FEEDBACK_FAILURE, error: e });
    dispatch(toast('error', e));
  }
};

export const postNewFeedback =
  ({ type, comment }, coverRef) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_REQUEST, src: 'postNewFeedback' });

      const fileId = await postImage(dispatch, coverRef);

      const body = {
        type: type.value,
        comment,
        asset: fileId,
        location: window.location.pathname,
      };

      const fetchDataInfo = {
        url: api.FEEDBACK_API_URL,
        type: 'POST',
        body,
      };

      await fetchData(fetchDataInfo);
      dispatch({ type: LOADING_FINISHED, src: 'postNewFeedback' });
      dispatch({ type: MODAL_CLOSE });
      dispatch(toast('success', 'Thank you for your feedback.'));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'postNewFeedback' });
      dispatch(toast('error', e));
    }
  };
