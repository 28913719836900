import React, { useEffect, useRef, useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { reportFetch, fetchPdfReport } from '../../actions/reportActions';
import { Line, Scatter } from 'react-chartjs-2';
import { subWeeks, format } from 'date-fns';
import { get } from 'lodash';
import Button from '../../components/Button/Button';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import clean from 'sanitize-filename';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  // PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';

import './ReportModal.scss';

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://themes.googleusercontent.com/static/fonts/lato/v6/v0SdcGFAl2aezM9Vq_aFTQ.ttf',
      fontStyle: 'normal',
      fontWeight: 400,
    },
  ],
});

function objectToArray(data) {
  const arrayOfArrays = Object.entries(data);
  let out = [];
  arrayOfArrays.map((x) => out.push({ key: x[0], item: x[1] }));
  return out;
}

const genColors = (alpha) => ({
  Open: `rgba(16, 133, 135, ${alpha})`,
  'In Progress': `rgba(242, 175, 62, ${alpha})`,
  'On Hold': `rgba(82, 185, 159, ${alpha})`,
  Resolved: `rgba(236, 83, 62, ${alpha})`,
  Closed: `rgba(29, 34, 22, ${alpha})`,
});

const colors = genColors(1);
const colorsTrans = genColors(0.5);

// this is used to show project report on project nav
const ReportModal = ({
  fetch,
  report,
  loading,
  getPdf,
  translations,
  project,
}) => {
  const {
    projectNavReport__allItemsGraph_header,
    projectNavReport__domainItems_header,
    projectNavReport__download_button,
    projectNavReport__overdueNoAssingee_header,
    projectNavReport__overdueWithAssingee_header,
    projectNavReport__userItems_header,
    projectNavReport__dueDateTable_header,
    projectNavReport__unresolvedDueDate_header,
    projectNavReport__userTable_header,
    projectNavReport__itemsRemainingGraph_header,
    projectNavReport__weeksTillResolvedGraph_header,
    projectNavReport__unassingedReadyItems_header,
    projectNavReport__totalTable_header,
    projectNavReport__overdueTable_header,
    projectNavReport__perDueDate_header,
  } = translations.byKeyTranslations;

  const {
    weeklyStatus = {},
    burnDown = [],
    cycleTime = {},
    byStatus = {},
    byDueDate = {},
    dueDateAndUnresolved,
    noUsers,
    overdueAssignee,
    overdueNoAssignee,
    openByUser = [],
    byDomain = [],
  } = report;

  const howManyRecords = get(weeklyStatus, '[Open]', []).length;
  const dataSets = Object.entries(weeklyStatus).map(([label, data]) => ({
    data,
    label,
    borderColor: colors[label],
    pointBackgroundColor: colors[label],
    pointStrokeColor: '#fff',
    pointHighlightFill: '#fff',
    borderCapStyle: 'butt',
    fillColor: 'rgba(255,255,255,0)',
  }));

  const lineData = {
    datasets: dataSets,
    labels: weeklyStatus['Open']
      ? [...Array(howManyRecords).keys()].map((item) =>
          format(subWeeks(new Date(), howManyRecords - item), 'dd/MM/yyyy')
        )
      : [],
  };

  const dataSetsStack = Object.entries(weeklyStatus).map(([label, data]) => ({
    data,
    label,
    fill: true,
    backgroundColor: colorsTrans[label],
    pointBackgroundColor: 'transparent',
    pointBorderColor: 'transparent',
    borderColor: colors[label],
    pointHighlightStroke: 'transparent',
    borderCapStyle: 'butt',
  }));

  const lineDataStack = {
    datasets: dataSetsStack,
    labels: weeklyStatus['Open']
      ? [...Array(howManyRecords).keys()].map((item) =>
          format(subWeeks(new Date(), howManyRecords - item), 'dd/MM/yyyy')
        )
      : [],
  };

  const burnDownStack = {
    datasets: [{ data: burnDown, label: 'Items remaining' }],
    labels: burnDown
      ? [...Array(howManyRecords).keys()].map((item) =>
          format(subWeeks(new Date(), howManyRecords - item), 'dd/MM/yyyy')
        )
      : [],
  };

  const scatterData = {
    datasets: JSON.parse(JSON.stringify(cycleTime)),
    labels: howManyRecords
      ? [...Array(howManyRecords).keys()].map((item) =>
          format(subWeeks(new Date(), howManyRecords - item), 'dd/MM/yyyy')
        )
      : [],
  };

  const chartRef1 = useRef(null);
  const chartRef2 = useRef(null);
  const chartRef3 = useRef(null);
  const chartRef4 = useRef(null);

  const [testImage1, setTestImage1] = useState(null);
  const [testImage2, setTestImage2] = useState(null);
  const [testImage3, setTestImage3] = useState(null);
  const [testImage4, setTestImage4] = useState(null);

  const [generateReport, setGenerateReport] = useState(false);
  const [allowDownload, setAllowDownload] = useState(false);

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (generateReport) {
      async function runThis() {
        await runCharts();
        setAllowDownload(true);
      }
      runThis();
    }
  }, [generateReport]);

  async function runCharts() {
    if (chartRef1) {
      const base64Image = chartRef1.current?.toBase64Image();
      setTestImage1(base64Image);
    }
    if (chartRef2) {
      const base64Image = chartRef2.current?.toBase64Image();
      setTestImage2(base64Image);
    }
    if (chartRef3) {
      const base64Image = chartRef3.current?.toBase64Image();
      setTestImage3(base64Image);
    }
    if (chartRef4) {
      const base64Image = chartRef4.current?.toBase64Image();
      setTestImage4(base64Image);
    }
  }

  // Create styles
  const styles = StyleSheet.create({
    page: {
      backgroundColor: '#FFFFFF',
      fontFamily: 'Lato',
      fontSize: '12px',
    },
    baseSection: {
      padding: 20,
      height: '100%',
    },
    textTitle: { paddingVertical: 8 },
    tableCell: {
      padding: 5,
      fontSize: 8,
    },
  });

  function PDFReport() {
    return (
      <Document
        title={`Project Report - ${project.activeProject.name}`}
        author="bcapp.com.au"
      >
        <Page size="A4" style={styles.page}>
          <View style={{ ...styles.baseSection, height: '100%' }}>
            <Text style={styles.textTitle}>{project.activeProject.name}</Text>
            <Text style={styles.textTitle}>
              {projectNavReport__allItemsGraph_header}
            </Text>
            <Image src={`${testImage1}`} />
            <Image src={`${testImage2}`} />
          </View>

          <View style={{ ...styles.baseSection, height: '100%' }}>
            <Text style={styles.textTitle}>
              {projectNavReport__itemsRemainingGraph_header}
            </Text>
            <Image src={`${testImage3}`} />
            <Text style={styles.textTitle}>
              {projectNavReport__weeksTillResolvedGraph_header}
            </Text>
            <Image src={`${testImage4}`} />
          </View>

          <View style={{ ...styles.baseSection }}>
            <Text style={styles.textTitle}>Status Summary</Text>
            <Table data={objectToArray(byStatus)}>
              <TableHeader>
                <TableCell style={styles.tableCell}>Status</TableCell>
                <TableCell style={styles.tableCell}>Total</TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.key}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.item}
                />
              </TableBody>
            </Table>

            <Text style={styles.textTitle}>Item Activity Summary</Text>
            <Table
              data={[
                {
                  key: projectNavReport__unassingedReadyItems_header,
                  value: noUsers,
                },
                {
                  key: projectNavReport__overdueWithAssingee_header,
                  value: overdueAssignee,
                },
                {
                  key: projectNavReport__overdueNoAssingee_header,
                  value: overdueNoAssignee,
                },
                {
                  key: projectNavReport__unresolvedDueDate_header,
                  value: dueDateAndUnresolved,
                },
              ]}
            >
              <TableHeader>
                <TableCell style={styles.tableCell}>Summary</TableCell>
                <TableCell style={styles.tableCell}>Total</TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.key}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.value}
                />
              </TableBody>
            </Table>

            <Text style={styles.textTitle}>
              {projectNavReport__domainItems_header}
            </Text>
            <Table data={byDomain}>
              <TableHeader>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__userTable_header}
                </TableCell>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__totalTable_header}
                </TableCell>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__overdueTable_header}
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => (r._id ? r._id : 'Unassigned')}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.total}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.overdue}
                />
              </TableBody>
            </Table>
          </View>

          <View style={{ ...styles.baseSection }}>
            <Text style={styles.textTitle}>
              {projectNavReport__userItems_header}
            </Text>
            <Table data={openByUser}>
              <TableHeader>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__userTable_header}
                </TableCell>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__totalTable_header}
                </TableCell>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__overdueTable_header}
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.name}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.total}
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.overdue}
                />
              </TableBody>
            </Table>

            <Text style={styles.textTitle}>
              {projectNavReport__perDueDate_header}
            </Text>
            <Table data={objectToArray(byDueDate)}>
              <TableHeader>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__dueDateTable_header}
                </TableCell>
                <TableCell style={styles.tableCell}>
                  {projectNavReport__totalTable_header}
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) =>
                    r.key !== 'null'
                      ? format(Date.parse(r.key), 'dd/MM/yyyy')
                      : `No ${projectNavReport__dueDateTable_header}`
                  }
                />
                <DataTableCell
                  style={styles.tableCell}
                  getContent={(r) => r.item}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      </Document>
    );
  }

  const modalBody = (
    <div id="report" className="pt-4">
      {/* {testImage1 && (
        <PDFViewer width={700} height={500}>
          <PDFReport />
        </PDFViewer>
      )} */}

      <h4>{projectNavReport__allItemsGraph_header}</h4>
      <Line data={lineData} ref={chartRef1} />
      <Line
        data={lineDataStack}
        ref={chartRef2}
        options={{
          scales: {
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        }}
      />

      <h4>{projectNavReport__itemsRemainingGraph_header}</h4>
      <Line data={burnDownStack} ref={chartRef3} />

      <h4>{projectNavReport__weeksTillResolvedGraph_header}</h4>
      <Scatter
        data={scatterData}
        ref={chartRef4}
        options={{
          scales: {
            xAxes: [
              {
                ticks: {
                  callback: (value) =>
                    format(
                      subWeeks(new Date(), howManyRecords - value),
                      'dd/MM/yyyy'
                    ),
                },
              },
            ],
          },
        }}
      />
      <br />

      <div className="unassigned__statuses">
        {Object.entries(byStatus).map(([key, item]) => (
          <div key={key}>
            <h4>{key}</h4> <p>{item}</p>
          </div>
        ))}
      </div>

      <div className="report__details">
        <div>
          <h4>{projectNavReport__unassingedReadyItems_header}</h4>
          <p>{noUsers}</p>
        </div>
        <div>
          <h4>{projectNavReport__overdueWithAssingee_header}</h4>
          <p>{overdueAssignee}</p>
        </div>
      </div>

      <div className="report__details">
        <div>
          <h4>{projectNavReport__overdueNoAssingee_header}</h4>
          <p>{overdueNoAssignee}</p>
        </div>
        <div>
          <h4>{projectNavReport__unresolvedDueDate_header}</h4>
          <p>{dueDateAndUnresolved}</p>
        </div>
      </div>

      <h4>{projectNavReport__domainItems_header}</h4>

      <table>
        <thead>
          <tr>
            <th>{projectNavReport__userTable_header}</th>
            <th className="report__small-cell">
              {projectNavReport__totalTable_header}
            </th>
            <th className="report__small-cell">
              {projectNavReport__overdueTable_header}
            </th>
          </tr>
        </thead>
        <tbody>
          {byDomain.map(({ _id, total, overdue }) => (
            <tr key={_id ? _id : 'Unassigned'}>
              <td>{_id ? _id : 'Unassigned'}</td>
              <td className="report__small-cell">{total}</td>
              <td className="report__small-cell">{overdue}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h4>{projectNavReport__userItems_header}</h4>

      <table>
        <thead>
          <tr>
            <th>{projectNavReport__userTable_header}</th>
            <th className="report__small-cell">
              {projectNavReport__totalTable_header}
            </th>
            <th className="report__small-cell">
              {projectNavReport__overdueTable_header}
            </th>
          </tr>
        </thead>
        <tbody>
          {openByUser.map(({ name, total, overdue }) => (
            <tr key={name}>
              <td>{name}</td>
              <td className="report__small-cell">{total}</td>
              <td className="report__small-cell">{overdue}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h4>{projectNavReport__perDueDate_header}</h4>

      <table>
        <thead>
          <tr>
            <th>{projectNavReport__dueDateTable_header}</th>
            <th className="report__small-cell">
              {projectNavReport__totalTable_header}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(byDueDate).map(([key, item]) => (
            <tr key={key}>
              <td>
                {key !== 'null'
                  ? format(Date.parse(key), 'dd/MM/yyyy')
                  : `No ${projectNavReport__dueDateTable_header}`}
              </td>
              <td className="report__small-cell">{item}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const modalFooter = (
    // <button onClick={generatePDF} type="button">
    //   Export PDF
    // </button>
    <>
      {generateReport ? (
        <PDFDownloadLink
          document={<PDFReport />}
          fileName={`${clean(project.activeProject.name)}-${format(
            new Date(),
            'yyyy.MM.dd-HHmm'
          )}.pdf`}
        >
          {({ blob, url, loading, error }) => (
            <Button
              onClick={() => {
                setGenerateReport(false);
                setAllowDownload(false);
              }}
              disabled={!allowDownload}
              danger={true}
            >
              {!allowDownload
                ? 'Loading report...'
                : `${projectNavReport__download_button}`}
            </Button>
          )}
        </PDFDownloadLink>
      ) : (
        <Button
          onClick={() => {
            setGenerateReport(true);
          }}
        >
          Generate Report
        </Button>
      )}
    </>
  );

  return <ModalFormat body={modalBody} footer={modalFooter} />;
};

ReportModal.propTypes = {
  dashboard: object,
  loading: object,
  pdf: func,
  csv: func,
  fetch: func,
  report: object,
  getPdf: func,
  translations: object,
};

const mapStateToProps = ({ loading, report, translations, project }) => ({
  loading,
  report,
  translations,
  project,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(reportFetch()),
  getPdf: () => dispatch(fetchPdfReport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);
