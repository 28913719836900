import constants from '../constants';

const { PROJECT_EDIT_UPDATE, PROJECT_EDIT_CLEAR, PROJECT_EDIT_BULK, LOGOUT } =
  constants;

const initState = {
  name: '',
  description: '',
  locations: [],
  coverImage: '',
  navisZip: '',
  powerBI: '',
  members: [],
  error: '',
  template_id: '',
};

export const editProject = (state = initState, action) => {
  switch (action.type) {
    case PROJECT_EDIT_UPDATE:
      return {
        ...state,
        [action.field]: action.value,
      };

    case PROJECT_EDIT_CLEAR:
      return initState;

    case PROJECT_EDIT_BULK:
      return { ...state, ...action.bulkData };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
