import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import {
  adminProjectsFetch,
  adminItemsFetch,
  adminProjectEventsFetch,
  adminProjectStatsFetch,
} from '../../actions/adminProjectsViewActions';
import { setActiveProject } from '../../actions/projectActions';
import { formatDistanceToNow } from 'date-fns';
import Link from '../../components/Link/Link';

import AdminContainer from '../../components/AdminContainer/AdminContainer';
import Stat from '../../components/Stat/Stat';
import { membersLevels } from '../../constants/labels';
import { userUrl } from '../../helpers/adminUrls';
import {
  tableHeadIssues,
  tableHeadMembers,
  tableHeadEvents,
} from './AdminProjectViewTable';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminProjectView.scss';

// admin route /admin/projects/id
const AdminProjectView = ({
  adminProjectsView,
  fetchProjects,
  fetchItems,
  fetchEvents,
  fetchStats,
  match,
  setActive,
  loading,
}) => {
  const URL_ID = match.params.id;

  useEffect(() => {
    fetchProjects(URL_ID);
    fetchItems(URL_ID);
    fetchEvents(URL_ID);
    fetchStats(URL_ID);
  }, []); // eslint-disable-line

  const {
    // searchResults,
    projects,
    items,
    events,
    stats,
  } = adminProjectsView;

  const { name, createdAt, creator, members = [] } = projects || {};

  const { _id, firstName, lastName, email } = creator || {};

  const issueColumns = tableHeadIssues(URL_ID);
  const memberColumns = tableHeadMembers();
  const eventColumns = tableHeadEvents();

  return (
    <AdminContainer>
      <h1>{name}</h1>
      <p title={createdAt}>
        {createdAt &&
          `Created ${formatDistanceToNow(Date.parse(createdAt))} ago by`}{' '}
        {email && (
          <Link to={userUrl(_id)} dark>
            {(firstName && `${firstName} ${lastName}`) || email}
          </Link>
        )}
      </p>

      <Link dark onClick={() => setActive(projects)}>
        View on bcapp.com.au
      </Link>

      <h2>Stats</h2>
      <article className="admin__stats-container">
        <Stat
          name="Admins"
          value={
            members.filter((mem) => mem.memberLevel === membersLevels.admin)
              .length
          }
        />
        <Stat
          name="Collaborators"
          value={
            members.filter(
              (mem) => mem.memberLevel === membersLevels.collaborator
            ).length
          }
        />
        <Stat
          name="Guests"
          value={
            members.filter((mem) => mem.memberLevel === membersLevels.guest)
              .length
          }
        />
        <Stat name="Total Items" value={items.length} />
        <Stat
          name="Open Items"
          value={
            items.filter((item) => item.status === 'Open' && !item.deleted)
              .length
          }
        />
        <Stat
          name="In Progress Items"
          value={
            items.filter(
              (item) => item.status === 'In Progress' && !item.deleted
            ).length
          }
        />
        <Stat
          name="On Hold Items"
          value={
            items.filter((item) => item.status === 'On Hold' && !item.deleted)
              .length
          }
        />
        <Stat
          name="Resolved Items"
          value={
            items.filter((item) => item.status === 'Resolved' && !item.deleted)
              .length
          }
        />

        <Stat
          name="Closed Items"
          value={
            items.filter((item) => item.status === 'Closed' && !item.deleted)
              .length
          }
        />
        <Stat
          name="Deleted Items"
          value={items.filter((item) => item.deleted).length}
        />
        <Stat
          name="Comments"
          value={items.reduce((acc, item) => {
            return acc + ((item.comments && item.comments.length) || 0);
          }, 0)}
        />
        <Stat
          name="Attachments"
          value={items.reduce((acc, item) => {
            return acc + ((item.assets && item.assets.length) || 0);
          }, 0)}
        />
        <Stat
          name="Average Events per Day"
          value={stats.avgEventsPerDay && stats.avgEventsPerDay.toFixed(2)}
        />
        <Stat
          name="Items Closed 1 Day Ago"
          value={stats.itemsClosedDayAgo || 0}
        />
        <Stat
          name="Items Closed 7 Days Ago"
          value={stats.itemsClosedWeekAgo || 0}
        />
        <Stat
          name="Items Closed 30 Days Ago"
          value={stats.itemsClosedMonthAgo || 0}
        />
      </article>

      <h2>Items</h2>

      <FilterTable
        defaultData={items}
        defaultColumns={issueColumns}
        showPagination={true}
        placeholder="Search items..."
        loading={loading.loading}
      />

      <h2>Members</h2>

      <FilterTable
        defaultData={members}
        defaultColumns={memberColumns}
        showPagination={true}
        placeholder="Search members..."
        loading={loading.loading}
      />

      <h2>Recent Events</h2>

      <FilterTable
        defaultData={events}
        defaultColumns={eventColumns}
        showPagination={true}
        placeholder="Search events..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminProjectView.propTypes = {
  fetchProjects: func,
  fetchItems: func,
  fetchEvents: func,
  fetchStats: func,
  adminProjectsView: object,
  match: object,
  setActive: func,
};

const mapStateToProps = ({ adminProjectsView, loading }) => ({
  adminProjectsView,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: (id) => dispatch(adminProjectsFetch(id)),
  fetchItems: (id) => dispatch(adminItemsFetch(id)),
  fetchEvents: (id) => dispatch(adminProjectEventsFetch(id)),
  fetchStats: (id) => dispatch(adminProjectStatsFetch(id)),
  setActive: (project) => dispatch(setActiveProject(project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminProjectView);
