import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bulkDeleteItems } from '../../actions/itemActions';
import Button from '../../components/Button/Button';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './DeleteItemsBulkModal.scss';

// confirm bulk delete modal, could reuse the other delete modal
const DeleteItemsBulkModal = ({ deleteItems, loading, translations }) => {
  const {
    bulkDelete__helper_text,
    bulkDelete__step1_text,
    bulkDelete__step2_text,
    bulkDelete__delete_button,
  } = translations.byKeyTranslations;
  const [range, setRange] = useState(0);

  return (
    <div>
      <p>{bulkDelete__helper_text}</p>

      <h4>{bulkDelete__step1_text}</h4>
      <div className="delete-modal__range">
        <Slider
          min={0}
          max={100}
          onChange={setRange}
          trackStyle={[{ backgroundColor: '#6cf' }]}
          handleStyle={{
            borderColor: '#6cf',
            backgroundColor: 'white',
          }}
        />
      </div>

      <h4>{bulkDelete__step2_text}</h4>

      <div className="delete-modal__button">
        <Button
          onClick={deleteItems}
          danger
          disabled={range < 100}
          loading={loading.loading}
        >
          {bulkDelete__delete_button}
        </Button>
      </div>
    </div>
  );
};

DeleteItemsBulkModal.propTypes = {
  // data: string,
  // deleteProject: func,
  deleteItems: func,
  loading: object,
  translations: object,
};

const mapStateToProps = ({ loading, translations }) => ({
  loading,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  deleteItems: () => dispatch(bulkDeleteItems()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteItemsBulkModal);
