import React, { useRef } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Label from '../Label/Label';
import { genericYearDate } from '../../helpers/formatDates';
import DayPicker from 'react-day-picker';
import DropDown from '../DropDown/DropDown';
import Button from '../Button/Button';
import { isPast, isToday } from 'date-fns';
import getPermissions from '../../helpers/getPermissions';
import { updateActiveIssue, updateTask } from '../../actions/itemActions';
import { changeBulkEdit } from '../../actions/bulkActions';

import 'react-day-picker/lib/style.css';

const IssueDocumentInfo = ({
  dashboard,
  updateField,
  bulkEditMode,
  updateBulkEdit,
  bulkEdit,
  translations,
  hideLabel,
  dueTask,
  updateDueTask,
}) => {
  const { field__dueDate_tag } = translations.byKeyTranslations;
  const dueDateDropRef = useRef();
  const { _id, dueDate } = bulkEditMode
    ? bulkEdit
    : dueTask
    ? dueTask
    : dashboard.activeIssue;
  const canEditDueDate = getPermissions('item:edit:dueDate');

  const DueDateLabel = () => (
    <Label
      hover={canEditDueDate}
      danger={
        dueDate
          ? isPast(Date.parse(dueDate)) && !isToday(Date.parse(dueDate))
          : false
      }
      warn={dueDate ? isToday(Date.parse(dueDate)) : false}
    >
      {genericYearDate(dueDate) || '-'}
    </Label>
  );

  const clearAction = () => {
    switch (true) {
      case !!bulkEditMode:
        return updateBulkEdit('dueDate', '');
      case !!dueTask:
        return updateDueTask(_id, dueTask.childId, { dueDate: '' });
      default:
        return updateField(_id, 'dueDate', '');
    }
  };

  const pickerAction = (value) => {
    switch (true) {
      case !!bulkEditMode:
        return updateBulkEdit('dueDate', value);
      case !!dueTask:
        return updateDueTask(_id, dueTask.childId, { dueDate: value });
      case !!value.getTime() !== new Date(dueDate).getTime():
        return updateField(_id, 'dueDate', value);
      default:
        return updateField(_id, 'dueDate', '');
    }
  };

  return (
    <div>
      {!hideLabel && <h5>{field__dueDate_tag}</h5>}

      {canEditDueDate ? (
        <DropDown
          border
          right
          input={<DueDateLabel />}
          output={
            <div>
              <DayPicker
                onDayClick={(value) => {
                  dueDateDropRef.current.closeAction();
                  pickerAction(value);
                }}
                selectedDays={new Date(dueDate)}
              />
              {dueDate && (
                <div className="issue-document__button">
                  <Button
                    onClick={() => {
                      dueDateDropRef.current.closeAction();
                      clearAction();
                    }}
                  >
                    Clear Due Date
                  </Button>
                </div>
              )}
            </div>
          }
          customCloseAction={true}
          ref={dueDateDropRef}
        />
      ) : (
        <DueDateLabel />
      )}
    </div>
  );
};

IssueDocumentInfo.propTypes = {
  dashboard: object,
  project: object,
  updateField: func,
  bulkEditMode: bool,
  updateBulkEdit: func,
  bulkEdit: object,
  translations: object,
  hideLabel: bool,
  dueTask: object,
  updateDueTask: func,
};

const mapStateToProps = ({ dashboard, project, bulkEdit, translations }) => ({
  dashboard,
  project,
  bulkEdit,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  updateBulkEdit: (target, value) => dispatch(changeBulkEdit(target, value)),
  updateDueTask: (parent, child, value) =>
    dispatch(updateTask(parent, child, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocumentInfo);
