import constants from '../constants';

const { WORKSPACE_EDIT_UPDATE, WORKSPACE_EDIT_CLEAR, WORKSPACE_EDIT_BULK } =
  constants;

export const loadCurrentWorkspace = (bulkData) => async (dispatch) => {
  dispatch({ type: WORKSPACE_EDIT_BULK, bulkData });
};

export const updateWorkspaceAction =
  ({ field, value }) =>
  async (dispatch) =>
    dispatch({ type: WORKSPACE_EDIT_UPDATE, field, value });

export const clearWorkspaceEdit = () => async (dispatch) =>
  dispatch({ type: WORKSPACE_EDIT_CLEAR });
