// these are the search filters base
const filters = () => ({
  status: [],
  priority: [],
  tags: [],
  assignee: [],
  creator: [],
  dueDate: '',
  location: [],
  subscriber: [],
  createdAtFrom: '',
  createdAtTo: '',
});

export default filters;
