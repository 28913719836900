import React, { useEffect, Fragment } from 'react';
import Link from '../Link/Link';
import { Link as LinkRouter } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import logo from '../../assets/logo.svg';
import { logoutAction } from '../../actions/loginActions';
import Badge from '../UI/Badge/Badge';
import DropDown from '../DropDown/DropDown';
import NotificationFeed from '../NotificationFeed/NotificationFeed';
import { getUserNotifications } from '../../actions/notificationActions';
import useInterval from '../../helpers/useInterval';
import { fetchCurrentUser } from '../../actions/loginActions';
import Avatar from '../UI/Avatar/Avatar';
import imageUrl from '../../helpers/imageUrl';
import { push } from 'connected-react-router';
import useMsal from '../../hooks/useMsal';
import { msalConfig } from '../../config/authConfig';
import HeroIconBell from '../HeroIcon/HeroIconBell';
import HeroIconArrowLeft from '../HeroIcon/HeroIconArrowLeft';

import { openModal } from '../../actions/modalActions';

import './TopNav.scss';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const feedbackModal = {
  heading: 'Share your thoughts',
  contents: 'FeedbackModal',
};

// Very top navigation bar with logo and user
const TopNav = ({
  login,
  logout,
  fetchNotifcations,
  fetchLoginUser,
  translations,
  project,
  push,
  openModal,
}) => {
  const {
    topNav__accountSettings_link,
    topNav__logout_link,
    topNav__teamSettings_link,
    workspaceNav__feedback_button,
  } = translations.byKeyTranslations;

  const { notifications, userProfile = {} } = login;
  const { picture = {}, strategy } = userProfile;

  const { msalLogout } = useMsal(msalConfig);

  useEffect(() => {
    // fetch user profile
    fetchLoginUser();
  }, []); // eslint-disable-line

  useInterval(() => {
    // every 60 seconds check if user has new notifcations
    fetchLoginUser();
  }, 60 * 1000);

  return (
    <div className="top-nav__container">
      <div className="top-nav__top" id="top__nav__target">
        <div className="top-nav__logo">
          <div
            className="top-nav__back"
            onClick={() =>
              push(
                `/project/${
                  project.activeProject._id ? project.activeProject._id : ''
                }`
              )
            }
          >
            <HeroIconArrowLeft />
          </div>

          <Link to="/">
            <img src={logo} alt="site logo" />
          </Link>
        </div>

        <div className="top-nav__navigation">
          {login.user.globalAdmin && (
            <Menu as="div" className="menu">
              <Menu.Button>Admin</Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-out duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="menu__items top-nav__admin-links">
                  <Menu.Item>
                    <div>
                      <Link to="/admin" dark>
                        Stats
                      </Link>
                    </div>
                  </Menu.Item>
                  {/* Disabled Admin projects to stop killing the server */}
                  {/* <Menu.Item>
                    <div>
                      <Link to="/admin/projects" dark>
                        Projects
                      </Link>
                    </div>
                  </Menu.Item> */}
                  <Menu.Item>
                    <div>
                      <Link to="/admin/users" dark>
                        Users
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div>
                      <Link to="/admin/feedback" dark>
                        Feedback
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div>
                      <Link to="/admin/teams" dark>
                        Teams
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div>
                      <Link to="/admin/translations" dark>
                        Translations
                      </Link>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div>
                      <Link to="/admin/tokens" dark>
                        Tokens
                      </Link>
                    </div>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          )}

          <DropDown
            input={
              <span className="top-nav__notifcations">
                <span className="top-nav__notifcations--icon">
                  <HeroIconBell size={25} />
                </span>
                {!!notifications && (
                  <span className="top-nav__notifcations--badge">
                    <Badge value={notifications} theme="danger" />
                  </span>
                )}
              </span>
            }
            output={<NotificationFeed />}
            right
            action={fetchNotifcations}
            blur={fetchLoginUser}
            style={{ marginRight: '32px' }}
          />

          <div>
            <Menu as="div" className="menu">
              <div>
                <Menu.Button className="menu__button--topNav">
                  <Avatar
                    size="medium"
                    src={picture ? imageUrl(picture.key, picture.url) : ''}
                    borderColor="#383e48"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="menu__items--topNav ">
                  <div className="menu__padding">
                    <Menu.Item>
                      {({ active }) => (
                        <LinkRouter
                          to="/profile#account"
                          className={classNames(
                            active ? 'menu__item1' : 'menu__item2',
                            'menu__item3'
                          )}
                        >
                          {topNav__accountSettings_link}
                        </LinkRouter>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <LinkRouter
                          to="/teams"
                          className={classNames(
                            active ? 'menu__item1' : 'menu__item2',
                            'menu__item3'
                          )}
                        >
                          {topNav__teamSettings_link}
                        </LinkRouter>
                      )}
                    </Menu.Item>
                    {/* Move feedback button here */}
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => openModal(feedbackModal)}
                          className={classNames(
                            active ? 'menu__item1' : 'menu__item2',
                            'menu__item3'
                          )}
                        >
                          {workspaceNav__feedback_button}
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => logout(msalLogout, strategy)}
                          className={classNames(
                            active ? 'menu__item1' : 'menu__item2',
                            'menu__item3'
                          )}
                        >
                          {topNav__logout_link}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

TopNav.propTypes = {
  login: object,
  logout: func,
  match: object,
  fetchNotifcations: func,
  fetchLoginUser: func,
  translations: object,
  project: object,
  history: object,
  push: func,
  openModal: func,
};

const mapStateToProps = ({ login, translations, project, history }) => ({
  login,
  translations,
  project,
  history,
});

const mapDispatchToProps = (dispatch) => ({
  logout: (msalLogout, strategy) =>
    dispatch(logoutAction(msalLogout, strategy)),
  fetchNotifcations: () => dispatch(getUserNotifications()),
  fetchLoginUser: () => dispatch(fetchCurrentUser()),
  push: (url) => dispatch(push(url)),
  openModal: (data) => dispatch(openModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
