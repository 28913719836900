import React, { useRef, useEffect, useState, Fragment } from 'react';
import { string, object, func } from 'prop-types';
import { genericYearDate } from '../../helpers/formatDates';
import Seen from '../Seen/Seen';
import Label from '../Label/Label';
import cx from 'classnames';
import Tag from '../Tag/Tag';
import { setActiveIssue } from '../../actions/dashboardActions';
import { connect } from 'react-redux';
import Dragger from '../Dragger/Dragger';
import ThumbNail from '../ThumbNail/ThumbNail';
import { closeMenu } from '../../actions/menuActions';
import imageUrl from '../../helpers/imageUrl';
import { format, isPast, isToday } from 'date-fns';
import getUrlProps from '../../helpers/getUrlProps';
import { addNewCoverImage } from '../../actions/itemActions';
import { userLabelText } from '../../helpers/userLabel';
import Checkbox from '../CheckBox/CheckBox';
import { addItemsToBulk } from '../../actions/bulkActions';
import { openModal, closeModal } from '../../actions/modalActions';
import {
  setComfortableList,
  setCompactList,
} from '../../actions/dashboardActions';
import HeroIconPlus from '../HeroIcon/HeroIconPlus';

import './SortTableRow.scss';

const modal = {
  contents: 'UploadsModal',
  heading: 'Upload Cover image',
  maxWidth: '480px',
};

// this is the row used in the main table for the items list
const SortTableRow = ({
  setActive,
  item = {},
  login,
  newAsset,
  closeM,
  router,
  project,
  bulkEdit,
  addToBulk,
  open,
  close,
  dashboard,
}) => {
  const scrollPos = useRef();
  const tagRef = useRef();
  const tagWidthRef = useRef();
  const [tagSlice, setTagSlice] = useState({
    count: 30,
    width: 0,
    overFlow: false,
  });
  //const [density, setDensity] = useState('comfortable')
  const { id } = login.user;
  const { locations = [] } = project.activeProject;
  const locationCount = locations.length;
  const issueUrl = getUrlProps('item', router.location.pathname);
  const {
    _id,
    itemId,
    seenBy,
    coverImage,
    name,
    descriptionText,
    tags,
    priority,
    status,
    assignee,
    dueDate,
    createdAt,
    location,
  } = item;
  const parseDesc = descriptionText;

  useEffect(() => {
    if (scrollPos.current && _id === issueUrl) {
      scrollPos.current.scrollIntoView({ block: 'center' });
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const boxWidth = Math.floor(tagWidthRef.current.clientWidth);
    const children = tagRef.current && Array.from(tagRef.current.children);

    const calTags = children.reduce(
      (acc, item) => {
        // work out how many tags we can fit and show +x for the rest
        const itemWidth = Math.ceil(item.getBoundingClientRect().width) + 5; // 5px margin right
        if (acc.width + itemWidth > boxWidth) {
          acc.count = acc.count - 1;
          acc.width = acc.width - itemWidth;
          acc.overFlow = true;
        } else {
          acc.count = acc.count + 1;
          acc.width = acc.width + itemWidth;
        }

        return acc;
      },
      { count: 0, width: 0, overFlow: false }
    );
    setTagSlice(calTags);
  }, [tags, dashboard.listDensity]);

  const compactList = () => {
    return (
      <div
        key={_id}
        className={cx('sort-table__container--compact', {
          'sort-table__table--active': _id === issueUrl,
        })}
        onClick={
          bulkEdit.open
            ? () => addToBulk(_id)
            : () => {
                setActive(item);
                closeM();
              }
        }
        ref={scrollPos}
      >
        {status === 'Closed' && <div className="sort-table__table--closed" />}

        <div className="sort-table__seen">
          <div
            className={cx('sort-table__bulk-edit--compact', {
              'sort-table__bulk-edit--visible': bulkEdit.open,
            })}
          >
            <Checkbox isChecked={bulkEdit.items.includes(_id)} />
          </div>
          <div className="sort-table__seen--icon--compact">
            <Seen seen={seenBy && !!seenBy.find((item) => item.user === id)} />
          </div>
        </div>

        <div className="sort-table__split">
          <div className="sort-table__split--top">
            <div className="sort-table__desc-container--compact">
              <div className="sort-table__name--compact">{name}</div>
              <div className="sort-table__desc--loz--compact" ref={tagWidthRef}>
                {tags &&
                  tags
                    .slice(0, tagSlice.count)
                    .map((item) => <Tag text={item.slice(0, 5)} key={item} />)}
                {tags && tags.length > tagSlice.count && (
                  <Tag
                    text={`+ ${tags.length - tagSlice.count}`}
                    active={true}
                    title={tags.slice(tagSlice.count, tags.length).join(', ')}
                  />
                )}
                <div className="sort-table__desc--hidden-tags" ref={tagRef}>
                  {tags &&
                    tags.map((item) => (
                      <Tag text={item.slice(0, 5)} key={item} />
                    ))}
                </div>
              </div>

              <div className="sort-table__labels">
                <Label>{priority}</Label>
                <Label>{status}</Label>
                <Label>{userLabelText(assignee)}</Label>
                <Label
                  danger={
                    dueDate
                      ? isPast(Date.parse(dueDate)) &&
                        !isToday(Date.parse(dueDate))
                      : false
                  }
                  warn={dueDate ? isToday(Date.parse(dueDate)) : false}
                >
                  {dueDate ? genericYearDate(dueDate) : '-'}
                </Label>
                {locationCount > 0 && <Label>{location || '-'}</Label>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const comfortableList = () => {
    return (
      <div
        key={_id}
        className={cx('sort-table__container', {
          'sort-table__table--active': _id === issueUrl,
        })}
        onClick={
          bulkEdit.open
            ? () => addToBulk(_id)
            : () => {
                setActive(item);
                closeM();
              }
        }
        ref={scrollPos}
      >
        {status === 'Closed' && <div className="sort-table__table--closed" />}

        <div className="sort-table__seen">
          <div
            className={cx('sort-table__bulk-edit', {
              'sort-table__bulk-edit--visible': bulkEdit.open,
            })}
          >
            <Checkbox isChecked={bulkEdit.items.includes(_id)} />
          </div>
          <div className="sort-table__seen--icon">
            <Seen seen={seenBy && !!seenBy.find((item) => item.user === id)} />
            <span className="sort-table__seen-text">{itemId || '##'}</span>
          </div>
          <div>{format(Date.parse(createdAt), 'dd/MM/yy')}</div>
        </div>

        <div className="sort-table__cover-image">
          {coverImage && coverImage.url ? (
            <ThumbNail
              url={imageUrl(coverImage.key, coverImage.url)}
              flex={true}
              noLink={true}
              darkBorder
            />
          ) : (
            <Dragger
              action={(e) => {
                const file = e.dataTransfer.files[0];
                newAsset(_id, file);
              }}
            >
              <div
                className="sort-table__cover-image--placeholder"
                onClick={() =>
                  open({
                    ...modal,
                    data: {
                      callback: (file) => {
                        close();
                        newAsset(_id, file);
                      },
                    },
                  })
                }
              >
                <HeroIconPlus />
              </div>
            </Dragger>
          )}
        </div>

        <div className="sort-table__split">
          <div className="sort-table__split--top">
            <div className="sort-table__desc-container">
              <div className="sort-table__name">{name}</div>
              {parseDesc && <div className="sort-table__desc">{parseDesc}</div>}
            </div>
          </div>

          <div className="sort-table__split--bottom">
            <div className="sort-table__desc--loz" ref={tagWidthRef}>
              {tags &&
                tags
                  .slice(0, tagSlice.count)
                  .map((item) => <Tag text={item.slice(0, 5)} key={item} />)}
              {tags && tags.length > tagSlice.count && (
                <Tag
                  text={`+ ${tags.length - tagSlice.count}`}
                  active={true}
                  title={tags.slice(tagSlice.count, tags.length).join(', ')}
                />
              )}
              <div className="sort-table__desc--hidden-tags" ref={tagRef}>
                {tags &&
                  tags.map((item) => (
                    <Tag text={item.slice(0, 5)} key={item} />
                  ))}
              </div>
            </div>

            <div className="sort-table__labels">
              <Label>{priority}</Label>
              <Label>{status}</Label>
              <Label>{userLabelText(assignee)}</Label>
              <Label
                danger={
                  dueDate
                    ? isPast(Date.parse(dueDate)) &&
                      !isToday(Date.parse(dueDate))
                    : false
                }
                warn={dueDate ? isToday(Date.parse(dueDate)) : false}
              >
                {dueDate ? genericYearDate(dueDate) : '-'}
              </Label>
              {locationCount > 0 && <Label>{location || '-'}</Label>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {dashboard.listDensity === 'compact' ? compactList() : comfortableList()}
    </Fragment>
  );
};

SortTableRow.propTypes = {
  item: object,
  setActive: func,
  login: object,
  url: string,
  newAsset: func,
  closeM: func,
  router: object,
  project: object,
  bulkEdit: object,
  addToBulk: func,
  open: func,
  close: func,
  dashboard: object,
};

const mapStateToProps = ({ login, router, project, bulkEdit, dashboard }) => ({
  login,
  router,
  project,
  bulkEdit,
  dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  setActive: (data) => dispatch(setActiveIssue(data)),
  newAsset: (id, data) => dispatch(addNewCoverImage(id, data)),
  closeM: () => dispatch(closeMenu()),
  addToBulk: (id) => dispatch(addItemsToBulk(id)),
  open: (data) => dispatch(openModal(data)),
  close: () => dispatch(closeModal()),
  setCompactList: () => dispatch(setCompactList()),
  setComfortableList: () => dispatch(setComfortableList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortTableRow);
