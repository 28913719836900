import React, { useState } from 'react';
import { oneOfType, arrayOf, node, func, bool } from 'prop-types';
import cx from 'classnames';

import './Dragger.scss';

// dragger wrapper
const Dragger = ({ action, children, offset }) => {
  const [dropActive, setDropActive] = useState(false);
  const [enterTarget, setEnterTarget] = useState('');

  return (
    <div
      onDrop={e => {
        e.preventDefault();
        e.stopPropagation();
        setDropActive(false);
        action && action(e);
      }}
      onDragOver={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnter={e => {
        e.preventDefault();
        e.stopPropagation();
        setEnterTarget(e.target);
        setDropActive(true);
      }}
      onDragLeave={e => {
        if (enterTarget === e.target) {
          e.stopPropagation();
          e.preventDefault();
          setDropActive(false);
        }
      }}
      className="dragger__container"
    >
      <div
        className={cx({
          'dragger__container--active': !offset && dropActive,
          'dragger__container--active-offset': offset && dropActive,
        })}
      />
      {children}
    </div>
  );
};
Dragger.propTypes = {
  action: func,
  children: oneOfType([arrayOf(node), node]).isRequired,
  offset: bool,
};

export default Dragger;
