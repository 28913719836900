const Avatar = ({ size, src, borderColor = null, alt }) => {
  const sizeLookup = {
    xsmall: 'inline-block h-4 w-4 rounded-full',
    small: 'inline-block h-6 w-6 rounded-full',
    medium: 'inline-block h-8 w-8 rounded-full',
    large: 'inline-block h-10 w-10 rounded-full',
    xlarge: 'inline-block h-24 w-24 rounded-full',
    xxlarge: 'inline-block h-32 w-32 rounded-full',
  };

  const pickSize = size ? sizeLookup[size] : sizeLookup.medium;

  const pickBorder = borderColor
    ? { border: '2px solid ' + borderColor }
    : { border: null };

  return (
    <>
      {src !== '' ? (
        <img
          className={pickSize}
          src={src}
          alt={alt ? alt : ''}
          style={pickBorder}
        />
      ) : (
        <span
          className={pickSize + ' overflow-hidden bg-gray-100'}
          style={pickBorder}
        >
          <svg
            className="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      )}
    </>
  );
};

export default Avatar;
