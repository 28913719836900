import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { closeModal } from '../../actions/modalActions';
import index from '../../modals';
import { withRouter, matchPath } from 'react-router';
import cx from 'classnames';
import HeroIconX from '../HeroIcon/HeroIconX';

import { Dialog, Transition } from '@headlessui/react';

import './Modal.scss';

// modal wrapper, just passes options to a modal constructor
const Modal = ({ modal = {}, close, history, translations }) => {
  const GetModal = modal.contents ? index[modal.contents] : null;

  const match = matchPath(history.location.pathname, {
    path: '/project/:projectId',
  });
  const { heading, editMode, maxWidth, data, isOpen } = modal;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        open={isOpen}
        onClose={close}
        className="relative z-[100] bg-white"
        as="div"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-out duration-1000"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#091e42] bg-opacity-50" />
        </Transition.Child>

        {/* <div
          className={cx(
            'fixed inset-x-0 md:top-12 top-0 mx-auto max-w-full',
            maxWidth ? `w-[${maxWidth}]` : `w-[1000px]`
          )}
        >
          <div className="fixed inset-x-0 overflow-y-auto"> */}
        <div className="fixed inset-x-0 overflow-y-auto md:top-12 sm:top-0 bottom-0 flex justify-center">
          <div
            className={cx(
              'max-w-full',
              maxWidth === 'full'
                ? 'w-full'
                : maxWidth
                ? `w-[${maxWidth}]`
                : 'w-[1000px]'
            )}
          >
            <div className="min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-in duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-out duration-1000"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={cx(
                    'mx-auto min-h-[100px] max-h-[90vh] transform overflow-auto md:rounded bg-white p-6 text-left align-middle shadow-xl transition-all'
                  )}
                >
                  <div
                    className={cx('modal__heading', {
                      'modal__heading--no-text': !heading,
                    })}
                  >
                    <h1>{heading}</h1>
                    <div className="modal__close" onClick={close}>
                      <HeroIconX paddingTop={10} />
                    </div>
                  </div>
                  {GetModal && (
                    <GetModal
                      projectId={match && match.params.projectId}
                      editMode={editMode}
                      data={data}
                      translations={translations.byKeyTranslations}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

Modal.propTypes = {
  modal: object,
  close: func,
  props: object,
  history: object,
  translations: object,
};

const mapStateToProps = ({ modal, translations }) => ({
  modal,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Modal));
