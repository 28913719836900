import { get } from 'lodash';

// turns items into select data e.g {label: '', value: ''}
const tagify = (arr) => {
  // remove this once data is normalised
  if (get(arr, '[0].name', '')) {
    return arr.map((item) => ({
      label: item.name.toUpperCase(),
      value: item.name,
    }));
  } else if (arr) {
    return arr.map((item) => ({
      label: item.toUpperCase(),
      value: item,
    }));
  }
};

// turns select data into array
const deTagify = (arr) => arr.map((item) => item.value);

export { tagify, deTagify };
