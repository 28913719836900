import './HeroIcon.scss';

const HeroIconDotsVertical = ({
  size = 18,
  marginRight = 0,
  fillColor = '#fff',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="heroicon"
      style={{ width: size, marginRight }}
      viewBox="0 0 20 20"
      // fill="#9a9fa7"
      fill={fillColor}
    >
      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
    </svg>
  );
};

export default HeroIconDotsVertical;
