import React, { useEffect, useRef } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import {
  filterDashboard,
  filterDashboardMulti,
  setComfortableList,
  setCompactList,
} from '../../actions/dashboardActions';
import { updateUserSettings } from '../../actions/createNewProjectActions';
import cx from 'classnames';
import Filters from '../Filters/Filters';
import { openMenu, closeMenu } from '../../actions/menuActions';
import Chevvy from '../Chevvy/Chevvy';
import Tag from '../Tag/Tag';
import { sortClick } from '../../actions/sortTableActions';
import Link from '../Link/Link';
import { genericYearDate } from '../../helpers/formatDates';
import { isSameDay } from 'date-fns';

import './SortFilterSection.scss';

// filter drop down with sections
const SortFilterSection = ({
  dashboard,
  project,
  updateFilter,
  menu,
  openM,
  closeM,
  sortClickAction,
  sortTable,
  updateMultiFilter,
  teams,
  translations,
  setComfortableList,
  setCompactList,
  updateUserSettings,
}) => {
  const {
    mainSection__filters_link,
    mainSection__sort_link,
    field__creationDate_tag,
    field__recentlyUpdated_tag,
    field__title_tag,
    field__priority_tag,
    field__status_tag,
    field__assignee_tag,
    field__dueDate_tag,
    field__location_tag,
  } = translations.byKeyTranslations;

  const outputRef = useRef();
  const outputButtonRef = useRef();
  const sortRef = useRef();
  const sortButtonRef = useRef();
  const densityRef = useRef();
  const densityButtonRef = useRef();
  const { target, aToZ } = sortTable;

  const getUser = (id, letter) => {
    const { label } =
      teams.activeTeamLabels.find((item) => item.value === id) || {};
    return letter ? `${letter} ${label || '-'}` : label || '-';
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return function () {
      document.removeEventListener('click', handleClick);
    };
  });

  const handleClick = ({ target }) => {
    if (
      !outputButtonRef.current?.contains(target) &&
      !outputRef.current?.contains(target) &&
      menu.filterMenu
    ) {
      closeM();
    } else if (
      !sortButtonRef.current?.contains(target) &&
      !sortRef.current?.contains(target) &&
      menu.sortMenu
    ) {
      closeM();
    } else if (
      !densityButtonRef.current?.contains(target) &&
      !densityRef.current?.contains(target) &&
      menu.densityMenu
    ) {
      closeM();
    }
  };

  const {
    priority,
    status,
    dueDate,
    createdAtFrom,
    createdAtTo,
    tags,
    location = [],
    assignee,
    creator,
    subscriber = [],
  } = dashboard.filters;

  const createDateSet = createdAtFrom || createdAtTo;
  const singleDate = isSameDay(
    Date.parse(createdAtFrom),
    Date.parse(createdAtTo)
  );

  const filtersArr = [
    ...priority,
    ...status,
    ...assignee.map((item) => getUser(item)),
    ...creator.map((item) => getUser(item)),
    ...subscriber.map((item) => getUser(item)),
    dueDate ? `${field__dueDate_tag}: ${dueDate}` : undefined,
    createDateSet
      ? singleDate
        ? `Created At: ${genericYearDate(createdAtFrom)}`
        : `Created At: ${genericYearDate(createdAtFrom)} - ${genericYearDate(
            createdAtTo
          )}`
      : undefined,
    ...tags,
    ...location,
  ].filter(Boolean);

  return (
    <div className="dashboard__top">
      <div className="dashboard__top-filter-container">
        <p className="dashboard__top-text">
          {`Items ${dashboard.sortedIssues.length} of
          ${dashboard.issues.length}`}
        </p>
        <div className="dashboard__top-filter-child">
          {filtersArr.map((item) => (
            <Tag text={item} key={item} active />
          ))}
        </div>
      </div>

      <div>
        <div className="dashboard__filter-item">
          <h5
            className="dashboard__filter-menu--click"
            onClick={() => (menu.filterMenu ? closeM() : openM('filterMenu'))}
            ref={outputButtonRef}
          >
            {mainSection__filters_link} <Chevvy active={menu.filterMenu} />
          </h5>
          <div className="dashboard__filter-menu">
            <div
              className={cx('dashboard__filter-menu-container', {
                'dashboard__filter-menu--open': menu.filterMenu,
              })}
            >
              <div className="dashboard__filter-menu-inner" ref={outputRef}>
                <Filters
                  filters={dashboard.filters}
                  updateFilter={updateFilter}
                  updateMultiFilter={updateMultiFilter}
                  project={project.activeProject}
                  issues={dashboard.issues}
                  translations={translations}
                />
              </div>
            </div>
          </div>
          <h5
            className="dashboard__filter-menu--click"
            onClick={() => (menu.sortMenu ? closeM() : openM('sortMenu'))}
            ref={sortButtonRef}
          >
            {mainSection__sort_link} <Chevvy active={menu.sortMenu} />
          </h5>

          <div className="dashboard__filter-menu">
            <div
              className={cx(
                'dashboard__filter-menu-container dashboard__filter-menu-container--small',
                {
                  'dashboard__filter-menu--open': menu.sortMenu,
                }
              )}
            >
              <div
                className="dashboard__filter-menu-inner dashboard__filter-menu--sort"
                ref={sortRef}
              >
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'createdAt'}
                    onClick={() => sortClickAction('createdAt')}
                  >
                    {field__creationDate_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'createdAt' && aToZ}
                    activeColor={target === 'createdAt'}
                  />
                </div>

                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'updatedAt'}
                    onClick={() => sortClickAction('updatedAt')}
                  >
                    {field__recentlyUpdated_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'updatedAt' && aToZ}
                    activeColor={target === 'updatedAt'}
                  />
                </div>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'name'}
                    onClick={() => sortClickAction('name')}
                  >
                    {field__title_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'name' && aToZ}
                    activeColor={target === 'name'}
                  />
                </div>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'priority'}
                    onClick={() => sortClickAction('priority')}
                  >
                    {field__priority_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'priority' && aToZ}
                    activeColor={target === 'priority'}
                  />
                </div>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'status'}
                    onClick={() => sortClickAction('status')}
                  >
                    {field__status_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'status' && aToZ}
                    activeColor={target === 'status'}
                  />
                </div>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'assignee.firstName'}
                    onClick={() => sortClickAction('assignee.firstName')}
                  >
                    {field__assignee_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'assignee.firstName' && aToZ}
                    activeColor={target === 'assignee.firstName'}
                  />
                </div>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'dueDate'}
                    onClick={() => sortClickAction('dueDate')}
                  >
                    {field__dueDate_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'dueDate' && aToZ}
                    activeColor={target === 'dueDate'}
                  />
                </div>

                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={target === 'location'}
                    onClick={() => sortClickAction('location')}
                  >
                    {field__location_tag}
                  </Link>
                  <Chevvy
                    activeRotate={target === 'location' && aToZ}
                    activeColor={target === 'location'}
                  />
                </div>
              </div>
            </div>
          </div>
          <h5
            className="dashboard__filter-menu--click"
            onClick={() => (menu.densityMenu ? closeM() : openM('densityMenu'))}
            ref={densityButtonRef}
          >
            List <Chevvy active={menu.densityMenu} />
          </h5>
          <div className="dashboard__filter-menu">
            <div
              className={cx(
                'dashboard__filter-menu-container dashboard__filter-menu-container--comfortable',
                {
                  'dashboard__filter-menu--open': menu.densityMenu,
                }
              )}
            >
              <div className="dashboard__filter-menu-inner" ref={densityRef}>
                <div className="dashboard__filter-menu--container">
                  <Link
                    dark
                    bright={dashboard.listDensity === 'comfortable'}
                    onClick={() => {
                      setComfortableList();
                      updateUserSettings('comfortable');
                      closeM();
                    }}
                  >
                    Comfortable
                  </Link>
                </div>
                <div className="dashboard__filter-menu--container" />
                <Link
                  dark
                  bright={dashboard.listDensity === 'compact'}
                  onClick={() => {
                    setCompactList();
                    updateUserSettings('compact');
                    closeM();
                  }}
                >
                  Compact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SortFilterSection.propTypes = {
  dashboard: object,
  match: object,
  project: object,
  updateFilter: func,
  menu: object,
  openM: func,
  closeM: func,
  login: object,
  openModal: func,
  sortClickAction: func,
  sort: object,
  sortTable: object,
  updateMultiFilter: func,
  teams: object,
  translations: object,
  setComfortableList: func,
  setCompactList: func,
  updateUserSettings: func,
};

const mapStateToProps = ({
  dashboard,
  project,
  menu,
  sortTable,
  teams,
  translations,
}) => ({
  dashboard,
  project,
  menu,
  sortTable,
  teams,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (taget, value) => dispatch(filterDashboard(taget, value)),
  updateMultiFilter: (data) => dispatch(filterDashboardMulti(data)),
  openM: (item) => dispatch(openMenu(item)),
  closeM: () => dispatch(closeMenu()),
  sortClickAction: (data) => dispatch(sortClick(data)),
  setComfortableList: () => dispatch(setComfortableList()),
  setCompactList: () => dispatch(setCompactList()),
  updateUserSettings: (data) => dispatch(updateUserSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortFilterSection);
