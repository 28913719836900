import React, { useEffect } from 'react';
import { oneOfType, arrayOf, node, func } from 'prop-types';
import TopNav from '../TopNav/TopNav';
import Modal from '../Modal/Modal';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import './AdminContainer.scss';

// wrapper for admin sections
const AdminContainer = ({ children, redirect }) => {
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      redirect();
    }
  }, []); // eslint-disable-line

  return (
    <div className="admin__container">
      <Modal />
      <TopNav />
      <div className="admin__container--child">{children}</div>
    </div>
  );
};

AdminContainer.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  redirect: func,
};

const mapStateToProps = ({ router }) => ({
  router,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: () => dispatch(push('/login')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer);
