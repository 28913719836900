import constants from '../constants';
import filters from '../constants/filters';

const {
  ISSUES_SUCCESS,
  ISSUES_FAILURE,
  ISSUES_SET_ACTIVE,
  CLEAR_FILTER,
  ISSUES_RESET,
  ISSUES_UPDATE_FILTER,
  ISSUES_UPDATE_FILTERED_ISSUES,
  ISSUES_UPDATE_SEARCH_FILTERED_ISSUES,
  ISSUES_SORTED_ISSUES,
  ISSUES_FUZZY_TERM,
  ISSUES_SORTED_CHUNKED,
  RESET_ALL_ISSUES,
  ALL_ISSUE_DATA_UPDATES,
  LOGOUT,
  COMPACT_LIST,
  COMFORTABLE_LIST,
} = constants;

const initState = {
  error: '',
  activeIssue: {},
  filters: filters(),
  projectFuzzyTerm: '',
  issues: [],
  filteredIssues: [],
  filterBySearch: [],
  sortedIssues: [],
  sortedIssuesChunked: [],
  listDensity: 'comfortable',
};

export const dashboard = (state = initState, action) => {
  switch (action.type) {
    case ISSUES_RESET:
      return {
        ...initState,
      };

    case ISSUES_SUCCESS:
      return {
        ...state,
        issues: action.issues,
      };

    case ISSUES_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case ISSUES_SET_ACTIVE:
      return {
        ...state,
        activeIssue: action.activeIssue,
      };

    case ISSUES_UPDATE_FILTER:
      return {
        ...state,
        filters: action.filters ? action.filters : filters(),
      };

    case ALL_ISSUE_DATA_UPDATES:
      return {
        ...state,

        // issues: action.issues,
        filteredIssues: action.filteredIssues,
        filterBySearch: action.filterBySearch,
        sortedIssues: action.sortedIssues,
        sortedIssuesChunked: action.sortedIssuesChunked,
      };

    case ISSUES_UPDATE_FILTERED_ISSUES:
      return {
        ...state,
        filteredIssues: action.filteredIssues,
      };

    case ISSUES_UPDATE_SEARCH_FILTERED_ISSUES:
      return {
        ...state,
        filterBySearch: action.filterBySearch,
      };

    case ISSUES_SORTED_ISSUES:
      return {
        ...state,
        sortedIssues: action.sortedIssues,
      };

    case RESET_ALL_ISSUES:
      return {
        ...state,
        issues: [],
        filteredIssues: [],
        filterBySearch: [],
        sortedIssues: [],
        sortedIssuesChunked: [],
      };

    case ISSUES_SORTED_CHUNKED:
      return {
        ...state,
        sortedIssuesChunked: action.sortedIssuesChunked,
      };

    case ISSUES_FUZZY_TERM:
      return {
        ...state,
        projectFuzzyTerm: action.projectFuzzyTerm,
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filters: filters(),
        filteredIssues: state.issues,
      };

    case COMPACT_LIST:
      return { ...state, listDensity: 'compact' };

    case COMFORTABLE_LIST:
      return { ...state, listDensity: 'comfortable' };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
