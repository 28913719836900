import fetchData from '../helpers/fetch';
import mainApi from '../apis';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import fileParser from '../helpers/fileParser';
import { push } from 'connected-react-router';
import postImage from '../helpers/postImage';
import genUrl from '../helpers/genUrl';
import { get } from 'lodash';
import { projectFetch } from '../actions/projectActions';

const {
  CREATE_NEW_PROJECT_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  MODAL_CLOSE,
  PROJECT_EDIT_CLEAR,
} = constants;

const updateNavis = async (dispatch, zip, id, upateNavis) => {
  const { file } = fileParser(zip);

  if (!file) {
    return false;
  }

  file.append('projectId', id);

  const uploadAsset = {
    url: '/upload-navis',
    type: upateNavis ? 'PATCH' : 'POST',
    body: file,
    isUpload: true,
  };

  try {
    return await fetchData(uploadAsset); // upload zip
  } catch (e) {
    dispatch(toast('error', e));
  }
};

export const updateProject = (template) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateProject' });

  const { editProject, teams } = getState();

  const {
    _id: id,
    name,
    description,
    powerBI,
    locations,
    members,
    coverRef,
    navisRef,
    template_id,
  } = editProject;

  const { _id: activeTeamId } = teams.activeTeam;

  const coverImage = await postImage(dispatch, coverRef);
  const newMembers = members ? members.filter((item) => item.__isNew__) : [];

  const newUsersPost = {
    url: api.REGISTER_URL,
    type: 'POST',
    body: newMembers.map(({ user: { email } }) => email),
  };

  const allNewUsers =
    newMembers.length > 0 ? await fetchData(newUsersPost) : [];

  if (allNewUsers.errors) {
    return dispatch(toast('error', allNewUsers.message));
  }

  const parseNewEmailIds = members
    ? members.map(
        ({ user: { _id, email: topEmail }, memberLevel, listDensity }) => {
          const user = _id
            ? _id
            : get(
                allNewUsers.find(({ email }) => email === topEmail),
                '_id'
              );
          return {
            user,
            memberLevel,
            listDensity,
          };
        }
      )
    : [];

  const body = {
    name,
    description,
    members: parseNewEmailIds,
    locations,
    coverImage,
    powerBI,
    team: activeTeamId,
    template,
    template_id,
  };

  // remove empty for patch or it will patch empty records
  const allowEmpty = [
    'description',
    'powerBI',
    'locations',
    'members',
    'template',
  ];

  const filterEmptyBody = Object.keys(body).reduce((acc, key) => {
    const itemValue = body[key];
    if ((itemValue && itemValue.length) || allowEmpty.includes(key)) {
      acc[key] = itemValue;
    }
    return acc;
  }, {});

  const fetchDataInfo = {
    url: id ? `${api.PROJECTS_API_URL}/${id}` : api.PROJECTS_API_URL,
    type: id ? 'PATCH' : 'POST',
    body: filterEmptyBody,
  };

  try {
    const project = await fetchData(fetchDataInfo);
    await updateNavis(dispatch, navisRef, project._id, id ? true : false);
    dispatch({ type: MODAL_CLOSE });
    dispatch({ type: PROJECT_EDIT_CLEAR });
    if (!id) {
      dispatch(push(genUrl(project._id)));
    }
    dispatch({ type: LOADING_FINISHED, src: 'updateProject' });
    dispatch(projectFetch());
  } catch (e) {
    dispatch({ type: CREATE_NEW_PROJECT_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'updateProject' });
    dispatch(toast('error', e));
  }
};

export const updateUserSettings =
  (listDensity) => async (dispatch, getState) => {
    const { project, login } = getState();

    const members = await project.activeProject.members;

    const updatedMembers = members.map((item) => {
      if (item.user._id === login.user.id) {
        return {
          user: item.user._id,
          memberLevel: item.memberLevel,
          listDensity: listDensity,
        };
      }
      return {
        user: item.user._id,
        memberLevel: item.memberLevel,
        listDensity: item.listDensity || 'comfortable',
      };
    });

    try {
      await mainApi.patch(
        `${api.PROJECTS_API_URL}/${project.activeProject._id}`,
        { name: project.activeProject.name, members: updatedMembers }
      );
    } catch (e) {
      console.log(e);
    }
  };
