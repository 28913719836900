import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import {
  updateAcccountCheck,
  getRegisterUser,
  getAllCompanies,
  clearAllData,
} from '../../actions/registerActions';
import Link from '../../components/Link/Link';
import ExternalWrapper from '../../components/ExternalWrapper/ExternalWrapper';
import { rolesLabel } from '../../constants/labels';
import './Register.scss';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect from 'react-select';

// register route, only from email with id /reigster/id
const Register = ({
  register,
  postRegister,
  getUser,
  match,
  getCompanies,
  clearData,
  translations,
}) => {
  const {
    register__back_button,
    register__chooseDisipline_input,
    register__company_input,
    register__disipline_input,
    register__email_input,
    register__firstName_input,
    register__lastName_input,
    register__password_input,
    register__passwordConfirm_input,
    register__passwordHelper_text,
    register__save_button,
  } = translations.byKeyTranslations;

  // const { error } = register;
  const id = match.params.id;
  const { email = '' } = register.user;

  const {
    register: formRegister,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const [submittingForm, setSubmittingForm] = useState(false);
  const [registerForm, setRegisterForm] = useState(null);

  useEffect(() => {
    clearData();
    getCompanies();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (id) {
      getUser(id);
    }
  }, [id]); // eslint-disable-line

  useEffect(() => {
    setRegisterForm({
      email: email || 'INVALID ACCOUNT ID',
    });
  }, [setRegisterForm, email]);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
      }
    }
  }, [isSubmitting, errors]);

  useEffect(() => {
    reset(registerForm);
  }, [reset, registerForm]);

  return (
    <ExternalWrapper big>
      <form
        onSubmit={handleSubmit((data) => {
          postRegister(id, data);
        })}
        className="grid grid-cols-2 gap-4"
      >
        <div>
          <label>
            {register__firstName_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <input
            className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('firstName', {
              required: ' First name is required',
              validate: {
                moreThanTwo: (v) =>
                  v.length > 2 || 'Needs to be more than 2 characters long',
              },
            })}
            type="text"
          />

          <label>
            {register__lastName_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="lastName"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <input
            className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('lastName', {
              required: ' Last name is required',
              validate: {
                moreThanTwo: (v) =>
                  v.length > 2 || 'Needs to be more than 2 characters long',
              },
            })}
            type="text"
          />

          <label>
            {register__company_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="company"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <input
            className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('company', {
              required: ' Company is required',
              validate: {
                moreThanTwo: (v) =>
                  v.length > 2 || 'Needs to be more than 2 characters long',
              },
            })}
            type="text"
          />

          <label>
            {register__disipline_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="discipline"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <Controller
            name="discipline"
            control={control}
            rules={{ required: ' Discipline is required' }}
            render={({ field }) => (
              <ReactSelect
                {...formRegister('discipline')}
                {...field}
                options={rolesLabel}
                isClearable
                isSearchable
                className="pt-[10px] mb-[10px]"
                placeholder={register__chooseDisipline_input}
                styles={{
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menuList: (base) => ({
                    ...base,
                    maxHeight: '300px',
                  }),
                  control: (base, state) => ({
                    ...base,
                    background: '#fafbfc',
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                    outline: state.isFocused
                      ? '#66ccff solid 2px !important'
                      : 'none',
                    '*': {
                      boxShadow: 'none !important',
                    },
                    '&:hover': {
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? '#9a9fa7'
                      : state.isFocused
                      ? '#f1f5f9'
                      : base.backgroundColor,
                    '&:hover': {
                      backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                    },
                  }),
                }}
              />
            )}
          />
        </div>
        <div>
          <label>
            {register__email_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <input
            className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('email', {
              required: ' Email is required',
            })}
            disabled={true}
            type="email"
          />

          <label>
            {register__password_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>
          <input
            className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('password', {
              required: ' Password is required',
              validate: {
                moreThanEight: (v) =>
                  v.length >= 8 || `${register__passwordHelper_text}`,
                hasLetters: (v) =>
                  v.match(/[a-z]/) != null || 'Invalid password, no letters',
                hasNumbers: (v) =>
                  v.match(/\d/) != null || 'Invalid password, no numbers',
              },
            })}
            defaultValue=""
            type="password"
          />

          <label>
            {register__passwordConfirm_input}
            <span className="text-red-600"> *</span>
            <ErrorMessage
              errors={errors}
              name="confirmPassword"
              render={({ message }) => (
                <span className="text-red-600">{message}</span>
              )}
            />
          </label>

          <input
            className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
            {...formRegister('confirmPassword', {
              required: ' Confirmation password is required',
              validate: {
                matchPassword: (v) =>
                  v === getValues().password || 'Passwords dont match',
              },
            })}
            defaultValue=""
            type="password"
          />
        </div>

        <div className="col-span-2 mt-6">
          <ButtonGroup>
            <Link to="/login" dark inline>
              {register__back_button}
            </Link>

            <Button loading={submittingForm || register.registering}>
              {register__save_button || ''}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </ExternalWrapper>
  );
};

Register.propTypes = {
  register: object,
  postRegister: func,
  getUser: func,
  match: object,
  getCompanies: func,
  clearData: func,
  translations: object,
};

const mapStateToProps = ({ register, translations }) => ({
  register,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postRegister: (id, data) => dispatch(updateAcccountCheck(id, data, true)),
  getUser: (id) => dispatch(getRegisterUser(id)),
  getCompanies: () => dispatch(getAllCompanies()),
  clearData: () => dispatch(clearAllData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
