import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import {
  updateAcccount,
  getAllCompanies,
  updateAcccountCheck,
} from '../../actions/registerActions';
import { fetchCurrentUser } from '../../actions/loginActions';
import { rolesLabel } from '../../constants/labels';
import { openModal } from '../../actions/modalActions';
import imageUrl from '../../helpers/imageUrl';
import Avatar from '../../components/UI/Avatar/Avatar';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect from 'react-select';

import './ProfileAccount.scss';

const modal = {
  contents: 'ProfilePictureModal',
  heading: 'Upload new Profile Picture',
  maxWidth: '480px',
};

// profile account route /profile#account
const ProfileAccount = ({
  register,
  getCompanies,
  login = {},
  getUser,
  saveUser,
  openM,
  translations,
}) => {
  const {
    accountDetailsPage__profilePicture_header,
    accountDetailsPage__accountDetails_header,
    accountDetailsPage__firstName_input,
    accountDetailsPage__lastName_input,
    accountDetailsPage__company_input,
    accountDetailsPage__disipline_input,
    accountDetailsPage__discipline_placeholder,
    accountDetailsPage__email_input,
    accountDetailsPage__save_button,
  } = translations.byKeyTranslations;

  const {
    firstName,
    lastName,
    email,
    company,
    discipline,
    picture = {},
    strategy,
  } = login.userProfile || {};

  const { id } = login.user;

  const {
    register: formRegister,
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [profile, setProfile] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  useEffect(() => {
    getCompanies();
    getUser();
  }, []); // eslint-disable-line

  useEffect(() => {
    setProfile({
      firstName: firstName || '',
      lastName: lastName || '',
      company: company || '',
      discipline: rolesLabel.find((item) => item.value === discipline),
      email: email || '',
    });
  }, [setProfile, firstName, lastName, company, discipline, email]);

  useEffect(() => {
    reset(profile);
  }, [reset, profile]);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
        setTimeout(() => setSubmittingForm(false), 3000);
      }
    }
  }, [getValues, isSubmitting, errors]);

  return (
    <div>
      <h1>{accountDetailsPage__profilePicture_header}</h1>

      <div className="profile__picture">
        <button
          onClick={() => openM({ ...modal })}
          disabled={strategy && strategy !== 'local' ? true : false}
        >
          <Avatar
            src={imageUrl(picture.key, picture.url)}
            size="xxlarge"
            borderColor="#DFE1E6"
          />
        </button>
      </div>

      <h1>{accountDetailsPage__accountDetails_header}</h1>

      <form onSubmit={handleSubmit((data) => saveUser(id, data))}>
        <h5>
          {accountDetailsPage__firstName_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="firstName"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...formRegister('firstName', {
            required: ' First name is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
          })}
          disabled={strategy && strategy !== 'local' ? true : false}
          // defaultValue={firstName || ''}
          type="text"
        />

        <h5>
          {accountDetailsPage__lastName_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="lastName"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...formRegister('lastName', {
            required: ' Last name is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
          })}
          disabled={strategy && strategy !== 'local' ? true : false}
          // defaultValue={lastName || ''}
          type="text"
        />

        <h5>
          {accountDetailsPage__company_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="company"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...formRegister('company', {
            required: ' Company is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
          })}
          disabled={strategy && strategy !== 'local' ? true : false}
          // defaultValue={company || ''}
          type="text"
        />

        <h5>
          {accountDetailsPage__disipline_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="discipline"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="discipline"
          control={control}
          rules={{ required: ' Discipline is required' }}
          render={({ field }) => (
            <ReactSelect
              {...formRegister('discipline')}
              {...field}
              options={rolesLabel}
              isClearable
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              placeholder={accountDetailsPage__discipline_placeholder}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        <h5>
          {accountDetailsPage__email_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...formRegister('email', {
            required: ' Email is required',
          })}
          disabled={strategy && strategy !== 'local' ? true : false}
          // defaultValue={email || ''}
          type="email"
        />

        <div className="mt-6">
          <ButtonGroup>
            <Button
              loading={submittingForm || register.registering}
              disabled={strategy && strategy !== 'local' ? true : false}
            >
              {accountDetailsPage__save_button || ''}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
};

ProfileAccount.propTypes = {
  register: object,
  getUser: func,
  login: object,
  updateUser: func,
  updateSubs: func,
  saveUser: func,
  openM: func,
  getCompanies: func,
  translations: object,
};

const mapStateToProps = ({ register, login, translations }) => ({
  register,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data) => dispatch(updateAcccount(id, data)),
  getUser: () => dispatch(fetchCurrentUser()),
  getCompanies: () => dispatch(getAllCompanies()),
  saveUser: (id, data) => dispatch(updateAcccountCheck(id, data)),
  openM: (target) => dispatch(openModal(target)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccount);
