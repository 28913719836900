import React, { useRef, useState, Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import IssueDocument from '../../components/IssueDocument/IssueDocument';
import { openMenu, closeMenu } from '../../actions/menuActions';
import SortTable from '../../components/SortTable/SortTable';
import getPermissions from '../../helpers/getPermissions';
import { openModal } from '../../actions/modalActions';
import { createNewIssue } from '../../actions/createNewIssuesAction';
import {
  fetchDashboardSettings,
  updateSidebarOpen,
  updateSidebarWidth,
  updateIssueDocWidth,
} from '../../actions/loginActions';
import Button from '../../components/Button/Button';
import LeftNav from '../../components/LeftNav/LeftNav';
import BulkEdit from '../../components/BulkEdit/BulkEdit';
import SortFilterSection from '../../components/SortFilterSection/SortFilterSection';
import cx from 'classnames';
import urlProps from '../../helpers/getUrlProps';
import { push } from 'connected-react-router';
import './Dashboard.scss';
import HeroIconPencilAlt from '../../components/HeroIcon/HeroIconPencilAlt';
import HeroIconPlusCircle from '../../components/HeroIcon/HeroIconPlusCircle';

import { Popover, Transition } from '@headlessui/react';
import { useSpring, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';

import queryString from 'query-string';

const newIssueModal = {
  heading: 'Create new issue',
  contents: 'NewIssueModal',
};

const modal = (id) => ({
  heading: id ? 'Edit Workspace' : 'Create New Workspace',
  contents: 'NewWorkSpaceModal',
});

// main route for projects /project/id/item/id
const Dashboard = ({
  match,
  openModal,
  postNew,
  translations,
  router,
  redirect,
  login,
  menu,
  closeM,
  getDashboardSettings,
  updateSidebarOpen,
  updateSidebarWidth,
  updateIssueDocWidth,
}) => {
  const {
    mainSection__createNew_input,
    mainSection__createNew_button,
    mainSection__createCancel_button,
    workspaceNav__newWorkspace_link,
  } = translations.byKeyTranslations;
  const [inputValue, setInputValue] = useState('');
  const url = match.params.projectId;
  const { search } = useLocation();

  const inputRef = useRef();
  const urlId = urlProps('item', router.location.pathname);

  const teamsViewerWidth = 1492;

  const [sidebarWidth, setSidebarWidth] = useState(256);
  const [issueWidth, setIssueWidth] = useState(440);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.length) {
      postNew({ name: inputValue }, url);
      setInputValue('');
    }
  };

  const saveNew = () => {
    if (inputValue.length) {
      postNew({ name: inputValue }, url);
      setInputValue('');
    }
  };

  const clearNew = () => {
    setInputValue('');
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      redirect();
    }
  }, []); // eslint-disable-line

  // Value stored in database user object
  const [dbSidebarOpen, setDbSidebarOpen] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const buttonRef = useRef(null);

  // SIDEBAR REISZING
  const [{ width: sbWidth }, sbApi] = useSpring(() => {
    return {
      x: 0,
      width: 256,
    };
  });

  const sbBind = useDrag(
    ({ offset: [ox] }) => {
      sbApi.start({
        x: ox,
        width: 256 + ox,
      });
    },
    {
      from: () => [sidebarWidth - 256, 0],
      axis: 'x',
      bounds: { left: 0, right: 200 },
    }
  );

  // ISSUE DOCUMENT RESIZING
  const [{ width: issWidth }, issApi] = useSpring(() => {
    return {
      x: 0,
      width: 440, // start width of right panel
    };
  });
  const issBind = useDrag(
    ({ offset: [ox] }) => {
      issApi.start({
        x: ox,
        width: 440 - ox,
      });
    },
    {
      from: () => [440 - issueWidth, 0],
      axis: 'x',
      bounds: { left: -160, right: 0 }, // how far relative to the start position
    }
  );

  function handleSidebarState() {
    const sidebarState = !sidebarOpen;
    setSidebarOpen(sidebarState);
    updateSidebarOpen(sidebarState);
    setDbSidebarOpen(sidebarState);
  }

  function onHover() {
    buttonRef?.current?.click();
  }

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if (windowSize.width <= teamsViewerWidth && sidebarOpen === true) {
      setSidebarOpen(false);
    }

    if (windowSize.width > teamsViewerWidth && dbSidebarOpen === true) {
      setSidebarOpen(true);
    }

    if (windowSize.width > 600) {
      closeM();
    }
  }, [windowSize]); // eslint-disable-line

  async function fetchData() {
    const values = queryString.parse(search);
    await getDashboardSettings();
    const sidebarState =
      login?.user?.sidebarOpen === undefined ? true : login?.user?.sidebarOpen;
    const sidebarWidthState = login?.user?.sidebarWidth || 256;
    const issueWidthState = login?.user?.issueDocWidth || 440;
    const isTeamsViewer = values?.sidebarOpen === 'false';

    const sidebarInitial =
      isTeamsViewer || window.innerWidth <= teamsViewerWidth
        ? false
        : sidebarState;

    setSidebarOpen(sidebarInitial);
    setDbSidebarOpen(sidebarInitial);

    setIssueWidth(issueWidthState);
    // issApi.start({ x: issueWidthState - 440, width: issueWidthState });
    issApi.set({ x: issueWidthState - 440, width: issueWidthState });
    // issApi.update({ x: issueWidthState - 440, width: issueWidthState });

    setSidebarWidth(sidebarWidthState);
    // sbApi.start({ x: sidebarWidthState - 256, width: sidebarWidthState });
    sbApi.set({ x: sidebarWidthState - 256, width: sidebarWidthState });
    //  sbApi.update({ x: sidebarWidthState - 256, width: sidebarWidthState });
  }

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      {/*Start Sidebar Popout*/}
      {!sidebarOpen && (
        <Popover>
          {({ popoverOpen }) => (
            <div onMouseEnter={onHover} onMouseLeave={onHover} className="z-40">
              <Popover.Button
                ref={buttonRef}
                className="fixed top-0 bottom-0 left-0 z-40 flex bg-bcapp-menulightblue items-center focus:outline-none"
                disabled={windowSize.width < 600}
              >
                <div className="h-full w-2"></div>
              </Popover.Button>
              <Transition
                show={popoverOpen}
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Popover.Panel className="container__left-hover-panel z-50 absolute top-0 left-0 bottom-0">
                  <div className="h-full bg-bcapp-menulightblue min-w-[16rem] rounded-tr-md rounded-br-md shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="flex flex-col pr-2 w-[16rem] h-full">
                      {/* Hover sidebar */}
                      <LeftNav
                        toggleSidebar={handleSidebarState}
                        sidebarShow={sidebarOpen}
                        isHover={true}
                      />
                      {getPermissions('project:workspace:create') && (
                        <div
                          className="left-nav__newWorkspace"
                          onClick={() => openModal(modal())}
                        >
                          {workspaceNav__newWorkspace_link}
                        </div>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          )}
        </Popover>
      )}
      {/*End Sidebar Popout*/}

      <div className="flex w-full h-full fixed top-[112px]">
        {/*Start Sidebar Drawer*/}
        <div
          className={cx(
            'container__left-hover-sidebar',
            sidebarOpen ? 'flex' : 'hidden'
          )}
        >
          <animated.div
            style={{ width: sbWidth }} // original
            className="flex flex-col min-w-[16rem] h-full bg-bcapp-menulightblue"
          >
            {/* Close sidebar */}
            <LeftNav
              toggleSidebar={handleSidebarState}
              sidebarShow={sidebarOpen}
            />
            {getPermissions('project:workspace:create') && (
              <div
                className="left-nav__newWorkspace"
                onClick={() => openModal(modal())}
              >
                {workspaceNav__newWorkspace_link}
              </div>
            )}
          </animated.div>
          <div className="r-0 t-0 b-0 z-10 w-0">
            <div
              {...sbBind()}
              className="h-full touch-none w-2 cursor-col-resize bg-bcapp-menulightblue"
              onClick={() => {
                sbWidth && setSidebarWidth(sbWidth?.animation?.to);
                sbWidth && updateSidebarWidth(sbWidth?.animation?.to);
              }}
            ></div>
          </div>
        </div>
        {/*End Sidebar Drawer*/}

        {/*Start Content - DASHBOARD*/}
        <div className={`flex flex-row flex-grow h-full`}>
          {menu.workSpaceMenu && windowSize.width < 600 && (
            <LeftNav /> // Mini leftnav
          )}
          <div
            className={cx('container__leftNavMobileCover', {
              'container__leftNavMobileCover--open': menu.workSpaceMenu,
            })}
            onClick={() => closeM()}
          />
          <div className={cx('container__content-wrapper')}>
            {/* START RESPONSIVE DESIGN */}
            <div
              className={cx(
                'dashboard__container',
                windowSize.width >= 600
                  ? 'dashboard__container_desktop'
                  : 'dashboard__container_mobile'
              )}
              // style={{
              //   width: sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
              // }}
            >
              {/* START */}
              <div
                className={cx(
                  'dashboard__split',
                  windowSize.width >= 600
                    ? 'dashboard__split_desktop'
                    : 'dashboard__split_mobile',
                  {
                    'dashboard__container--active': urlId,
                  }
                )}
              >
                {getPermissions('item:create') && (
                  <div className="sort-table__add-container">
                    <div
                      className="sort-table__add--left"
                      onClick={() => inputRef.current.focus()}
                    >
                      <HeroIconPlusCircle size={30} />
                      <input
                        className="sort-table__add--text focus:ring-0"
                        type="text"
                        placeholder={mainSection__createNew_input}
                        required
                        onKeyDown={handleKeyDown}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        ref={inputRef}
                      />
                    </div>

                    {inputValue.length ? (
                      <div className="sort-table__add--right">
                        <Button onClick={() => clearNew()} subtle space>
                          {mainSection__createCancel_button}
                        </Button>
                        <Button onClick={() => saveNew()}>
                          {mainSection__createNew_button}
                        </Button>
                      </div>
                    ) : (
                      <div
                        className="sort-table__open-modal"
                        onClick={() => openModal(newIssueModal)}
                      >
                        <HeroIconPencilAlt size={22} />
                      </div>
                    )}
                  </div>
                )}
                <div className={cx('dashboard__table-container')}>
                  <BulkEdit />
                  <SortFilterSection />
                  <SortTable />
                </div>
              </div>
              {windowSize.width >= 600 ? (
                <Fragment>
                  {/* Start Dragable Divider */}
                  <div className="r-0 t-0 b-0 z-10 w-0">
                    <div
                      {...issBind()}
                      className="-ml-1.5 h-full w-2 cursor-col-resize touch-none bg-bcapp-menulightblue"
                      onClick={() => {
                        issWidth && setIssueWidth(issWidth?.animation?.to);
                        issWidth &&
                          updateIssueDocWidth(issWidth?.animation?.to);
                      }}
                    ></div>
                  </div>
                  {/* End Dragable Divider */}
                  {/* Start Column 2 */}
                  <animated.div
                    style={{ width: issWidth }}
                    className="overflow-y-none relative z-40 focus:outline-none bg-white"
                  >
                    <div
                      className={cx('dashboard__container--aniContainer', {
                        'dashboard__container--active': urlId,
                      })}
                      // style={{ width: issueWidth }}
                    >
                      <IssueDocument isMobile={false} />
                    </div>
                  </animated.div>
                  {/* End Column 2*/}
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    className={cx(
                      'dashboard__container--aniContainer',
                      'min-w-full',
                      {
                        'dashboard__container--active': urlId,
                      }
                    )}
                  >
                    <IssueDocument isMobile={true} />
                  </div>
                </Fragment>
              )}
              {/* END */}
            </div>
            {/* END OF RESPONSIVE DESIGN */}
          </div>
        </div>
        {/*End Content - DASHBOARD*/}
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  match: object,
  menu: object,
  openM: func,
  closeM: func,
  login: object,
  openModal: func,
  postNew: func,
  sort: object,
  translations: object,
  router: object,
};

const mapStateToProps = ({
  menu,
  sortTable,
  translations,
  router,
  project,
  login,
}) => ({
  menu,
  sortTable,
  translations,
  router,
  project,
  login,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: () => dispatch(push('/login')),
  openM: (item) => dispatch(openMenu(item)),
  closeM: () => dispatch(closeMenu()),
  openModal: (data) => dispatch(openModal(data)),
  postNew: (data) => dispatch(createNewIssue(data)),
  getDashboardSettings: () => dispatch(fetchDashboardSettings()),
  updateSidebarOpen: (open) => dispatch(updateSidebarOpen(open)),
  updateSidebarWidth: (width) => dispatch(updateSidebarWidth(width)),
  updateIssueDocWidth: (width) => dispatch(updateIssueDocWidth(width)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
