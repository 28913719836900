import React, { Fragment, useState, useEffect } from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { updateDescription } from '../../actions/itemActions';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import getPermissions from '../../helpers/getPermissions';
import parseJson from '../../helpers/parseJson';

import './Description.scss';

const toolbarPlugin = createToolbarPlugin();

const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

// item desc edit section
const Description = ({
  dashboard,
  updateField,
  translations,
  callback,
  formLabel,
  blankStart,
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const { field__description_tag } = translations.byKeyTranslations;
  const { _id, description } = dashboard.activeIssue;
  const [active, setActive] = useState(false);

  const parseDesc = parseJson(description);

  const [editorState, editorStateChange] = useState(
    blankStart || !parseDesc || parseDesc.blocks.length === 0
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(parseDesc))
  );

  useEffect(() => {
    editorStateChange(
      blankStart || !parseDesc || parseDesc.blocks.length === 0
        ? EditorState.createEmpty()
        : EditorState.createWithContent(convertFromRaw(parseDesc))
    );
  }, [description]); // eslint-disable-line

  return (
    <Fragment>
      <h5>{field__description_tag}</h5>
      <div
        className={cx(
          'description__container',
          {
            'description__container--open ring-bcapp-blue border border-bcapp-blue ring-2':
              active,
          },
          'mt-[10px]'
        )}
      >
        <Toolbar>
          {(externalProps) => (
            <div
              className={cx('editor__buttons', {
                'editor__buttons--visible': active,
              })}
            >
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />

              <Separator {...externalProps} />

              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
              <BlockquoteButton {...externalProps} />
            </div>
          )}
        </Toolbar>

        <div className="desc__editor">
          <Editor
            editorState={editorState}
            onChange={(e) => {
              const currentContent = editorState.getCurrentContent();
              const newContent = e.getCurrentContent();

              if (currentContent !== newContent) {
                setHasChanged(true);
              }

              editorStateChange(e);
            }}
            plugins={plugins}
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => {
              const text = JSON.stringify(
                convertToRaw(editorState.getCurrentContent())
              );
              if (hasChanged) {
                callback ? callback(text) : updateField(_id, text);
                setHasChanged(false);
              }
              setActive(false);
            }}
            readOnly={!getPermissions('item:edit:desc')}
            spellCheck={true}
          />
        </div>
      </div>
    </Fragment>
  );
};

Description.propTypes = {
  dashboard: object,
  updateField: func,
  translations: object,
  callback: func,
  formLabel: bool,
  blankStart: bool,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, data) => dispatch(updateDescription(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Description);
