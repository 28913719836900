import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  itemGetHistory,
  itemRemoveHistory,
} from '../../actions/itemHistoryActions';
import { format } from 'date-fns';
import { startCase } from 'lodash';

import './ItemHistory.scss';

const getCorrectWorking = (item) => {
  switch (item) {
    case 'remove':
      return 'removed';
    case 'patch':
      return 'changed';
    case 'create':
      return 'added';
    default:
      return '';
  }
};

const keysToRemove = [
  'createdAt',
  'creator',
  'projectId',
  'descriptionText',
  'statusUpdatedAt',
  'itemId',
  'navisId',
  'seenBy',
  'clashDate',
  'createdFromNavisworks',
  'id',
];

const getCorrectKeys = (obj) =>
  Object.keys(obj)
    .filter((item) => !keysToRemove.includes(item))
    .map(startCase)
    .join(', ');

const ItemHistory = ({ getHistory, itemHistory, emptyHistory }) => {
  const { history = [] } = itemHistory;

  useEffect(() => {
    getHistory();
    return () => emptyHistory();
  }, []); // eslint-disable-line

  return (
    <div className="item-history__container">
      {history.map(({ _id, user, createdAt, method, newVal }) => (
        <div key={_id} className="item-history__row">
          {format(Date.parse(createdAt), 'HH:mm dd/MM/yyyy')} - {user.firstName}{' '}
          {user.lastName} {getCorrectWorking(method)} {getCorrectKeys(newVal)}
        </div>
      ))}
    </div>
  );
};

ItemHistory.propTypes = {
  newAsset: func,
  dashboard: object,
  getHistory: func,
  itemHistory: object,
  emptyHistory: func,
};

const mapStateToProps = ({ itemHistory }) => ({
  itemHistory,
});

const mapDispatchToProps = (dispatch) => ({
  getHistory: () => dispatch(itemGetHistory()),
  emptyHistory: () => dispatch(itemRemoveHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemHistory);
