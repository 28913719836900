import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { login } from './loginReducer';
import { register } from './registerReducer';
import { toastsReducer as toasts } from 'react-toastify-redux';
import { adminProjects } from './adminProjectsReducer';
import { adminUsers } from './adminUsersReducer';
import { adminDashboard } from './adminDashboardReducer';
import { loading } from './loadingReducer';
import { adminProjectsView } from './adminProjectsViewReducer';
import { adminUserView } from './adminUserViewReducer';
import { modal } from './modalReducer';
import { project } from './projectReducer';
import { dashboard } from './dashboardReducer';
import { menu } from './menuReducer';
import { feedback } from './feedbackReducer';
import { forgotPassword } from './forgotPasswordReducer';
import { sortTable } from './sortTableReducer';
import { notifications } from './notificationReducer';
import { editProject } from './editProjectReducer';
import { teams } from './teamsReducer';
import { adminTeams } from './adminTeamsReducer';
import { bulkEdit } from './bulkReducer';
import { report } from './reportReducer';
import { itemHistory } from './historyReducer';
import { editWorkspace } from './editWorkspaceReducer';
import { translations } from './adminTranslationsReducer';
import { adminTokens } from './adminTokenReducer';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    login,
    register,
    toasts,
    adminProjects,
    adminUsers,
    adminDashboard,
    loading,
    adminProjectsView,
    adminUserView,
    modal,
    project,
    dashboard,
    menu,
    feedback,
    forgotPassword,
    sortTable,
    notifications,
    editProject,
    teams,
    adminTeams,
    bulkEdit,
    report,
    itemHistory,
    editWorkspace,
    translations,
    adminTokens,
  });

export default reducers;
