import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import Chevvy from '../Chevvy/Chevvy';
import { sortClick } from '../../actions/sortTableActions';
import Link from '../Link/Link';
import { toggleMenu } from '../../actions/bulkActions';
import getPermissions from '../../helpers/getPermissions';
import { setActiveIssue } from '../../actions/dashboardActions';
import cx from 'classnames';

import './SortTableHeader.scss';

// header for the sort table
const SortTableHeader = ({
  sortTable,
  sortClickAction,
  project,
  openBulk,
  setActive,
  translations,
  dashboard,
}) => {
  const {
    mainSection__bulkEdit_link,
    field__priority_tag,
    field__status_tag,
    field__assignee_tag,
    field__title_tag,
    field__dueDate_tag,
  } = translations.byKeyTranslations;
  const { target, aToZ } = sortTable;
  const { locations = [] } = project.activeProject;
  const locationCount = locations.length;

  return (
    <div className="sort-table__filters">
      <div>
        <span
          className={
            dashboard.listDensity === 'compact'
              ? 'sort-table__filters--id--compact'
              : 'sort-table__filters--id'
          }
        >
          {dashboard.listDensity === 'compact' ? (
            ''
          ) : (
            <div
              onClick={() => sortClickAction('createdAt')}
              className={cx('sort-table__filters--child', {
                'sort__title--active': target === 'createdAt',
              })}
            >
              #{' '}
              <Chevvy
                activeRotate={target === 'createdAt' && aToZ}
                activeColor={target === 'createdAt'}
              />
            </div>
          )}
          <div
            className={
              dashboard.listDensity === 'compact'
                ? 'sort-table__bulk--compact'
                : 'sort-table__bulk'
            }
          >
            {getPermissions('item:edit:bulk') && (
              <Link
                bright
                onClick={() => {
                  setActive();
                  openBulk();
                }}
              >
                {mainSection__bulkEdit_link}
              </Link>
            )}
          </div>
        </span>
        <span
          onClick={() => sortClickAction('name')}
          className={cx({ 'sort__title--active': target === 'name' })}
        >
          {field__title_tag}{' '}
          <Chevvy
            activeRotate={target === 'name' && aToZ}
            activeColor={target === 'name'}
          />
        </span>
      </div>
      <div className="sort-table__filters--flex-end">
        <span
          onClick={() => sortClickAction('priority')}
          className={cx({ 'sort__title--active': target === 'priority' })}
        >
          {field__priority_tag}{' '}
          <Chevvy
            activeRotate={target === 'priority' && aToZ}
            activeColor={target === 'priority'}
          />
        </span>
        <span
          onClick={() => sortClickAction('status')}
          className={cx({ 'sort__title--active': target === 'status' })}
        >
          {field__status_tag}{' '}
          <Chevvy
            activeRotate={target === 'status' && aToZ}
            activeColor={target === 'status'}
          />
        </span>

        <span
          onClick={() => sortClickAction('assignee.firstName')}
          className={cx({
            'sort__title--active': target === 'assignee.firstName',
          })}
        >
          {field__assignee_tag}{' '}
          <Chevvy
            activeRotate={target === 'assignee.firstName' && aToZ}
            activeColor={target === 'assignee.firstName'}
          />
        </span>
        <span
          onClick={() => sortClickAction('dueDate')}
          className={cx({ 'sort__title--active': target === 'dueDate' })}
        >
          {field__dueDate_tag}{' '}
          <Chevvy
            activeRotate={target === 'dueDate' && aToZ}
            activeColor={target === 'dueDate'}
          />
        </span>
        {locationCount > 0 && (
          <span
            onClick={() => sortClickAction('location')}
            className={cx({ 'sort__title--active': target === 'location' })}
          >
            Location{' '}
            <Chevvy
              activeRotate={target === 'location' && aToZ}
              activeColor={target === 'location'}
            />
          </span>
        )}
      </div>
    </div>
  );
};

SortTableHeader.propTypes = {
  sortTable: object,
  openBulk: func,
  setActive: func,
  sortClickAction: func,
  project: object,
  translations: object,
  dashboard: object,
};

const mapStateToProps = ({ sortTable, project, translations, dashboard }) => ({
  sortTable,
  project,
  translations,
  dashboard,
});

const mapDispatchToProps = (dispatch) => ({
  sortClickAction: (data) => dispatch(sortClick(data)),
  openBulk: () => dispatch(toggleMenu()),
  setActive: () => dispatch(setActiveIssue()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortTableHeader);
