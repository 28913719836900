import React, { useEffect, Fragment, useState } from 'react';
import { oneOfType, arrayOf, node, func, object } from 'prop-types';
import TopNav from '../TopNav/TopNav';
import Loading from '../Loading/Loading2';
import Modal from '../Modal/Modal';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button from '../Button/Button';
import { openModal } from '../../actions/modalActions';
import getPermissions from '../../helpers/getPermissions';
import { Helmet } from 'react-helmet';
import imageUrl from '../../helpers/imageUrl';
import logo from '../../assets/logo-invert.svg';
import ProjectNav from '../ProjectNav/ProjectNav';
import VerifyEmail from '../VerifyEmail/VerifyEmail';
import { closeMenu } from '../../actions/menuActions';
import QuickUpload from '../QuickUpload/QuickUpload';

import './Container.scss';

const newProjectModal = {
  heading: 'Create New Project',
  contents: 'NewProjectModal',
};

// main container
const Container = ({
  children,
  redirect,
  project,
  open,
  loading,
  dashboard,
  login,
  translations,
  closeM,
}) => {
  const { itemView__empty_text, itemView__emptyCreateNew_text } =
    translations.byKeyTranslations;
  const { name, coverImage, descriptionText } = dashboard.activeIssue;
  const coverImageUrl = coverImage && imageUrl(coverImage.key, coverImage.url);
  const { emailVerified } = login.userProfile || {};

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      redirect();
    }
  }, []); // eslint-disable-line

  const metaTitle = name ? `BCapp - ${name}` : 'BCapp';
  const desc = descriptionText || '';
  const metaDesc = desc ? `BCapp - ${desc}` : 'BCapp';

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener
      window.addEventListener('resize', handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    if (windowSize.width <= 1024 && sidebarOpen === true) {
      setSidebarOpen(false);
    }

    if (windowSize.width > 600) {
      closeM();
    }
  }, [windowSize]); // eslint-disable-line

  return (
    <div className="container__top-level">
      <Helmet>
        <title>
          Bcapp{`${name ? ' - ' : ''}`}
          {`${name ? name : ''}`}
        </title>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:image" content={coverImageUrl || logo} />
        <meta property="og:description" content={metaDesc} />
      </Helmet>

      <Modal />
      <TopNav />
      <QuickUpload />

      {emailVerified === false && <VerifyEmail />}

      {emailVerified === true && (
        <Fragment>
          <ProjectNav />
          <div className="container__left-wrapper">
            {project.projects.length || project.activeProject._id ? (
              <Fragment>
                {/*Start Content*/}
                <div className="flex flex-row flex-grow h-full w-full top-[112px] fixed">
                  <Loading />
                  {children}
                </div>
                {/*End Content*/}
              </Fragment>
            ) : (
              <div className="container__no-projects">
                {!loading.loading && (
                  <div className="fade-in">
                    <h1>{itemView__empty_text}</h1>
                    {getPermissions('project:create') && (
                      <Button onClick={() => open(newProjectModal)}>
                        {itemView__emptyCreateNew_text || ''}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

Container.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  redirect: func,
  project: object,
  loading: object,
  dashboard: object,
  open: func,
  login: object,
  translations: object,
  closeM: func,
};

const mapStateToProps = ({
  project,
  loading,
  router,
  dashboard,
  login,
  translations,
}) => ({
  project,
  loading,
  router,
  dashboard,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: () => dispatch(push('/login')),
  open: (data) => dispatch(openModal(data)),
  closeM: () => dispatch(closeMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
