import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { matchSorter } from 'match-sorter';
import toast from '../helpers/toast';

const {
  ADMIN_USERS_SUCCESS,
  ADMIN_USERS_FAILURE,
  ADMIN_USERS_FUZZY_TERM,
  ADMIN_USERS_FUZZY_SEARCH,
  LOADING_REQUEST,
  LOADING_FINISHED,
  ADMIN_USERS_LABELS,
  ADMIN_USERS_LIST,
} = constants;

export const adminFuzzy = (searchTerm) => (dispatch) => {
  dispatch({ type: ADMIN_USERS_FUZZY_TERM, searchTerm });
  dispatch(adminFuzzySearch());
};

export const adminFuzzySearch = () => (dispatch, getState) => {
  const { searchTerm, users } = getState().adminUsers;

  const searchData = matchSorter(users, searchTerm, {
    keys: [
      'name',
      'company',
      'email',
      'roles.0.role.description',
      'roles.1.role.description',
      'roles.3.role.description',
      'roles.4.role.description',
    ],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const searchResults = searchData.length > 0 ? searchData : null;
  dispatch({ type: ADMIN_USERS_FUZZY_SEARCH, searchResults });
};

export const adminUsersFetch = (dontFinishLoading) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminUsersFetch' });

  const options = {
    url: `${api.USERS_API_URL}?$sort[firstName]=1`,
  };

  try {
    const users = await fetchData(options);

    dispatch({ type: ADMIN_USERS_SUCCESS, users });

    const userList = users.reduce((acc, item) => {
      acc[item.id] = `${item.firstName || item.email} ${item.lastName || ' '}`;
      return acc;
    }, {});

    const userLabels = users.map(
      ({
        _id,
        firstName,
        email,
        lastName,
        discipline,
        company,
        emailVerified,
        picture,
      }) => ({
        value: _id,
        label: firstName && lastName ? `${firstName} ${lastName}` : email,
        description: `${(discipline && discipline.toUpperCase()) || ' '} - ${
          company || ' '
        }`,
        emailVerified,
        picture,
        filterValues: [firstName, lastName, email],
      })
    );

    dispatch({
      type: ADMIN_USERS_LABELS,
      userLabels,
    });
    dispatch({
      type: ADMIN_USERS_LIST,
      userList,
    });

    if (!dontFinishLoading) {
      dispatch({ type: LOADING_FINISHED, src: 'adminUsersFetch' });
    }
  } catch (e) {
    dispatch({ type: ADMIN_USERS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminUsersFetch' });
    dispatch(toast('error', e));
  }
};

export const adminUsersUpdateRole =
  ({ id, body }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'adminUsersUpdateRole' });

    const options = {
      url: `/users/${id}`,
      type: 'PATCH',
      body,
    };

    try {
      await fetchData(options);
      await dispatch(adminUsersFetch());
      dispatch({ type: LOADING_FINISHED, src: 'adminUsersUpdateRole' });
      dispatch(adminFuzzySearch());
    } catch (e) {
      dispatch({ type: ADMIN_USERS_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'adminUsersUpdateRole' });
      dispatch(toast('error', e));
    }
  };
