import React from 'react';
import { bool } from 'prop-types';
import cx from 'classnames';

import './Seen.scss';

// 'seen' circle
const Seen = ({ seen }) => (
  <div
    className={cx('seen__container', { 'seen__container--active': !seen })}
  />
);

Seen.propTypes = {
  seen: bool,
};

export default Seen;
