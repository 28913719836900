import React from 'react';
import { string } from 'prop-types';

import './PowerBIModal.scss';

// shows power bi link in iframe
const PowerBIModal = ({ data }) => (
  <div className="bi__modal">
    <iframe src={data} frameBorder="0" allowFullScreen="true" title="PowerBI" />
  </div>
);

PowerBIModal.propTypes = {
  data: string,
};

export default PowerBIModal;
