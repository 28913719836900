import constants from '../constants';

const { MODAL_OPEN, MODAL_CLOSE, LOGOUT } = constants;

const initState = {
  contents: null,
  heading: '',
  isOpen: false,
  editMode: false,
  data: {},
  width: 'medium',
};

export const modal = (state = initState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isOpen: true,
        ...action,
      };
    case MODAL_CLOSE:
      return initState;

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
