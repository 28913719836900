import React from 'react';
import { string } from 'prop-types';

import './Stat.scss';

// stat panel used on admin seciton
const Stat = ({ name, value }) => (
  <div className="stat__container-child">
    <div className="stat__name">{name}</div>
    <div className="stat__value">{value}</div>
  </div>
);

Stat.propTypes = {
  name: string,
  value: string,
};

export default Stat;
