import constants from '../constants';

const {
  ADMIN_PROJECTS_SUCCESS,
  ADMIN_PROJECTS_FAILURE,
  ADMIN_PROJECTS_FUZZY_TERM,
  ADMIN_PROJECTS_FUZZY_SEARCH,
  LOGOUT,
} = constants;

const initState = {
  projects: [],
  error: '',
  searchTerm: '',
  searchResults: null,
};

export const adminProjects = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
      };
    case ADMIN_PROJECTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_PROJECTS_FUZZY_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case ADMIN_PROJECTS_FUZZY_SEARCH:
      return {
        ...state,
        searchResults: action.searchResults,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
