import ai from '../assets/fileIcons/ai.png';
import avi from '../assets/fileIcons/avi.png';
import css from '../assets/fileIcons/css.png';
import csv from '../assets/fileIcons/csv.png';
import dbf from '../assets/fileIcons/dbf.png';
import doc from '../assets/fileIcons/doc.png';
import dwg from '../assets/fileIcons/dwg.png';
import exe from '../assets/fileIcons/exe.png';
import fla from '../assets/fileIcons/fla.png';
import html from '../assets/fileIcons/html.png';
import iso from '../assets/fileIcons/iso.png';
import json from '../assets/fileIcons/json.png';
import mp3 from '../assets/fileIcons/mp3.png';
import mp4 from '../assets/fileIcons/mp4.png';
import pdf from '../assets/fileIcons/pdf.png';
import ppt from '../assets/fileIcons/ppt.png';
import psd from '../assets/fileIcons/psd.png';
import txt from '../assets/fileIcons/txt.png';
import xls from '../assets/fileIcons/xls.png';
import xml from '../assets/fileIcons/xml.png';
import zip from '../assets/fileIcons/zip.png';
import file from '../assets/fileIcons/file.png';

const extensions = {
  ai,
  avi,
  css,
  csv,
  dbf,
  doc,
  dwg,
  exe,
  fla,
  html,
  iso,
  json,
  mp3,
  mp4,
  pdf,
  ppt,
  psd,
  txt,
  xls,
  xml,
  zip,
  file,
};

export default extensions;
