import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { get } from 'lodash';
import toast from '../helpers/toast';
import { fetchIssuesAction } from '../actions/dashboardActions';
import removeEmptyFromObj from '../helpers/removeEmptyFromObj';

const {
  BULK_EDIT_MENU,
  BULK_EDIT_ITEMS,
  BULK_EDIT_EDIT,
  BULK_EDIT_TAGS,
  LOADING_REQUEST,
  LOADING_FINISHED,
  PROJECT_NAV_SET_ACTIVE,
} = constants;

const makeArrUniq = (arr = []) => [...new Set(arr)];

export const toggleMenu = () => async (dispatch) =>
  dispatch({ type: BULK_EDIT_MENU });

export const changeBulkEdit = (target, value) => async (dispatch) =>
  dispatch({ type: BULK_EDIT_EDIT, target, value });

export const addItemsToBulk = (id) => async (dispatch, getState) => {
  const { bulkEdit, dashboard, sortTable } = getState();
  const { items } = bulkEdit;
  const { sortedIssuesChunked } = dashboard;
  const { paginationActive } = sortTable;

  const data = items.includes(id)
    ? items.filter((item) => item !== id)
    : [...items, id];

  // Return updated shared tags
  // Get all tags per item
  const allItems = get(sortedIssuesChunked, [paginationActive], []).map(
    (item) => {
      return { _id: item._id, tags: item.tags };
    }
  );
  const allItemTags = allItems
    .filter((item) => {
      return data.includes(item._id);
    })
    .map((item) => item.tags);

  // Filter through and only return the shared tags
  const viewSharedTags = allItemTags.reduce((x, y) =>
    x.filter((z) => y.includes(z))
  );

  // Everytime a user adds or removes from the list of bulk items
  // It resets the addTags and removeTags
  // Only sharedTags gets recalculated
  dispatch({
    type: BULK_EDIT_ITEMS,
    items: data,
    sharedTags: viewSharedTags,
    addTags: [],
    removeTags: [],
    baseTags: viewSharedTags,
  });
};

export const addAllItemsToBulk = () => async (dispatch, getState) => {
  const { bulkEdit, dashboard, sortTable } = getState();
  const { items } = bulkEdit;
  const { sortedIssuesChunked } = dashboard;
  const { paginationActive } = sortTable;

  const allIdsOnPage = get(sortedIssuesChunked, [paginationActive], []).map(
    (item) => item._id
  );

  const allAreIncluded = allIdsOnPage.every((id) => items.includes(id));

  const data = allAreIncluded
    ? items.filter((id) => !items.includes(id))
    : makeArrUniq([...items, ...allIdsOnPage]);

  // Get all tags per item
  const allItemTags = get(sortedIssuesChunked, [paginationActive], []).map(
    (item) => {
      const tags = [...item.tags];
      return tags;
    }
  );

  // Filter through and only return the shared tags
  const sharedTags = allItemTags.reduce((x, y) =>
    x.filter((z) => y.includes(z))
  );

  dispatch({
    type: BULK_EDIT_ITEMS,
    items: data,
    sharedTags,
    addTags: [],
    removeTags: [],
    baseTags: sharedTags,
  });
};

export const bulkEditAction = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'bulkEditAction' });
  const { project, bulkEdit } = getState();
  const {
    items,
    priority,
    status,
    assignee,
    dueDate,
    location,
    removeTags,
    addTags,
  } = bulkEdit;

  const body = {
    priority,
    status,
    assignee,
    dueDate,
    location,
  };

  const data = removeEmptyFromObj(body);

  const fetchDataInfo = {
    url: api.BULK_EDIT_API,
    type: 'POST',
    body: {
      items,
      data,
      projectId: project.activeProject._id,
      removeTags,
      addTags,
    },
  };

  try {
    const activeProject = await fetchData(fetchDataInfo);
    dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject });
    await dispatch(fetchIssuesAction(project.activeProject._id));
    dispatch(toggleMenu());
    dispatch({ type: LOADING_FINISHED, src: 'bulkEditAction' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'bulkEditAction' });
    dispatch(toast('error', e));
  }
};

// BULK EDIT TAGS

export const updateBulkTags = (action, tag) => async (dispatch, getState) => {
  try {
    const { bulkEdit } = getState();
    const { sharedTags, baseTags, addTags, removeTags } = bulkEdit;

    // Need to check whether the tag exists in the items first before
    // adding it to the delete tags list

    // What is shown to the user
    let viewSharedTags = sharedTags ? [...sharedTags] : [];

    let tagsToRemove = removeTags ? [...removeTags] : [];
    let tagsToAdd = addTags ? [...addTags] : [];

    if (action === 'create') {
      const newTag = tag.toUpperCase();
      viewSharedTags = [...new Set([...viewSharedTags, newTag])];
      tagsToAdd = viewSharedTags.filter(
        (sharedTag) => !baseTags.includes(sharedTag)
      );
      tagsToRemove = tagsToRemove.filter(
        (tag) => !tagsToAdd.includes(tag) && baseTags.includes(tag)
      );
    }

    if (action === 'delete') {
      viewSharedTags = viewSharedTags.filter((sharedTag) => sharedTag !== tag);
      tagsToAdd = viewSharedTags.filter(
        (sharedTag) => !baseTags.includes(sharedTag)
      );
      tagsToRemove = !baseTags.includes(tag)
        ? [...tagsToRemove]
        : [...new Set([...tagsToRemove, tag])];
    }

    if (action === 'update') {
      // tag = tags as it passes in a whole new array of tags
      viewSharedTags = tag.map((x) => x.value);
      tagsToAdd = viewSharedTags.filter(
        (sharedTag) => !baseTags.includes(sharedTag)
      );
      tagsToRemove = tagsToRemove.filter(
        (tag) => !tagsToAdd.includes(tag) && baseTags.includes(tag)
      );
    }

    dispatch({
      type: BULK_EDIT_TAGS,
      sharedTags: viewSharedTags,
      removeTags: tagsToRemove, // sent to bulk-edit
      addTags: tagsToAdd, // sent to bulk-edit
    });
  } catch (error) {
    console.error({ error });
  }
};
