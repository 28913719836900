import {
  isPast,
  isSameDay,
  isTomorrow,
  differenceInDays,
  isWithinInterval,
} from 'date-fns';
import deepClone from '../helpers/deepClone';
import { matchSorter } from 'match-sorter';
import { priorityWeight } from '../constants/labels';
import { orderBy, get, lowerCase } from 'lodash';

// merge new with old filters
export const updateMultiFilters = (filters, data) => {
  // this was mutating up the tree, better way ?
  const newFilter = deepClone(filters);
  const mergeNew = { ...newFilter, ...data };
  return mergeNew;
};

// this changes the filters when a new one is added
export const updateFilters = (filters, target, value) => {
  // this was mutating up the tree, better way ?

  const newFilter = deepClone(filters);

  // these 3 work different
  if (
    target === 'dueDate' ||
    target === 'createdAtFrom' ||
    target === 'createdAtTo'
  ) {
    if (newFilter[target] === value) {
      newFilter[target] = '';
    } else {
      newFilter[target] = value;
    }
  } else if (newFilter[target] && newFilter[target].includes(value)) {
    newFilter[target] = newFilter[target].filter((item) => item !== value);
  } else {
    newFilter[target].push(value);
  }

  return newFilter;
};

export const filterData = (
  {
    status: statusFilter,
    priority: priorityFilter,
    tags: tagsFilter,
    assignee: assigneeFilter,
    creator: creatorFilter,
    subscriber: subscriberFilter = [],
    dueDate: dueDateFilter,
    location: locationFilter = [],
    createdAtFrom: createdAtFromFilter,
    createdAtTo: createdAtToFilter,
  },
  issues
) =>
  issues.filter(
    ({
      status,
      priority,
      tags,
      assignee,
      creator,
      subscribers,
      dueDate,
      location,
      createdAt,
      subTasks,
    }) => {
      const dateDifference = differenceInDays(Date.parse(dueDate), new Date());

      const dateMatrix = [
        dueDateFilter === 'None' ? !dueDate : true,
        dueDateFilter === 'Past Due'
          ? isPast(Date.parse(dueDate) || new Date())
          : true,
        dueDateFilter === 'Today'
          ? isSameDay(Date.parse(dueDate), new Date())
          : true,
        dueDateFilter === 'Next Day' ? isTomorrow(Date.parse(dueDate)) : true,
        dueDateFilter === 'Next 7 Days'
          ? dateDifference >= 0 && dateDifference <= 7
          : true,
      ];

      const filterMatrix = [
        // status is included
        statusFilter.length ? statusFilter.includes(status) : true,
        // location is included
        locationFilter.length ? locationFilter.includes(location) : true,
        // priority is included
        priorityFilter.length ? priorityFilter.includes(priority) : true,
        // every tag is included
        tagsFilter.length
          ? tagsFilter.every((tag) => tags && tags.includes(tag))
          : true,

        // assignee is included
        assigneeFilter.length
          ? assigneeFilter.includes(assignee && assignee._id) ||
            // also check if any sub tasks have assingee of the filtered user
            (subTasks &&
              subTasks.length > 0 &&
              assigneeFilter.some((item) =>
                subTasks
                  .map((item) => item.assignee && item.assignee._id)
                  .filter(Boolean)
                  .includes(item)
              )) ||
            false
          : true,
        // creator is included
        creatorFilter.length
          ? creatorFilter.includes(creator && creator._id)
          : true,
        // subscribers is included
        subscriberFilter.length
          ? subscriberFilter.every(
              (sub) =>
                subscribers && subscribers.map((item) => item._id).includes(sub)
            )
          : true,
        // is within range of dates
        // if same day range is selectd, do a is same day vs range compare
        createdAtFromFilter && createdAtToFilter
          ? isSameDay(
              Date.parse(createdAtFromFilter),
              Date.parse(createdAtToFilter)
            )
            ? isSameDay(Date.parse(createdAtFromFilter), Date.parse(createdAt))
            : isWithinInterval(Date.parse(createdAt), {
                start: Date.parse(createdAtFromFilter),
                end: Date.parse(createdAtToFilter),
              })
          : true,

        ...dateMatrix,
      ];

      return !filterMatrix.includes(false);
    }
  );

// items search filter sort
export const searchFilter = (issues, searchTerm) => {
  const filteredIssues = matchSorter(issues, searchTerm, {
    keys: [
      'name',
      'description',
      'status',
      'creator.firstName',
      'creator.lastName',
      'assignee.firstName',
      'assignee.lastName',
      'priority',
      'tags',
      'itemId',
    ],
    threshold: matchSorter.rankings.CONTAINS,
  });

  return filteredIssues;
};

// sort data by targets
export const sortData = (data, target, reverse = false) => {
  if (target === 'priority') {
    // priorityWeight needs to be ordered small to big for this to work
    const sorted = data.sort(
      (a, b) => priorityWeight[a[target]] - priorityWeight[b[target]]
    );
    return reverse ? sorted : sorted.reverse();
  }

  return orderBy(
    data,
    [(item) => lowerCase(get(item, target, '')), 'itemId'],
    [reverse ? 'asc' : 'desc', 'desc']
  );
};
