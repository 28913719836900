import constants from '../constants';
import imageUrl from '../helpers/imageUrl';

const {
  PROJECT_NAV_SUCCESS,
  PROJECT_NAV_FAILURE,
  PROJECT_NAV_SET_ACTIVE,
  PROJECT_NAV_WORKSPACE,
  PROJECT_NAV_FUZZY_TERM,
  PROJECT_MENU_FUZZY_SEARCH,
  PROJECT_NAV_RESET,
  LOGOUT,
} = constants;

const initState = {
  projects: [],
  error: '',
  activeProject: {},
  activeWorkspace: '',
  filteredMenus: [],
  fuzzyProjects: null,
  fuzzyTerm: '',
  mentions: [],
};

export const project = (state = initState, action) => {
  switch (action.type) {
    case PROJECT_NAV_RESET:
      return {
        ...initState,
      };
    case PROJECT_NAV_SUCCESS:
      return {
        ...state,
        projects: action.projects,
      };
    case PROJECT_NAV_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case PROJECT_NAV_SET_ACTIVE:
      return {
        ...state,
        activeProject: action.activeProject,
        mentions: action.activeProject.members.map(({ user = {} }) => ({
          name:
            user.firstName || user.lastName
              ? `@${user.firstName || ''} ${user.lastName || ''}`
              : user.email,
          avatar: imageUrl(
            user.picture && user.picture.key,
            user.picture && user.picture.url
          ),
          id: user._id,
        })),
      };
    case PROJECT_NAV_WORKSPACE:
      return {
        ...state,
        activeWorkspace: action.id,
        activeWorkspaceIssues: action.activeWorkspaceIssues || [],
      };
    case PROJECT_NAV_FUZZY_TERM:
      return {
        ...state,
        fuzzyTerm: action.fuzzyTerm,
      };
    case PROJECT_MENU_FUZZY_SEARCH:
      return {
        ...state,
        fuzzyProjects: action.fuzzyProjects,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
