import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import {
  adminUsersFetch,
  adminFuzzy,
  adminUsersUpdateRole,
} from '../../actions/adminUsersActions';
import RoundBadge from '../../components/UI/Badge/RoundBadge';
import { roles as roleList } from '../../constants/labels';
import AdminContainer from '../../components/AdminContainer/AdminContainer';
import { userUrl } from '../../helpers/adminUrls';
import { genericTimeYearDate } from '../../helpers/formatDates';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminUsers.scss';

// admin route /admin/users
const AdminUsers = ({
  adminUsers,
  fetchUsers,
  updateUser,
  resetSearch,
  loading,
}) => {
  const tableHead2 = [
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}` || '-',
      id: 'name',
      cell: (props) =>
        props.getValue() ? (
          <a href={userUrl(props.row.original._id)}>{props.getValue()}</a>
        ) : (
          '-'
        ),
      header: 'Name',
      enableSorting: true,
    },
    {
      accessorKey: 'company',
      cell: (props) => props.getValue() || '-',
      header: 'Company',
    },
    {
      accessorKey: 'discipline',
      cell: (props) => roleList[props.getValue()] || '-',
      header: 'Discipline',
    },
    {
      accessorKey: 'email',
      cell: (props) => props.getValue(),
      header: 'Email',
    },
    {
      accessorKey: 'emailVerified',
      cell: (props) => (props.getValue() ? 'Yes' : 'No'),
      header: 'Email Verified',
    },
    {
      accessorKey: 'lastLoginDate',
      cell: (props) =>
        props.getValue() ? genericTimeYearDate(props.getValue()) : '-',
      header: 'Last Login',
    },
    {
      id: 'roles',
      cell: (props) => (
        <Fragment>
          <span
            className="admin-users__lozange"
            onClick={() =>
              updateUser({
                id: props.row.original._id,
                body: { globalAdmin: !props.row.original.globalAdmin },
              })
            }
          >
            <RoundBadge
              theme={props.row.original.globalAdmin ? 'success' : 'default'}
              value="Global Admin"
            />
          </span>
        </Fragment>
      ),
      header: '',
    },
  ];

  useEffect(() => {
    fetchUsers();

    return () => {
      // on unmount reset the search
      resetSearch();
    };
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <h1>Admin Users</h1>

      <FilterTable
        defaultData={adminUsers.users}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search users..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminUsers.propTypes = {
  fetchUsers: func,
  adminUsers: object,
  updateUser: func,
  resetSearch: func,
};

const mapStateToProps = ({ adminUsers, loading }) => ({
  adminUsers,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(adminUsersFetch()),
  updateUser: (data) => dispatch(adminUsersUpdateRole(data)),
  resetSearch: () => dispatch(adminFuzzy('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
