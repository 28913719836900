import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import cx from 'classnames';
import filters from '../../constants/filters';
import constants from '../../constants';
import { get, orderBy } from 'lodash';
import Link from '../Link/Link';
import getPermissions from '../../helpers/getPermissions';
import {
  setActiveWorkspaceAndFilter,
  sortWorkSpace,
} from '../../actions/workspaceActions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HeroIconViewGrid from '../HeroIcon/HeroIconViewGrid';
import HeroIconUser from '../HeroIcon/HeroIconUser';
import HeroIconUserGroup from '../HeroIcon/HeroIconUserGroup';
import HeroIconAtSymbol from '../HeroIcon/HeroIconAtSymbol';

import './LeftNav.scss';
import HeroIconDotsVertical from '../HeroIcon/HeroIconDotsVertical';
import HeroIconChevronDoubleLeft from '../HeroIcon/HeroIconChevronDoubleLeft';
import HeroIconChevronDoubleRight from '../HeroIcon/HeroIconChevronDoubleRight';

const { ALL_ISSUES, MY_ISSUES } = constants;

const modal = (id) => ({
  heading: id ? 'Edit Workspace' : 'Create New Workspace',
  contents: 'NewWorkSpaceModal',
});

// re-order drag results
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// left hand side nav contains the workspaces
const LeftNav = ({
  open,
  project,
  setWorkspace,
  login,
  saveSortOrder,
  translations,
  menu,
  toggleSidebar,
  sidebarShow,
  isHover,
}) => {
  const {
    workspaceNav__allItems_button,
    workspaceNav__myItems_button,
    workspaceNav__newWorkspace_link,
    workspaceNav__personalWorkspace_header,
    workspaceNav__workspace_header,
  } = translations.byKeyTranslations;

  const { id } = login.user;
  const workspaces = get(project, 'activeProject.workSpaces', []);

  const [sortPublicWorkspaces, setSortPublicWorkspaces] = useState([]);
  const [sortPersonalWorkspaces, setSortPersonalWorkspaces] = useState([]);

  const onDragEnd = ({ destination, source }, data, cb) => {
    if (!destination) {
      return false;
    }

    const sort = reorder(data, source.index, destination.index);
    cb(sort);
    saveSortOrder(sort);
  };

  useEffect(() => {
    const publicWorkspace = workspaces.filter((item) => !item.owner);
    const personalWorkspaces = workspaces.filter((item) => item.owner === id);
    setSortPublicWorkspaces(orderBy(publicWorkspace, 'sortOrder'));
    setSortPersonalWorkspaces(orderBy(personalWorkspaces, 'sortOrder'));
  }, [workspaces]); // eslint-disable-line

  // Fixed drag-and-drop offset by wrapping with a react portal
  // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/portal/portal-app.jsx

  const sharedWorkspacesPortal = document.createElement('div');
  sharedWorkspacesPortal.classList.add('shared-portal');

  const personalWorkspacesPortal = document.createElement('div');
  personalWorkspacesPortal.classList.add('personal-portal');

  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }

  document.body.appendChild(sharedWorkspacesPortal);
  document.body.appendChild(personalWorkspacesPortal);

  return (
    <div
      //className={cx('left-nav__container', 'pr-2 sm:pr-0', {
      //'left-nav__container--open': menu.workSpaceMenu,
      //})}

      className={cx(
        'left-nav__container custom-scroll-dark',
        {
          'left-nav__container--open': menu.workSpaceMenu, // Modal for mobile
          'left-nav__container--hover': isHover, // Different height on hover
        },
        menu.workSpaceMenu ? 'py-2' : 'my-2'
      )}
    >
      <div>
        <div className="left-nav__workspace">
          <h4>{workspaceNav__workspace_header}</h4>

          <button onClick={() => toggleSidebar()} style={{ color: 'white' }}>
            {sidebarShow === true ? (
              <HeroIconChevronDoubleLeft />
            ) : sidebarShow === false ? (
              <HeroIconChevronDoubleRight />
            ) : getPermissions('project:workspace:create') ? (
              <Link onClick={() => open(modal())} bright>
                {workspaceNav__newWorkspace_link}
              </Link>
            ) : (
              <></>
            )}
          </button>
        </div>

        <div
          className={cx('left-nav__item', {
            'left-nav__item--active': project.activeWorkspace === ALL_ISSUES,
          })}
          onClick={() => setWorkspace(ALL_ISSUES, filters())}
        >
          <span className="left-nav__item--text">
            <HeroIconViewGrid marginRight={10} />{' '}
            {workspaceNav__allItems_button}
          </span>
        </div>

        <div
          className={cx('left-nav__item', {
            'left-nav__item--active': project.activeWorkspace === MY_ISSUES,
          })}
          onClick={() =>
            setWorkspace(MY_ISSUES, {
              ...filters(),
              assignee: [login.user.id],
            })
          }
        >
          <span className="left-nav__item--text">
            <HeroIconAtSymbol marginRight={10} /> {workspaceNav__myItems_button}
          </span>
        </div>

        {getPermissions('project:workspace:public:edit') ? (
          <DragDropContext
            onDragEnd={(data) =>
              onDragEnd(data, sortPublicWorkspaces, setSortPublicWorkspaces)
            }
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sortPublicWorkspaces.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          return ReactDOM.createPortal(
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                key={item._id}
                                className={cx('left-nav__item', {
                                  'left-nav__item--active':
                                    project.activeWorkspace === item._id,
                                })}
                                onClick={() =>
                                  setWorkspace(
                                    item._id,
                                    { ...item.filters },
                                    item.items
                                  )
                                }
                              >
                                <span className="left-nav__item--text">
                                  <HeroIconUserGroup marginRight={10} />
                                  <div className="left-nav__item--inner">
                                    {item.name}
                                  </div>
                                </span>
                                {getPermissions(
                                  'project:workspace:public:edit'
                                ) && (
                                  <span
                                    className="left-nav__item--icon"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      open({
                                        ...modal(true),
                                        editMode: true,
                                        data: item,
                                      });
                                    }}
                                  >
                                    <HeroIconDotsVertical fillColor="#9a9fa7" />
                                  </span>
                                )}
                              </div>
                            </div>,
                            sharedWorkspacesPortal
                          );
                        } else {
                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                key={item._id}
                                className={cx('left-nav__item', {
                                  'left-nav__item--active':
                                    project.activeWorkspace === item._id,
                                })}
                                onClick={() =>
                                  setWorkspace(
                                    item._id,
                                    { ...item.filters },
                                    item.items
                                  )
                                }
                              >
                                <span className="left-nav__item--text">
                                  <HeroIconUserGroup marginRight={10} />
                                  <div className="left-nav__item--inner">
                                    {item.name}
                                  </div>
                                </span>
                                {getPermissions(
                                  'project:workspace:public:edit'
                                ) && (
                                  <span
                                    className="left-nav__item--icon"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      open({
                                        ...modal(true),
                                        editMode: true,
                                        data: item,
                                      });
                                    }}
                                  >
                                    <HeroIconDotsVertical fillColor="#9a9fa7" />
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        }
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          sortPublicWorkspaces.map((item) => (
            <div
              key={item._id}
              className={cx('left-nav__item', {
                'left-nav__item--active': project.activeWorkspace === item._id,
              })}
              onClick={() =>
                setWorkspace(item._id, { ...item.filters }, item.items)
              }
            >
              <span className="left-nav__item--text">
                <HeroIconUserGroup />
                <div className="left-nav__item--inner">{item.name}</div>
              </span>
              {getPermissions('project:workspace:public:edit') && (
                <span
                  className="left-nav__item--icon"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    open({
                      ...modal(true),
                      editMode: true,
                      data: item,
                    });
                  }}
                >
                  <HeroIconDotsVertical fillColor="#9a9fa7" />
                </span>
              )}
            </div>
          ))
        )}

        {!!sortPersonalWorkspaces.length && (
          <div className="left-nav__workspace">
            <h4>{workspaceNav__personalWorkspace_header}</h4>
          </div>
        )}

        <DragDropContext
          onDragEnd={(data) =>
            onDragEnd(data, sortPersonalWorkspaces, setSortPersonalWorkspaces)
          }
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortPersonalWorkspaces.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      if (snapshot.isDragging) {
                        return ReactDOM.createPortal(
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              key={item._id}
                              className={cx('left-nav__item', {
                                'left-nav__item--active':
                                  project.activeWorkspace === item._id,
                              })}
                              onClick={() =>
                                setWorkspace(
                                  item._id,
                                  { ...item.filters },
                                  item.items
                                )
                              }
                            >
                              <span className="left-nav__item--text">
                                <HeroIconUser marginRight={10} />
                                <div className="left-nav__item--inner">
                                  {item.name}
                                </div>
                              </span>
                              <span
                                className="left-nav__item--icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  open({
                                    ...modal(true),
                                    editMode: true,
                                    data: item,
                                  });
                                }}
                              >
                                <HeroIconDotsVertical fillColor="#9a9fa7" />
                              </span>
                            </div>
                          </div>,
                          personalWorkspacesPortal
                        );
                      } else {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              key={item._id}
                              className={cx('left-nav__item', {
                                'left-nav__item--active':
                                  project.activeWorkspace === item._id,
                              })}
                              onClick={() =>
                                setWorkspace(
                                  item._id,
                                  { ...item.filters },
                                  item.items
                                )
                              }
                            >
                              <span className="left-nav__item--text">
                                <HeroIconUser marginRight={10} />
                                <div className="left-nav__item--inner">
                                  {item.name}
                                </div>
                              </span>
                              <span
                                className="left-nav__item--icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  open({
                                    ...modal(true),
                                    editMode: true,
                                    data: item,
                                  });
                                }}
                              >
                                <HeroIconDotsVertical fillColor="#9a9fa7" />
                              </span>
                            </div>
                          </div>
                        );
                      }
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

LeftNav.propTypes = {
  open: func,
  project: object,
  setWorkspace: func,
  login: object,
  saveSortOrder: func,
  translations: object,
  menu: object,
};

const mapStateToProps = ({ project, login, translations, menu }) => ({
  project,
  login,
  translations,
  menu,
});

const mapDispatchToProps = (dispatch) => ({
  open: (data) => dispatch(openModal(data)),
  setWorkspace: (id, data, issues) =>
    dispatch(setActiveWorkspaceAndFilter(id, data, issues, true)),
  saveSortOrder: (data) => dispatch(sortWorkSpace(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);
