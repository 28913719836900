import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store.js';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NotFound from './pages/NotFound/NotFound';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import AdminProjects from './pages/AdminProjects/AdminProjects';
import AdminProjectView from './pages/AdminProjectView/AdminProjectView';
import AdminUsers from './pages/AdminUsers/AdminUsers';
import AdminUserView from './pages/AdminUserView/AdminUserView';
import Feedback from './pages/Feedback/Feedback';
import Profile from './pages/Profile/Profile';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import LandingPage from './pages/LandingPage/LandingPage';
import Teams from './pages/Teams/Teams';
import AdminTeams from './pages/AdminTeams/AdminTeams';
import AdminTranslations from './pages/AdminTranslations/AdminTranslations';
import AdminTokens from './pages/AdminTokens/AdminTokens';

import Container from './components/Container/Container';
import Dashboard from './pages/Dashboard/Dashboard';

const App = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register/:id" component={Register} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:id" component={ResetPassword} />
      <Route path="/admin/projects/:id" component={AdminProjectView} />
      <Route path="/admin/projects" component={AdminProjects} />
      <Route path="/admin/users/:id" component={AdminUserView} />
      <Route path="/admin/users" component={AdminUsers} />
      <Route path="/admin/feedback" component={Feedback} />
      <Route path="/admin/teams" component={AdminTeams} />
      <Route path="/admin/translations" component={AdminTranslations} />
      <Route path="/admin/tokens" component={AdminTokens} />
      <Route path="/admin" component={AdminDashboard} />
      <Route path="/profile" component={Profile} />
      <Route path="/teams" component={Teams} />
      <Route path="/confirm-email/:id" component={ConfirmEmail} />
      <Route path="/" component={LandingPage} exact />
      <Container>
        <Switch>
          <Route
            path="/project/:projectId/item/:issueId"
            component={Dashboard}
          />
          <Route path="/project/:projectId" component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      </Container>
    </Switch>
  </ConnectedRouter>
);

export default App;
