import React, { Fragment, useState, useRef } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import Link from '../Link/Link';
import Dragger from '../Dragger/Dragger';
import AssetCell from '../AssetCell/AssetCell';
import getPermissions from '../../helpers/getPermissions';
import { addNewAsset } from '../../actions/itemActions';
import { flatMap } from 'lodash';
import { openModal, closeModal } from '../../actions/modalActions';
import cx from 'classnames';

import './Assets.scss';

const modal = {
  contents: 'UploadsModal',
  heading: 'New attachment',
  maxWidth: '480px',
};

// main item assets section
const Assets = ({ newAsset, dashboard, translations, open, close }) => {
  const heightBox = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    itemView__attachments_header,
    itemView__attachmentsAdd_link,
    itemView__attachmentComment_text,
  } = translations.byKeyTranslations;

  const canAdd = getPermissions('item:attachment:add');

  const { _id, assets = [], comments = [] } = dashboard.activeIssue;

  const commentAssets = flatMap(comments, (comment) => comment.assets);

  const maxHeight = menuOpen
    ? { maxHeight: heightBox.current.clientHeight + 40 }
    : {};

  return (
    <Fragment>
      <div
        className={cx('assets__cropBox', { 'assets__cropBox--open': menuOpen })}
        style={maxHeight}
      >
        <div ref={heightBox}>
          <Dragger
            action={(e) => (canAdd ? newAsset(_id, e.dataTransfer.files) : {})}
            offset
          >
            <div className="issue__attachment--add">
              <h5>{itemView__attachments_header}</h5>

              {canAdd && (
                <Link
                  onClick={() => {
                    open({
                      ...modal,
                      data: {
                        callback: (e) => {
                          close();
                          newAsset(_id, e);
                        },
                        multi: true,
                      },
                    });
                  }}
                  bright
                >
                  {itemView__attachmentsAdd_link}
                </Link>
              )}
            </div>

            <div className="issue__attachments-container">
              {assets.filter(Boolean).map((item) => (
                <AssetCell data={item} key={item._id} />
              ))}
            </div>
          </Dragger>

          {commentAssets.length > 0 && (
            <Fragment>
              <h5 className="issue__comments-header assets__commentsHeader--comments">
                {itemView__attachmentComment_text}
              </h5>
              {commentAssets.map((item) => (
                <AssetCell data={item} key={item._id} noDelete />
              ))}
            </Fragment>
          )}
        </div>
      </div>
      {/* 2 here because the css hides more than 2 items */}
      {commentAssets.length + assets.length > 2 && (
        <div
          className="assets__cropText"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span className="assets__cropText--inner">{`${
            menuOpen ? 'Show Less' : 'Show More'
          }`}</span>
        </div>
      )}
    </Fragment>
  );
};

Assets.propTypes = {
  newAsset: func,
  dashboard: object,
  translations: object,
  open: func,
  close: func,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  newAsset: (id, data) => dispatch(addNewAsset(id, data)),
  open: (data) => dispatch(openModal(data)),
  close: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assets);
