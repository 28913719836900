import React, { useRef } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Label from '../Label/Label';
import DropDown from '../DropDown/DropDown';
import getPermissions from '../../helpers/getPermissions';
import { updateActiveIssue, updateTask } from '../../actions/itemActions';
import { selectProfile } from '../../helpers/selectProfile';
import userLabel, { userLabelText } from '../../helpers/userLabel';
import { changeBulkEdit } from '../../actions/bulkActions';

import ReactSelect from 'react-select';

const IssueDocumentInfo = ({
  dashboard,
  updateField,
  project,
  bulkEditMode,
  updateBulkEdit,
  bulkEdit,
  translations,
  hideLabel,
  dueAssignee,
  updateDueAssignee,
}) => {
  const { field__assignee_tag } = translations.byKeyTranslations;
  const assigneeRef = useRef();
  const assigneeDropRef = useRef();

  const { _id, assignee = {} } = bulkEditMode
    ? bulkEdit
    : dueAssignee
    ? dueAssignee
    : dashboard.activeIssue;
  const { members = [] } = project.activeProject;

  const projectUsers = members.map(
    ({ user: { discipline, company, emailVerified, picture }, user }) => ({
      ...userLabel(user),
      description: `${(discipline && discipline.toUpperCase()) || ' '} - ${
        company || ' '
      }`,
      emailVerified,
      picture,
    })
  );

  const canEditAssingee = getPermissions('item:edit:assignee');

  const AssingeeLabel = () => {
    return (
      <Label hover={canEditAssingee}>
        {bulkEditMode
          ? (projectUsers.find((item) => item.value === assignee) || {})
              .label || '-'
          : userLabelText(assignee)}
      </Label>
    );
  };

  const pickerAction = (data) => {
    switch (true) {
      case !!bulkEditMode:
        return updateBulkEdit('assignee', data.value);
      case !!dueAssignee:
        return updateDueAssignee(_id, dueAssignee.childId, {
          assignee: data.value,
        });
      case !!data.value !== (assignee && assignee._id):
        return updateField(_id, 'assignee', data.value);
      default:
        return {};
    }
  };

  const closeAction = (e) => {
    e.preventDefault();
    e.stopPropagation();

    assigneeDropRef.current.closeAction();
    switch (true) {
      case !!bulkEditMode:
        return updateBulkEdit('assignee', null);
      case !!dueAssignee:
        return updateDueAssignee(_id, dueAssignee.childId, {
          assignee: null,
        });
      default:
        return updateField(_id, 'assignee', null);
    }
  };

  const fieldValue = bulkEditMode
    ? projectUsers.find((item) => item.value === assignee)
    : userLabel(assignee);

  return (
    <div>
      {!hideLabel && <h5>{field__assignee_tag}</h5>}

      {canEditAssingee ? (
        <DropDown
          action={() => assigneeRef.current.focus()}
          input={<AssingeeLabel />}
          right={!!bulkEditMode || !!dueAssignee}
          output={
            <ReactSelect
              placeholder="-"
              options={projectUsers}
              value={fieldValue}
              onChange={(data) => {
                assigneeDropRef.current.closeAction();
                pickerAction(data);
              }}
              menuIsOpen
              isClearable={false}
              ref={assigneeRef}
              {...selectProfile({
                active: assignee && assignee._id,
                action: (e) => closeAction(e),
              })}
              styles={{
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          }
          customCloseAction={true}
          ref={assigneeDropRef}
        />
      ) : (
        <AssingeeLabel />
      )}
    </div>
  );
};

IssueDocumentInfo.propTypes = {
  dashboard: object,
  project: object,
  updateField: func,
  bulkEditMode: bool,
  updateBulkEdit: func,
  bulkEdit: object,
  translations: object,
  hideLabel: bool,
  dueAssignee: object,
  updateDueAssignee: func,
};

const mapStateToProps = ({ dashboard, project, bulkEdit, translations }) => ({
  dashboard,
  project,
  bulkEdit,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  updateBulkEdit: (target, value) => dispatch(changeBulkEdit(target, value)),
  updateDueAssignee: (parent, child, value) =>
    dispatch(updateTask(parent, child, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocumentInfo);
