import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from './Router.jsx';
import configureStore from './store.js';
import { ToastContainer } from 'react-toastify-redux';

import { adminTranslationsFetch } from './actions/adminTranslationsActions';

import './styles/index.css';
import './styles/index.scss';

import 'react-toastify/dist/ReactToastify.css';

// fetch translations before all things happen
configureStore.dispatch((dispatch) => dispatch(adminTranslationsFetch()));

ReactDOM.render(
  <Provider store={configureStore}>
    <ToastContainer pauseOnFocusLoss={false} />
    <Router />
  </Provider>,
  document.getElementById('root')
);
