import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import toast from '../helpers/toast';

const {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOADING_REQUEST,
  LOADING_FINISHED,
  PROJECT_NAV_RESET,
  ISSUES_RESET,
  SORT_TABLE_RESULT,
  USER_NOTIFICATIONS,
  USER_PROFILE,
  USER_SUBS,
  USER_SIDEBAROPEN,
  USER_SIDEBARWIDTH,
  USER_ISSUEDOCWIDTH,
} = constants;

export const loginAction =
  ({ email, password, remember = true, strategy = 'local', idToken, token }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'loginAction' });
    const fetchDataInfo = {
      url: api.LOGIN_API_URL,
      type: 'POST',
      body: {
        email,
        password,
        strategy,
        idToken,
        token,
      },
    };

    try {
      const { accessToken } = await fetchData(fetchDataInfo);
      const user = jwtDecode(accessToken);

      if (remember) {
        localStorage.setItem('token', accessToken);
      }

      dispatch({ type: LOGIN_SUCCESS, token: accessToken, user });
      dispatch(push('/'));
    } catch (e) {
      if (strategy !== 'local') {
        localStorage.clear();
        dispatch({ type: LOGIN_FAILURE, error: true });
        dispatch({ type: LOADING_FINISHED, src: 'loginAction' });
        dispatch(toast('error', 'Single sign-on failed.'));
      } else {
        dispatch({ type: LOGIN_FAILURE, e });
        dispatch({ type: LOADING_FINISHED, src: 'loginAction' });
        dispatch(toast('error', e));
      }
    }
  };

export const logoutAction = (msalLogout, strategy) => (dispatch) => {
  localStorage.removeItem('token');

  dispatch({ type: LOGOUT });
  dispatch({ type: PROJECT_NAV_RESET });
  dispatch({ type: ISSUES_RESET });
  dispatch({ type: SORT_TABLE_RESULT, data: [] });

  if (strategy === 'architectus') {
    msalLogout();
  } else {
    dispatch(push('/login'));
  }
};

export const fetchCurrentUser = () => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${id}`,
    type: 'GET',
  };

  try {
    const user = await fetchData(fetchDataInfo);
    dispatch({ type: USER_NOTIFICATIONS, notifications: user.notifications });
    dispatch({ type: USER_PROFILE, userProfile: user });
  } catch (e) {
    console.log(e);
  }
};

export const fetchCurrentUserSubscriptions =
  () => async (dispatch, getState) => {
    const { id } = getState().login.user;

    const fetchDataInfo = {
      url: `${api.USERS_SUBS_API_URL}/${id}`,
      type: 'GET',
    };

    try {
      const subs = await fetchData(fetchDataInfo);
      dispatch({ type: USER_SUBS, subs });
    } catch (e) {
      console.log(e);
    }
  };

// Get and update sidebar/issue document width state on user
export const fetchDashboardSettings = () => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${id}`,
    type: 'GET',
  };

  try {
    const user = await fetchData(fetchDataInfo);
    dispatch({
      type: USER_SIDEBAROPEN,
      sidebarOpen: user.sidebarOpen,
    });
    dispatch({
      type: USER_SIDEBARWIDTH,
      sidebarWidth: user.sidebarWidth ? user.sidebarWidth : 256,
    });
    dispatch({
      type: USER_ISSUEDOCWIDTH,
      issueDocWidth: user.issueDocWidth ? user.issueDocWidth : 440,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateSidebarOpen = (open) => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      sidebarOpen: open,
    },
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch({
      type: USER_SIDEBAROPEN,
      sidebarOpen: open,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateSidebarWidth = (width) => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      sidebarWidth: width,
    },
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch({
      type: USER_SIDEBARWIDTH,
      sidebarWidth: width,
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateIssueDocWidth = (width) => async (dispatch, getState) => {
  const { id } = getState().login.user;

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      issueDocWidth: width,
    },
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch({
      type: USER_ISSUEDOCWIDTH,
      issueDocWidth: width,
    });
  } catch (e) {
    console.log(e);
  }
};
