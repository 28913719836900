import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { matchSorter } from 'match-sorter';
import toast from '../helpers/toast';
import { closeModal } from '../actions/modalActions';
import removeEmptyFromObj from '../helpers/removeEmptyFromObj';

const {
  ADMIN_TEAMS_SUCCESS,
  ADMIN_TEAMS_FAILURE,
  ADMIN_TEAMS_FUZZY_TERM,
  ADMIN_TEAMS_FUZZY_SEARCH,
  LOADING_REQUEST,
  LOADING_FINISHED,
  ADMIN_TEAMS_LABELS,
  ADMIN_TEAMS_LIST,
} = constants;

export const adminFuzzy = (searchTerm) => (dispatch) => {
  dispatch({ type: ADMIN_TEAMS_FUZZY_TERM, searchTerm });
  dispatch(adminFuzzySearch());
};

export const adminFuzzySearch = () => (dispatch, getState) => {
  const { searchTerm, teams } = getState().adminTeams;

  const searchData = matchSorter(teams, searchTerm, {
    keys: ['name', 'teamLeader.firstName', 'teamLeader.lastName'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const searchResults = searchData.length > 0 ? searchData : null;
  dispatch({ type: ADMIN_TEAMS_FUZZY_SEARCH, searchResults });
};

export const adminTeamsFetch = (dontFinishLoading) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminTeamsFetch' });

  const options = {
    url: `${api.TEAMS_API}?$sort[name]=1`,
  };

  try {
    const teams = await fetchData(options);

    dispatch({ type: ADMIN_TEAMS_SUCCESS, teams });

    const teamList = teams.reduce((acc, item) => {
      acc[item.id] = `${item.firstName || item.email} ${item.lastName || ' '}`;
      return acc;
    }, {});

    const teamLabels = teams.map(
      ({
        _id,
        firstName,
        email,
        lastName,
        discipline,
        company,
        emailVerified,
        picture,
      }) => ({
        value: _id,
        label: `${firstName || email} ${lastName || ' '}`,
        description: `${(discipline && discipline.toUpperCase()) || ' '} - ${
          company || ' '
        }`,
        emailVerified,
        picture,
        filterValues: [firstName, lastName, email],
      })
    );

    dispatch({
      type: ADMIN_TEAMS_LABELS,
      teamLabels,
    });
    dispatch({
      type: ADMIN_TEAMS_LIST,
      teamList,
    });

    if (!dontFinishLoading) {
      dispatch({ type: LOADING_FINISHED, src: 'adminTeamsFetch' });
    }
  } catch (e) {
    dispatch({ type: ADMIN_TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminTeamsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminTeamsCreate =
  (id, { name, teamSize, teamLeader }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'adminTeamsCreate' });
    const body = {
      name,
      teamSize,
      teamLeader: teamLeader.value,
    };

    // remove empty for patch or it will patch empty records
    const filterEmptyBody = removeEmptyFromObj(body);

    const options = {
      url: id ? `${api.TEAMS_API}/${id}` : api.TEAMS_API,
      type: id ? 'PATCH' : 'POST',
      body: filterEmptyBody,
    };

    try {
      await fetchData(options);
      await dispatch(adminTeamsFetch());
      dispatch({ type: LOADING_FINISHED, src: 'adminTeamsCreate' });
      dispatch(adminFuzzySearch());
      dispatch(closeModal());
    } catch (e) {
      dispatch({ type: ADMIN_TEAMS_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'adminTeamsCreate' });
      dispatch(toast('error', e));
    }
  };
