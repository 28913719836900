import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { adminTokenCreate } from '../../actions/adminTokenActions';
import { adminUsersFetch } from '../../actions/adminUsersActions';
import { selectProfile } from '../../helpers/selectProfile';
import { formatOptionLabelNoPicture } from '../../helpers/selectProfile';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect, { createFilter } from 'react-select';

import DatePicker from '../../components/UI/DateInput/DatePicker';

import './AdminTokenCreate.scss';

// admin create new team section
const AdminTokenCreate = ({
  postNew,
  adminUsers,
  fetchUsers,
  data,
  adminTeams,
}) => {
  const {
    _id: tokenId,
    name,
    user: { _id, firstName, lastName, email } = {},
    expire,
  } = data;

  const adminTeamUserIds = adminTeams.teams.map((item) => item.teamLeader._id);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [submittingForm, setSubmittingForm] = useState(false);

  const [formData, setFormData] = useState(null);

  const [tokenName, setTokenName] = useState(name);
  const [userId, setUserId] = useState(_id);
  const [teamLeader, setTeamLeader] = useState(
    firstName ? firstName + ' ' + lastName : email
  );

  let [firstDay, setFirstDay] = useState(expire || null);

  useEffect(() => {
    if (teamLeader !== undefined) {
      setFormData({
        name: tokenName || '',
        user: { value: userId, label: teamLeader } || '',
        expire: firstDay || '',
      });
    }
  }, [teamLeader, userId, firstDay, tokenName]);

  useEffect(() => {
    reset(formData);
  }, [reset, formData]);

  useEffect(() => {
    fetchUsers();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => {
          postNew(tokenId, data);
        })}
      >
        <h5>
          Name
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
          {...register('name', {
            required: ' First name is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
          })}
          type="text"
          onChange={(e) => setTokenName(e.target.value)}
        />

        <h5>
          Owner
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="user"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="user"
          control={control}
          rules={{ required: ' Owner is required' }}
          render={({ field }) => (
            <ReactSelect
              {...register('user')}
              {...field}
              onChange={(e) => {
                setUserId(e.value);
                setTeamLeader(e.label);
              }}
              options={adminUsers.userLabels}
              isClearable={false}
              isSearchable={true}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              {...selectProfile}
              filterOption={createFilter({
                ignoreAccents: false,
                ignoreCase: true,
                trim: true,
              })} // meant to improve perf
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
              formatOptionLabel={(vals, context) =>
                formatOptionLabelNoPicture(
                  vals,
                  context,
                  adminTeamUserIds,
                  '(Already a team leader.)'
                )
              }
            />
          )}
        />

        <h5>
          Expiration Date
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="expire"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>

        <DatePicker
          value={firstDay}
          setValue={setFirstDay}
          formatString="yyyy-MM-dd"
        />

        <div className="mt-6">
          <ButtonGroup>
            <Button loading={submittingForm}>Save</Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
};

AdminTokenCreate.propTypes = {
  login: object,
  postNew: func,
  projectId: string,
  adminUsers: object,
  project: object,
  fetchUsers: func,
  data: object,
  adminTeams: object,
};

const mapStateToProps = ({ adminUsers, adminTeams }) => ({
  adminUsers,
  adminTeams,
});

const mapDispatchToProps = (dispatch) => ({
  postNew: (id, data) => dispatch(adminTokenCreate(id, data)),
  fetchUsers: () => dispatch(adminUsersFetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTokenCreate);
