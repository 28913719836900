import React, { Fragment, useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import TopNav from '../../components/TopNav/TopNav';
import { projectFetch } from '../../actions/projectActions';
import imageUrl from '../../helpers/imageUrl';
import { setActiveProject } from '../../actions/projectActions';
import SearchBox from '../../components/SearchBox/SearchBox';
import { projectMenuSearch } from '../../actions/menuActions';
import { openModal } from '../../actions/modalActions';
import Modal from '../../components/Modal/Modal';
import VerifyEmail from '../../components/VerifyEmail/VerifyEmail';
import { groupBy, orderBy } from 'lodash';
import { setActiveTeam } from '../../actions/teamsActions';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import Loading from '../../components/Loading/Loading2';
import { Link } from 'react-router-dom';
import HeroIconPlus from '../../components/HeroIcon/HeroIconPlus';

import './LandingPage.scss';

const modal = {
  heading: 'Create New Project',
  contents: 'NewProjectModal',
};

// main index route /
const LandingPage = ({
  project,
  fetchP,
  setActive,
  open,
  login,
  setTeam,
  loading,
  translations,
}) => {
  const {
    dashboard__yourProjects_header,
    dashboard__noProjects_helper,
    dashboard__newProject_button,
    dashboard__search_input,
  } = translations.byKeyTranslations;
  const { projects = [], fuzzyProjects = [] } = project;
  const projectsList = fuzzyProjects || projects;
  const { emailVerified = true } = login.userProfile || {};
  const sortProjects = orderBy(projectsList, 'team.name', 'asc');
  const groupedProjects = groupBy(sortProjects, 'team.name');
  useEffect(() => {
    fetchP();
  }, []); // eslint-disable-line

  return (
    <Fragment>
      <TopNav />
      <Modal />

      {!emailVerified && <VerifyEmail />}
      {emailVerified && (
        <div className="landing-page__container">
          <Loading />

          <div className="landing-page__title">
            <h1>{dashboard__yourProjects_header}</h1>

            {projects.length > 0 && (
              <SearchBox
                action={projectMenuSearch}
                placeholder={dashboard__search_input}
              />
            )}
          </div>

          {!loading.loading && projects.length === 0 && (
            <div className="landing-page__no-projects">
              <div className="landing-page__no-projects--sticker">
                ¯\_(ツ)_/¯
              </div>
              <h2>{dashboard__noProjects_helper}</h2>
            </div>
          )}

          {Object.entries(groupedProjects).map(
            ([projectTitle, projectsList]) => (
              <Fragment key={projectTitle}>
                <h2>{projectTitle}</h2>
                <div className="landing-page__rows">
                  {projectsList.map((item) => {
                    const { _id, name, coverImage, description } = item;
                    return (
                      <Link
                        key={_id}
                        className="landing-page__cell"
                        onClick={() => setActive(item)}
                        to={`/project/${_id}`}
                      >
                        <div
                          className="landing-page__image"
                          style={{
                            backgroundImage: `url("${imageUrl(
                              coverImage && coverImage.key,
                              coverImage && coverImage.url
                            )}")`,
                          }}
                        />

                        <h4>{name}</h4>
                        <p>{description}</p>
                      </Link>
                    );
                  })}

                  {getTeamPermissions(
                    'team:project:new',
                    projectsList[0].team
                  ) && (
                    <div
                      className="landing-page__cell"
                      onClick={() => {
                        setTeam(projectsList[0].team);
                        open();
                      }}
                    >
                      <div className="landing-page__image">
                        <HeroIconPlus size={30} />
                      </div>
                      <h4>{dashboard__newProject_button}</h4>
                    </div>
                  )}
                </div>
              </Fragment>
            )
          )}
        </div>
      )}
    </Fragment>
  );
};

LandingPage.propTypes = {
  project: object,
  login: object,
  setActive: func,
  setTeam: func,
  open: func,
  fetchP: func,
  loading: object,
  translations: object,
};

const mapStateToProps = ({ project, login, loading, translations }) => ({
  project,
  login,
  loading,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  fetchP: () => dispatch(projectFetch(undefined, true)),
  setActive: (project) => dispatch(setActiveProject(project, true)),
  open: () => dispatch(openModal(modal)),
  setTeam: (data) => dispatch(setActiveTeam(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
