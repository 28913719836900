// test if its json and returns
const parseJson = json => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return undefined;
  }
};

export default parseJson;
