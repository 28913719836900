import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { matchSorter } from 'match-sorter';
import { format } from 'date-fns';
import { closeModal } from '../actions/modalActions';
import { setActiveIssue } from '../actions/dashboardActions';

const {
  ITEM_HISTORY_SUCCESS,
  ITEM_HISTORY_FAILURE,
  PROJECT_HISTORY_SUCCESS,
  PROJECT_HISTORY_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  HISTORY_FUZZY_TERM,
  HISTORY_FUZZY_SEARCH,
} = constants;

export const itemGetHistory = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'itemGetHistory' });
  const { _id } = getState().dashboard.activeIssue;

  const fetchDataInfo = {
    url: `${api.ITEM_HISTORY_URL}/${_id}`,
  };

  try {
    const history = await fetchData(fetchDataInfo);

    dispatch({ type: ITEM_HISTORY_SUCCESS, history });
    dispatch({ type: LOADING_FINISHED, src: 'itemGetHistory' });
  } catch (e) {
    dispatch({ type: ITEM_HISTORY_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'itemGetHistory' });
    dispatch(toast('error', e));
  }
};

export const itemRemoveHistory = () => async (dispatch) =>
  dispatch({ type: ITEM_HISTORY_SUCCESS, history: [] });

export const projectGetHistory = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'itemGetHistory' });
  const { _id } = getState().project.activeProject;

  const fetchDataInfo = {
    url: `${api.PROJECT_HISTORY_URL}/${_id}`,
  };

  try {
    const projectHistory = await fetchData(fetchDataInfo);

    dispatch({ type: PROJECT_HISTORY_SUCCESS, projectHistory });
    dispatch({ type: LOADING_FINISHED, src: 'itemGetHistory' });
  } catch (e) {
    dispatch({ type: PROJECT_HISTORY_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'itemGetHistory' });
    dispatch(toast('error', e));
  }
};

export const projectRemoveHistory = () => async (dispatch) =>
  dispatch({ type: PROJECT_HISTORY_SUCCESS, projectHistory: [] });

export const historyFuzzy = (searchTerm) => (dispatch) => {
  dispatch({ type: HISTORY_FUZZY_TERM, searchTerm });
  dispatch(historyFuzzySearch());
};

export const historyFuzzySearch = () => (dispatch, getState) => {
  const { searchTerm, projectHistory } = getState().itemHistory;

  const searchData = matchSorter(projectHistory, searchTerm, {
    keys: [
      'user.firstName',
      'itemsId.name',
      (item) => format(Date.parse(item.createdAt), 'dd/MM/yyyy'),
      (item) => (item.newVal ? Object.keys(item.newVal) : null),
    ],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const searchResults = searchData.length > 0 ? searchData : null;
  dispatch({ type: HISTORY_FUZZY_SEARCH, searchResults });
};

export const openNewItemHistory = (id) => (dispatch, getState) => {
  const { issues } = getState().dashboard;
  const correctIssue = issues.filter((item) => item._id === id);

  if (correctIssue.length > 0) {
    dispatch(setActiveIssue(correctIssue[0]));
  }

  dispatch(closeModal());
};
