import React from 'react';
import { func, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import './SearchBox.scss';
import HeroIconSearch from '../HeroIcon/HeroIconSearch';

// generic search box
const SearchBox = ({ action, search, float, placeholder = '', startBlock }) => {
  let timer;

  const debounce = (event) => {
    event.persist();
    clearTimeout(timer);
    timer = setTimeout(() => {
      search(action, String(event.target.value));
    }, 150);
  };

  return (
    <div
      className={cx(
        'search-box__container',
        {
          'search-box__container--block': startBlock,
          'search-box__container--float': float,
        },
        'pl-0.5'
      )}
    >
      <input
        onChange={debounce}
        placeholder={placeholder}
        defaultValue=""
        type="text"
        className="w-full rounded-md border-[#DFE1E6] bg-[#FAFBFC] focus:ring-bcapp-blue shadow-none focus:border focus:border-bcapp-blue focus:ring-2"
      />

      <div>
        <span className="search-box__icon">
          <HeroIconSearch />
        </span>
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  search: func,
  action: func,
  float: bool,
  placeholder: string,
  startBlock: bool,
};

const mapDispatchToProps = (dispatch) => ({
  search: (action, term) => dispatch(action(term)),
});

export default connect(null, mapDispatchToProps)(SearchBox);
