import React from 'react';
import { bool } from 'prop-types';
import IssueDocumentInfoPriority from './IssueDocumentInfoPriority';
import IssueDocumentInfoStatus from './IssueDocumentInfoStatus';
import IssueDocumentInfoAssignee from './IssueDocumentInfoAssignee';
import IssueDocumentInfoDueDate from './IssueDocumentInfoDueDate';
import IssueDocumentInfoLocation from './IssueDocumentInfoLocation';

import './IssueDocumentInfo.scss';

// item top info section, status etc
const IssueDocumentInfo = ({ bulkEditMode }) => (
  <div className="issue-document__container">
    <IssueDocumentInfoPriority bulkEditMode={bulkEditMode} />
    <IssueDocumentInfoStatus bulkEditMode={bulkEditMode} />
    <IssueDocumentInfoAssignee bulkEditMode={bulkEditMode} />
    <IssueDocumentInfoDueDate bulkEditMode={bulkEditMode} />
    <IssueDocumentInfoLocation bulkEditMode={bulkEditMode} />
  </div>
);

IssueDocumentInfo.propTypes = {
  bulkEditMode: bool,
};

export default IssueDocumentInfo;
