import './HeroIcon.scss';

const HeroIconPencil = ({
  size = 18,
  marginRight = 0,
  marginLeft = 0,
  marginBottom = 0,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="heroicon"
      style={{ width: size, marginRight, marginLeft, marginBottom }}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
    </svg>
  );
};

export default HeroIconPencil;
