import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { push } from 'connected-react-router';
import urlProps from '../helpers/getUrlProps';
import {
  updateFilters,
  updateMultiFilters,
  filterData,
  searchFilter,
  sortData,
} from '../helpers/updateFilters';
import chunk from 'lodash/chunk';
import genUrl from '../helpers/genUrl';
import { PAGINATION_LENGTH } from '../constants';

const {
  ISSUES_SUCCESS,
  ISSUES_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  ISSUES_SET_ACTIVE,
  ISSUES_UPDATE_FILTER,
  SUB_LOADING,
  ALL_ISSUE_DATA_UPDATES,
  COMPACT_LIST,
  COMFORTABLE_LIST,
} = constants;

const { ITEMS_API_URL, UPDATE_SEEN } = api;

export const setActiveIssue =
  (activeIssue = {}) =>
  async (dispatch, getState) => {
    const { activeProject } = getState().project;

    dispatch({ type: ISSUES_SET_ACTIVE, activeIssue });

    dispatch(
      push(genUrl(activeProject._id, activeIssue._id ? activeIssue._id : ''))
    );
  };

export const fetchIssuesAction = (id, disableLoad) => async (dispatch) => {
  if (!disableLoad) {
    dispatch({ type: LOADING_REQUEST, src: 'fetchIssuesAction' });
  }

  const queryParams = '&deleted=false&$sort[itemId]=-1';

  const fetchDataInfo = {
    url: `${ITEMS_API_URL}?projectId=${id}${queryParams}`,
  };

  try {
    const issues = await fetchData(fetchDataInfo);
    dispatch({ type: ISSUES_SUCCESS, issues });
    dispatch(filterDashboardMany(issues));
  } catch (e) {
    dispatch({ type: ISSUES_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'fetchIssuesAction' });
    dispatch(toast('error', e));
  }
};

export const filterDashboard =
  (target, value) => async (dispatch, getState) => {
    const { filters } = getState().dashboard;
    const newFilters = updateFilters(filters, target, value);
    dispatch({ type: ISSUES_UPDATE_FILTER, filters: newFilters });
    dispatch(filterDashboardMany());
  };

export const filterDashboardMulti = (data) => async (dispatch, getState) => {
  const { filters } = getState().dashboard;
  const newFilters = updateMultiFilters(filters, data);
  dispatch({ type: ISSUES_UPDATE_FILTER, filters: newFilters });
  dispatch(filterDashboardMany());
};

export const filterDashboardMany = (data) => async (dispatch, getState) => {
  const url = urlProps('item');

  const {
    issues: savedIssues,
    filters,
    projectFuzzyTerm,
  } = getState().dashboard;

  const { activeWorkspaceIssues } = getState().project;

  const issues = data || savedIssues;

  const parseIssues =
    activeWorkspaceIssues && activeWorkspaceIssues.length
      ? issues.filter((item) => activeWorkspaceIssues.includes(item._id))
      : issues;

  const filteredIssues = filterData(filters, parseIssues);
  const filterBySearch = projectFuzzyTerm
    ? searchFilter(filteredIssues, projectFuzzyTerm)
    : filteredIssues;

  const urlAvaible = filteredIssues.find((item) => item._id === url);

  dispatch(sortAndChunk(filterBySearch, parseIssues));

  if (urlAvaible) {
    dispatch(setActiveIssue(urlAvaible));
  } else {
    dispatch(setActiveIssue());
  }
};

export const sortAndChunk =
  (filterBySearch, filteredIssues) => async (dispatch, getState) => {
    const { target, aToZ } = getState().sortTable;

    const sortedIssues = sortData(filterBySearch, target, aToZ);
    const sortedIssuesChunked = chunk(sortedIssues, PAGINATION_LENGTH);

    dispatch({
      type: ALL_ISSUE_DATA_UPDATES,
      filteredIssues,
      filterBySearch,
      sortedIssues,
      sortedIssuesChunked,
    });
    dispatch({ type: LOADING_FINISHED, src: 'sortAndChunk' });
  };

export const markIssueAsSeen = (id) => async (dispatch, getState) => {
  const { subLoading } = getState().loading;

  dispatch({ type: SUB_LOADING, subLoading: true });

  const updateSeenIssue = {
    url: `${UPDATE_SEEN}/${id}`,
    type: 'PATCH',
  };

  try {
    const { projectId } = await fetchData(updateSeenIssue);

    if (!subLoading) {
      // if page is currently loading dont spam it
      await dispatch(fetchIssuesAction(projectId, true));
      dispatch({ type: SUB_LOADING, subLoading: false });
    }
  } catch (e) {
    dispatch(toast('error', e));
  }
};

export const setCompactList = () => {
  return { type: COMPACT_LIST };
};

export const setComfortableList = () => {
  return { type: COMFORTABLE_LIST };
};
