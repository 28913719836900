import constants from '../constants';

const {
  ITEM_HISTORY_SUCCESS,
  ITEM_HISTORY_FAILURE,
  PROJECT_HISTORY_SUCCESS,
  PROJECT_HISTORY_FAILURE,
  LOGOUT,
  HISTORY_FUZZY_TERM,
  HISTORY_FUZZY_SEARCH,
} = constants;

const initState = {
  history: [],
  projectHistory: [],
  error: '',
  searchTerm: '',
  searchResults: [],
};

export const itemHistory = (state = initState, action) => {
  switch (action.type) {
    case ITEM_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.history,
      };
    case ITEM_HISTORY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case PROJECT_HISTORY_SUCCESS:
      return {
        ...state,
        projectHistory: action.projectHistory,
      };
    case PROJECT_HISTORY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case HISTORY_FUZZY_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case HISTORY_FUZZY_SEARCH:
      return {
        ...state,
        searchResults: action.searchResults,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
