import { Tab } from '@headlessui/react';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Tabs({ tabs, selectedIndex, setSelectedIndex }) {
  return (
    <Tab.Group
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
      as="div"
      className="grid"
    >
      <Tab.List>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            as="div"
            className="inline-block focus-visible:outline-0"
          >
            {({ selected }) => (
              <div
                key={index}
                className={classNames(
                  'select-none focus:ring-0 focus:ring-offset-0 whitespace-nowrap p-4 border-b-2 font-medium text-sm cursor-pointer',
                  selected
                    ? 'border-bcapp-blue text-bcapp-blue'
                    : 'text-black hover:text-gray-700 border-gray-300 hover:border-gray-400'
                )}
              >
                {tab.label}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map((tab, index) => (
          <Tab.Panel key={index}>{tab.content}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
