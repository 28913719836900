import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { genericYearDate } from '../../helpers/formatDates';
import cx from 'classnames';
import { get } from 'lodash';
import imageUrl from '../../helpers/imageUrl';
import { openModal } from '../../actions/modalActions';
import Link from '../Link/Link';
import getPermissions from '../../helpers/getPermissions';
import { imageType } from '../../helpers/correctIcon';
import {
  updateActiveIssue,
  deleteIssue,
  duplicateIssue,
} from '../../actions/itemActions';
import Button, { ButtonGroup } from '../Button/Button';
import HeroIconDotsVertical from '../HeroIcon/HeroIconDotsVertical';
import HeroIconPencil from '../HeroIcon/HeroIconPencil';
import HeroIconX from '../HeroIcon/HeroIconX';

import './IssueDocumentTop.scss';

const modal = {
  contents: 'AssetViewer',
  maxWidth: 'full',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// top of item container with cover photo and title
const IssueDocument = ({
  dashboard = {},
  updateField,
  deleteActiveIssue,
  duplicateActiveIssue,
  openM,
  translations,
}) => {
  const {
    itemView__createdBy_text,
    itemView__delete_button,
    // itemView__duplicate_button, // TODO: add translation
    itemView__clashDate_text,
  } = translations.byKeyTranslations;
  const titleRef = useRef();
  const {
    _id,
    name,
    creator = {},
    createdAt,
    clashDate,
    coverImage = {},
    createdFromNavisworks,
  } = dashboard.activeIssue;

  const [titleVal, setTitleVal] = useState(name);
  const [titleActive, setTitleActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTitleVal(name);
  }, [name]);

  useEffect(() => {
    if (_id && window.innerWidth < 600) {
      if (name === 'Quick Issue') {
        setTitleActive(true);
      }
    }
  }, [_id, name]);

  const coverIsImage = imageType.includes(
    get(coverImage, 'extension', '').replace('.', '').toLowerCase()
  );

  const coverImageUrl = get(coverImage, 'url', '');
  const coverImageKey = get(coverImage, 'key', '');
  const coverImageId = get(coverImage, '_id', '');

  useEffect(() => {
    if (titleActive) {
      titleRef.current.focus();
    }
  }, [titleActive]);

  let openDeleteModal = async () => {
    setIsOpen(true);
  };

  let confirmDelete = async (id) => {
    setIsOpen(false);
    deleteActiveIssue(id);
  };

  return (
    <Fragment>
      <div className="issue__top-section">
        {coverImageUrl && coverIsImage && (
          <Link
            onClick={() => {
              if (window.innerWidth > 600)
                openM({ ...modal, data: coverImageId });
            }}
          >
            <div className="issue__image grid justify-center">
              <img
                src={imageUrl(coverImageKey, coverImageUrl)}
                alt={coverImageUrl}
              />
            </div>
          </Link>
        )}
        <div
          className={cx('issues__top-box', {
            'issues__top-box--gradient': coverImageUrl && coverIsImage,
          })}
        >
          <div className="issues__title">
            {!titleActive ? (
              <p
                className="issue__top-box--title grid grid-cols-[auto_1fr]"
                onClick={
                  getPermissions('item:edit:name')
                    ? () => setTitleActive(true)
                    : () => {}
                }
              >
                <span>{titleVal}</span>
                <span className="justify-self-start cursor-pointer">
                  {getPermissions('item:edit:name') && (
                    <HeroIconPencil marginBottom={-3} marginLeft={5} />
                  )}
                </span>
              </p>
            ) : (
              <input
                value={titleVal}
                className="issue__top-box--title issue__top-box--active border text-gray-400 border-bcapp-blue focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2"
                onChange={(e) => setTitleVal(e.target.value)}
                onBlur={(e) => {
                  setTitleActive(false);
                  if (e.target.value !== name) {
                    updateField(_id, 'name', titleVal);
                  }
                }}
                onFocus={(e) => {
                  titleRef.current.style.height =
                    titleRef.current.scrollHeight + 'px';
                  if (window.innerWidth < 600) {
                    e.target.select();
                  }
                }}
                ref={titleRef}
                onKeyUp={() => {
                  titleRef.current.style.height =
                    titleRef.current.scrollHeight + 'px';
                }}
              />
            )}

            <h5 className="issue__top-box--date">
              {itemView__createdBy_text} {creator && creator.firstName}{' '}
              {creator && creator.lastName} on {genericYearDate(createdAt)}
            </h5>
            {clashDate &&
              createdFromNavisworks === true &&
              clashDate !== createdAt && (
                <h5 className="issue__top-box--date">
                  {itemView__clashDate_text} {genericYearDate(clashDate)}
                </h5>
              )}
          </div>

          {getPermissions('item:delete') && (
            <div>
              <Menu as="div" className="menu">
                <div>
                  <Menu.Button className="menu__button">
                    <HeroIconDotsVertical fillColor="#9a9fa7" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-out duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className=" menu__items">
                    <div className="menu__padding">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => duplicateActiveIssue(_id)}
                            className={classNames(
                              active ? 'menu__item1' : 'menu__item2',
                              'menu__item3'
                            )}
                          >
                            Duplicate
                            {/* {itemView__duplicate_button} // TODO: add translation in db */}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="menu__padding">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={openDeleteModal}
                            className={classNames(
                              active ? 'menu__item1' : 'menu__item2',
                              'menu__item3'
                            )}
                          >
                            {itemView__delete_button}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-[100] bg-white w-full"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-1000"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-[#091e42] bg-opacity-50" />
            </Transition.Child>

            <div className="fixed inset-x-0 md:top-12 top-0 flex justify-center w-full">
              <div className="sm:w-full md:w-[600px]">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-out duration-1000"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="snap-y snap-start w-full min-h-[100px] max-h-[90vh] overflow-auto transform md:rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className="deleteModal__wrapper">
                      <h1 className="deleteModal__heading">Delete item</h1>
                      <div
                        className="modal__close"
                        onClick={() => setIsOpen(false)}
                      >
                        <HeroIconX paddingTop={10} />
                      </div>
                    </div>
                    <p>Are you sure you want to delete this item?</p>

                    <p>
                      All of your data will be permanently removed. This action
                      cannot be undone.
                    </p>
                    <div className="deleteModal__buttonGroup">
                      <ButtonGroup right>
                        <Button danger onClick={() => confirmDelete(_id)}>
                          Delete
                        </Button>
                        <Button subtle onClick={() => setIsOpen(false)}>
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </Fragment>
  );
};

IssueDocument.propTypes = {
  dashboard: object,
  updateField: func,
  deleteActiveIssue: func,
  duplicateActiveIssue: func,
  openM: func,
  translations: object,
  openDeleteModal: func,
};

const mapStateToProps = ({ dashboard, translations }) => ({
  dashboard,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  deleteActiveIssue: (id) => dispatch(deleteIssue(id)),
  duplicateActiveIssue: (id) => dispatch(duplicateIssue(id)),
  openM: (target) => dispatch(openModal(target)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocument);
