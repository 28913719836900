import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { push } from 'connected-react-router';

const { LOADING_FINISHED, LOADING_REQUEST } = constants;

export const forgotPasswordAction =
  ({ email }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'forgotPasswordAction' });

    const fetchDataInfo = {
      url: api.FORGOT_PASSWORD,
      type: 'POST',
      body: {
        email,
      },
    };

    try {
      const message = await fetchData(fetchDataInfo);
      dispatch(toast('success', message));
      dispatch({ type: LOADING_FINISHED, src: 'forgotPasswordAction' });
      dispatch(push(`/login`));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'forgotPasswordAction' });
      dispatch(toast('error', e));
    }
  };
