import React from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Label from '../Label/Label';
import { priorityLabel } from '../../constants/labels';
import DropDown from '../DropDown/DropDown';
import getPermissions from '../../helpers/getPermissions';
import { updateActiveIssue } from '../../actions/itemActions';
import { changeBulkEdit } from '../../actions/bulkActions';

import ReactSelect from 'react-select';

const IssueDocumentInfo = ({
  dashboard,
  updateField,
  bulkEditMode,
  bulkEdit,
  updateBulkEdit,
  translations,
}) => {
  const { field__priority_tag } = translations.byKeyTranslations;
  const { _id, priority } = bulkEditMode ? bulkEdit : dashboard.activeIssue;
  const canEditPriority = getPermissions('item:edit:priority');

  const PriorityLabel = () => (
    <Label hover={canEditPriority}>
      {priority || (bulkEditMode ? '-' : 'Normal')}
    </Label>
  );

  return (
    <div>
      <h5>{field__priority_tag}</h5>

      {canEditPriority ? (
        <DropDown
          input={<PriorityLabel />}
          output={
            <ReactSelect
              options={priorityLabel}
              value={{ value: priority, label: priority }}
              onChange={(data) =>
                bulkEditMode
                  ? updateBulkEdit('priority', data.value)
                  : data.value !== priority
                  ? updateField(_id, 'priority', data.value)
                  : {}
              }
              menuIsOpen
              isClearable={false}
              components={{
                Control: () => <div style={{ display: 'none' }} />,
              }}
              styles={{
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          }
        />
      ) : (
        <PriorityLabel />
      )}
    </div>
  );
};

IssueDocumentInfo.propTypes = {
  dashboard: object,
  updateField: func,
  markAsSeen: func,
  login: object,
  updateDesc: func,
  updateDescImage: func,
  deleteActiveIssue: func,
  newDescAsset: func,
  bulkEditMode: bool,
  bulkEdit: object,
  updateBulkEdit: func,
  translations: object,
};

const mapStateToProps = ({ dashboard, login, bulkEdit, translations }) => ({
  dashboard,
  login,
  bulkEdit,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  updateBulkEdit: (target, value) => dispatch(changeBulkEdit(target, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocumentInfo);
