import React, { Fragment, useEffect } from 'react';
import TopNav from '../../components/TopNav/TopNav';
import { connect } from 'react-redux';
import { confirmEmail } from '../../actions/registerActions';
import { func, object } from 'prop-types';
import Link from '../../components/Link/Link';

import './ConfirmEmail.scss';

// confirm email route /confirm-email/id
const ConfirmEmail = ({ sendConfirmation, match, translations }) => {
  const { confirmEmail__info_text } = translations.byKeyTranslations;
  const id = match.params.id;
  useEffect(() => {
    if (id) {
      sendConfirmation(id);
    }
  }, []); // eslint-disable-line

  return (
    <Fragment>
      <TopNav />
      <div className="confirm__container">
        <h2>{confirmEmail__info_text}</h2>
        <span>
          <Link to="/" bright inline>
            Home
          </Link>{' '}
          -{' '}
          <Link to="/profile#account" bright inline>
            My Account
          </Link>
        </span>
      </div>
    </Fragment>
  );
};

ConfirmEmail.propTypes = {
  sendConfirmation: func,
  match: object,
  translations: object,
};

const mapStateToProps = ({ translations }) => ({ translations });

const mapDispatchToProps = (dispatch) => ({
  sendConfirmation: (id) => dispatch(confirmEmail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
