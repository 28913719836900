import constants from '../constants';

const {
  ADMIN_USER_VIEW_SUCCESS,
  ADMIN_USER_VIEW_FAILURE,
  ADMIN_USER_PROJECTS_SUCCESS,
  ADMIN_USER_PROJECTS_FAILURE,
  ADMIN_USER_EVENTS_SUCCESS,
  ADMIN_USER_EVENTS_FAILURE,
  ADMIN_USER_STATS_SUCCESS,
  ADMIN_USER_STATS_FAILURE,
  LOGOUT,
} = constants;

const initState = {
  user: {},
  projects: [],
  events: [],
  stats: {},
  error: '',
};

export const adminUserView = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_USER_VIEW_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case ADMIN_USER_VIEW_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_USER_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
      };
    case ADMIN_USER_PROJECTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_USER_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.events,
      };
    case ADMIN_USER_EVENTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_USER_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
      };
    case ADMIN_USER_STATS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
