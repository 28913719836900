import constants from '../constants';

const {
  ADMIN_USERS_SUCCESS,
  ADMIN_USERS_FAILURE,
  ADMIN_USERS_FUZZY_TERM,
  ADMIN_USERS_FUZZY_SEARCH,
  ADMIN_USERS_LABELS,
  ADMIN_USERS_LIST,
  LOGOUT,
} = constants;

const initState = {
  users: [],
  error: '',
  searchTerm: '',
  searchResults: null,
  userLabels: [],
};

export const adminUsers = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case ADMIN_USERS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_USERS_FUZZY_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case ADMIN_USERS_FUZZY_SEARCH:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case ADMIN_USERS_LIST: {
      return { ...state, userList: action.userList };
    }
    case ADMIN_USERS_LABELS: {
      return { ...state, userLabels: action.userLabels };
    }
    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
