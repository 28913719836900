import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { forgotPasswordAction } from '../../actions/forgotPasswordActions';
import Link from '../../components/Link/Link';
import ExternalWrapper from '../../components/ExternalWrapper/ExternalWrapper';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './ForgotPassword.scss';

// forgot password route /forgot-password
const ForgotPassword = ({ post, translations }) => {
  const {
    fogotPassword__email_input,
    fogotPassword__back_button,
    fogotPassword__sendResetLink_button,
  } = translations.byKeyTranslations;

  const [submittingForm, setSubmittingForm] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <ExternalWrapper>
      <form onSubmit={handleSubmit(post)}>
        <label>
          {fogotPassword__email_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <input
          className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
          {...register('email', {
            required: ' Email is required',
          })}
          defaultValue=""
          type="email"
        />

        <ButtonGroup>
          <Link to="/login" inline dark>
            {fogotPassword__back_button}
          </Link>
          <Button loading={submittingForm}>
            <span>{fogotPassword__sendResetLink_button}</span>
          </Button>
        </ButtonGroup>
      </form>
    </ExternalWrapper>
  );
};

ForgotPassword.propTypes = {
  post: func,
  translations: object,
};

const mapStateToProps = ({ translations }) => ({ translations });

const mapDispatchToProps = (dispatch) => ({
  post: (data) => dispatch(forgotPasswordAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
