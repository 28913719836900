import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { matchSorter } from 'match-sorter';
import toast from '../helpers/toast';

const {
  ADMIN_PROJECTS_SUCCESS,
  ADMIN_PROJECTS_FAILURE,
  ADMIN_PROJECTS_FUZZY_TERM,
  ADMIN_PROJECTS_FUZZY_SEARCH,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

const { PROJECTS_API_URL } = api;

export const projectsFuzzy = (searchTerm) => (dispatch) => {
  dispatch({ type: ADMIN_PROJECTS_FUZZY_TERM, searchTerm });
  dispatch(adminFuzzySearch());
};

export const adminFuzzySearch = () => (dispatch, getState) => {
  const { searchTerm, projects } = getState().adminProjects;

  const searchData = matchSorter(projects, searchTerm, {
    keys: ['name', 'description'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const searchResults = searchData.length > 0 ? searchData : null;
  dispatch({ type: ADMIN_PROJECTS_FUZZY_SEARCH, searchResults });
};

export const adminProjectsFetch = () => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminProjectsFetch' });

  const fetchDataInfo = {
    url: `${PROJECTS_API_URL}`,
  };

  try {
    const projects = await fetchData(fetchDataInfo);

    dispatch({ type: ADMIN_PROJECTS_SUCCESS, projects });

    dispatch({ type: LOADING_FINISHED, src: 'adminProjectsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_PROJECTS_FAILURE, e });

    dispatch({ type: LOADING_FINISHED, src: 'adminProjectsFetch' });

    dispatch(toast('error', e));
  }
};
