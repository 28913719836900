import { error, success, warning, info } from 'react-toastify-redux';

// toast warpper that shows correct toast
const toast = (type = 'info', message) => {
  const toastList = { error, success, warning, info };
  const correctToast = toastList[type];
  return correctToast(message, {
    position: 'top-center',
  });
};

export default toast;
