import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { getTeamArchive, updateTeamArchive } from '../../actions/teamsActions';

import Button from '../../components/Button/Button';

import FilterTable from '../../components/UI/Table/FilterTable';

import './TeamArchive.scss';

const TeamArchive = ({ fetch, teams, loading, archiveAction }) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => props.getValue(),
      header: 'Name',
    },
    {
      accessorKey: 'description',
      cell: (props) => props.getValue(),
      header: 'Description',
    },
    {
      accessorKey: '_id',
      cell: (props) => (
        <Button onClick={() => archiveAction(props.getValue())}>
          Unarchive
        </Button>
      ),
      header: '',
      enableSorting: false,
    },
  ];

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line
  return (
    <div>
      <h1>Archived Projects</h1>

      <FilterTable
        defaultData={teams.teamArchive}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search projects..."
        loading={loading.loading}
      />
    </div>
  );
};

TeamArchive.propTypes = {
  fetch: func,
  archiveAction: func,
  teams: object,
  loading: object,
};

const mapStateToProps = ({ teams, loading }) => ({
  teams,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(getTeamArchive()),
  archiveAction: (id) => dispatch(updateTeamArchive(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamArchive);
