import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { push } from 'connected-react-router';
import toast from '../helpers/toast';
import { fetchCurrentUserSubscriptions } from '../actions/loginActions';
import { openModal, closeModal } from '../actions/modalActions';
import { matchSorter } from 'match-sorter';
import postImage from '../helpers/postImage';
import removeEmptyFromObj from '../helpers/removeEmptyFromObj';

const {
  LOADING_REQUEST,
  LOADING_FINISHED,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  COMPANY_SUCCESS,
  USER_PROFILE,
  LOGIN_SUCCESS,
  MODAL_CLOSE,
  LOGOUT,
} = constants;

export const getRegisterUser = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'getRegisterUser' });

  const fetchDataInfo = {
    url: `${api.REGISTER_URL}/${id}`,
    type: 'GET',
  };

  try {
    const user = await fetchData(fetchDataInfo);
    dispatch({ type: REGISTER_SUCCESS, user });
    dispatch({ type: LOADING_FINISHED, src: 'getRegisterUser' });
  } catch (e) {
    dispatch({ type: REGISTER_FAILURE, error: 'Invalid User ID' });
    dispatch({ type: LOADING_FINISHED, src: 'getRegisterUser' });
    dispatch(toast('error', 'Invalid User ID'));
  }
};

export const getAllCompanies = () => async (dispatch) => {
  const fetchDataInfo = {
    url: api.COMPANY_URL,
  };

  try {
    const company = await fetchData(fetchDataInfo);
    dispatch({ type: COMPANY_SUCCESS, company });
  } catch (e) {
    dispatch({ type: REGISTER_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getAllCompanies' });
    dispatch(toast('error', e));
  }
};

export const registerAction =
  (id, { firstName, lastName, company, discipline, password }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'registerAction' });

    const fetchDataInfo = {
      url: `${api.REGISTER_URL}/${id}`,
      type: 'PATCH',
      body: {
        firstName,
        lastName,
        company: company,
        discipline: discipline.value,
        password,
      },
    };

    try {
      const user = await fetchData(fetchDataInfo);
      dispatch({ type: REGISTER_SUCCESS, user });
      dispatch({ type: LOADING_FINISHED, src: 'registerAction' });
      dispatch(
        toast('success', `Registration successful. Please login ${firstName}.`)
      );
      dispatch(push('/login'));
    } catch (e) {
      dispatch({ type: REGISTER_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'registerAction' });
      dispatch(toast('error', e));
    }
  };

export const updateAcccountCheck =
  (id, data, register) => async (dispatch, getState) => {
    const modal = {
      contents: 'CompanySuggestionModal',
      heading: 'Is your company correct?',
    };

    const { company } = getState().register;
    const { company: newCompany } = data;

    const whichAction = register ? registerAction : updateAcccount;

    if (company.includes(newCompany) || !newCompany) {
      dispatch(whichAction(id, data));
    } else {
      const list = matchSorter(company, newCompany);

      dispatch(
        openModal({
          ...modal,
          data: {
            list,
            newCompany,
            action: (companyFromModal) => {
              companyFromModal
                ? dispatch(
                    whichAction(id, {
                      ...data,
                      company: companyFromModal,
                    })
                  )
                : dispatch(whichAction(id, data));
              dispatch({ type: MODAL_CLOSE });
            },
          },
        })
      );
    }
  };

export const updateAcccount =
  (
    id,
    {
      firstName,
      lastName,
      company = '',
      discipline = {},
      password,
      interval = {},
      currentPassword,
      email,
      noProjectAddEmail,
      noMetionEmail,
      reports,
      noWeeklyReminder,
      taskDueInterval = {},
    }
  ) =>
  async (dispatch, getState) => {
    const { user } = getState().login;
    dispatch({ type: LOADING_REQUEST, src: 'updateAcccount' });

    const body = {
      firstName,
      lastName,
      company,
      discipline: discipline.value,
      password,
      currentPassword,
      interval: interval.value,
      email,
      noProjectAddEmail,
      noMetionEmail,
      reports,
      noWeeklyReminder,
      taskDueInterval: taskDueInterval.value,
    };

    // remove empty for patch or it will patch empty records
    const filterEmptyBody = removeEmptyFromObj(body);

    const fetchDataInfo = {
      url: `${api.USERS_API_URL}/${id}`,
      type: 'PATCH',
      body: filterEmptyBody,
    };

    try {
      const userProfile = await fetchData(fetchDataInfo);

      const updateUserName = {
        ...user,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        company: userProfile.company,
        discipline: userProfile.discipline,
        email: userProfile.email,
      };
      dispatch({ type: USER_PROFILE, userProfile });
      dispatch({ type: LOGIN_SUCCESS, user: updateUserName });
      dispatch(toast('success', 'Account Updated Successfully'));
      dispatch({ type: LOADING_FINISHED, src: 'updateAcccount' });
    } catch (e) {
      dispatch({ type: REGISTER_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'updateAcccount' });
      dispatch(toast('error', e));
    }
  };

export const updateSubscriptions = (ids) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateSubscriptions' });
  const id = getState().login.user.id;

  const fetchDataInfo = {
    url: `${api.USERS_SUBS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      ids,
    },
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch(fetchCurrentUserSubscriptions());
    dispatch(toast('success', `Account Updated Successfully`));
  } catch (e) {
    dispatch({ type: REGISTER_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'updateSubscriptions' });
    dispatch(toast('error', e));
  }
};

export const sendConfirmEmail = (id) => async (dispatch) => {
  const fetchDataInfo = {
    url: `${api.CONFIRM_EMAIL}/${id}`,
    type: 'GET',
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch(toast('success', `Email sent.`));
  } catch (e) {
    dispatch(toast('error', e));
  }
};

export const confirmEmail = (id) => async (dispatch) => {
  const fetchDataInfo = {
    url: api.CONFIRM_EMAIL,
    type: 'POST',
    body: {
      id,
    },
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch(toast('success', 'Thanks for confirming your email.'));
    dispatch(push('/'));
  } catch (e) {
    dispatch(toast('error', e));
  }
};

export const updateProfilePhoto = (data) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateProfilePhoto' });

  const {
    _id,
    firstName,
    lastName,
    picture: oldPicture,
  } = getState().login.userProfile;
  const type = data.split(';')[0].split('/')[1];
  const fileSource = await fetch(data);
  const fileData = await fileSource.blob();
  const file = new File(
    [fileData],
    `${firstName}_${lastName}_profile_picture.${type}`
  );

  const picture = await postImage(dispatch, file);

  const fetchDataInfo = {
    url: `${api.USERS_API_URL}/${_id}`,
    type: 'PATCH',
    body: {
      picture,
    },
  };

  try {
    const userProfile = await fetchData(fetchDataInfo);
    dispatch({ type: USER_PROFILE, userProfile });
    if (oldPicture) {
      const { _id } = oldPicture;
      const deleteOldAssetReq = {
        url: `${api.ASSET_URL}/${_id}`,
        type: 'DELETE',
      };
      await fetchData(deleteOldAssetReq);
    }
    dispatch(closeModal());
    dispatch({ type: LOADING_FINISHED, src: 'updateProfilePhoto' });
    dispatch(toast('success', 'Photo saved.'));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateProfilePhoto' });
    dispatch(toast('error', e));
  }
};

export const clearAllData = () => async (dispatch) =>
  dispatch({ type: LOGOUT });
