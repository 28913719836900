import React, { Fragment } from 'react';
import { memberLabels, teamLabels } from '../../constants/labels';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import { object, func, string, array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import { format } from 'date-fns';
import ReactSelect, { createFilter } from 'react-select';

import FilterTable from '../../components/UI/Table/FilterTable';

const modal = {
  heading: 'Projects',
  contents: 'UserProjectsModal',
};

const Table = ({
  data,
  action,
  deleteAction,
  title,
  team,
  showPagination,
  enableSearch,
  setEditUser,
  openM,
  loading,
  showUserProjects = true,
}) => {
  const tableHead2 = [
    {
      id: 'name',
      accessorFn: (row) =>
        row.user?.firstName
          ? `${row.user.firstName} ${row.user.lastName}`
          : '-',
      cell: (props) =>
        props.row.original.user?.firstName ? props.getValue() : '-',
      header: 'Name',
      enableGlobalFilter: true,
    },
    {
      id: 'discipline',
      accessorFn: (row) => `${row.user.discipline}` || '-',
      cell: (props) =>
        props.row.original.user?.discipline
          ? props.getValue().toUpperCase()
          : '-',
      header: 'Discipline',
      enableGlobalFilter: false,
    },
    {
      id: 'company',
      accessorFn: (row) => `${row.user.company}` || '-',
      cell: (props) =>
        props.row.original.user?.company ? props.getValue() : '-',
      header: 'Company',
      enableGlobalFilter: true,
    },
    {
      id: 'email',
      accessorFn: (row) => `${row.user.email}` || '-',
      cell: (props) =>
        props.row.original.user?.email && showUserProjects ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              openM({
                ...modal,
                editMode: true,
                data: {
                  id: props.row.original.user._id,
                  email: props.row.original.user.email,
                },
              });
            }}
          >
            <span className="hover:underline hover:cursor-pointer text-bcapp-blue">
              {props.getValue() || '-'}
            </span>
            <div className="custom-select__not-verified--bottom">
              {!props.row.original.user.emailVerified && 'Not Verified'}
            </div>
          </div>
        ) : props.row.original.user?.email && !showUserProjects ? (
          <div>
            {props.getValue() || '-'}
            <div className="custom-select__not-verified--bottom">
              {!props.row.original.user.emailVerified && 'Not Verified'}
            </div>
          </div>
        ) : (
          '-'
        ),
      header: 'Email',
      enableGlobalFilter: true,
    },
    {
      id: 'role',
      header: 'Role',
      accessorFn: (row) =>
        row.teamLevel
          ? `${row.teamLevel}`
          : row.memberLevel
          ? `${row.memberLevel}`
          : '-',
      cell: (props) =>
        action ? (
          <span className="project-edit__select-cell">
            {team && !getTeamPermissions('team:edit:members') ? (
              teamLabels.find(
                (item) => item.value === props.row.original.teamLevel
              ).label
            ) : (
              <ReactSelect
                onChange={({ value }) => {
                  // console.log({ props });
                  setEditUser({
                    action: 'update',
                    id: props.row.original.user._id,
                    value,
                  });
                  // action(
                  //   value,
                  //   props.row.original.user._id,
                  //   props.table.options.data
                  // );
                }}
                value={(props.row.original.memberLevel
                  ? memberLabels
                  : teamLabels
                ).find(
                  ({ value }) =>
                    value ===
                    (props.row.original.memberLevel
                      ? props.row.original.memberLevel
                      : props.row.original.teamLevel)
                )}
                options={
                  props.row.original.memberLevel ? memberLabels : teamLabels
                }
                isClearable={false}
                isSearchable={false}
                menuPortalTarget={document.body}
                filterOption={createFilter({
                  ignoreAccents: false,
                  ignoreCase: true,
                  trim: true,
                })} // meant to improve perf
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base, state) => ({
                    ...base,
                    background: '#fafbfc',
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                    outline: state.isFocused
                      ? '#66ccff solid 2px !important'
                      : 'none',
                    '*': {
                      boxShadow: 'none !important',
                    },
                    '&:hover': {
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                    },
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected
                      ? '#9a9fa7'
                      : state.isFocused
                      ? '#f1f5f9'
                      : base.backgroundColor,
                    '&:hover': {
                      backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                    },
                  }),
                }}
              />
            )}
          </span>
        ) : null,
      enableGlobalFilter: false,
    },
    {
      id: 'lastLoginDate',
      header: 'Last Login',
      accessorFn: (row) => {
        return row.user?.lastLoginDate ? `${row.user.lastLoginDate}` : '-';
      },
      cell: (props) =>
        props.row.original.user?.lastLoginDate
          ? format(new Date(props.getValue()), 'do MMM yyyy')
          : '-',
      enableGlobalFilter: false,
    },
    {
      id: 'delete',
      enableGlobalFilter: false,
      accessorFn: (row) => `${row.user._id}`,
      cell: (props) =>
        deleteAction && props.row.original?.user._id ? (
          <div
            className="project-edit__delete-cell"
            onClick={() => {
              // console.log(props.getValue());
              setEditUser({ action: 'delete', id: props.getValue() });
              // deleteAction(props.getValue(), props.table.options.data);
            }}
          >
            x
          </div>
        ) : null,
      enableSorting: false,
    },
  ];

  return (
    <Fragment>
      {title && <h3 className="edit-members__title">{title}:</h3>}

      <FilterTable
        defaultData={data}
        defaultColumns={tableHead2}
        showPagination={showPagination}
        enableSearch={enableSearch}
        placeholder="Search members..."
        loading={loading.loading}
      />
    </Fragment>
  );
};

Table.propTypes = {
  data: array,
  action: func,
  title: string,
  team: object,
  loading: bool,
  pagination: bool,
  setEditUser: func,
};

const mapStateToProps = ({ dashboard, translations, loading }) => ({
  dashboard,
  translations,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  openM: (target) => dispatch(openModal(target)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Table);

// export default Table;
