import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { adminDashboardFetch } from '../../actions/adminDashboardActions';
import AdminContainer from '../../components/AdminContainer/AdminContainer';
import Stat from '../../components/Stat/Stat';
import { projectUrl, userUrl } from '../../helpers/adminUrls';
import Link from '../../components/Link/Link';

import './AdminDashboard.scss';

// admin route /admin
const AdminDashboard = ({ adminDashboard, fetchProjects }) => {
  useEffect(() => {
    fetchProjects();
  }, []); // eslint-disable-line

  const { overall = {}, today = {}, top = {} } = adminDashboard.stats || {};
  const {
    usersByActivities = [],
    projectsByItems = [],
    projectsByMembers = [],
    companiesByUsers = [],
  } = top;

  return (
    <AdminContainer>
      <h1>Overall</h1>
      <div className="admin__stats-container">
        <Stat name="Users" value={String(overall.users)} />
        <Stat name="Projects" value={String(overall.projects)} />
        <Stat name="Comments" value={String(overall.comments)} />
        <Stat name="Open Items" value={String(overall['Open'] || 0)} />
        <Stat
          name="In Progress Items"
          value={String(overall['In Progress'] || 0)}
        />
        <Stat name="Resolved Items" value={String(overall['Resolved'] || 0)} />
        <Stat name="Closed Items" value={String(overall['Closed'] || 0)} />
        <Stat
          name="Average Items Per Project"
          value={String(
            overall.avgItemsPerProject && overall.avgItemsPerProject.toFixed(2)
          )}
        />
        <Stat
          name="Average Members Per Project"
          value={String(
            overall.avgMembersPerProject &&
              overall.avgMembersPerProject.toFixed(2)
          )}
        />
        <Stat
          name="Average Comments Per Item"
          value={String(
            overall.avgCommentsPerItem && overall.avgCommentsPerItem.toFixed(2)
          )}
        />
      </div>

      <h1>Today</h1>
      <div className="admin__stats-container">
        <Stat name="New Users" value={String(today.users)} />
        <Stat name="New Projects" value={String(today.projects)} />
        <Stat name="New Comments" value={String(today.comments)} />
        <Stat name="New Events" value={String(today.events)} />
        <Stat name="Items Opened" value={String(today['Open'] || 0)} />
        <Stat
          name="Items In Progress"
          value={String(today['In Progress'] || 0)}
        />
        <Stat name="Items Resolved" value={String(today['Resolved'] || 0)} />
        <Stat name="Items Closed" value={String(today['Closed'] || 0)} />
      </div>

      <h1>Top Lists</h1>
      <div className="admin__stats-lists-container">
        <div>
          <h2>Users By Activity Count</h2>
          <div className="admin__stats-table">
            {usersByActivities.map(({ user, value }, index) => (
              <div
                key={String(user ? user._id : user) + String(index)}
                className="admin__stats-table--row"
              >
                <div>
                  {
                    // null users are actions done by the system
                    user && (
                      <Link to={user && userUrl(user._id)} dark>
                        {`${user.firstName} ${user.lastName}`}
                      </Link>
                    )
                  }
                  {!user && 'SYSTEM'}
                </div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h2>Projects By Item Count</h2>
          <div className="admin__stats-table">
            {projectsByItems.map(({ project, value }) => (
              <div key={project._id} className="admin__stats-table--row">
                <div>
                  <Link to={`${projectUrl(project._id)}`} dark>
                    {project.name}
                  </Link>
                </div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h2>Projects By Member Count</h2>
          <div className="admin__stats-table">
            {projectsByMembers.map(({ project, value }) => (
              <div key={project._id} className="admin__stats-table--row">
                <div>
                  <Link to={`${projectUrl(project._id)}`} dark>
                    {project.name}
                  </Link>
                </div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h2>Companies By User Count</h2>
          <div className="admin__stats-table">
            {companiesByUsers.map(({ name, value }) => (
              <div key={name} className="admin__stats-table--row">
                <div>{name || 'NO COMPANY NAME'}</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AdminContainer>
  );
};

AdminDashboard.propTypes = {
  fetchProjects: func,
  adminDashboard: object,
};

const mapStateToProps = ({ adminDashboard }) => ({
  adminDashboard,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(adminDashboardFetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
