import React, { useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { openModal } from '../../actions/modalActions';
import imageUrl from '../../helpers/imageUrl';
import Avatar from '../../components/UI/Avatar/Avatar';
import { updateTeamDetails } from '../../actions/teamsActions';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import template from 'string-template';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './TeamSettings.scss';

const modal = {
  contents: 'ProfilePictureModal',
  heading: 'Upload new Profile Picture',
  data: { team: true },
  maxWidth: '480px',
};

// team setting route /teams
const TeamSettings = ({ saveTeam, openM, teams = {}, translations }) => {
  const {
    teamSettings__teamSeats_header,
    teamSettings__teamSizeInfo_text,
    teamSettings__usedSeatsInfo_text,
  } = translations.byKeyTranslations;
  const {
    name,
    logo: { key, url } = {},
    teamSize = 0,
    teamMembers = [],
  } = teams.activeTeam;

  const [UserFormChanged, setUserFormChanged] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const onSubmit = handleSubmit(({ name }) => {
    saveTeam({ field: 'name', value: name });
  });

  return (
    <div>
      <h1>{name} Details</h1>

      <form onSubmit={onSubmit} onChange={() => setUserFormChanged(true)}>
        <h5>
          Team Name<span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <input
          className="block w-full py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm focus-visible:outline-0"
          {...register('name', {
            required: ' Name is required',
            validate: {
              moreThanTwo: (v) =>
                v.length > 2 || 'Needs to be more than 2 characters long',
            },
          })}
          defaultValue={name}
          disabled={!getTeamPermissions('team:edit:name')}
        />

        {getTeamPermissions('team:edit:name') && (
          <div className="mt-6">
            <ButtonGroup>
              <Button disabled={!UserFormChanged}>Save</Button>
            </ButtonGroup>
          </div>
        )}
      </form>

      <h1>Team Logo</h1>

      <div className="profile__picture">
        <button
          onClick={
            getTeamPermissions('team:edit:picture')
              ? () => openM({ ...modal })
              : null
          }
        >
          <Avatar
            src={imageUrl(key, url)}
            size="xxlarge"
            borderColor="#DFE1E6"
            alt={name}
          />
        </button>
      </div>

      {getTeamPermissions('team:view:members') && (
        <div>
          <h1>{teamSettings__teamSeats_header}</h1>
          <p>{template(teamSettings__teamSizeInfo_text, { teamSize })}</p>
          <p>
            {teamMembers.length} / {teamSize} {teamSettings__usedSeatsInfo_text}
          </p>
        </div>
      )}
    </div>
  );
};

TeamSettings.propTypes = {
  saveTeam: func,
  openM: func,
  team: object,
  teams: object,
  translations: object,
};

const mapStateToProps = ({ teams, translations }) => ({ teams, translations });

const mapDispatchToProps = (dispatch) => ({
  saveTeam: (data) => dispatch(updateTeamDetails(data)),
  openM: (target) => dispatch(openModal(target)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamSettings);
