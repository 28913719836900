const teamRoles = {
  'team:edit': 'team:edit',
  'team:edit:name': 'team:edit:name',
  'team:edit:picture': 'team:edit:picture',
  'team:edit:members': 'team:edit:members',
  'team:project:new': 'team:project:new',
  'team:view:members': 'team:view:members',
  'team:view:projects': 'team:view:projects',
  'team:view:archive': 'team:view:archive',
};

// TEAM ADMIN
export const teamAdminRole = Object.values(teamRoles);

// TEAM MEMBER
export const teamMemberRole = [];
