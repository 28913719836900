import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { adminTokenFetch } from '../../actions/adminTokenActions';
import AdminContainer from '../../components/AdminContainer/AdminContainer';
import Button from '../../components/Button/Button';
import { openModal } from '../../actions/modalActions';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminTokens.scss';

const modal = {
  contents: 'AdminTokenCreate',
  heading: 'Create New Token',
};

const AdminTokens = ({ adminTokens = {}, fetchTokens, openM, loading }) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <button
          className="text-bcapp-blue"
          onClick={() =>
            openM({ ...modal, heading: 'Edit Token', data: props.row.original })
          }
        >
          {props.getValue()}
        </button>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'token',
      cell: (props) => props.getValue(),
      header: 'Token',
    },
    {
      id: 'user',
      accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}` || '-',
      cell: (props) => props.getValue(),
      header: 'User',
    },
    {
      accessorKey: 'expire',
      cell: (props) => props.getValue(),
      header: 'Expire',
    },
  ];

  useEffect(() => {
    fetchTokens();
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <div className="admin-team__title">
        <h1>Admin Tokens</h1>
        <Button onClick={() => openM(modal)}>Create New Token</Button>
      </div>

      <FilterTable
        defaultData={adminTokens.tokens}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search tokens..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminTokens.propTypes = {
  adminTokens: object,
  fetchTokens: func,
  openM: func,
};

const mapStateToProps = ({ adminTokens, loading }) => ({
  adminTokens,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTokens: () => dispatch(adminTokenFetch()),
  openM: (data) => dispatch(openModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTokens);
