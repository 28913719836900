import React from 'react';
import { func, string, object } from 'prop-types';
import { connect } from 'react-redux';
import { setActiveProject, archiveProject } from '../../actions/projectActions';
import cx from 'classnames';
import { openModal } from '../../actions/modalActions';
import imageUrl from '../../helpers/imageUrl';
import ThumbNail from '../ThumbNail/ThumbNail';
import getPermissions from '../../helpers/getPermissions';
import HeroIconDotsVertical from '../HeroIcon/HeroIconDotsVertical';

import { Menu } from '@headlessui/react';

import './ProjectNavItem.scss';

const modal = {
  heading: 'Confirm Delete',
  contents: 'DeleteProjectModal',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// this is the items inside the left hand side menu
const ProjectNavItem = ({
  project,
  setActive,
  activeId,
  archiveProject,
  open,
  translations,
}) => {
  const {
    projectNav__archive_link,
    //  projectNav__delete_link
  } = translations.byKeyTranslations;
  const { coverImage = {} } = project;

  return (
    <div
      className={cx('project-nav__item-container', {
        'project-nav__item--active': activeId === project._id,
      })}
      key={project._id}
      onClick={() => (activeId !== project._id ? setActive(project) : {})}
    >
      <div className="project-nav__item-sub">
        <div className="project-nav__item-cell">
          <div className="project-nav__image">
            <ThumbNail
              url={imageUrl(
                coverImage && coverImage.key,
                coverImage && coverImage.url
              )}
              name={coverImage && coverImage.url}
              noLink
              flex
              noPadding
            />
          </div>

          <div className="project-nav__item">
            <div className="project-nav__item--clip">{project.name}</div>
            <div className="project-nav__desc">{project.description}</div>
          </div>
        </div>

        {getPermissions('project:edit') && (
          <div className="project-nav__sub-menu">
            <Menu as="div" className="menu">
              <Menu.Button className="menu__button">
                <HeroIconDotsVertical fillColor="#9a9fa7" />
              </Menu.Button>
              <Menu.Items className="project-menu__items">
                <div className="project-menu__padding">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          archiveProject(project._id);
                        }}
                        className={classNames(
                          active ? 'menu__item1' : 'menu__item2',
                          'project-menu__item3'
                        )}
                      >
                        {projectNav__archive_link}
                      </button>
                    )}
                  </Menu.Item>
                </div>
                {/* Disable delete project */}
                {/* <div className="menu__padding">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          open(project._id);
                        }}
                        className={classNames(
                          active ? 'menu__item1' : 'menu__item2',
                          'project-menu__item3'
                        )}
                      >
                        {projectNav__delete_link}
                      </button>
                    )}
                  </Menu.Item>
                </div> */}
              </Menu.Items>
            </Menu>
          </div>
        )}
      </div>
    </div>
  );
};

ProjectNavItem.propTypes = {
  setActive: func,
  activeId: string,
  archiveProject: func,
  open: func,
  project: object,
  translations: object,
};

const mapStateToProps = ({ login, translations }) => ({
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  setActive: (project) => dispatch(setActiveProject(project)),
  archiveProject: (id) => dispatch(archiveProject(id)),
  open: (id) => dispatch(openModal({ ...modal, data: id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNavItem);
