import React from 'react';
import ProfileAccount from '../../pages/ProfileAccount/ProfileAccount';
import ProfileSubs from '../../pages/ProfileSubs/ProfileSubs';
import ProfilePassword from '../../pages/ProfilePassword/ProfilePassword';
import ProfileNotifications from '../../pages/ProfileNotifications/ProfileNotifications';
import AccountNav from '../../components/AccountNav/AccountNav';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import HeroIconBell from '../../components/HeroIcon/HeroIconBell';
import HeroIconLock from '../../components/HeroIcon/HeroIconLock';
import HeroIconAtSymbol from '../../components/HeroIcon/HeroIconAtSymbol';
import HeroIconUserCircle from '../../components/HeroIcon/HeroIconUserCircle';

import './Profile.scss';

// profile route /profile
const Profile = ({ translations }) => {
  const {
    accountNav__settings_header,
    accountNav__accountDetails_link,
    accountNav__password_link,
    accountNav__notifications_link,
    accountNav__subscriptions_link,
  } = translations.byKeyTranslations;
  return (
    <AccountNav
      links={[
        {
          title: accountNav__settings_header,
          isHeader: true,
        },
        {
          url: '#account',
          title: accountNav__accountDetails_link,
          component: <ProfileAccount />,
          icon: <HeroIconUserCircle marginRight={10} />,
        },
        {
          url: '#password',
          title: accountNav__password_link,
          component: <ProfilePassword />,
          icon: <HeroIconLock marginRight={10} />,
        },
        {
          url: '#notifications',
          title: accountNav__notifications_link,
          component: <ProfileNotifications />,
          icon: <HeroIconAtSymbol marginRight={10} />,
        },
        {
          url: '#subscriptions',
          title: accountNav__subscriptions_link,
          component: <ProfileSubs />,
          icon: <HeroIconBell marginRight={10} />,
        },
      ]}
    />
  );
};

Profile.propTypes = { translations: object };

const mapStateToProps = ({ translations }) => ({
  translations,
});

export default connect(mapStateToProps, null)(Profile);
