import React from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import { formatDistance } from 'date-fns';
import Seen from '../Seen/Seen';
import { openProjectAndItem } from '../../actions/notificationActions';
import { orderBy, startCase } from 'lodash';

import './NotificationFeed.scss';
import { generatePdf } from '../../actions/projectActions';

const getCorrectWorking = (item) => {
  switch (item) {
    case 'remove':
      return 'removed';
    case 'patch':
      return 'changed';
    case 'create':
      return 'added';
    default:
      return '';
  }
};
//
// notifcation feed pop down
const NotificationFeed = ({
  notifications = {},
  openItem,
  getPdf,
  login = {},
  translations,
}) => {
  const { topNav__projectNotifications_header } =
    translations.byKeyTranslations;
  const { notifications: notificationsFeed } = notifications;
  const { notifications: notificationCount } = login;

  const orderedFeed = orderBy(notificationsFeed, 'createdAt', 'desc');

  return (
    <div className="notifications__container">
      <div className="notifications__title">
        {topNav__projectNotifications_header}
      </div>

      <div className="notifications__container--scroll">
        {orderedFeed.map(
          ({ _id, event, createdAt, mention, eventString }, index) => {
            const { method, itemsId = {}, newVal, user, table } = event;
            const { firstName, lastName } = user;
            const { name, projectId = {}, _id: iId } = itemsId || {};
            const { name: projectName, _id: pId } = projectId;
            const items = Object.keys(newVal).map(startCase).join(', ');

            if (
              eventString === 'Report' &&
              method === 'create' &&
              table === 'assets'
            ) {
              return (
                <div
                  key={_id}
                  className="notifications__row"
                  onClick={() =>
                    getPdf({ key: newVal.assets, name: newVal.projectName })
                  }
                >
                  <div className="notifications__row--seen">
                    <Seen seen={index >= notificationCount} />
                  </div>
                  <div className="notifications__row--cut">
                    Your report is ready, click to{' '}
                    <span className=" text-bcapp-blue">download</span>.
                    <div className="notifications__date">
                      <span className="notifications__date--name">
                        {newVal.projectName}
                      </span>
                      <span>
                        {formatDistance(new Date(), Date.parse(createdAt), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={_id}
                  className="notifications__row"
                  onClick={() => openItem(pId, iId)}
                >
                  <div className="notifications__row--seen">
                    <Seen seen={index >= notificationCount} />
                  </div>
                  <div className="notifications__row--cut">
                    {/* handle mentions differently */}
                    {mention
                      ? `${firstName} ${lastName} mentioned you on
                item: ${name}`
                      : `${firstName} ${lastName} ${getCorrectWorking(
                          method
                        )} ${items} on
                item: ${name}`}
                    <div className="notifications__date">
                      <span className="notifications__date--name">
                        {projectName}
                      </span>
                      <span>
                        {formatDistance(new Date(), Date.parse(createdAt), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          }
        )}
      </div>
    </div>
  );
};

NotificationFeed.propTypes = {
  notifications: object,
  nav: func,
  login: object,
  openItem: func,
  getPdf: func,
  translations: object,
};

const mapStateToProps = ({ notifications, login, translations }) => ({
  notifications,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  openItem: (pId, iId) => dispatch(openProjectAndItem(pId, iId)),
  getPdf: (data) => dispatch(generatePdf(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationFeed);
