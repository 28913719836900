import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import FilterTable from '../../components/UI/Table/FilterTable';
import { projectsByUserFetch } from '../../actions/projectActions';

import './UserProjectsModal.scss';

const UserProjectsModal = ({ data, fetchUserProjects, project, loading }) => {
  useEffect(() => {
    fetchUserProjects(data?.id);
  }, []); // eslint-disable-line

  const tableHead = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <a href={`/project/${props.row.original._id}`}>{props.getValue()}</a>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'description',
      cell: (props) => props.getValue(),
      header: 'Description',
    },
    {
      accessorKey: 'members',
      cell: (props) => props.getValue().length,
      header: 'Members',
    },
  ];

  const modalBody = (
    <div className="user__projects-container px-0.5">
      <p>
        Projects assigned to <span className="underline">{data?.email}</span>
      </p>

      <FilterTable
        defaultData={project?.projects}
        defaultColumns={tableHead}
        showPagination={true}
        pagination={10}
        enableSearch={true}
        placeholder="Search projects..."
        loading={loading.loading}
        filterColumn="name"
      />
    </div>
  );

  return <ModalFormat body={modalBody} />;
};

UserProjectsModal.propTypes = {
  data: object,
  translations: object,
  fetchUserProjects: func,
};

const mapStateToProps = ({ project, loading }) => {
  return { project, loading };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserProjects: (uid) => dispatch(projectsByUserFetch(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProjectsModal);

// export default UserProjectsModal;
