import React, { useEffect, useState, useRef } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import {
  projectGetHistory,
  projectRemoveHistory,
  historyFuzzy,
  openNewItemHistory,
} from '../../actions/itemHistoryActions';
import { format } from 'date-fns';
import { startCase, chunk, get } from 'lodash';
import Link from '../../components/Link/Link';
import ReactPaginate from 'react-paginate';
import SearchBox from '../../components/SearchBox/SearchBox';

import './ProjectHistoryModal.scss';

const getCorrectWorking = (item) => {
  switch (item) {
    case 'remove':
      return 'removed';
    case 'patch':
      return 'changed';
    case 'create':
      return 'added';
    default:
      return '';
  }
};

const keysToRemove = [
  'createdAt',
  'creator',
  'projectId',
  'descriptionText',
  'statusUpdatedAt',
  'itemId',
  'navisId',
  'seenBy',
  'clashDate',
  'createdFromNavisworks',
  '_id',
];

const getCorrectKeys = (obj) =>
  Object.keys(obj || {})
    .filter((item) => !keysToRemove.includes(item))
    .map(startCase)
    .join(', ');

// this is used to show project report on project nav
const ProjectHistoryModal = ({
  fetch,
  clear,
  itemHistory,
  open,
  translations,
}) => {
  const { projectNavHistory__search_input } = translations.byKeyTranslations;

  const [activePage, setActivePage] = useState(0);
  const pageContainer = useRef();
  const { projectHistory, searchResults = [] } = itemHistory;
  useEffect(() => {
    fetch();
    return () => clear();
  }, []); // eslint-disable-line

  const correctResults =
    searchResults === null || searchResults.length > 0
      ? searchResults
      : projectHistory;

  const chunkedHistory = chunk(correctResults, 50) || [];
  const historyToShow = get(chunkedHistory, [activePage], []);

  return (
    <div
      className="item-history__container custom-scroll pr-2 pt-4"
      ref={pageContainer}
    >
      <div className="item-history__search">
        <SearchBox
          action={historyFuzzy}
          placeholder={projectNavHistory__search_input}
        />
      </div>

      {historyToShow.map(
        ({ _id, user, createdAt, method, newVal, itemsId = {} }) => (
          <div key={_id} className="item-history__row">
            {format(Date.parse(createdAt), 'dd/MM/yyyy HH:mm')} -{' '}
            {user.firstName} {user.lastName} {getCorrectWorking(method)}{' '}
            {getCorrectKeys(newVal)}
            <Link bright onClick={() => open(itemsId._id)}>
              {itemsId.name}
            </Link>
          </div>
        )
      )}

      <ReactPaginate
        onPageChange={(val) => {
          pageContainer.current.scrollTop = 0;
          setActivePage(val.selected);
        }}
        pageCount={chunkedHistory.length}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        containerClassName="history__pagination"
        subContainerClassName="history__pages history__pagination"
        activeClassName="history__active"
      />
    </div>
  );
};

ProjectHistoryModal.propTypes = {
  itemHistory: object,
  fetch: func,
  clear: func,
  open: func,
  translations: object,
};

const mapStateToProps = ({ itemHistory, translations }) => ({
  itemHistory,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(projectGetHistory()),
  clear: () => dispatch(projectRemoveHistory()),
  open: (id) => dispatch(openNewItemHistory(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectHistoryModal);
