import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import {
  updateAcccount,
  updateSubscriptions,
} from '../../actions/registerActions';
import {
  fetchCurrentUser,
  fetchCurrentUserSubscriptions,
} from '../../actions/loginActions';
import Link from '../../components/Link/Link';
import Checkbox from '../../components/CheckBox/CheckBox';
import { genericTimeYearDate } from '../../helpers/formatDates';
import { flatMap } from 'lodash';

import './ProfileSubs.scss';

// profile subscriptions route /profile#subscriptions
const ProfileSubs = ({ login, getUser, getSubs, updateSubs, translations }) => {
  const {
    subscriptionsDetailsPage__info_helper,
    subscriptionsDetailsPage__removeAll_link,
    subscriptionsDetailsPage__save_button,
    subscriptionsDetailsPage__selectAll_link,
    subscriptionsDetailsPage__subscriptions_header,
  } = translations.byKeyTranslations;

  const [remove, setRemove] = useState([]);

  const { subs = {} } = login;

  useEffect(() => {
    getUser();
    getSubs();
  }, []); // eslint-disable-line

  return (
    <div>
      <h1>{subscriptionsDetailsPage__subscriptions_header}</h1>

      <p>{subscriptionsDetailsPage__info_helper}</p>

      {flatMap(Object.entries(subs), (item) => {
        const [key, values] = item;
        return (
          <Fragment key={key}>
            <h2>{key}</h2>
            <p>
              <Link
                onClick={() => {
                  setRemove([...remove, ...values.map(({ _id }) => _id)]);
                }}
                dark
                inline
              >
                {subscriptionsDetailsPage__selectAll_link}
              </Link>{' '}
              -{' '}
              <Link
                dark
                inline
                onClick={() => {
                  setRemove(
                    remove.filter(
                      (item) => !values.map(({ _id }) => _id).includes(item)
                    )
                  );
                }}
              >
                {subscriptionsDetailsPage__removeAll_link}
              </Link>
            </p>
            <div className="profile__sub--container">
              {values.map(
                ({ _id, name, descriptionText, creator, createdAt }) => (
                  <div
                    key={_id}
                    className="profile__sub--item"
                    onClick={() =>
                      remove.includes(_id)
                        ? setRemove(remove.filter((item) => item !== _id))
                        : setRemove([...remove, _id])
                    }
                  >
                    <div>
                      <Checkbox isChecked={remove.includes(_id)} />
                    </div>

                    <div className="big-flex">
                      <div className="profile__sub--title">
                        <h5>{name}</h5>
                        <p>
                          {creator.firstName} {creator.lastName} on{' '}
                          {genericTimeYearDate(createdAt)}
                        </p>
                      </div>
                      <p>{descriptionText}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </Fragment>
        );
      })}

      <div className="mt-6">
        <ButtonGroup>
          <Button onClick={() => updateSubs(remove)}>
            {subscriptionsDetailsPage__save_button || ''}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

ProfileSubs.propTypes = {
  register: object,
  getUser: func,
  login: object,
  updateUser: func,
  updateSubs: func,
  getSubs: func,
  translations: object,
};

const mapStateToProps = ({ register, login, translations }) => ({
  register,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data) => dispatch(updateAcccount(id, data)),
  getUser: () => dispatch(fetchCurrentUser()),
  getSubs: () => dispatch(fetchCurrentUserSubscriptions()),
  updateSubs: (ids) => dispatch(updateSubscriptions(ids)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSubs);
