const roles = {
  'project:create': 'project:create',
  'project:delete': 'project:delete',
  'project:archive': 'project:archive',
  'project:edit': 'project:edit',
  'project:edit:title': 'project:edit:title',
  'project:edit:members': 'project:edit:members',
  'project:edit:cover': 'project:edit:cover',
  'project:edit:navis': 'project:edit:navis',
  'project:edit:powerBi': 'project:edit:powerBi',
  'project:edit:locations': 'project:edit:locations',
  'project:workspace:create': 'project:workspace:create',
  'project:workspace:public:create': 'project:workspace:public:create',
  'project:workspace:public:edit': 'project:workspace:public:edit',
  'project:history:view': 'project:history:view',
  'item:create': 'item:create',
  'item:delete': 'item:delete',
  'item:edit:bulk': 'item:edit:bulk',
  'item:edit:name': 'item:edit:name',
  'item:edit:desc': 'item:edit:desc',
  'item:edit:priority': 'item:edit:priority',
  'item:edit:status': 'item:edit:status',
  'item:edit:status:closed': 'item:edit:status:closed',
  'item:edit:assignee': 'item:edit:assignee',
  'item:edit:dueDate': 'item:edit:dueDate',
  'item:edit:tags': 'item:edit:tags',
  'item:edit:location': 'item:edit:location',
  'item:edit:subscribers': 'item:edit:subscribers',
  'item:attachment:add': 'item:attachment:add',
  'item:attachment:delete': 'item:attachment:delete',
  'item:attachment:changeCover': 'item:attachment:changeCover',
  'item:history:view': 'item:history:view',
  'item:comment:add': 'item:comment:add',
  'item:comment:edit': 'item:comment:edit',
  'item:comment:delete': 'item:comment:delete',
  'item:comment:edit:self': 'item:comment:edit:self',
  'item:comment:delete:self': 'item:comment:delete:self',
  'subtask:create': 'subtask:create',
  'subtask:edit': 'subtask:edit',
  'subtask:delete': 'subtask:delete',
};

// arr of all edit actions
const editProject = [
  roles['project:edit:title'],
  roles['project:edit:collabs'],
  roles['project:edit:admins'],
  roles['project:edit:cover'],
  roles['project:edit:locations'],
  roles['project:edit:navis'],
  roles['project:edit:powerBi'],
  roles['project:edit:members'],
  roles['project:workspace:public:create'],
  roles['project:workspace:public:edit'],
];

// arr of all item edit actions
const editItem = [
  roles['item:create'],
  roles['item:delete'],
  roles['item:edit:name'],
  roles['item:edit:desc'],
  roles['item:edit:priority'],
  roles['item:edit:status'],
  roles['item:edit:assignee'],
  roles['item:edit:dueDate'],
  roles['item:edit:tags'],
  roles['item:edit:location'],
  roles['project:workspace:create'],
];

// ADMIN ROLE
export const projectAdminRole = [
  roles['project:delete'],
  roles['project:edit'],
  roles['project:history:view'],
  ...editProject,
  ...editItem,
  roles['item:history:view'],
  roles['item:edit:status:closed'],
  roles['item:attachment:add'],
  roles['item:attachment:delete'],
  roles['item:attachment:changeCover'],
  roles['item:comment:add'],
  roles['item:comment:edit:self'],
  roles['item:comment:delete:self'],
  roles['item:edit:subscribers'],
  roles['item:edit:bulk'],
  roles['subtask:create'],
  roles['subtask:edit'],
  roles['subtask:delete'],
];

// COLLAB ROLE
export const projectCollaboratorRole = [
  roles['project:history:view'],
  ...editItem,
  roles['item:history:view'],
  roles['item:attachment:add'],
  roles['item:attachment:delete'],
  roles['item:attachment:changeCover'],
  roles['item:comment:add'],
  roles['item:comment:edit:self'],
  roles['item:comment:delete:self'],
  roles['item:edit:subscribers'],
  roles['item:edit:bulk'],
  roles['subtask:create'],
  roles['subtask:edit'],
  roles['subtask:delete'],
];

// GUEST ROLE
export const projectGuestRole = [
  roles['item:comment:add'],
  roles['item:comment:edit:self'],
  roles['item:comment:delete:self'],
  roles['project:workspace:create'],
];
