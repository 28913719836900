import fetchData from '../helpers/fetch';
import constants, { api } from '../constants/';
import toast from '../helpers/toast';
import { fetchIssuesAction, setActiveIssue } from '../actions/dashboardActions';
import postImage from '../helpers/postImage';
import filters from '../constants/filters';
import { setActiveWorkspaceOnly } from '../actions/workspaceActions';
import draft2Text from '../helpers/draft2Text';

const {
  CREATE_NEW_ISSUE_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  MODAL_CLOSE,
  ALL_ISSUES,
} = constants;

export const createNewIssue =
  (
    { name, description, dueDate, status = {}, priority = {}, assignee, tags },
    coverRef
  ) =>
  async (dispatch, getState) => {
    const { activeProject } = getState().project;

    try {
      dispatch({ type: LOADING_REQUEST, src: 'createNewIssue' });

      const coverImage = coverRef
        ? await postImage(dispatch, coverRef)
        : undefined;

      const body = {
        name,
        description,
        descriptionText: draft2Text(description),
        coverImage,
        status: status.value,
        priority: priority.value,
        dueDate,
        projectId: activeProject._id,
        assignee: assignee && assignee.value,
        tags,
      };

      const fetchDataInfo = {
        url: api.ITEMS_API_URL,
        type: 'POST',
        body,
      };

      const newissue = await fetchData(fetchDataInfo);
      dispatch({ type: MODAL_CLOSE });
      dispatch(setActiveWorkspaceOnly(ALL_ISSUES, filters()));
      dispatch(setActiveIssue(newissue));
      await dispatch(fetchIssuesAction(activeProject._id));
    } catch (e) {
      dispatch({ type: CREATE_NEW_ISSUE_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'createNewIssue' });
      dispatch(toast('error', e));
    }
  };
