import { func, object, array } from 'prop-types';
import React, { Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import { updateBulkTags } from '../../actions/bulkActions';
import getPermissions from '../../helpers/getPermissions';
import { tagify } from '../../helpers/tagify';
import DropDown from '../DropDown/DropDown';
import Tag from '../Tag/Tag';

import CustomReactSelect from 'react-select/creatable';

import './TagsShared.scss';

// rounded tags used on filter and tags
// also a create new is avaible
const TagsShared = ({
  createTag,
  deleteTag,
  updateTags,
  project,
  translations,
  sharedTags,
  bulkEdit,
}) => {
  const { field__tags_tag } = translations.byKeyTranslations;
  const tagRef = useRef();
  const canUpdate = getPermissions('item:edit:tags');

  const { items } = bulkEdit;

  return (
    <Fragment>
      <div className="flex flex-wrap items-center">
        <h5 className="pb-1">{field__tags_tag}</h5>

        {canUpdate && items.length > 0 ? (
          <div className="pl-2">
            <DropDown
              action={() => tagRef.current.focus()}
              inline={true}
              input={<Tag text="+" />}
              output={
                <CustomReactSelect
                  options={tagify(project.activeProject.tags)}
                  value={tagify(sharedTags)}
                  onChange={(tags) => updateTags(tags)}
                  isClearable
                  isMulti
                  menuIsOpen
                  onCreateOption={(value) => createTag(value)}
                  ref={tagRef}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background: '#fafbfc',
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      outline: state.isFocused
                        ? '#66ccff solid 2px !important'
                        : 'none',
                      '*': {
                        boxShadow: 'none !important',
                      },
                      '&:hover': {
                        borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      },
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected
                        ? '#9a9fa7'
                        : state.isFocused
                        ? '#f1f5f9'
                        : base.backgroundColor,
                      '&:hover': {
                        backgroundColor: state.isSelected
                          ? '#9a9fa7'
                          : '#f1f5f9',
                      },
                    }),
                  }}
                />
              }
            />
          </div>
        ) : (
          <div className="pl-2">
            <Tag text="+" isDisabled={true} />
          </div>
        )}

        {sharedTags &&
          sharedTags.map((tag) => (
            <div className="shrink">
              <Tag
                key={tag}
                text={tag}
                onClick={canUpdate ? () => deleteTag(tag) : false}
                remove={canUpdate}
              />
            </div>
          ))}
      </div>
    </Fragment>
  );
};

TagsShared.propTypes = {
  dashboard: object,
  project: object,
  newTag: func,
  createTag: func,
  deleteTag: func,
  updateTags: func,
  translations: object,
  sharedTags: array,
  bulkEdit: object,
};

const mapStateToProps = ({ dashboard, project, translations, bulkEdit }) => ({
  dashboard,
  project,
  translations,
  bulkEdit,
});

const mapDispatchToProps = (dispatch) => ({
  createTag: (tag) => dispatch(updateBulkTags('create', tag)),
  deleteTag: (tag) => dispatch(updateBulkTags('delete', tag)),
  updateTags: (tags) => dispatch(updateBulkTags('update', tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsShared);
