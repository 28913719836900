import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import Button from '../../components/Button/Button';
import Link from '../../components/Link/Link';
import { addNewTeamMembers } from '../../actions/teamsActions';
import template from 'string-template';
import { useForm } from 'react-hook-form';

import './NewTeamMembersModal.scss';

// the multi add member inside teams members settings
const NewTeamMembersModal = ({ teams, updateTeam, translations }) => {
  const {
    newTeamMember__top_text,
    newTeamMember__helper_text,
    newTeamMember__noSeats_text,
    newTeamMember__addMany_link,
    newTeamMember__addSeperate_link,
    newTeamMember__upgrade_text,
    newTeamMember__addMore_link,
    newTeamMember__submit_button,
  } = translations.byKeyTranslations;

  const {
    register,
    handleSubmit,
    // formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [formData, setFormData] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  //

  const [bulkOpen, setBulkOpen] = useState(false);

  const [bulkEmail, setBulkEmail] = useState('');

  const { teamSize, teamMembers = [] } = teams.activeTeam;

  const existingEmails = teamMembers.map((x) => x.user.email.toLowerCase());


  const seatsLeft = teamSize - teamMembers.length;
  const [seatLoop, setSeatLoop] = useState(5);

  const seatLoopArr = [...Array(seatLoop).keys()];

  // useEffect(() => {
  //   console.log({ existingEmails });
  // }, []); // eslint-disable-line

  useEffect(() => {
    if (bulkOpen && bulkEmail) {
      setFormData({
        email_bulk: bulkEmail,
      });
    }
  }, [bulkOpen, bulkEmail]);

  useEffect(() => {
    setSeatLoop(seatsLeft > 5 ? 5 : seatsLeft > 0 ? seatsLeft : 0);
  }, []); // eslint-disable-line

  /**
   * Fix duplicate email error by filtering first through frontend before
   * Sending it to backend
   */
  const newEmails = (existing, bulkOpen, data) => {
    if (bulkOpen) {
      // BULK EMAIL - {email_bulk: "emily1@hello.com; user@bcapp.com; support@bcapp.com"}
      let uniqueEmails = [];
      const emails = data.email_bulk.split(';');
      for (let e = 0; e < emails.length; e++) {

        const email = emails[e].trim().toLowerCase();

        if (!existing.includes(email) && email !== '') {
          uniqueEmails = [...uniqueEmails, email];
        }
      }
      const output = { email_bulk: uniqueEmails.join(';') };
      console.log({ output });
      return output;
    } else {
      // DATA from multiple inputs
      // {
      //   email_0: "emily1@hello.com",
      //   email_1: "emily2@hello.com",
      //   email_2: "emily3@hello.com",
      //   email_3: "",
      //   email_4: ""
      // }
      let uniqueEmails = {};
      let counter = 1;
      const emails = Object.values(data);
      for (let e = 0; e < emails.length; e++) {

        const email = emails[e].toLowerCase();

        if (!existing.includes(email)) {
          const key = 'email_' + String(counter);
          uniqueEmails[key] = email;
          counter++;
        }
      }
      const output = uniqueEmails;
      console.log({ output });
      return output;
    }
  };

  return (
    <div>
      <p>{newTeamMember__top_text}</p>

      <form
        className="grid gap-2"
        onSubmit={
          // updateTeam
          handleSubmit((data) => {
            bulkOpen
              ? updateTeam(newEmails(existingEmails, bulkOpen, formData))
              : updateTeam(newEmails(existingEmails, bulkOpen, data));

            setIsSubmitting(true);
          })
        }
      >
        {bulkOpen ? (
          <span>
            <textarea
              required
              placeholder="List of email addresses separated by ;"
              onChange={(e) => setBulkEmail(e.target.value)}
              className="block w-full py-[8px] px-[10px] my-[10px] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
            />
            <p>{newTeamMember__helper_text}</p>
          </span>
        ) : (
          seatLoopArr.map((item) => (
            <input
              {...register(`email_${item}`)}
              name={`email_${item}`}
              key={item}
              required={item === 0}
              className="block w-full py-[8px] px-[10px] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
              type="email"
              placeholder="Email Address"
            />
          ))
        )}

        {seatsLeft > 0 && (
          <span>
            <br />

            <p>
              {!bulkOpen && seatsLeft - seatLoop > 0 && (
                <span>
                  <Link bright onClick={() => setSeatLoop(seatLoop + 1)} inline>
                    {newTeamMember__addMore_link}
                  </Link>{' '}
                  or{' '}
                </span>
              )}
              {!bulkOpen && (
                <Link bright onClick={() => setBulkOpen(true)} inline>
                  {newTeamMember__addMany_link}
                </Link>
              )}
              {bulkOpen && (
                <Link bright onClick={() => setBulkOpen(false)} inline>
                  {newTeamMember__addSeperate_link}
                </Link>
              )}
            </p>

            <br />

            <hr />

            <br />
          </span>
        )}
        <h5>
          Team seats: {teamMembers.length} / {teamSize}
        </h5>

        {seatsLeft <= 0 ? (
          <p style={{ color: 'red' }}>{newTeamMember__noSeats_text}</p>
        ) : (
          <p>
            {template(newTeamMember__upgrade_text, { seatsLeft })}{' '}
            <Link inline bright external to="mailTo:support@bcapp.com.au">
              support@bcapp.com.au
            </Link>
          </p>
        )}
        {seatsLeft > 0 && (
          <div className="flex justify-end">
            <Button loading={isSubmitting}>
              {newTeamMember__submit_button}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

NewTeamMembersModal.propTypes = {
  teams: object,
  updateTeam: func,
  translations: object,
};

const mapStateToProps = ({ teams, translations }) => ({
  teams,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateTeam: (data) => dispatch(addNewTeamMembers(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTeamMembersModal);
