import React from 'react';
import Avatar from '../components/UI/Avatar/Avatar';
import imageUrl from '../helpers/imageUrl';

// drop down select menu with no profile picture
export const formatOptionLabelNoPicture = (
  { label, description, emailVerified, value },
  { context },
  idList,
  text
) => {
  if (context === 'menu') {
    return (
      <div>
        <div className="custom-select__not-verified">
          <span className="custom-select__not-verified--top">{label}</span>
          {!emailVerified && (
            <span className="custom-select__not-verified--bottom">
              (Not Verified)
            </span>
          )}
          {idList && idList.includes(value) && (
            <span className="custom-select__not-verified--bottom">
              {text ? text : '(Already a member)'}
            </span>
          )}
        </div>
        {description ? (
          <div className="custom-select__assign-label">{description}</div>
        ) : null}
      </div>
    );
  }
  return label;
};

// drop down select menu with  profile picture
export const formatOptionLabel = (
  { label, value, description, emailVerified, picture = {} },
  { context },
  { active = '', action } = {}
) => {
  if (context === 'menu') {
    return (
      <div className="custom-select__container">
        <div className="custom-select__pic">
          <Avatar
            size="small"
            src={picture ? imageUrl(picture.key, picture.url) : ''}
          />
        </div>

        <div>
          <div className="custom-select__not-verified">
            <span className="custom-select__not-verified--top">{label}</span>
            {!emailVerified && (
              <span className="custom-select__not-verified--bottom">
                (Not Verified)
              </span>
            )}
          </div>
          {description ? (
            <div className="custom-select__assign-label">{description}</div>
          ) : null}
          {active === value && (
            <div className="custom-select__cancel" onClick={action}>
              X
            </div>
          )}
        </div>
      </div>
    );
  }
  return label;
};

export const selectProfile = (obj) => ({
  classNamePrefix: 'custom-select',
  formatOptionLabel: (value, context) => formatOptionLabel(value, context, obj),
  // isOptionDisabled: ({ emailVerified }) => !emailVerified,
});
