// used for constants
const exportCreator = (name) => ({
  [name]: name,
  [name + '_FAILURE']: `${name}_FAILURE`, // Weird bug when string literals are used
  [`${name}_SUCCESS`]: `${name}_SUCCESS`,
});

export default exportCreator;

export const exportFuzzyCreator = (name) => ({
  [`${name}_FUZZY_SEARCH`]: `${name}_SEARCH`,
  [`${name}_FUZZY_TERM`]: `${name}_TERM`,
});
