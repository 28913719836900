import React, { useState, useEffect } from 'react';
import { func, string, bool } from 'prop-types';
import cx from 'classnames';

import './CheckBox.scss';

const CheckBox = ({ name, onChange, isChecked, label, defaultIsChecked }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    setChecked(defaultIsChecked);
  }, [defaultIsChecked]);

  return (
    <div
      className="check-box__wrapper"
      onClick={() => {
        setChecked(!checked);
        onChange && onChange(!checked);
      }}
    >
      <div
        className={cx('check-box__container', {
          'check-box__container--active': checked,
        })}
      >
        <input
          type="checkbox"
          style={{ display: 'none' }}
          checked={checked || ''}
          name={name}
          readOnly
        />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          focusable="false"
          role="presentation"
        >
          <g>
            <rect
              fill="currentColor"
              x="6"
              y="6"
              width="12"
              height="12"
              rx="2"
            />
            <path
              d="M9.707 11.293a1 1 0 1 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L11 12.586l-1.293-1.293z"
              fill="inherit"
            />
          </g>
        </svg>
      </div>
      {label && <p className="m-0 pb-1">{label}</p>}
    </div>
  );
};

CheckBox.propTypes = {
  name: string,
  onChange: func,
  isChecked: bool,
  label: string,
  defaultIsChecked: bool,
};

export default CheckBox;
