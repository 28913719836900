import { api } from '../constants';
import store from '../store.js';
import { logoutAction } from '../actions/loginActions';
import downloadFile from '../helpers/downloadFile';

const { API_BASE_URL } = api;

const defaultHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `${store.getState().login.token}`,
});

// fetch helper method
const fetchBase = async ({
  type = 'GET',
  url = '',
  body = null,
  headers,
  isUpload = false,
  blob = false,
  buffer = false,
  fileName,
}) => {
  try {
    const mergeHeaders = Object.assign({}, defaultHeaders(), headers);

    const fetchHeaders = { method: type, headers: mergeHeaders };

    if (!fetchHeaders.headers.Authorization) {
      delete fetchHeaders.headers.Authorization;
    }

    // stringify data if upload
    if (body && !isUpload) {
      fetchHeaders.body = JSON.stringify(body);
    }

    if (body && isUpload) {
      // if form is upload, remove json header
      // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
      fetchHeaders.body = body;
      delete fetchHeaders.headers['Content-Type'];
    }

    const res = await fetch(`${API_BASE_URL}${url}`, fetchHeaders);

    const status = res.status;

    const data = blob ? await res.blob() : await res.json();

    if (status === 401) {
      const message = data.message;
      store.dispatch(logoutAction());
      if (message === 'Invalid login') {
        throw message;
      } else {
        throw 'Session expired - please login again.'; // eslint-disable-line
      }
    }

    if (![200, 201].includes(status)) {
      throw data.message || 'Server Error - please try again';
    }

    if (blob) {
      // force blob download
      return downloadFile(data, fileName);
    } else if (buffer) {
      // if buffer turn to unit 8 array then to buffer with correct type
      // will need to parse correct type in future
      var uint8Array = new Uint8Array(data.data);
      const newBlob = new Blob([uint8Array.buffer], {
        type: 'application/pdf',
      });
      return downloadFile(newBlob, fileName);
    }

    return data.data || data;
  } catch (e) {
    throw String(e);
  }
};

export default fetchBase;
