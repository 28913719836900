import constants from '../constants';

const { ADMIN_DASHBOARD_SUCCESS, ADMIN_DASHBOARD_FAILURE, LOGOUT } = constants;

const initState = {
  stats: [],
  error: '',
};

export const adminDashboard = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        stats: action.stats,
      };
    case ADMIN_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
