import React, { useEffect } from 'react';
import { func, object } from 'prop-types';
import Button from '../../components/Button/Button';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import { fetchTemplates } from '../../actions/teamsActions';
import { setActiveProject } from '../../actions/projectActions';

import FilterTable from '../../components/UI/Table/FilterTable';

import './TeamTemplates.scss';

const modal = {
  heading: 'Create New Template Project',
  contents: 'NewProjectModal',
  data: {
    template: true,
  },
};

const TeamTemplates = ({ open, fetch, loading, teams, setActive }) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <a
          href={`/project/${props.row.original._id}`}
          onClick={() => setActive(props.row.original)}
        >
          {props.getValue()}
        </a>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'description',
      cell: (props) => props.getValue(),
      header: 'Description',
    },
  ];

  useEffect(() => {
    fetch();
  }, [teams.activeTeam._id]); // eslint-disable-line

  return (
    <div>
      <div className="admin-team__title">
        <h1>Team Templates</h1>
        <Button onClick={() => open()}>Create New Template</Button>
      </div>

      <FilterTable
        defaultData={teams.teamTemplate}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search templates..."
        loading={loading.loading}
      />
    </div>
  );
};

TeamTemplates.propTypes = {
  open: func,
  fetch: func,
  teams: object,
  loading: object,
  setActive: func,
};

const mapStateToProps = ({ teams, loading }) => ({ teams, loading });

const mapDispatchToProps = (dispatch) => ({
  open: () => dispatch(openModal(modal)),
  fetch: () => dispatch(fetchTemplates()),
  setActive: (project) => dispatch(setActiveProject(project)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamTemplates);
