import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { format } from 'date-fns';
import clean from 'sanitize-filename';

const {
  REPORT_SUCCESS,
  REPORT_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  ADMIN_REPORTS_SUCCESS,
  ADMIN_REPORTS_FAILURE,
} = constants;

export const reportFetch = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'reportFetch' });
  const { _id } = getState().project.activeProject;

  const fetchDataInfo = {
    url: `${api.REPORT_API}/${_id}`,
  };

  try {
    const {
      weeklyStatus,
      burnDown,
      cycleTime,
      byStatus,
      byDueDate,
      dueDateAndUnresolved,
      noUsers,
      overdueAssignee,
      overdueNoAssignee,
      openByUser,
      byDomain,
    } = await fetchData(fetchDataInfo);

    dispatch({
      type: REPORT_SUCCESS,
      weeklyStatus,
      burnDown,
      cycleTime,
      byStatus,
      byDueDate,
      dueDateAndUnresolved,
      noUsers,
      overdueAssignee,
      overdueNoAssignee,
      openByUser,
      byDomain,
    });
    dispatch({ type: LOADING_FINISHED, src: 'reportFetch' });
  } catch (e) {
    dispatch({ type: REPORT_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'reportFetch' });
    dispatch(toast('error', e));
  }
};

export const fetchUserReportProjects = () => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'fetchUserReports' });

  const fetchDataInfo = {
    url: api.ADMIN_REPORT_API,
  };

  try {
    const userReports = await fetchData(fetchDataInfo);

    dispatch({
      type: ADMIN_REPORTS_SUCCESS,
      userReports,
    });
    dispatch({ type: LOADING_FINISHED, src: 'fetchUserReports' });
  } catch (e) {
    dispatch({ type: ADMIN_REPORTS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'fetchUserReports' });
    dispatch(toast('error', e));
  }
};

export const fetchPdfReport = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'fetchPdfReport' });
  const { _id, name } = getState().project.activeProject;

  const fetchDataInfo = {
    url: `${api.PDF_REPORT_API}/${_id}`,
    buffer: true,
    fileName: `${clean(name)}-${format(new Date(), 'yyyy.MM.dd-HHmm')}.pdf`,
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch({ type: LOADING_FINISHED, src: 'fetchPdfReport' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'fetchPdfReport' });
    dispatch(toast('error', e));
  }
};
