import constants from '../constants';

const { ADMIN_TOKEN_SUCCESS, ADMIN_TOKEN_FAILURE, LOGOUT } = constants;

const initState = {
  tokens: [],
  error: '',
};

export const adminTokens = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_TOKEN_SUCCESS:
      return {
        ...state,
        tokens: action.tokens,
      };
    case ADMIN_TOKEN_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
