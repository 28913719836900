import React, { Fragment } from 'react';
import { func, object, array, bool } from 'prop-types';
import { status, priority, dates } from '../../constants/labels';
import Tag from '../Tag/Tag';
import { DateRange } from 'react-date-range';
import { genericYearDate } from '../../helpers/formatDates';
import { uniqBy, flatMap } from 'lodash';
import { endOfDay } from 'date-fns';

import './Filters.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const tagifyUsers = ({ firstName, lastName, _id, email }) => ({
  name: firstName && lastName ? `${firstName} ${lastName}` : email || '-',
  id: _id,
});

const sort = (arr) => arr.sort((a, b) => a.name.localeCompare(b.name));

// filers drop down
const Filters = ({
  filters,
  updateFilter,
  updateMultiFilter,
  project,
  issues,
  allData = false,
  translations,
}) => {
  const {
    field__creationDate_tag,
    field__priority_tag,
    field__status_tag,
    field__assignee_tag,
    field__location_tag,
    field__tags_tag,
    field__creator_tag,
    field__subscribers_tag,
    field__dueDate_tag,
  } = translations.byKeyTranslations;

  const { tags, members = [], locations = [] } = project;
  const locationCount = locations.length > 0;

  const memberList = members
    .sort(
      (a, b) =>
        a.user.firstName && a.user.firstName.localeCompare(b.user.firstName)
    )
    .map(({ user }) => tagifyUsers(user));

  const assigneeUsers = uniqBy(issues, 'assignee._id').reduce(
    (acc, { assignee }) => {
      if (assignee) {
        acc.push(tagifyUsers(assignee));
      }
      return acc;
    },
    []
  );

  const subAll = flatMap(issues, (item) => item.subscribers);

  const subUsers = uniqBy(subAll, '_id').map(tagifyUsers);

  const subList = allData ? memberList : subUsers;

  // add option for non assignee
  const assigneeList = allData
    ? [{ name: 'Unassigned', id: null }, ...memberList]
    : [{ name: 'Unassigned', id: null }, ...sort(assigneeUsers)];

  const creatorUsers = allData
    ? memberList
    : sort(
        uniqBy(issues, 'creator._id').map(({ creator }) => tagifyUsers(creator))
      );

  const selectionRange = {
    startDate: filters.createdAtFrom
      ? new Date(filters.createdAtFrom)
      : new Date(),
    endDate: filters.createdAtTo ? new Date(filters.createdAtTo) : new Date(),
    key: 'selection',
  };

  return (
    <div className="filters__container">
      <h4>{field__status_tag}</h4>
      {status.map((item) => (
        <Tag
          text={item}
          key={item}
          active={filters.status.includes(item)}
          onClick={() => updateFilter('status', item)}
        />
      ))}
      <h4>{field__priority_tag}</h4>
      {priority.map((item) => (
        <Tag
          text={item}
          key={item}
          active={filters.priority.includes(item)}
          onClick={() => updateFilter('priority', item)}
        />
      ))}
      <h4>{field__assignee_tag}</h4>
      {assigneeList.map(({ name, id }) => (
        <Tag
          text={name}
          key={id}
          active={filters.assignee.includes(id)}
          onClick={() => updateFilter('assignee', id)}
        />
      ))}
      <h4>{field__creator_tag}</h4>
      {creatorUsers.map(({ name, id }) => (
        <Tag
          text={name}
          key={id}
          active={filters.creator.includes(id)}
          onClick={() => updateFilter('creator', id)}
        />
      ))}
      <h4>{field__subscribers_tag}</h4>
      {subList.map(({ name, id }) => (
        <Tag
          text={name}
          key={id}
          active={filters.subscriber && filters.subscriber.includes(id)}
          onClick={() => updateFilter('subscriber', id)}
        />
      ))}
      <h4>{field__dueDate_tag}</h4>
      {dates.map((date) => (
        <Tag
          text={date}
          key={date}
          active={filters.dueDate === date}
          onClick={() => updateFilter('dueDate', date)}
        />
      ))}
      <h4>{field__tags_tag}</h4>
      {tags &&
        tags.map((item) => (
          <Tag
            text={item}
            key={item}
            active={filters.tags.includes(item)}
            onClick={() => updateFilter('tags', item)}
          />
        ))}
      {locationCount && (
        <Fragment>
          <h4>{field__location_tag}</h4>

          {locations &&
            locations.map((item) => (
              <Tag
                text={item}
                key={item}
                active={filters.location && filters.location.includes(item)}
                onClick={() => updateFilter('location', item)}
              />
            ))}
        </Fragment>
      )}

      <h4>{field__creationDate_tag}</h4>

      <Tag
        text={`From: ${
          filters.createdAtFrom ? genericYearDate(filters.createdAtFrom) : '-'
        }`}
        active={!!filters.createdAtFrom}
      />
      <Tag
        text={`To: ${
          filters.createdAtTo ? genericYearDate(filters.createdAtTo) : '-'
        }`}
        active={!!filters.createdAtTo}
      />

      <Tag
        text="Clear"
        onClick={() =>
          updateMultiFilter({
            createdAtFrom: '',
            createdAtTo: '',
          })
        }
      />

      <div className="filters__creation-date">
        <DateRange
          ranges={[selectionRange]}
          onChange={({ selection }) => {
            updateMultiFilter({
              createdAtFrom: selection.startDate,
              createdAtTo: endOfDay(selection.endDate),
            });
          }}
          showDateDisplay={false}
          moveRangeOnFirstSelection={false}
          minDate={new Date(2016, 0, 0)}
          maxDate={new Date(new Date().getFullYear() + 5, 0, 0)}
          color="#39abff"
          rangeColors={['#39abff']}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  filters: object,
  updateFilter: func,
  tags: array,
  issues: array,
  updateMultiFilter: func,
  project: object,
  allData: bool,
  translations: object,
};

export default Filters;
