const RoundBadge = ({ value, theme = 'default' }) => {
  const themeLookup = {
    default: 'bg-gray-100 text-gray-800',
    danger: 'bg-red-100 text-red-800',
    warning: 'bg-yellow-100 text-yellow-800',
    success: 'bg-green-100 text-green-800',
    info: 'bg-blue-100 text-blue-800',
  };

  const pickTheme = themeLookup[theme];

  return (
    <span
      className={
        'inline-flex items-center px-2 py-0.5 rounded text-xs font-bold ' +
        pickTheme
      }
    >
      {value}
    </span>
  );
};

export default RoundBadge;
