import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
// import Link from '../../components/Link/Link';
import Button from '../../components/Button/Button';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import { openModal } from '../../actions/modalActions';
import { teamLevels } from '../../constants/labels';
import { orderBy } from 'lodash';
import cx from 'classnames';
import MemberEditTable from '../../components/MemberEditTable/MemberEditTable';
import { updateTeamDetails } from '../../actions/teamsActions';
import { resetTeamMemberSearch } from '../../actions/teamsActions';

import './TeamMembers.scss';

const modal = {
  heading: 'Invite Team Members',
  contents: 'NewTeamMembersModal',
};

// team members route /teams
const TeamMembers = ({ open, teams, loading, updateTeam, resetSearch }) => {
  useEffect(() => {
    resetSearch();
  }, []); // eslint-disable-line

  const {
    activeTeam,
    activeTeam: { teamMembers, teamLeader } = {},
    fuzzyMembers,
  } = teams;

  const [people, setPeople] = useState(teamMembers || []);

  const [editUser, setEditUser] = useState({});

  useEffect(() => {
    if (Object.keys(editUser).length > 0) {
      if (editUser.action === 'update') {
        tableAction(editUser.value, editUser.id, people);
      }
      if (editUser.action === 'delete') {
        deleteAction(editUser.id, people);
      }
    }
  }, [editUser]); // eslint-disable-line

  useEffect(() => {
    if (teamMembers && teamMembers.length > 0) {
      setPeople(teamMembers);
    }
  }, [teamMembers]);

  // const people = teamMembers || [];

  const adminList = people.filter(
    (item) => item.teamLevel === teamLevels.teamAdmin
  );

  const collabList = people.filter(
    (item) => item.teamLevel === teamLevels.teamMember
  );

  const tableAction = (value, userId, teamMembers) => {
    const index = teamMembers.findIndex((item) => item.user._id === userId);
    teamMembers[index].teamLevel = value;
    setPeople(teamMembers);
    updateTeam({
      field: 'teamMembers',
      value: orderBy(teamMembers, 'user.firstName'),
    });
  };

  const deleteAction = (id, teamMembers) => {
    const list = teamMembers.filter((item) => item.user._id !== id);
    setPeople(teamMembers);
    updateTeam({
      field: 'teamMembers',
      value: list,
    });
  };

  const members = !fuzzyMembers
    ? []
    : fuzzyMembers.length === 0
    ? collabList
    : fuzzyMembers;

  const allMembersList = [...adminList, ...members];

  return (
    <div>
      <div className="team-project__title">
        <h1>{activeTeam.name} Members</h1>

        {getTeamPermissions('team:edit:members') && (
          <Button onClick={() => open(modal)}>Invite Members</Button>
        )}
      </div>

      <div
        className={cx(
          'project-edit__member-container project-edit__member-container--teams px-0.5'
        )}
      >
        <MemberEditTable
          data={[{ user: teamLeader }]}
          title="Owner"
          loading={loading.loading}
          showPagination={false}
          enableSearch={false}
        />

        <br />

        <MemberEditTable
          title="Team Members"
          data={allMembersList}
          setEditUser={setEditUser}
          action={tableAction}
          deleteAction={
            getTeamPermissions('team:edit:members') ? deleteAction : false
          }
          team={activeTeam}
          loading={loading.loading}
          showPagination={true}
        />

        <br />
      </div>
    </div>
  );
};

TeamMembers.propTypes = {
  teams: object,
  getData: func,
  setActive: func,
  open: func,
  loading: object,
  updateTeam: func,
  resetSearch: func,
};

const mapStateToProps = ({ loading, teams }) => ({ loading, teams });

const mapDispatchToProps = (dispatch) => ({
  open: (data) => dispatch(openModal(data)),
  updateTeam: (data) => dispatch(updateTeamDetails(data)),
  resetSearch: () => dispatch(resetTeamMemberSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
