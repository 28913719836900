import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import { object } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import cx from 'classnames';
import Webcam from 'react-webcam';
import format from 'date-fns/format';
import ModalFormat from '../../components/ModalFormat/ModalFormat';
import HeroIconCloudUpload from '../../components/HeroIcon/HeroIconCloudUpload';
import HeroIconPaperclip from '../../components/HeroIcon/HeroIconPaperclip';
import HeroIconCamera from '../../components/HeroIcon/HeroIconCamera';
import HeroIconExpand from '../../components/HeroIcon/HeroIconExpand';
import { Tooltip } from 'react-tooltip';
import HeroIconInformationCircle from '../../components/HeroIcon/HeroIconInformationCircle';

import './UploadsModal.scss';

const UploadsModal = ({ data, translations }) => {
  const {
    uploadModal__drag__text,
    uploadModal__paste__text,
    uploadModal__selectFile__button,
    uploadModal__screenShot__button,
    uploadModal__takePhoto__button,
    uploadModal__uploadPhoto__button,
    uploadModal__capturePhoto__button,
    uploadModal__cancel__button,
    uploadModal__pastWarning__text,
  } = translations;

  const hasCameraAccess =
    navigator.mediaDevices && navigator.mediaDevices.getUserMedia;

  const { callback, multi = false } = data;
  const [dragging, setDragging] = useState(false);
  const [cam, setCam] = useState(false);
  const [pic, setPic] = useState(false);

  // this counter is used to prevent child events on the dragover, doubled up with css pointer-events: none
  const [counter, setCounter] = useState(0);
  const dropZone = useRef();
  const inputRef = useRef();
  const webcamRef = useRef(null);

  useEffect(() => {
    const target = dropZone.current;
    target.addEventListener('dragenter', handleDragIn);
    target.addEventListener('dragleave', handleDragOut);
    target.addEventListener('dragover', handleDrag);
    target.addEventListener('drop', handleDrop);
    window.addEventListener('paste', onPasteEvent);
    return () => {
      target.removeEventListener('dragenter', handleDragIn);
      target.removeEventListener('dragleave', handleDragOut);
      target.removeEventListener('dragover', handleDrag);
      target.removeEventListener('drop', handleDrop);
      window.removeEventListener('paste', onPasteEvent);
      setCounter(0);
    };
  }, []); // eslint-disable-line

  const handleDrag = (e) => {
    stopDefaults(e);
  };

  const handleDragIn = (e) => {
    stopDefaults(e);
    setCounter(counter + 1);
    const { items } = e.dataTransfer;
    if (items && items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    stopDefaults(e);
    setCounter(counter - 1);
    if (counter === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (e) => {
    stopDefaults(e);
    setDragging(false);
    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      callback && callback(multi ? files : files[0]);
      e.dataTransfer.clearData();
      setCounter(0);
    }
  };

  const stopDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const inputFileSelect = (e) => {
    stopDefaults(e);
    const { files } = e.target;

    if (files && files.length > 0) {
      callback && callback(multi ? files : files[0]);
    }
  };

  const onPasteEvent = async (e) => {
    stopDefaults(e);

    const permissionsCheck = await navigator.permissions.query({
      name: 'clipboard-read',
    });

    if (!permissionsCheck.state) {
      return false;
    }

    const clipboardItems = await navigator.clipboard.read();

    const files = [];

    for (const clipboardItem of clipboardItems) {
      for (const item of clipboardItem.types) {
        if (item.includes('image')) {
          const blob = await clipboardItem.getType(item);

          const file = new File(
            [blob],
            `photo_${format(new Date(), 'dd_MM_yyyy-HHmm')}.png`
          );

          files.push(file);
        }
      }
    }

    if (files.length > 0) {
      callback && callback(multi ? files : files[0]);
      return setCounter(0);
    }
  };

  const videoConstraints = {
    width: 2560,
    height: 1440,
    facingMode: 'environment',
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPic(imageSrc);
  }, [webcamRef]);

  const cancel = () => {
    setPic(false);
    setCam(false);
  };

  const uploadScreenCap = async (item) => {
    const res = await fetch(item);
    const buff = await res.arrayBuffer();
    const file = new File(
      [buff],
      `photo_${format(new Date(), 'dd_MM_yyyy-HHmm')}.jpg`,
      {
        type: 'image/jpeg',
      }
    );

    callback && callback(multi ? [file] : file);
  };

  const getScreenShot = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: 'screen' },
      });

      const track = stream.getVideoTracks()[0];

      const imageCapture = new ImageCapture(track);

      const bitmap = await imageCapture.grabFrame();

      track.stop();

      const canvas = document.createElement('canvas');

      canvas.width = bitmap.width;
      canvas.height = bitmap.height;

      const context = canvas.getContext('2d');

      context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);

      const image = canvas.toDataURL();

      uploadScreenCap(image);
    } catch (e) {
      console.log(e);
    }
  };

  const modalBody = (
    <div
      className={cx('uploadModal__container', 'm-5', {
        'uploadModal__container--active': dragging,
      })}
      ref={dropZone}
    >
      {!cam && (
        <div className="grid justify-items-center">
          <div className="hidden sm:grid justify-items-center">
            <div className="">
              <HeroIconCloudUpload size={40} />
            </div>
            <h4 className="uploadModal__info">
              {uploadModal__drag__text}
              {'  '}
              <span className="uploadModal__info--highlight">
                {uploadModal__paste__text}{' '}
              </span>
              <span
                className="text-red-600"
                data-tooltip-id="screenshot__warning"
                data-tooltip-content={uploadModal__pastWarning__text}
              >
                <HeroIconInformationCircle size={15} marginLeft={3} />
              </span>
            </h4>
            <Tooltip
              id="screenshot__warning"
              place="top"
              className="max-w-sm"
            />
            <p>or</p>
            <input
              accept="image/*"
              id="text-button-file"
              type="file"
              className="imageUploader__hiddenInput"
              onChange={(e) => inputFileSelect(e)}
              ref={inputRef}
              multiple={!!multi}
            />
          </div>
          <ButtonGroup between>
            <div className="visible sm:inline-flex sm:pr-3">
              <Button onClick={() => inputRef.current.click()}>
                <div className="grid-cols-[auto_1fr] place-items-center hidden sm:grid">
                  <HeroIconPaperclip
                    size={15}
                    marginRight={5}
                    marginBottom={-3}
                  />{' '}
                  {uploadModal__selectFile__button}
                </div>
                <div className="grid grid-cols-[auto_1fr] place-items-center sm:hidden p-2">
                  <HeroIconCamera size={15} marginRight={5} marginBottom={-3} />{' '}
                  Take or upload photo
                </div>
              </Button>
            </div>
            <div className="hidden sm:inline-flex sm:gap-3">
              <Button onClick={getScreenShot} hideOnMobile>
                <div className="grid grid-cols-[auto_1fr] place-items-center">
                  <HeroIconExpand size={15} marginRight={5} marginBottom={-3} />{' '}
                  {uploadModal__screenShot__button}
                </div>
              </Button>
              {hasCameraAccess && (
                <Button onClick={() => setCam(true)}>
                  <div className="grid grid-cols-[auto_1fr] place-items-center">
                    <HeroIconCamera
                      size={15}
                      marginRight={5}
                      marginBottom={-3}
                    />{' '}
                    {uploadModal__takePhoto__button}
                  </div>
                </Button>
              )}
            </div>
          </ButtonGroup>
        </div>
      )}

      <Fragment>
        {cam && (
          <div className="uploadModal__cam">
            <div className="uploadModal__camVideo">
              {pic ? (
                <img src={pic} alt="" />
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  minScreenshotHeight={2000}
                  minScreenshotWidth={2000}
                />
              )}
            </div>
          </div>
        )}
      </Fragment>

      <Fragment>
        {cam && (
          <Fragment>
            {pic ? (
              <ButtonGroup>
                <Button onClick={cancel} danger center>
                  {uploadModal__cancel__button}
                </Button>

                <Button onClick={() => uploadScreenCap(pic)} center>
                  {uploadModal__uploadPhoto__button}
                </Button>
              </ButtonGroup>
            ) : (
              <Button onClick={capture}>
                {uploadModal__capturePhoto__button}
              </Button>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );

  return <ModalFormat body={modalBody} />;
};

UploadsModal.propTypes = {
  data: object,
  translations: object,
};

export default UploadsModal;
