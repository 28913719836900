import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { fetchFeedback } from '../../actions/feedbackActions';
import { genericTimeYearDate } from '../../helpers/formatDates';
import imageUrl from '../../helpers/imageUrl';
import AdminContainer from '../../components/AdminContainer/AdminContainer';

import FilterTable from '../../components/UI/Table/FilterTable';

import './Feedback.scss';

// admin route /admin/feedback
const Feedback = ({ feedback, fetch, loading }) => {
  const tableHead2 = [
    {
      accessorKey: 'createdAt',
      cell: (props) => genericTimeYearDate(props.getValue()),
      header: 'Date',
    },
    {
      accessorKey: 'type',
      cell: (props) => props.getValue() || '-',
      header: 'Type',
    },
    {
      accessorKey: 'comment',
      cell: (props) => <div className="feedback__cell">{props.getValue()}</div>,
      header: 'Comment',
      enableSorting: false,
    },
    {
      id: 'creator',
      accessorFn: (row) =>
        `${row.creator.firstName} ${row.creator.lastName}` || '-',
      cell: (props) => props.getValue() || '-',
      header: 'Creator',
    },
    {
      accessorKey: 'location',
      cell: (props) => props.getValue(),
      header: 'Location',
    },
    {
      id: 'asset',
      accessorFn: (row) => (
        <a
          className="feedback__image"
          href={row.asset && imageUrl(row.asset.key, row.asset.url)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={row.asset && imageUrl(row.asset.key, row.asset.url)}
            alt={row.asset && row.asset.url}
          />
        </a>
      ),
      cell: (props) => props.getValue() || '-',
      enableSorting: false,
    },
  ];

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <h1>Admin Feedback</h1>

      <FilterTable
        defaultData={feedback.feedback}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search feedback..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

Feedback.propTypes = {
  feedback: object,
  fetch: func,
};

const mapStateToProps = ({ feedback, loading }) => ({
  feedback,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(fetchFeedback()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
