import React from 'react';
import { oneOfType, arrayOf, node, string, func, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import './Link.scss';

// link wrapper
const LinkWrapper = ({
  children,
  to,
  onClick,
  dark,
  bright,
  newWindow,
  inline,
  external,
  title,
}) => (
  <span
    onClick={onClick}
    className={cx('link__wrapper', {
      'link__wrapper--dark': dark,
      'link__wrapper--line': inline,
      'link__wrapper--bright': bright,
    })}
  >
    {!external && to ? (
      <Link to={to} target={newWindow ? '_blank' : ''} title={title}>
        {children}
      </Link>
    ) : (
      <a href={to} title={title}>
        {children}
      </a>
    )}
  </span>
);

LinkWrapper.defaultValues = {
  dark: false,
  newWindow: false,
  inline: false,
  external: false,
};

LinkWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  to: string,
  onClick: func,
  dark: bool,
  newWindow: bool,
  inline: bool,
  external: bool,
  bright: bool,
  title: string,
};

export default LinkWrapper;
