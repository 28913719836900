import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import urlProps from '../helpers/getUrlProps';
import {
  fetchIssuesAction,
  filterDashboardMany,
  setCompactList,
  setComfortableList,
} from '../actions/dashboardActions';
import filters from '../constants/filters';
import { format } from 'date-fns';
import { push } from 'connected-react-router';
import { setActiveWorkspaceOnly } from '../actions/workspaceActions';
import clean from 'sanitize-filename';
import { projectMenuSearch } from '../actions/menuActions';
import { setActiveTeam } from '../actions/teamsActions';
import FileSaver from 'file-saver';

const {
  PROJECT_NAV_SUCCESS,
  PROJECT_NAV_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  PROJECT_NAV_SET_ACTIVE,
  MODAL_CLOSE,
  MENU_CLOSE,
  ALL_ISSUES,
  ISSUES_FUZZY_TERM,
  RESET_ALL_ISSUES,
  PROJECT_NAV_RESET,
} = constants;

const { USER_PROJECTS, PROJECTS_API_URL, GENERATE_PDF, GENERATE_CSV } = api;

export const projectFetch =
  (pId, dontLoadNew, noLoadScreen) => async (dispatch) => {
    if (!noLoadScreen) {
      dispatch({ type: LOADING_REQUEST, src: 'projectFetch' });
    }

    const urlId = pId ? pId : urlProps('project');

    const fetchDataInfo = {
      url: USER_PROJECTS,
    };

    const urlProject = {
      url: `${PROJECTS_API_URL}/${urlId}`,
    };

    try {
      const projects = await fetchData(fetchDataInfo);

      dispatch({ type: PROJECT_NAV_SUCCESS, projects });
      const urlProjectRes = urlId ? await fetchData(urlProject) : [];
      const project = urlId ? urlProjectRes : projects[0];
      if (project && !dontLoadNew) {
        dispatch(setActiveProject(project));
      } else {
        dispatch({ type: LOADING_FINISHED, src: 'projectFetch' });
      }
      dispatch(projectMenuSearch(''));
    } catch (e) {
      console.error(e);
      dispatch({ type: PROJECT_NAV_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'projectFetch' });
      dispatch(toast('error', e));
    }
  };

export const setActiveProject =
  (activeProject, loading) => async (dispatch, getState) => {
    const { login } = getState();

    if (loading) {
      dispatch({ type: LOADING_REQUEST, src: 'setActiveProject' });
    }

    dispatch({ type: RESET_ALL_ISSUES });
    dispatch({
      type: PROJECT_NAV_SET_ACTIVE,
      activeProject: activeProject || {},
    });
    dispatch(setActiveTeam(activeProject.team));
    dispatch(setActiveWorkspaceOnly(ALL_ISSUES, filters()));

    //Set list density
    const members = activeProject.members;
    const objIndex = members.findIndex((obj) => obj.user._id === login.user.id);

    if (objIndex === -1) {
      dispatch(setComfortableList());
    } else {
      if (members[objIndex].listDensity) {
        if (members[objIndex].listDensity === 'compact') {
          dispatch(setCompactList());
        }
        if (members[objIndex].listDensity === 'comfortable') {
          dispatch(setComfortableList());
        }
      } else {
        dispatch(setComfortableList());
      }
    }

    dispatch({ type: ISSUES_FUZZY_TERM, projectFuzzyTerm: '' });

    await dispatch(fetchIssuesAction(activeProject._id, true));
    dispatch({ type: MENU_CLOSE, src: 'setActiveProject' });

    setTimeout(() => {
      // do this after the animation is finished to stop jumping
      dispatch(projectMenuSearch(''));
    }, 200);
  };

export const projectSearch = (projectFuzzyTerm) => async (dispatch) => {
  dispatch({ type: ISSUES_FUZZY_TERM, projectFuzzyTerm });
  dispatch(filterDashboardMany());
};

export const deleteProjectById = (id) => async (dispatch, getState) => {
  const { _id } = getState().project.activeProject;

  try {
    dispatch({ type: LOADING_REQUEST, src: 'setActiveProject' });

    const deleteProject = {
      url: `${PROJECTS_API_URL}/${id}`,
      type: 'DELETE',
    };
    await fetchData(deleteProject);
    dispatch({ type: MODAL_CLOSE });
    dispatch({ type: LOADING_FINISHED, src: 'setActiveProject' });
    if (_id === id) {
      dispatch(push('/'));
    } else {
      await dispatch(projectFetch(null, true));
    }
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'setActiveProject' });
    dispatch(toast('error', e));
  }
};

export const archiveProject = (id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'archiveProject' });

  const archiveParams = {
    url: `${PROJECTS_API_URL}/${id}`,
    type: 'PATCH',
    body: { archived: true },
  };

  try {
    await fetchData(archiveParams);
    const nextProject = getState().project.projects[0];
    await dispatch(setActiveProject(nextProject));
    await dispatch(projectFetch());
    dispatch({ type: LOADING_FINISHED, src: 'archiveProject' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'archiveProject' });
    dispatch(toast('error', e));
  }
};

export const generatePdf = (body) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'generatePdf' });

  const downloadParams = {
    url: GENERATE_PDF,
    type: 'POST',
    buffer: false,

    body,
  };

  const archiveParams = {
    url: GENERATE_PDF,
    type: 'POST',
    buffer: body.experimental ? false : true,
    body,
    fileName: `${clean(body.name)}-${format(
      new Date(),
      'yyyy.MM.dd-HHmm'
    )}.pdf`,
  };

  try {
    dispatch({ type: MODAL_CLOSE });
    const response = await fetchData(body.key ? downloadParams : archiveParams);
    if (body.key) FileSaver.saveAs(response, `${body.projectName}.pdf`);
    dispatch({ type: LOADING_FINISHED, src: 'generatePdf' });
    if (body.experimental)
      dispatch(
        toast(
          'info',
          'Your report is currently being processed. You will be notified when the PDF is ready for download.'
        )
      );
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'generatePdf' });
    dispatch(toast('error', e));
  }
};

export const generateCsv = (id, projectName) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'generateCsv' });

  const archiveParams = {
    url: `${GENERATE_CSV}/${id}`,
    type: 'GET',
    blob: true,
    fileName: `${clean(projectName)}-${format(
      new Date(),
      'yyyy.MM.dd-HHmm'
    )}.csv`,
  };

  try {
    dispatch({ type: MODAL_CLOSE });
    await fetchData(archiveParams);
    dispatch({ type: LOADING_FINISHED, src: 'generateCsv' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'generateCsv' });
    dispatch(toast('error', e));
  }
};

export const resetProject = () => async (dispatch) => {
  dispatch({ type: PROJECT_NAV_RESET });
};

/**
 * Return list of projects from a specifically selected user (Team Settings > Members Tab)
 */
export const projectsByUserFetch = (uid) => async (dispatch) => {
  const fetchDataInfo = {
    url: `${USER_PROJECTS}/${uid}`,
  };

  try {
    dispatch({ type: LOADING_REQUEST, src: 'projectByUserFetch' });
    dispatch({ type: PROJECT_NAV_SUCCESS, projects: [] });
    const projects = await fetchData(fetchDataInfo);

    dispatch({ type: PROJECT_NAV_SUCCESS, projects });
    dispatch({ type: LOADING_FINISHED, src: 'projectByUserFetch' });
  } catch (e) {
    console.error(e);
    dispatch({ type: PROJECT_NAV_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'projectByUserFetch' });
    dispatch(toast('error', e));
  }
};
