import constants from '../constants';

const { FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, LOGOUT } = constants;

const initState = {
  message: '',
  error: '',
};

export const forgotPassword = (state = initState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        message: action.message,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        error: action.error,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
