import React from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import IssueDocumentInfo from '../IssueDocumentInfo/IssueDocumentInfo';
import Button from '../Button/Button';
import Checkbox from '../CheckBox/CheckBox';
import { toggleMenu } from '../../actions/bulkActions';
import cx from 'classnames';
import { addAllItemsToBulk, bulkEditAction } from '../../actions/bulkActions';
import { get } from 'lodash';
import { openModal } from '../../actions/modalActions';
import HeroIconTrash from '../HeroIcon/HeroIconTrash';
import TagsShared from '../TagsShared/TagsShared';

import './BulkEdit.scss';

const modal = {
  contents: 'DeleteItemsBulkModal',
  heading: 'Delete Items?',
};

// bulk edit bar
const BulkEdit = ({
  bulkEdit,
  closeMenu,
  addAll,
  dashboard,
  sortTable,
  save,
  open,
  translations,
}) => {
  const {
    mainSection__bulkEditApply_button,
    mainSection__bulkEditCancel_button,
  } = translations.byKeyTranslations;
  const { sortedIssuesChunked } = dashboard;
  const { paginationActive } = sortTable;
  const { items, sharedTags } = bulkEdit;
  const noItemsSelected = items.length === 0;

  const allIdsOnPage = get(sortedIssuesChunked, [paginationActive], []).map(
    (item) => item._id
  );
  const allAreIncluded =
    allIdsOnPage.every((id) => items.includes(id)) || false;

  return (
    <div>
      <div
        className={cx('bulk-edit__container', {
          'bulk-edit__container--open': bulkEdit.open,
        })}
      >
        <div className="bulk-edit__select-all">
          <Checkbox isFullWidth onChange={addAll} isChecked={allAreIncluded} />
        </div>
        <div className="bulk-edit__sub-container">
          <div className="bulk-edit__tags grid gap-3 py-3 auto-rows-min">
            <IssueDocumentInfo bulkEditMode={true} />
            <TagsShared sharedTags={sharedTags} />
          </div>
          <div className="max-h-[100px] grid place-content-center px-2">
            <div className="grid gap-2 grid-flow-col">
              <Button onClick={save} disabled={noItemsSelected}>
                {mainSection__bulkEditApply_button || ''}{' '}
                {`(${bulkEdit.items.length})`}
              </Button>
              <Button danger onClick={open} disabled={noItemsSelected}>
                <HeroIconTrash size={16} />
              </Button>
              <Button subtle onClick={closeMenu}>
                {mainSection__bulkEditCancel_button || ''}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BulkEdit.propTypes = {
  bulkEdit: object,
  closeMenu: func,
  addAll: func,
  dashboard: object,
  sortTable: object,
  save: func,
  open: func,
  translations: object,
};

const mapStateToProps = ({ bulkEdit, dashboard, sortTable, translations }) => ({
  bulkEdit,
  dashboard,
  sortTable,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  closeMenu: () => dispatch(toggleMenu()),
  addAll: () => dispatch(addAllItemsToBulk()),
  save: () => dispatch(bulkEditAction()),
  open: () => dispatch(openModal(modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEdit);
