import constants from '../constants';

const { NOTIFICATIONS_SUCCESS, LOGOUT } = constants;

const initState = {
  notifications: [],
};

export const notifications = (state = initState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
