import React, { Fragment, useEffect } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { openModal } from '../../actions/modalActions';
import { projectSearch } from '../../actions/projectActions';
import cx from 'classnames';
import { openMenu, closeMenu } from '../../actions/menuActions';
import SearchBox from '../SearchBox/SearchBox';
import Link from '../Link/Link';
import { projectMenuSearch } from '../../actions/menuActions';
import ProjectNavItem from '../ProjectNavItem/ProjectNavItem';
import imageUrl from '../../helpers/imageUrl';
import ThumbNail from '../ThumbNail/ThumbNail';
import getPermissions from '../../helpers/getPermissions';
import Avatar from '../UI/Avatar/Avatar';
import { projectFetch } from '../../actions/projectActions';
import { membersLevels } from '../../constants/labels';
import { userLabelText } from '../../helpers/userLabel';
import { groupBy, get } from 'lodash';
import { setActiveTeam } from '../../actions/teamsActions';
import getTeamPermissions from '../../helpers/getTeamPermissions';
import HeroIconBurger from '../HeroIcon/HeroIconBurger';
import HeroIconPencil from '../HeroIcon/HeroIconPencil';
import { Menu, Transition } from '@headlessui/react';

import './ProjectNav.scss';

const modal = {
  heading: 'Create New Project',
  contents: 'NewProjectModal',
};

const modalBi = {
  contents: 'PowerBIModal',
};

const pdfModal = {
  contents: 'PdfModal',
  maxWidth: '480px',
};

const reportModal = (title) => ({
  contents: 'ReportModal',
  heading: title,
});

const historyModal = (title) => ({
  heading: title,
  contents: 'ProjectHistoryModal',
});

const memberDropDown = ({
  user: { _id, discipline, company, picture = {} },
  user,
}) => (
  <div key={_id} className="flex">
    <div className="pr-2 grid content-center">
      <Avatar
        size="medium"
        src={picture ? imageUrl(picture.key, picture.url) : ''}
        alt={user.firstName + ' ' + user.lastName}
      />
    </div>
    <div className="pb-2">
      <b>{userLabelText(user)}</b>
      <div className="project-nav__drop-down--text">
        <span className="to-upper">{discipline}</span> - {company}
      </div>
    </div>
  </div>
);

memberDropDown.propTypes = {
  user: object,
};

// this project nav bar on top under the TOP NAV
const ProjectNav = ({
  open,
  project,
  openM,
  closeM,
  menu,
  dashboard,
  router,
  fetchProjects,
  login,
  setTeam,
  translations,
}) => {
  const {
    leftNavOpen__createNew_link,
    leftNavOpen__yourProjects_header,
    leftNavOpen__search_input,
    projectNav__export_link,
    projectNav__history_link,
    projectNav__members_link,
    projectNav__report_link,
    projectNavHistory__projectHistory_header,
    projectNavMembers__admins_header,
    projectNavMembers__collaborators_header,
    projectNavMembers__guests_header,
    projectNavReport__projectReport_header,
    projectNav__searchBox_input,
    projectNav__metrics_link,
  } = translations.byKeyTranslations;
  const { projects, activeProject } = project;
  const { _id, members = [] } = activeProject;

  const projectsList = project.fuzzyProjects || project.projects;
  const groupedProjects = groupBy(projectsList, 'team.name');

  const coverImageUrl = get(project, 'activeProject.coverImage.url', '');
  const coverImageKey = get(project, 'activeProject.coverImage.key', '');

  const adminsOnly = members.filter(
    (item) => item.memberLevel === membersLevels.admin
  );
  const collabsOnly = members.filter(
    (item) => item.memberLevel === membersLevels.collaborator
  );
  const guestsOnly = members.filter(
    (item) => item.memberLevel === membersLevels.guest
  );

  const adminSection = router.location.pathname.indexOf('project') > -1;

  useEffect(() => {
    if (login.user.id && projects.length === 0) {
      fetchProjects();
    }
  }, []); // eslint-disable-line

  return (
    <div className="project-nav__container" id="project__nav__target">
      <div className="project-nav">
        <div className="project-nav__top-click">
          <span onClick={() => (menu.projectMenu ? closeM() : openM())}>
            <HeroIconBurger size={24} />
          </span>

          {project.activeProject._id && (
            <div
              className="project-nav__top-container"
              onClick={() =>
                getPermissions('project:edit')
                  ? open({ ...modal, editMode: true, heading: 'Edit Project' })
                  : {}
              }
            >
              <div className="project-nav__image">
                <ThumbNail
                  url={imageUrl(coverImageKey, coverImageUrl)}
                  name={coverImageUrl}
                  noLink
                  flex
                  noPadding
                />
              </div>

              <div className="project-nav__title-container">
                <h5 className="project-nav__title">
                  <span className="project-nav__title--overflow">
                    {project.activeProject.template && `TEMPLATE: `}
                    {project.activeProject.name}{' '}
                    {getPermissions('project:edit') && (
                      <div className="project-nav__edit-icon">
                        <HeroIconPencil />
                      </div>
                    )}
                  </span>
                </h5>
                <p className="project-nav__blurb">
                  {project.activeProject.description}
                </p>
              </div>
            </div>
          )}
        </div>

        <div
          className={cx('project-nav__drop-down', {
            'project-nav__drop-down--open': menu.projectMenu,
          })}
        >
          <div className="project-nav__drop-down--left">
            <div className="project-nav__header">
              <h5>{leftNavOpen__yourProjects_header}</h5>
            </div>

            <div className="project-nav__search">
              <SearchBox
                action={projectMenuSearch}
                placeholder={leftNavOpen__search_input}
              />
            </div>

            <div className="project-nav__item-container--scroll">
              {groupedProjects &&
                Object.entries(groupedProjects).map(
                  ([projectTitle, projectsList]) => (
                    <Fragment key={projectTitle}>
                      <div className="project-nav__item--title-container">
                        <div className="project-nav__item--title">
                          {projectTitle}
                        </div>
                        {getTeamPermissions(
                          'team:project:new',
                          projectsList[0].team
                        ) && (
                          <Link
                            onClick={() => {
                              setTeam(projectsList[0].team);
                              open(modal);
                            }}
                            bright
                          >
                            {leftNavOpen__createNew_link}
                          </Link>
                        )}
                      </div>

                      {projectsList.map((item) => (
                        <ProjectNavItem
                          key={item._id}
                          project={item}
                          activeId={project.activeProject._id}
                        />
                      ))}
                    </Fragment>
                  )
                )}
            </div>
          </div>
          <div
            className={cx('project-nav__drop-down--right', {
              'project-nav__drop-down--right--open': menu.projectMenu,
            })}
            onClick={() => (menu.projectMenu ? closeM() : openM())}
          />
        </div>
      </div>

      {adminSection && _id && (
        <div className="project-nav__top-search">
          <SearchBox
            action={projectSearch}
            placeholder={projectNav__searchBox_input}
            startBlock={true}
          />
        </div>
      )}

      <div className="project-nav__links">
        {getPermissions('project:history:view') && (
          <Link
            inline
            onClick={() =>
              open(historyModal(projectNavHistory__projectHistory_header))
            }
          >
            {projectNav__history_link}
          </Link>
        )}

        <Link
          inline
          onClick={() =>
            open(reportModal(projectNavReport__projectReport_header))
          }
        >
          {projectNav__report_link}
        </Link>

        {project.activeProject.powerBI && (
          <Link
            inline
            onClick={() =>
              open({ ...modalBi, data: project.activeProject.powerBI })
            }
          >
            {projectNav__metrics_link}
          </Link>
        )}

        {project.activeProject._id && (
          <Fragment>
            {dashboard.issues.length > 0 && (
              <Link inline onClick={() => open(pdfModal)}>
                {projectNav__export_link}
              </Link>
            )}

            <Menu as="div" className="text-white menu">
              <Menu.Button>
                {members.length} {projectNav__members_link}
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-out duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="menu__items px-4 py-2 max-h-80 overflow-auto w-max text-black custom-scroll">
                  <p className="font-semibold text-sm text-gray-600 text-left uppercase pb-2">
                    {projectNavMembers__admins_header}
                  </p>
                  {adminsOnly.map(memberDropDown)}
                  {collabsOnly.length > 0 && (
                    <Fragment>
                      <p className="font-semibold text-sm text-gray-600 text-left uppercase pt-2 pb-2">
                        {projectNavMembers__collaborators_header}
                      </p>

                      {collabsOnly.map(memberDropDown)}
                    </Fragment>
                  )}
                  {guestsOnly.length > 0 && (
                    <Fragment>
                      <p className="font-semibold text-sm text-gray-600 text-left uppercase pt-2 pb-2">
                        {projectNavMembers__guests_header}
                      </p>
                      {guestsOnly.map(memberDropDown)}
                    </Fragment>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </Fragment>
        )}
      </div>
    </div>
  );
};

ProjectNav.propTypes = {
  open: func,
  project: object,
  setActive: func,
  openEdit: func,
  menu: object,
  openM: func,
  closeM: func,
  dashboard: object,
  router: object,
  login: object,
  fetchProjects: func,
  setTeam: func,
  translations: object,
};

const mapStateToProps = ({
  project,
  menu,
  dashboard,
  router,
  login,
  translations,
}) => ({
  project,
  menu,
  dashboard,
  router,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  open: (data) => dispatch(openModal(data)),
  openM: () => dispatch(openMenu('projectMenu')),
  closeM: () => dispatch(closeMenu()),
  fetchProjects: () => dispatch(projectFetch()),
  setTeam: (data) => dispatch(setActiveTeam(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNav);
