import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';

const loggerMiddleware = createLogger({
  predicate: () => false, //|| process.env.NODE_ENV !== 'production',
});

const makeHistory = createBrowserHistory();

export const history = makeHistory;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(routerMiddleware(history), thunk, loggerMiddleware)
  )
);

export default configureStore;
