import React, { Fragment, useRef } from 'react';
import { func, object } from 'prop-types';
import {
  updateActiveIssue,
  deleteActiveSubscriber,
} from '../../actions/itemActions';
import { connect } from 'react-redux';
import Tag from '../Tag/Tag';
import DropDown from '../DropDown/DropDown';
import { deTagify } from '../../helpers/tagify';
import { orderBy } from 'lodash';
import getPermissions from '../../helpers/getPermissions';
import { selectProfile } from '../../helpers/selectProfile';
import userLabel, { userLabelText } from '../../helpers/userLabel';

import ReactSelect from 'react-select';

import './Subscribers.scss';

// subscribers section on main item page
const Subscribers = ({
  project,
  updateField,
  dashboard,
  removeSubscriber,
  login,
  translations,
}) => {
  const { field__subscribers_tag } = translations.byKeyTranslations;
  const subRef = useRef();
  const { members = [] } = project.activeProject;
  const { _id, subscribers = [] } = dashboard.activeIssue;
  const { id } = login.user;
  const projectUsers = members.map(
    ({ user: { discipline, company, emailVerified, picture }, user }) => ({
      ...userLabel(user),
      description: `${(discipline && discipline.toUpperCase()) || ' '} - ${
        company || ' '
      }`,
      emailVerified,
      picture,
    })
  );
  const sortSubscrbers = orderBy(subscribers, 'firstName');

  return (
    <Fragment>
      <h5>{field__subscribers_tag}</h5>

      {getPermissions('item:edit:subscribers') ? (
        <DropDown
          action={() => subRef.current.focus()}
          inline={true}
          input={<Tag text="+" />}
          output={
            <ReactSelect
              options={projectUsers}
              value={subscribers.map(userLabel)}
              shouldFitContainer
              closeMenuOnSelect={true}
              menuIsOpen
              isMulti
              onChange={(value) =>
                updateField(_id, 'subscribers', deTagify(value))
              }
              ref={subRef}
              {...selectProfile()}
              styles={{
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          }
        />
      ) : (
        !subscribers.find((item) => item._id === id) && (
          <Tag
            text="+"
            onClick={() =>
              updateField(_id, 'subscribers', [
                ...subscribers.map((item) => item._id),
                id,
              ])
            }
          />
        )
      )}

      {subscribers &&
        sortSubscrbers.map((item) => (
          <Tag
            key={item._id}
            text={userLabelText(item)}
            onClick={() =>
              getPermissions('item:edit:subscribers')
                ? removeSubscriber(item._id, _id)
                : item._id === id && removeSubscriber(id, _id)
            }
            remove={
              getPermissions('item:edit:subscribers') ? true : item._id === id
            }
          />
        ))}
    </Fragment>
  );
};

Subscribers.propTypes = {
  dashboard: object,
  project: object,
  updateField: func,
  removeSubscriber: func,
  login: object,
  translations: object,
};

const mapStateToProps = ({ dashboard, project, login, translations }) => ({
  dashboard,
  project,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  removeSubscriber: (id, itemId) =>
    dispatch(deleteActiveSubscriber(id, itemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscribers);
