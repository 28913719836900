import React, { Fragment } from 'react';
import { object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Label from '../Label/Label';
import { labelise } from '../../constants/labels';
import DropDown from '../DropDown/DropDown';
import getPermissions from '../../helpers/getPermissions';
import { updateActiveIssue } from '../../actions/itemActions';
import { changeBulkEdit } from '../../actions/bulkActions';

import ReactSelect from 'react-select';

const IssueDocumentInfo = ({
  dashboard,
  updateField,
  project,
  bulkEditMode,
  updateBulkEdit,
  bulkEdit,
  translations,
}) => {
  const { field__location_tag } = translations.byKeyTranslations;
  const { _id, location } = bulkEditMode ? bulkEdit : dashboard.activeIssue;
  const { locations = [] } = project.activeProject;
  const locationCount = locations.length;
  const canEditLocations = getPermissions('item:edit:location');

  const LocationLabel = () => (
    <Label hover={canEditLocations}>{location || '-'}</Label>
  );

  return (
    <Fragment>
      {locationCount > 0 && (
        <div>
          <h5>{field__location_tag}</h5>

          {canEditLocations ? (
            <DropDown
              input={<LocationLabel />}
              output={
                <ReactSelect
                  options={[
                    { label: 'None', value: '' },
                    ...labelise(project.activeProject.locations),
                  ]}
                  value={{
                    label: location,
                    value: location,
                  }}
                  onChange={(data) =>
                    bulkEditMode
                      ? updateBulkEdit('location', data.value)
                      : data.value !== location
                      ? updateField(_id, 'location', data.value)
                      : {}
                  }
                  menuIsOpen
                  isClearable={false}
                  components={{
                    Control: () => <div style={{ display: 'none' }} />,
                  }}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      background: '#fafbfc',
                      borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      outline: state.isFocused
                        ? '#66ccff solid 2px !important'
                        : 'none',
                      '*': {
                        boxShadow: 'none !important',
                      },
                      '&:hover': {
                        borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                      },
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isSelected
                        ? '#9a9fa7'
                        : state.isFocused
                        ? '#f1f5f9'
                        : base.backgroundColor,
                      '&:hover': {
                        backgroundColor: state.isSelected
                          ? '#9a9fa7'
                          : '#f1f5f9',
                      },
                    }),
                  }}
                />
              }
              right
            />
          ) : (
            <LocationLabel />
          )}
        </div>
      )}
    </Fragment>
  );
};

IssueDocumentInfo.propTypes = {
  dashboard: object,
  project: object,
  updateField: func,
  bulkEditMode: bool,
  updateBulkEdit: func,
  bulkEdit: object,
  translations: object,
};

const mapStateToProps = ({
  dashboard,
  project,
  login,
  bulkEdit,
  translations,
}) => ({
  dashboard,
  project,
  login,
  bulkEdit,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateField: (id, field, data) =>
    dispatch(updateActiveIssue(id, field, data)),
  updateBulkEdit: (target, value) => dispatch(changeBulkEdit(target, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocumentInfo);
