import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import cx from 'classnames';
import icon from '../../assets/logo-icon.png';
import { connect } from 'react-redux';

import './Loading2.scss';

// full page loading wrapper
const Loading = ({ loading }) => {
  const [exitAnimation, setExitAnimation] = useState(false);
  const { loading: loadingState } = loading;

  useEffect(() => {
    if (loadingState) {
      setExitAnimation(false);
    } else {
      setExitAnimation(true);
    }
  }, [loadingState]);

  return (
    <div
      id="loader"
      className={cx({
        'loading__container--animation': exitAnimation,
      })}
    >
      <div id="loader_spin_div" className="d-flex justify-content-center">
        <div className="img-fluid mx-auto" id="loader_spin">
          <img src={icon} alt="logo icon" />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  loading: object,
};

const mapStateToProps = ({ loading }) => ({
  loading,
});

export default connect(mapStateToProps, null)(Loading);
