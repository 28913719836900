import { format, parseISO } from 'date-fns';

export default function DatePicker({ value, setValue, formatString }) {
  const pickValue = value ? format(parseISO(value), formatString) : '';
  const handleChange = (e) => {
    console.log(e.target.value);
    setValue(e.target.value);
  };
  return (
    <input
      type="date"
      onChange={handleChange}
      value={pickValue}
      className="block w-60 py-[8px] px-[10px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm"
      placeholder={formatString}
    />
  );
}
