import './HeroIcon.scss';

const HeroIconArrowUp = ({ size = 18, marginRight = 0 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="heroicon"
      style={{ width: size, marginRight }}
    >
      <path
        fillRule="evenodd"
        d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default HeroIconArrowUp;
