import fileParser from '../helpers/fileParser';
import fetchData from '../helpers/fetch';

// uploads a image to backend server and returns the asset ID from db
const postImage = async (dispatch, image, fromComments) => {
  try {
    const { file } = fileParser(image, fromComments);

    if (!file) {
      return undefined;
    }

    const { _id } = await fetchData({
      url: '/upload',
      type: 'POST',
      body: file,
      isUpload: true,
    });

    return _id;
  } catch (e) {
    throw e;
  }
};

export default postImage;
