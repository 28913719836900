import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';

const {
  ADMIN_USER_VIEW_SUCCESS,
  ADMIN_USER_VIEW_FAILURE,
  ADMIN_USER_PROJECTS_SUCCESS,
  ADMIN_USER_PROJECTS_FAILURE,
  ADMIN_USER_EVENTS_SUCCESS,
  ADMIN_USER_EVENTS_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

const { USERS_API_URL, PROJECTS_API_URL, EVENTS_API_URL } = api;

export const adminUserFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminUserFetch' });

  const fetchDataInfo = {
    url: `${USERS_API_URL}/${id}`,
  };

  try {
    const user = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_USER_VIEW_SUCCESS, user });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_USER_VIEW_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserFetch' });
    dispatch(toast('error', e));
  }
};

export const adminUserProjectsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminUserProjectsFetch' });

  const fetchDataInfo = {
    url: `${PROJECTS_API_URL}?deleted=false&archived[$ne]=true&members.user=${id}&$sort[name]=1`,
  };

  try {
    const projects = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_USER_PROJECTS_SUCCESS, projects });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserProjectsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_USER_PROJECTS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserProjectsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminUserEventsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminUserEventsFetch' });

  const fetchDataInfo = {
    url: `${EVENTS_API_URL}?user=${id}&$sort[createdAt]=-1&$limit=20`,
  };

  try {
    const events = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_USER_EVENTS_SUCCESS, events });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserEventsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_USER_EVENTS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminUserEventsFetch' });
    dispatch(toast('error', e));
  }
};
