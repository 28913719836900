// this takes a ref and returns useuable file name and file objects from ref
const fileParser = (ref, fromComments) => {
  try {
    const asset = ref && ref.files ? ref.files[0] : ref;

    if (!asset) {
      return { fileName: '', file: '' };
    }

    const fileType = asset.type;
    const fileName = asset.name;

    const blob = asset.slice(0, asset.size, fileType);
    const newFile = new File([blob], fileName, { type: fileType });

    const file = new FormData();
    file.append('uri', newFile);

    if (fromComments) {
      file.append('fromComments', true);
    }

    return { fileName, file };
  } catch (e) {
    throw e;
  }
};

export default fileParser;
