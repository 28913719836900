import constants from '../constants';
import { orderBy } from 'lodash';

const { PROJECT_EDIT_UPDATE, PROJECT_EDIT_CLEAR, PROJECT_EDIT_BULK } =
  constants;

export const loadCurrentProject = () => async (dispatch, getState) => {
  const data = getState().project.activeProject;
  const bulkData = {
    ...data,
    members: orderBy(data.members, 'user.firstName'),
  };
  dispatch({ type: PROJECT_EDIT_BULK, bulkData });
};

export const updateSingleRecord =
  ({ field, value }) =>
  async (dispatch) =>
    dispatch({ type: PROJECT_EDIT_UPDATE, field, value });

export const clearProjectEdit = () => async (dispatch) =>
  dispatch({ type: PROJECT_EDIT_CLEAR });
