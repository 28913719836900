import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import AdminContainer from '../../components/AdminContainer/AdminContainer';
import Button from '../../components/Button/Button';
import { openModal } from '../../actions/modalActions';

import EditTable from '../../components/UI/Table/EditTable';

import './AdminTranslations.scss';

// admin route /translations
const AdminTranslations = ({ translations, open }) => {
  const [rows, setRows] = useState();

  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <span>
          <p>{props.getValue()}</p>
        </span>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'text',
      cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
        const initialValue = getValue();
        // We need to keep and update the state of the cell normally
        const [value, setValue] = useState(initialValue);

        const [edited, setEdited] = useState(false);

        // When the input is blurred, we'll call our table meta's updateData function
        const onBlur = () => {
          setEdited(true);
          table.options.meta?.updateData(index, id, value);
          table.options.meta?.updateData(index, 'changed', 'edited');
        };

        // If the initialValue is changed external, sync it up with our state
        useEffect(() => {
          setValue(initialValue);
        }, [initialValue]);

        return (
          <textarea
            value={value}
            className={`block w-full py-[10px] px-[15px] my-[10px] border border-[#DFE1E6] rounded focus:ring-bcapp-blue focus:border-bcapp-blue focus:ring-2 text-sm ${
              edited ? 'bg-red-300' : 'bg-[#FAFBFC]'
            }`}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onBlur={onBlur}
          />
        );
      },
      header: 'Text',
    },
    {
      accessorKey: 'changed',
      cell: (props) => (props.row.original.changed ? props.getValue() : null),
      header: 'Edited',
    },
  ];

  useEffect(() => {
    setRows(translations.translations);
  }, [translations]);

  useEffect(() => {
    setRows(translations.translations);
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <h1>Translations</h1>

      <div className="overflow-y-auto border">
        <EditTable
          defaultData={rows || []}
          defaultColumns={tableHead2}
          setTableData={setRows}
          showPagination={true}
          pagination={10}
        />
      </div>

      <div className="pt-4">
        <Button
          onClick={() =>
            open({
              contents: 'AdminTranslateConfirm',
              data: rows,
              heading: 'Please Confirm Changed Items',
              width: 'large',
            })
          }
        >
          Save
        </Button>
      </div>
    </AdminContainer>
  );
};

AdminTranslations.propTypes = {
  fetchProjects: func,
  translations: object,
  open: func,
};

const mapStateToProps = ({ translations }) => ({
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  open: (data) => dispatch(openModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTranslations);
