import { useState, useEffect } from 'react';
import * as msal from '@azure/msal-browser';

const useMsal = (config) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [idToken, setIdToken] = useState();
  const [publicClient, setPublicClient] = useState();
  const [loginError, setLoginError] = useState(false);

  useEffect(() => {
    const myMSALObj = new msal.PublicClientApplication(config);
    setPublicClient(myMSALObj);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (publicClient) {
      publicClient
        .handleRedirectPromise()
        .then(handleResponse)
        .catch((err) => {
          console.error(err);
        });

      const currentAccounts = publicClient.getAllAccounts();
      if (currentAccounts === null) {
        return;
      } else if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn('Multiple accounts detected.');
      } else if (currentAccounts.length === 1) {
        setUser(publicClient.getAllAccounts()[0]);
        setIsAuthenticated(true);
      }
    }
  }, [publicClient]); // eslint-disable-line

  async function handleResponse(resp) {
    if (resp !== null) {
      setUser(publicClient.getAllAccounts()[0]);
      setIsAuthenticated(true);
      let request = {
        scopes: ['user.read'],
      };
      request.account = publicClient.getAccountByUsername(
        resp.account.username
      );
      const response = await publicClient.acquireTokenSilent(request);
      setIdToken(response.idToken);
      setToken(response.accessToken);
    }
  }

  const msalLogin = async (loginRequest) => {
    publicClient.loginRedirect({ loginRequest });
  };

  /*
  const msalLogin = async loginRequest => {
    setPopupOpen(true);
    try {
      const loginResponse = await publicClient.loginPopup({ loginRequest });

      setIdToken(loginResponse.idToken);

      if (publicClient.getAllAccounts().length === 1) {
        setUser(publicClient.getAllAccounts()[0]);
        setIsAuthenticated(true);
      }
    } catch (error) {
      setLoginError(error);
    } finally {
      setPopupOpen(false);
    }
  };
  */
  const msalLogout = () => {
    publicClient.logout();
  };

  const getToken = async (loginRequest) => {
    try {
      loginRequest.account = publicClient.getAllAccounts()[0];
      const response = await publicClient.acquireTokenSilent(loginRequest);
      setIdToken(response.idToken);
      setToken(response.accessToken);
    } catch (error) {
      try {
        const response = await publicClient.acquireTokenRedirect(loginRequest);
        setToken(response.accessToken);
      } catch (error) {
        setLoginError(error);
      }
    }
  };

  return {
    isAuthenticated,
    user,
    idToken,
    token,

    loginError,
    msalLogin,
    msalLogout,
    getToken,
  };
};

export default useMsal;
