import React, { Fragment, useState } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { genericTimeYearDate } from '../../helpers/formatDates';
import NewComment from '../NewComment/NewComment';
import imageUrl from '../../helpers/imageUrl';
import ThumbNail from '../ThumbNail/ThumbNail';
import { openModal } from '../../actions/modalActions';
import getPermissions from '../../helpers/getPermissions';
import Link from '../Link/Link';
import { deleteComment } from '../../actions/itemActions';
import { orderBy } from 'lodash';
import Avatar from '../UI/Avatar/Avatar';
import { Editor, convertFromRaw, EditorState } from 'draft-js';
import cx from 'classnames';

import './Comments.scss';

const modal = {
  contents: 'AssetViewer',
  maxWidth: 'full',
};

// comments parent
const Comments = ({
  openM,
  dashboard,
  scrollToBottom,
  deleteCommentById,
  login,
  translations,
}) => {
  const { itemView__commentsEdit_link, itemView__commentsDelete_link } =
    translations.byKeyTranslations;
  const [editOpen, setEditOpen] = useState('');
  const { _id, comments = [] } = dashboard.activeIssue;
  const sortComments = orderBy(comments, 'createdAt');

  return (
    <Fragment>
      {sortComments.map(
        ({
          _id: cId,
          creator = {},
          updatedAt,
          comment,
          assets,
          navisCommentTime,
          edited,
        }) => {
          const parseComment = JSON.parse(comment);

          return (
            <div key={cId} className="issue__comments">
              <div className="issue__comments-top">
                <span className="issue__comments--name">
                  <span className="issue__comments--avatar">
                    <Avatar
                      size="medium"
                      src={imageUrl(
                        creator.picture && creator.picture.key,
                        creator.picture && creator.picture.url
                      )}
                      alt={creator.firstName + ' ' + creator.lastName}
                    />
                  </span>
                  {creator.firstName} {creator.lastName}
                </span>
                <span className="issues__comments--light">
                  {edited ? 'Edited - ' : ''}
                  {genericTimeYearDate(updatedAt)}
                </span>
              </div>

              {editOpen === cId ? (
                <NewComment
                  onActivatedCb={() => scrollToBottom()}
                  editComment={{ comment, cId, assets }}
                  closeCommentCallBack={() => setEditOpen('')}
                />
              ) : (
                <div className={cx({ naviswork__comment: navisCommentTime })}>
                  <Editor
                    editorState={
                      parseComment.blocks.length > 0
                        ? EditorState.createWithContent(
                            convertFromRaw(parseComment)
                          )
                        : EditorState.createEmpty()
                    }
                    readOnly={true}
                  />

                  <div className="issue__comments-assets">
                    {assets.length > 0 &&
                      assets.map(({ _id, url, key }) => (
                        <ThumbNail
                          key={_id}
                          url={imageUrl(key, url)}
                          name={url}
                          noLink
                          darkBorder
                          onClick={() => openM({ ...modal, data: _id })}
                        />
                      ))}
                  </div>
                </div>
              )}

              <div className="issue__comments--edit">
                {login.userProfile._id === creator._id &&
                  !navisCommentTime &&
                  getPermissions('item:comment:edit:self') && (
                    <Link
                      onClick={() => setEditOpen(editOpen === cId ? '' : cId)}
                      dark
                      inline
                    >
                      {itemView__commentsEdit_link}
                    </Link>
                  )}

                {login.user.id === creator._id && !navisCommentTime && ' - '}

                {(login.user.globalAdmin ||
                  (login.user.id === creator._id &&
                    getPermissions('item:comment:delete:self'))) && (
                  <Link onClick={() => deleteCommentById(_id, cId)} dark inline>
                    {itemView__commentsDelete_link}
                  </Link>
                )}
              </div>
            </div>
          );
        }
      )}
      {getPermissions('item:comment:add') && (
        <NewComment onActivatedCb={() => scrollToBottom()} />
      )}
    </Fragment>
  );
};

Comments.propTypes = {
  dashboard: object,
  openM: func,
  scrollToBottom: func,
  deleteCommentById: func,
  login: object,
  translations: object,
};

const mapStateToProps = ({ dashboard, login, translations }) => ({
  dashboard,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  openM: (target) => dispatch(openModal(target)),
  deleteCommentById: (id, itemId) => dispatch(deleteComment(id, itemId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
