import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import { matchSorter } from 'match-sorter';
import toast from '../helpers/toast';

const {
  ADMIN_PROJECTS_VIEW_SUCCESS,
  ADMIN_PROJECTS_VIEW_FAILURE,
  ADMIN_PROJECTS_ITEMS_SUCCESS,
  ADMIN_PROJECTS_ITEMS_FAILURE,
  ADMIN_PROJECTS_EVENTS_SUCCESS,
  ADMIN_PROJECTS_EVENTS_FAILURE,
  ADMIN_PROJECTS_STATS_SUCCESS,
  ADMIN_PROJECTS_STATS_FAILURE,
  ADMIN_PROJECTS_VIEW_FUZZY_TERM,
  ADMIN_PROJECTS_VIEW_FUZZY_SEARCH,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

const {
  PROJECTS_API_URL,
  ITEMS_API_URL,
  EVENTS_API_URL,
  ADMIN_PROJECT_STATS_URL,
} = api;

export const projectsFuzzy = (searchTerm) => (dispatch) => {
  dispatch({ type: ADMIN_PROJECTS_VIEW_FUZZY_TERM, searchTerm });
  dispatch(adminFuzzySearch());
};

export const adminFuzzySearch = () => (dispatch, getState) => {
  const { searchTerm, items } = getState().adminProjectsView;
  const searchData = matchSorter(items, searchTerm, {
    keys: ['name', 'status', 'description'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const searchResults = searchData.length > 0 ? searchData : null;
  dispatch({ type: ADMIN_PROJECTS_VIEW_FUZZY_SEARCH, searchResults });
};

export const adminProjectsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminProjectsFetch' });

  const fetchDataInfo = {
    url: `${PROJECTS_API_URL}/${id}`,
  };

  try {
    const projects = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_PROJECTS_VIEW_SUCCESS, projects });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_PROJECTS_VIEW_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminItemsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminItemsFetch' });

  const fetchDataInfo = {
    url: `${ITEMS_API_URL}?projectId=${id}&$sort[itemId]=-1`,
  };

  try {
    const items = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_PROJECTS_ITEMS_SUCCESS, items });
    dispatch({ type: LOADING_FINISHED, src: 'adminItemsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_PROJECTS_ITEMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminItemsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminProjectEventsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminProjectEventsFetch' });

  const fetchDataInfo = {
    url: `${EVENTS_API_URL}?projectsId=${id}&$sort[createdAt]=-1&$limit=20`,
  };

  try {
    const events = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_PROJECTS_EVENTS_SUCCESS, events });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectEventsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_PROJECTS_EVENTS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectEventsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminProjectStatsFetch = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminProjectStatsFetch' });

  const fetchDataInfo = {
    url: `${ADMIN_PROJECT_STATS_URL}/${id}`,
  };

  try {
    const stats = await fetchData(fetchDataInfo);
    dispatch({ type: ADMIN_PROJECTS_STATS_SUCCESS, stats });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectStatsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_PROJECTS_STATS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminProjectStatsFetch' });
    dispatch(toast('error', e));
  }
};
