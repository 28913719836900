import constants from '../constants';

const { MENU_OPEN, MENU_CLOSE, PROJECT_MENU_FUZZY_TERM, LOGOUT } = constants;

const initState = {
  projectMenu: false,
  filterMenu: false,
  sortMenu: false,
  densityMenu: false,
  projectFuzzyTerm: '',
  workSpaceMenu: false,
};

export const menu = (state = initState, action) => {
  switch (action.type) {
    case MENU_OPEN:
      return {
        ...state,
        [action.target]: true,
      };
    case MENU_CLOSE:
      return initState;
    case PROJECT_MENU_FUZZY_TERM:
      return {
        ...state,
        projectFuzzyTerm: action.projectFuzzyTerm,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
