import React, { useState } from 'react';
import { func, object } from 'prop-types';
import Button from '../Button/Button';
import { connect } from 'react-redux';
import cx from 'classnames';
import ThumbNail from '../ThumbNail/ThumbNail';
import Dragger from '../Dragger/Dragger';
import toast from '../../helpers/toast';
import { addNewComment, editComment } from '../../actions/itemActions';
import imageUrl from '../../helpers/imageUrl';
import Editor from 'draft-js-plugins-editor';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  // CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from 'draft-js-mention-plugin';
import parseJson from '../../helpers/parseJson';
import { openModal, closeModal } from '../../actions/modalActions';
import HeroIconUpload from '../HeroIcon/HeroIconUpload';

import './NewComment.scss';
import './editorStyles.scss';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';

// new comment at bottom, also used for edit comment
const positionSuggestions = ({ state, props }) => {
  let transform;
  let transition;
  let bottom = '0px';
  let display = 'flex';
  let flexDirection = 'column-reverse';
  let justifyContent = 'flex-start';

  if (state.isActive && props.suggestions.length > 0) {
    transform = 'scaleY(1)';
    transition = 'all 0.25s cubic-bezier(.3,1.2,.2,1)';
  } else if (state.isActive) {
    transform = 'scaleY(0)';
    transition = 'all 0.25s cubic-bezier(.3,1,.2,1)';
  }

  return {
    transform,
    transition,
    bottom,
    display,
    flexDirection,
    justifyContent,
  };
};

let files = [];

const mentionsPlugin = createMentionPlugin({
  positionSuggestions,
  entityMutability: 'IMMUTABLE',
});

const toolbarPlugin = createToolbarPlugin();

const modal = {
  contents: 'UploadsModal',
  heading: 'New attachment',
  maxWidth: '480px',
};

const { Toolbar } = toolbarPlugin;
const { MentionSuggestions } = mentionsPlugin;

const plugins = [toolbarPlugin, mentionsPlugin];

const NewComment = ({
  dashboard,
  postNewComment,
  loading,
  onActivatedCb,
  throwError,
  editComment = {},
  edit,
  closeCommentCallBack,
  project,
  translations,
  open,
  close,
}) => {
  const { itemView__commentPost_button, itemView__commentSave_button } =
    translations.byKeyTranslations;
  const parseComment = parseJson(editComment.comment);

  const [suggestions, setSuggestions] = useState([]);
  const [editorState, editorStateChange] = useState(
    !parseComment || parseComment.blocks.length === 0
      ? EditorState.createEmpty()
      : EditorState.createWithContent(convertFromRaw(parseComment))
  );

  const [fileUrls, updatefileUrls] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isFocused, isFocusedSet] = useState(false);
  const { _id } = dashboard.activeIssue;

  const isEmpty = !editorState.getCurrentContent().hasText();
  const { mentions } = project;

  const addFile = (allFiles) => {
    if (files.length + allFiles.length > 5) {
      throwError('No more than 5 files per comment.');
      return false;
    }
    for (const file of allFiles) {
      files.push(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        updatefileUrls((prevState) => [...prevState, e.target.result]);
      };
      reader.readAsDataURL(file);
    }

    onActivatedCb();
  };

  const onSearchChange = ({ value }) =>
    setSuggestions(defaultSuggestionsFilter(value, mentions));

  return (
    <div
      className={cx('new-comment__container', {
        'new-comment__container--bottom-pad': editComment._id,
      })}
    >
      <Dragger action={(e) => addFile(e.dataTransfer.files)}>
        <div className="new-comment__files">
          {((editComment && editComment.assets) || [])
            .filter(({ _id }) => !deletedFiles.includes(_id))
            .map(({ _id, key, url }) => (
              <div
                className="new-comment__files-inner"
                key={url}
                onClick={() => {
                  setDeletedFiles([...deletedFiles, _id]);
                }}
              >
                <ThumbNail
                  url={imageUrl(key, url)}
                  noLink
                  noPadding
                  darkBorder
                  deleteIcon
                />
              </div>
            ))}

          {fileUrls.map((url, index) => (
            <div
              className="new-comment__files-inner"
              key={url}
              onClick={() => {
                files.splice(index, 1);
                const state = [...fileUrls];
                state.splice(index, 1);
                updatefileUrls(state);
              }}
            >
              <ThumbNail
                directUrl={url}
                noLink
                noPadding
                darkBorder
                deleteIcon
              />
            </div>
          ))}
        </div>

        <div
          className={cx('new-comment__container-inner', {
            'new-comment__container-inner--open ring-bcapp-blue border-bcapp-blue ring-2':
              isFocused || !isEmpty,
          })}
        >
          <div className="new-comment__rte editor">
            <Toolbar>
              {(externalProps) => (
                <div
                  className={cx('editor__buttons', {
                    'editor__buttons--visible': isFocused || !isEmpty,
                  })}
                >
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  {/* <CodeButton {...externalProps} /> */}
                  <Separator {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <OrderedListButton {...externalProps} />
                  <BlockquoteButton {...externalProps} />
                </div>
              )}
            </Toolbar>
            <div className="new-comment__rte--meiton">
              <div style={{ position: 'relative' }}>
                <MentionSuggestions
                  onSearchChange={onSearchChange}
                  suggestions={suggestions}
                />
              </div>
            </div>

            <Editor
              editorState={editorState}
              onChange={(e) => editorStateChange(e)}
              plugins={plugins}
              onFocus={() => {
                isFocusedSet(true);
                onActivatedCb();
              }}
              onBlur={() => isFocusedSet(false)}
              spellCheck={true}
            />
          </div>

          <div className="new-comment__buttons">
            <div
              className="new-comment__upload"
              onClick={() => {
                open({
                  ...modal,
                  data: {
                    callback: (e) => {
                      close();
                      addFile(e);
                    },
                    multi: true,
                  },
                });
              }}
            >
              <HeroIconUpload />
            </div>
            <Button
              appearance="primary"
              disabled={isEmpty}
              onClick={
                editComment.cId
                  ? () => {
                      edit({
                        id: _id,
                        cId: editComment.cId,
                        comment: convertToRaw(editorState.getCurrentContent()),
                        oldComment: convertToRaw(
                          EditorState.createWithContent(
                            convertFromRaw(parseComment)
                          ).getCurrentContent()
                        ),
                        files: files,
                        notDeletedFiles: (
                          (editComment && editComment.assets) ||
                          []
                        ).filter(({ _id }) => !deletedFiles.includes(_id)),
                      });
                      closeCommentCallBack();
                    }
                  : () => {
                      postNewComment({
                        id: _id,
                        comment: convertToRaw(editorState.getCurrentContent()),
                        files,
                      });
                      files = [];
                      editorStateChange(EditorState.createEmpty());
                      updatefileUrls([]);
                      isFocusedSet(false);
                    }
              }
              loading={loading.loading}
            >
              {editComment.cId
                ? itemView__commentSave_button
                : itemView__commentPost_button}
            </Button>
          </div>
        </div>
      </Dragger>
    </div>
  );
};

NewComment.propTypes = {
  postNewComment: func,
  loading: object,
  cb: func,
  throwError: func,
  editComment: object,
  dashboard: object,
  onActivatedCb: func,
  edit: func,
  closeCommentCallBack: func,
  project: object,
  translations: object,
  open: func,
  close: func,
};

const mapStateToProps = ({ dashboard, loading, project, translations }) => ({
  dashboard,
  loading,
  project,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postNewComment: (data) => dispatch(addNewComment(data)),
  edit: (data) => dispatch(editComment(data)),
  throwError: (e) => dispatch(toast('error', e)),
  open: (data) => dispatch(openModal(data)),
  close: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewComment);
