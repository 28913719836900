import React, { useRef, useState } from 'react';
import { func, object } from 'prop-types';
import Cropper from 'react-cropper';
import Button from '../../components/Button/Button';
import { imageAttachments } from '../../constants/labels';
import Dragger from '../../components/Dragger/Dragger';
import { connect } from 'react-redux';
import { updateProfilePhoto } from '../../actions/registerActions';
import Link from '../../components/Link/Link';
import { updateTeamPhoto } from '../../actions/teamsActions';

import 'cropperjs/dist/cropper.css';
import './ProfilePictureModal.scss';

// this is used in account section to crop photo
const ProfilePictureModal = ({
  submitProfilePhoto,
  submitTeamPhoto,
  data: { team },
}) => {
  const cropper = useRef();
  const fileRef = useRef();
  const [debounce, setDebouce] = useState(false);
  const [fileUrl, updatefileUrls] = useState(null);
  const [cropData, setCropData] = useState(null);

  const crop = () => {
    clearTimeout(debounce);
    setDebouce(
      setTimeout(() => {
        if (typeof cropper !== 'undefined') {
          const dataUrl = cropper.current.cropper
            .getCroppedCanvas()
            .toDataURL();
          setCropData(dataUrl);
        }
      }, 250)
    );
  };

  const addFile = (fileEvent) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      updatefileUrls(e.target.result);
    };
    reader.readAsDataURL(fileEvent);
  };

  return (
    <div className="profile-pic__container">
      <div className="profile-pic__container--dragger">
        <Dragger action={(e) => addFile(e.dataTransfer.files[0])}>
          {!fileUrl && (
            <div className="profile-pic__container--text">
              <h3>
                Upload a{' '}
                <Link
                  onClick={() => fileRef.current.click()}
                  inline
                  dark
                  bright
                >
                  photo
                </Link>{' '}
                or drag here
              </h3>

              <Button onClick={() => fileRef.current.click()}>
                Upload photo
              </Button>
            </div>
          )}
          <input
            type="file"
            ref={fileRef}
            style={{ display: 'none' }}
            onChange={() => {
              addFile(fileRef.current.files[0]);
            }}
            acccept={imageAttachments}
          />

          <Cropper
            ref={cropper}
            src={fileUrl}
            style={{ height: 'auto', maxHeight: '70vh', width: '100%' }}
            aspectRatio={1 / 1}
            guides={true}
            crop={crop}
            viewMode={1}
          />
        </Dragger>
      </div>

      {fileUrl && (
        <Button
          onClick={() =>
            team ? submitTeamPhoto(cropData) : submitProfilePhoto(cropData)
          }
        >
          Upload Photo
        </Button>
      )}
    </div>
  );
};

ProfilePictureModal.propTypes = {
  submitPhoto: func,
  submitProfilePhoto: func,
  submitTeamPhoto: func,
  data: object,
};

const mapDispatchToProps = (dispatch) => ({
  submitProfilePhoto: (data) => dispatch(updateProfilePhoto(data)),
  submitTeamPhoto: (data) => dispatch(updateTeamPhoto(data)),
});

export default connect(null, mapDispatchToProps)(ProfilePictureModal);
