import React from 'react';
import { get } from 'lodash';
import getFileIcon from '../helpers/getFileIcon';

const videoType = ['mp4', 'avi', 'mov', 'wmv'];

export const imageType = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];

// returns correct file type icon
const correctIcon = (url = '') => {
  const ext = url.split('.').pop().toLowerCase();

  const fileIcon = get(getFileIcon, ext, '');

  if (imageType.includes(ext)) {
    return url;
  } else if (videoType.includes(ext)) {
    return getFileIcon.mp4;
  } else if (fileIcon) {
    return fileIcon;
  } else if (url) {
    return getFileIcon.file;
  } else {
    return '';
  }
};

// returns correct file source, video etc
export const correctHtml = (url = '', title) => {
  const ext = url.split('.').pop().toLowerCase();

  const fileIcon = get(getFileIcon, ext, '');

  if (imageType.includes(ext)) {
    return <img src={url} title={title} alt="" />;
  } else if (videoType.includes(ext)) {
    return (
      <video controls>
        <source src={url} />
      </video>
    );
  } else if (ext === 'pdf') {
    return <embed src={`${url}#view=FitH`} />;
  } else {
    return <img src={fileIcon || getFileIcon.file} title={title} alt="" />;
  }
};

export default correctIcon;
