// parse url props
const urlProps = (search, url) => {
  const data = (url || window.location.pathname).split('/');
  const index = data.indexOf(search);
  if (index && data[index + 1]) {
    return data[index + 1];
  }
  return '';
};

export default urlProps;
