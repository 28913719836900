import constants from '../constants';
import filters from '../constants/filters';

const {
  WORKSPACE_EDIT_UPDATE,
  WORKSPACE_EDIT_CLEAR,
  WORKSPACE_EDIT_BULK,
  LOGOUT,
} = constants;

const initState = {
  name: '',
  publicToggle: null,
  filters: filters(),
  items: [],
};

export const editWorkspace = (state = initState, action) => {
  switch (action.type) {
    case WORKSPACE_EDIT_UPDATE:
      return {
        ...state,
        [action.field]: action.value,
      };

    case WORKSPACE_EDIT_CLEAR:
      return initState;

    case WORKSPACE_EDIT_BULK:
      return { ...state, ...action.bulkData };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
