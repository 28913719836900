import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { closeModal } from '../actions/modalActions';

const {
  ADMIN_TRANSLATIONS_SUCCESS,
  ADMIN_TRANSLATIONS_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

export const adminTranslationsFetch = () => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminTranslationsFetch' });

  const fetchDataInfo = {
    url: api.ADMIN_TRANSLATIONS_URL,
  };

  try {
    const translations = await fetchData(fetchDataInfo);

    const byKeyTranslations = translations.reduce((acc, { name, text }) => {
      acc[name] = text;
      return acc;
    }, {});

    dispatch({
      type: ADMIN_TRANSLATIONS_SUCCESS,
      translations,
      byKeyTranslations,
    });
    dispatch({ type: LOADING_FINISHED, src: 'adminTranslationsFetch' });
  } catch (e) {
    dispatch({ type: ADMIN_TRANSLATIONS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminTranslationsFetch' });
    dispatch(toast('error', e));
  }
};

export const adminTranslationsUpdate = (data) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminTranslationsFetch' });
  dispatch(closeModal());

  const fetchDataInfo = {
    url: api.ADMIN_TRANSLATIONS_EDIT_URL,
    type: 'POST',
    body: data,
  };

  try {
    const translations = await fetchData(fetchDataInfo);
    const byKeyTranslations = translations.reduce((acc, { name, text }) => {
      acc[name] = text;
      return acc;
    }, {});

    dispatch({
      type: ADMIN_TRANSLATIONS_SUCCESS,
      translations,
      byKeyTranslations,
    });
    dispatch({ type: LOADING_FINISHED, src: 'adminTranslationsFetch' });
    dispatch(toast('success', 'Translations have been updated.'));
  } catch (e) {
    dispatch({ type: ADMIN_TRANSLATIONS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminTranslationsFetch' });
    dispatch(toast('error', e));
  }
};
