import constants from '../constants';

const {
  REPORT_SUCCESS,
  AREPORT_FAILURE,
  ADMIN_REPORTS_SUCCESS,
  ADMIN_REPORTS_FAILURE,
  LOGOUT,
} = constants;

const initState = {
  weeklyStatus: [],
  weeklyCumulativeStatus: [],
  burnDown: [],
  cycleTime: [],
  byDomain: [],
  byStatus: {},
  byDueDate: {},
  dueDateAndUnresolved: 0,
  noUsers: 0,
  overdueAssignee: 0,
  overdueNoAssignee: 0,
  error: '',
  userReports: [],
};

export const report = (state = initState, action) => {
  switch (action.type) {
    case REPORT_SUCCESS:
      return {
        ...state,
        weeklyStatus: action.weeklyStatus,
        weeklyCumulativeStatus: action.weeklyCumulativeStatus,
        burnDown: action.burnDown,
        cycleTime: action.cycleTime,
        byStatus: action.byStatus,
        byDueDate: action.byDueDate,
        dueDateAndUnresolved: action.dueDateAndUnresolved,
        overdueAssignee: action.overdueAssignee,
        noUsers: action.noUsers,
        overdueNoAssignee: action.overdueNoAssignee,
        openByUser: action.openByUser,
        byDomain: action.byDomain,
      };
    case AREPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case ADMIN_REPORTS_SUCCESS:
      return {
        ...state,
        userReports: action.userReports,
      };

    case ADMIN_REPORTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
