export const labelise = (data = []) =>
  data.map(value => ({
    value,
    label: value,
  }));

export const roles = {
  arc: 'Architectural',
  bim: 'BIM',
  civ: 'Civil',
  ele: 'Electrical',
  fir: 'Fire',
  hyd: 'Hydraulics',
  mec: 'Mechanical',
  oth: 'Other',
  prj: 'Project Management',
  str: 'Structural',
};

export const rolesLabel = Object.entries(roles).map(([value, label]) => ({
  label,
  value,
}));

// ensure this is small to big order as is used in the sort functionality
export const priority = ['Low', 'Normal', 'High'];
export const priorityLabel = labelise(priority);
export const priorityWeight = priority.reduce((acc, item, index) => {
  acc[item] = index;
  return acc;
}, {});

export const status = ['Open', 'In Progress', 'On Hold', 'Resolved', 'Closed'];
export const statusLabel = labelise(status);

export const dates = ['None', 'Past Due', 'Today', 'Next Day', 'Next 7 Days'];

export const imageAttachments = '.jpeg, .jpg, .png, .gif';
export const imageAttachmentsMime =
  'image/jpeg, image/jpg, image/png, image/gif';

export const membersLevels = {
  admin: 'admin',
  collaborator: 'collaborator',
  guest: 'guest',
};

export const memberLabels = [
  { label: 'Project Admin', value: 'admin' },
  { label: 'Collaborator', value: 'collaborator' },
  { label: 'Guest', value: 'guest' },
];

export const teamLevels = {
  teamAdmin: 'teamAdmin',
  teamMember: 'teamMember',
};

export const teamLabels = [
  { label: 'Team Admin', value: 'teamAdmin' },
  { label: 'Team Member', value: 'teamMember' },
];
