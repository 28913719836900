import React, { useState } from 'react';
import { string, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { deleteProjectById } from '../../actions/projectActions';
import Button from '../../components/Button/Button';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './DeleteProjectModal.scss';

// confirm delete project modal
const DeleteProjectModal = ({ data, deleteProject, loading, translations }) => {
  const {
    projectDelete__helper_text,
    projectDelete__step1_text,
    projectDelete__step2_text,
    projectDelete__confirmDelete_button,
  } = translations.byKeyTranslations;
  const [range, setRange] = useState(0);

  return (
    <div>
      <p>{projectDelete__helper_text}</p>

      <h4>{projectDelete__step1_text}</h4>

      <div className="delete-modal__range">
        <Slider
          min={0}
          max={100}
          onChange={setRange}
          trackStyle={[{ backgroundColor: '#6cf' }]}
          handleStyle={{
            borderColor: '#6cf',
            backgroundColor: 'white',
          }}
        />
      </div>

      <h4>{projectDelete__step2_text}</h4>

      <div className="delete-modal__button">
        <Button
          onClick={() => deleteProject(data)}
          danger
          disabled={range < 100}
          loading={loading.loading}
        >
          {projectDelete__confirmDelete_button}
        </Button>
      </div>
    </div>
  );
};

DeleteProjectModal.propTypes = {
  data: string,
  deleteProject: func,
  loading: object,
  translations: object,
};

const mapStateToProps = ({ loading, translations }) => ({
  loading,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProject: (id) => dispatch(deleteProjectById(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProjectModal);
