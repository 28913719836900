import constants from '../constants';

const { ADMIN_FEEDBACK_SUCCESS, ADMIN_FEEDBACK_FAILURE, LOGOUT } = constants;

const initState = {
  feedback: [],
  error: '',
};

export const feedback = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: action.feedback,
      };
    case ADMIN_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
