import constants from '../constants';

const { ADMIN_TRANSLATIONS_SUCCESS, ADMIN_TRANSLATIONS_FAILURE } = constants;

const initState = {
  translations: [],
  byKeyTranslations: {},
  error: '',
};

export const translations = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.translations,
        byKeyTranslations: action.byKeyTranslations,
      };
    case ADMIN_TRANSLATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
