import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import postImage from '../helpers/postImage';
import { closeModal } from '../actions/modalActions';
import { get } from 'lodash';
import { matchSorter } from 'match-sorter';
import validator from 'email-validator';

const {
  TEAMS_SUCCESS,
  TEAMS_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
  TEAMS_SET_ACTIVE,
  TEAM_PROJECTS,
  TEAMS_PROJECT_FUZZY_TERM,
  TEAMS_PROJECT_FUZZY_SERCH,
  TEAMS_MEMBER_FUZZY_TERM,
  TEAMS_MEMBER_FUZZY_SEARCH,
  TEAMS_MEMBER_RESET_SEARCH,
  TEAM_ARCHIVE_SUCCESS,
  TEAM_TEMPLATE_SUCCESS,
} = constants;

export const setActiveTeam = (activeTeam) => (dispatch) =>
  dispatch({ type: TEAMS_SET_ACTIVE, activeTeam });

export const getTeamDetails = (teamId) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'getTeamDetails' });

  const fetchDataInfo = {
    url: api.MY_TEAMS_API,
  };

  try {
    const { myTeams, myTeam } = await fetchData(fetchDataInfo);
    const correctTeam = myTeams.find((item) => item._id === teamId);
    // this checks if there is a URL team if not select MY team or FIrst team
    const myTeamOrFirst = myTeam._id ? myTeam : myTeams[0];
    const checkCorrecTeam = correctTeam ? correctTeam : myTeamOrFirst;

    dispatch({ type: TEAMS_SUCCESS, myTeams, myTeam });
    dispatch(setActiveTeam(checkCorrecTeam));
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
    dispatch(toast('error', e));
  }
};

export const getTeamArchive = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'getTeamDetails' });
  const { _id } = getState().teams.activeTeam;

  const fetchDataInfo = {
    url: `${api.TEAM_ARCHIVE_URL}/${_id}`,
  };

  try {
    const teamArchive = await fetchData(fetchDataInfo);
    dispatch({ type: TEAM_ARCHIVE_SUCCESS, teamArchive });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
    dispatch(toast('error', e));
  }
};

export const updateTeamArchive = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'getTeamDetails' });

  const fetchDataInfo = {
    url: `${api.TEAM_ARCHIVE_URL}/${id}`,
    type: 'PATCH',
  };

  try {
    await fetchData(fetchDataInfo);
    dispatch(getTeamArchive());
    dispatch(toast('success', 'Project updated'));
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
    dispatch(toast('error', e));
  }
};

export const getTeamProjects = (id) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'getTeamDetails' });
  dispatch(resetTeamMemberSearch());
  const { _id } = getState().teams.activeTeam;

  const fetchDataInfo = {
    url: `${api.MY_TEAMS_API}/${id || _id}`,
  };

  try {
    const teamProjects = await fetchData(fetchDataInfo);
    dispatch({ type: TEAM_PROJECTS, teamProjects });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
    dispatch(toast('error', e));
  }
};

export const updateTeamPhoto = (data) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateTeamPhoto' });

  const { name, picture: oldPicture } = getState().teams.activeTeam;

  const type = data.split(';')[0].split('/')[1];
  const fileSource = await fetch(data);
  const fileData = await fileSource.blob();
  const file = new File([fileData], `${name}_team_picture.${type}`);

  const picture = await postImage(dispatch, file);

  try {
    dispatch(updateTeamDetails({ value: picture, field: 'logo' }));
    if (oldPicture) {
      const { _id } = oldPicture;
      const deleteOldAssetReq = {
        url: `${api.ASSET_URL}/${_id}`,
        type: 'DELETE',
      };
      await fetchData(deleteOldAssetReq);
    }
    dispatch(closeModal());
    dispatch({ type: LOADING_FINISHED, src: 'updateProfilePhoto' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateProfilePhoto' });
    dispatch(toast('error', e));
  }
};

export const updateTeamDetails =
  ({ field, value }) =>
  async (dispatch, getState) => {
    const teamId = getState().teams.activeTeam._id;

    const isMembersUpdate = field === 'teamMembers';

    dispatch({ type: LOADING_REQUEST, src: 'updateTeamDetails' });

    const newMembers = isMembersUpdate
      ? value.filter((item) => item.__isNew__)
      : [];

    const newUsersPost = {
      url: api.REGISTER_URL,
      type: 'POST',
      body: newMembers.map(({ user: { email } }) => email),
    };

    const allNewUsers =
      newMembers.length > 0 ? await fetchData(newUsersPost) : [];

    if (allNewUsers.errors) {
      return dispatch(toast('error', allNewUsers.message));
    }

    const parseNewTeamMembers = isMembersUpdate
      ? value.map(({ user: { _id, email: topEmail }, teamLevel }) => {
          const user = _id
            ? _id
            : get(
                allNewUsers.find(({ email }) => email === topEmail),
                '_id'
              );
          return {
            user,
            teamLevel,
          };
        })
      : [];

    const data = isMembersUpdate ? parseNewTeamMembers : value;

    const fetchDataInfo = {
      url: `${api.TEAMS_API}/${teamId}`,
      type: 'PATCH',
      body: { [field]: data },
    };

    try {
      const activeTeam = await fetchData(fetchDataInfo);
      dispatch(setActiveTeam(activeTeam));
      dispatch(getTeamDetails());
      dispatch({ type: LOADING_FINISHED, src: 'updateTeamDetails' });
      dispatch(toast('success', 'Team updated'));
    } catch (e) {
      dispatch({ type: TEAMS_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'updateTeamDetails' });
      dispatch(toast('error', e));
    }
  };

export const teamFuzzy = (fuzzyTerm) => (dispatch) => {
  dispatch({ type: TEAMS_PROJECT_FUZZY_TERM, fuzzyTerm });
  dispatch(adminFuzzySearch());
};

export const adminFuzzySearch = () => (dispatch, getState) => {
  const { fuzzyTerm, teamProjects } = getState().teams;

  const searchData = matchSorter(teamProjects, fuzzyTerm, {
    keys: ['name', 'description'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  const fuzzyTeams = searchData.length > 0 ? searchData : null;
  dispatch({ type: TEAMS_PROJECT_FUZZY_SERCH, fuzzyTeams });
};

export const addNewTeamMembers = (data) => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'addNewTeamMembers' });
  const { _id, teamSize, teamMembers = [] } = getState().teams.activeTeam;

  const seatsLeft = teamSize - teamMembers.length;

  const emails = data.email_bulk
    ? data.email_bulk // if bulk email remove white space, split and filter empty
        .replace(/\s/g, '')
        .split(';')
        .filter(Boolean)
    : Object.values(data).filter(Boolean); // grab values and remove empty

  // make emails uniq
  const uniqEmails = [...new Set(emails)];

  if (uniqEmails.length > seatsLeft) {
    return dispatch(
      toast('error', 'You dont have enough empty seats for this many members.')
    );
  }

  for (const email of uniqEmails) {
    if (!validator.validate(email)) {
      return dispatch(toast('error', `${email} is not a valid email.`));
    }
  }

  const fetchDataInfo = {
    url: `${api.TEAM_MEMBER_API}/${_id}`,
    type: 'PATCH',
    body: { emails: uniqEmails },
  };

  try {
    const activeTeam = await fetchData(fetchDataInfo);
    dispatch(setActiveTeam(activeTeam));
    dispatch(closeModal());
    dispatch({ type: LOADING_FINISHED, src: 'addNewTeamMembers' });
    dispatch(toast('success', 'New Team members added'));
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'addNewTeamMembers' });
    dispatch(toast('error', e));
  }
};

export const teamMemberSearch =
  (fuzzyMemberTerm, teamMembers) => async (dispatch) => {
    dispatch({ type: TEAMS_MEMBER_FUZZY_TERM, fuzzyMemberTerm });
    dispatch(teamMemberFuzzySearch(teamMembers));
  };

export const teamMemberFuzzySearch = (members) => (dispatch, getState) => {
  const { fuzzyMemberTerm } = getState().teams;

  const fuzzyMembers = matchSorter(members, fuzzyMemberTerm, {
    keys: ['user.firstName', 'user.lastName', 'user.email', 'user.company'],
    threshold: matchSorter.rankings.CONTAINS,
  });

  dispatch({ type: TEAMS_MEMBER_FUZZY_SEARCH, fuzzyMembers });
};

export const resetTeamMemberSearch = () => (dispatch) => {
  dispatch({ type: TEAMS_MEMBER_RESET_SEARCH });
};

export const fetchTemplates = () => async (dispatch, getState) => {
  dispatch({ type: LOADING_REQUEST, src: 'getTeamDetails' });
  const { _id } = getState().teams.activeTeam;

  const fetchDataInfo = {
    url: `${api.TEAM_GET_TEMPLATES}/${_id}`,
  };

  try {
    const teamTemplate = await fetchData(fetchDataInfo);
    dispatch({ type: TEAM_TEMPLATE_SUCCESS, teamTemplate });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
  } catch (e) {
    dispatch({ type: TEAMS_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'getTeamDetails' });
    dispatch(toast('error', e));
  }
};
