import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import postImage from '../helpers/postImage';
import { fetchIssuesAction, setActiveIssue } from '../actions/dashboardActions';
import { projectFetch } from '../actions/projectActions';
import draft2Text from '../helpers/draft2Text';
import { closeModal } from '../actions/modalActions';
import { toggleMenu } from '../actions/bulkActions';

const { LOADING_REQUEST, LOADING_FINISHED, PROJECT_NAV_SET_ACTIVE } = constants;

const {
  ITEMS_API_URL,
  SUB_DOC_UPDATE,
  ASSET_URL,
  SUB_DOC_DELETE,
  DELETE_COMMENT,
  EDIT_COMMENT,
  NEW_TAG,
  BULK_DELETE,
  ITEMS_SUBITEM_API_URL,
  DELETE_SUBTASK_API_URL,
  DUPLICATE_ITEM_API_URL,
} = api;

export const updateActiveIssue = (id, field, data) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateActiveIssue' });

  const fetchDataInfo = {
    url: `${ITEMS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      [field]: data,
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'updateActiveIssue' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateActiveIssue' });
    dispatch(toast('error', e));
  }
};

export const updateNewTag =
  ({ id, projectId, newTag }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'updateNewTag' });

    const updateIssueTags = {
      url: `${NEW_TAG}`,
      type: 'POST',
      body: {
        newTag,
        projectId,
        id,
      },
    };

    try {
      const activeProject = await fetchData(updateIssueTags);
      dispatch({ type: PROJECT_NAV_SET_ACTIVE, activeProject });
      await dispatch(fetchIssuesAction(activeProject._id));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'updateNewTag' });
      dispatch(toast('error', e));
    }
  };

export const addSingleCurrentTag = (id, tags) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'addSingleCurrentTag' });

  const updateIssueTags = {
    url: `${ITEMS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      tags: tags.map((item) => item.value),
    },
  };

  try {
    const activeIssue = await fetchData(updateIssueTags);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'addSingleCurrentTag' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'addSingleCurrentTag' });
    dispatch(toast('error', e));
  }
};

export const deleteActiveTag = (id, childTarget) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'deleteActiveTag' });

  const updateIssueTags = {
    url: `${SUB_DOC_DELETE}/${id}`,
    type: 'PATCH',
    body: {
      childTarget,
      table: 'items',
      target: 'tags',
      idTarget: false,
    },
  };

  try {
    const activeIssue = await fetchData(updateIssueTags);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'deleteActiveTag' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteActiveTag' });
    dispatch(toast('error', e));
  }
};

export const deleteActiveSubscriber = (id, itemId) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'deleteActiveSubscriber' });

  const updateSubscribers = {
    url: `${SUB_DOC_DELETE}/${itemId}`,
    type: 'PATCH',
    body: {
      childTarget: id,
      table: 'items',
      target: 'subscribers',
      idTarget: false,
    },
  };

  try {
    const activeIssue = await fetchData(updateSubscribers);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'deleteActiveSubscriber' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteActiveSubscriber' });
    dispatch(toast('error', e));
  }
};

export const addNewAsset = (id, fileRef) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'addNewAsset' });
  try {
    const newAssets = [];

    for (const file of fileRef) {
      newAssets.push(await postImage(dispatch, file));
    }

    const updateAssets = {
      url: `${SUB_DOC_UPDATE}/${id}`,
      type: 'PATCH',
      body: {
        data: newAssets,
        target: 'assets',
        table: 'items',
      },
    };
    const activeIssue = await fetchData(updateAssets);

    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'addNewAsset' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'addNewAsset' });
    dispatch(toast('error', e));
  }
};

export const addNewCoverImage = (id, fileRef) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'addNewCoverImage' });
  try {
    const coverImage = await postImage(dispatch, fileRef);

    const fetchDataInfo = {
      url: `${ITEMS_API_URL}/${id}`,
      type: 'PATCH',
      body: {
        coverImage,
      },
    };

    await fetchData(fetchDataInfo);

    const updateAssets = {
      url: `${SUB_DOC_UPDATE}/${id}`,
      type: 'PATCH',
      body: {
        data: coverImage,
        target: 'assets',
        table: 'items',
      },
    };

    const activeIssue = await fetchData(updateAssets);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'addNewCoverImage' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'addNewCoverImage' });
    dispatch(toast('error', e));
  }
};

export const deleteIssue = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'deleteIssue' });

  const deleteIssue = {
    url: `${ITEMS_API_URL}/${id}`,
    type: 'DELETE',
  };

  try {
    const { projectId } = await fetchData(deleteIssue);
    await dispatch(fetchIssuesAction(projectId, true));
    await dispatch(projectFetch(projectId, false, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteIssue' });
    dispatch(toast('error', e));
  }
};

export const duplicateIssue = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'duplicateIssue' });

  const duplicateIssue = {
    url: `${DUPLICATE_ITEM_API_URL}/${id}`,
    type: 'PUT',
  };

  try {
    const { projectId } = await fetchData(duplicateIssue);
    await dispatch(fetchIssuesAction(projectId, true));
    await dispatch(projectFetch(projectId, false, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'duplicateIssue' });
    dispatch(toast('error', e));
  }
};

export const deleteItemAsset = (id, itemId) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'deleteItemAsset' });

  const updateItemAssets = {
    url: `${SUB_DOC_DELETE}/${itemId}`,
    type: 'PATCH',
    body: {
      childTarget: id,
      table: 'items',
      target: 'assets',
      idTarget: false,
    },
  };

  const deleteAssetParams = {
    url: `${ASSET_URL}/${id}`,
    type: 'DELETE',
  };

  try {
    const activeIssue = await fetchData(updateItemAssets);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'deleteItemAsset' });
    await fetchData(deleteAssetParams);
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteItemAsset' });
    dispatch(toast('error', e));
  }
};

export const removeCoverImage = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'removeCoverImage' });

  const deleteAssetParams = {
    url: `${ITEMS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      coverImage: null,
    },
  };

  try {
    const activeIssue = await fetchData(deleteAssetParams);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'removeCoverImage' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'removeCoverImage' });
    dispatch(toast('error', e));
  }
};

export const deleteComment = (id, cId) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'deleteComment' });

  const deleteCommentParams = {
    url: `${DELETE_COMMENT}/${cId}`,
    type: 'PATCH',
    body: {
      item: id,
    },
  };

  try {
    const activeIssue = await fetchData(deleteCommentParams);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'deleteComment' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'deleteComment' });
    dispatch(toast('error', e));
  }
};

export const addNewComment =
  ({ id, comment, files }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'addNewComment' });

    const commentString = JSON.stringify(comment);

    const mentions = [
      ...new Set(
        Object.values(comment.entityMap).map((entity) => entity.data.mention.id)
      ),
    ];

    const assets = [];

    if (files.length) {
      for (const file of files) {
        const imageId = await postImage(dispatch, file, true);
        assets.push(imageId);
      }
    }

    const updateIssueComments = {
      url: `${SUB_DOC_UPDATE}/${id}`,
      type: 'PATCH',
      body: {
        data: {
          comment: commentString,
          commentText: draft2Text(commentString),
          assets,
          mentions,
        },
        target: 'comments',
        table: 'items',
      },
    };

    try {
      const activeIssue = await fetchData(updateIssueComments);
      dispatch(setActiveIssue(activeIssue));
      dispatch({ type: LOADING_FINISHED, src: 'addNewComment' });
      await dispatch(fetchIssuesAction(activeIssue.projectId, true));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'addNewComment' });
      dispatch(toast('error', e));
    }
  };

export const editComment =
  ({ id, cId, comment, oldComment, files, notDeletedFiles }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'editComment' });

    const commentString = JSON.stringify(comment);

    const mentions = [
      ...new Set(
        Object.values(comment.entityMap).map((entity) => entity.data.mention.id)
      ),
    ];

    const oldMentions = [
      ...new Set(
        Object.values(oldComment.entityMap).map(
          (entity) => entity.data.mention.id
        )
      ),
    ];

    const newMentions = mentions.filter((item) => !oldMentions.includes(item));

    const assets = [];

    if (files.length) {
      for (const file of files) {
        const imageId = await postImage(dispatch, file, true);
        assets.push(imageId);
      }
    }

    const updateIssueComments = {
      url: `${EDIT_COMMENT}/${id}`,
      type: 'PATCH',
      body: {
        comment: commentString,
        commentText: draft2Text(commentString),
        mentions: newMentions,
        childId: cId,
        assets: [...assets, ...notDeletedFiles],
      },
    };

    try {
      const activeIssue = await fetchData(updateIssueComments);
      dispatch(setActiveIssue(activeIssue));
      dispatch({ type: LOADING_FINISHED, src: 'editComment' });
      await dispatch(fetchIssuesAction(activeIssue.projectId, true));
    } catch (e) {
      dispatch({ type: LOADING_FINISHED, src: 'editComment' });
      dispatch(toast('error', e));
    }
  };

export const updateDescription = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateDescription' });

  const fetchDataInfo = {
    url: `${ITEMS_API_URL}/${id}`,
    type: 'PATCH',
    body: {
      description: data,
      descriptionText: draft2Text(data),
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'updateDescription' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateDescription' });
    dispatch(toast('error', e));
  }
};

export const bulkDeleteItems = () => async (dispatch, getState) => {
  const { bulkEdit, project } = getState();

  const { items } = bulkEdit;
  const { _id } = project.activeProject;

  dispatch({ type: LOADING_REQUEST, src: 'bulkDeleteItems' });

  const deleteIssue = {
    url: `${BULK_DELETE}`,
    type: 'PATCH',
    body: {
      ids: items,
    },
  };

  try {
    await fetchData(deleteIssue);
    await dispatch(fetchIssuesAction(_id, true));
    dispatch(closeModal());
    dispatch(toggleMenu());
    dispatch({ type: LOADING_FINISHED, src: 'bulkDeleteItems' });
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'bulkDeleteItems' });
    dispatch(toast('error', e));
  }
};

export const addNewTask = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'addNewTask' });

  const fetchDataInfo = {
    url: ITEMS_SUBITEM_API_URL,
    type: 'POST',
    body: {
      id,
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'addNewTask' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'addNewTask' });
    dispatch(toast('error', e));
  }
};

export const updateTask = (parentId, childId, data) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateTask' });

  const fetchDataInfo = {
    url: `${ITEMS_SUBITEM_API_URL}/${parentId}`,
    type: 'PATCH',
    body: {
      childId,
      ...data,
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'updateTask' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateTask' });
    dispatch(toast('error', e));
  }
};

export const deleteSubTask = (parentId, childId) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'updateTask' });

  const fetchDataInfo = {
    url: `${DELETE_SUBTASK_API_URL}/${parentId}`,
    type: 'PATCH',
    body: {
      childId,
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'updateTask' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'updateTask' });
    dispatch(toast('error', e));
  }
};

export const sortTasks = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'sortTasks' });
  const fetchDataInfo = {
    url: `${ITEMS_SUBITEM_API_URL}/${id}`,
    type: 'PUT',
    body: {
      data,
    },
  };

  try {
    const activeIssue = await fetchData(fetchDataInfo);
    dispatch(setActiveIssue(activeIssue));
    dispatch({ type: LOADING_FINISHED, src: 'sortTasks' });
    await dispatch(fetchIssuesAction(activeIssue.projectId, true));
  } catch (e) {
    dispatch({ type: LOADING_FINISHED, src: 'sortTasks' });
    dispatch(toast('error', e));
  }
};
