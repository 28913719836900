import constants from '../constants';

const { MODAL_OPEN, MODAL_CLOSE } = constants;

export const openModal = (data) => async (dispatch) => {
  dispatch({ type: MODAL_OPEN, ...data });
};

export const closeModal = () => async (dispatch) => {
  dispatch({ type: MODAL_CLOSE });
};
