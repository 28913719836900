import React from 'react';
import { func, bool, oneOfType, arrayOf, node } from 'prop-types';
import cx from 'classnames';

import './Button.scss';

// button wrapper
const Button = ({
  onClick,
  children,
  loading,
  disabled = false,
  space,
  subtle,
  danger,
  center,
  hideOnMobile,
}) => (
  <button
    type="submit"
    onClick={disabled || loading ? () => {} : onClick}
    className={cx(
      'button__container',
      {
        'button--disabled': disabled,
        'button--space': space,
        'button--subtle': subtle,
        'button--danger': danger,
        'button--loading': loading,
        'button--center': center,
        'button--hideOnMobile': hideOnMobile,
      },
      disabled
        ? 'bg-[#9a9fa7]'
        : subtle
        ? 'bg-[#dfe0e5]'
        : danger
        ? 'bg-[#e04848]'
        : 'bg-bcapp-blue'
    )}
    disabled={disabled || loading ? 'disabled' : null}
  >
    {children}
  </button>
);

Button.propTypes = {
  onClick: func,
  children: oneOfType([arrayOf(node), node]).isRequired,
  loading: bool,
  disabled: bool,
  space: bool,
  subtle: bool,
  danger: bool,
  center: bool,
  hideOnMobile: bool,
};

export default Button;

export const ButtonGroup = ({ children, left, center, between }) => (
  <div
    className={cx('button__group', {
      'button__group--left': left,
      'button__group--center': center,
      'button__group--between': between,
    })}
  >
    {children}
  </div>
);

ButtonGroup.propTypes = {
  left: bool,
  center: bool,
  between: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
};
