import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';
import { resetPassword } from '../../actions/resetPasswordActions';
import ExternalWrapper from '../../components/ExternalWrapper/ExternalWrapper';
import Link from '../../components/Link/Link';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import './ResetPassword.scss';

// password reset route /password-reset
const ResetPassword = ({ postPassword, match, translations }) => {
  const {
    resetPassword__back_button,
    resetPassword__confirmPassword_input,
    resetPassword__helper_text,
    resetPassword__newPassword_input,
    resetPassword__submit_button,
  } = translations.byKeyTranslations;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const [submittingForm, setSubmittingForm] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
        setTimeout(() => setSubmittingForm(false), 3000);
      }
    }
  }, [isSubmitting, errors]);

  return (
    <ExternalWrapper>
      <form
        onSubmit={handleSubmit((data) => postPassword(data, match.params.id))}
      >
        <label>
          {resetPassword__newPassword_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>
        <input
          className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
          {...register('password', {
            required: ' Password is required',
            validate: {
              moreThanEight: (v) =>
                v.length >= 8 || `${resetPassword__helper_text}`,
              hasLetters: (v) =>
                v.match(/[a-z]/) != null || 'Invalid password, no letters',
              hasNumbers: (v) =>
                v.match(/\d/) != null || 'Invalid password, no numbers',
            },
          })}
          defaultValue=""
          type="password"
        />

        <label>
          {resetPassword__confirmPassword_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="confirmPassword"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </label>

        <input
          className="block w-full py-[10px] px-[15px] my-[10px] bg-[#FAFBFC] border border-[#DFE1E6] rounded"
          {...register('confirmPassword', {
            required: ' Confirmation password is required',
            validate: {
              matchPassword: (v) =>
                v === getValues().password || 'Passwords dont match',
            },
          })}
          defaultValue=""
          type="password"
        />

        <div className="mt-6">
          <ButtonGroup>
            <Link to="/login" dark inline>
              {resetPassword__back_button}
            </Link>
            <Button loading={submittingForm}>
              {resetPassword__submit_button || ''}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </ExternalWrapper>
  );
};

ResetPassword.propTypes = {
  postRegister: func,
  postPassword: func,
  match: object,
  translations: object,
};

const mapStateToProps = ({ translations }) => ({ translations });

const mapDispatchToProps = (dispatch) => ({
  postPassword: (data, id) => dispatch(resetPassword(data, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
