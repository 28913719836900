import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { adminTeamsFetch, adminFuzzy } from '../../actions/adminTeamsActions';

import AdminContainer from '../../components/AdminContainer/AdminContainer';

import Button from '../../components/Button/Button';
import { openModal } from '../../actions/modalActions';
import { userLabelText } from '../../helpers/userLabel';

import FilterTable from '../../components/UI/Table/FilterTable';

import './AdminTeams.scss';

const modal = {
  contents: 'AdminTeamCreate',
  heading: 'Create New Team',
};

const AdminTeams = ({
  adminTeams,
  fetchTeams,
  resetSearch,
  openM,
  loading,
}) => {
  const tableHead2 = [
    {
      accessorKey: 'name',
      cell: (props) => (
        <button
          className="text-bcapp-blue"
          onClick={() =>
            openM({
              ...modal,
              heading: 'Edit Team',
              data: props.row.original,
            })
          }
        >
          {props.getValue()}
        </button>
      ),
      header: 'Name',
    },
    {
      id: 'teamLeader',
      accessorFn: (row) =>
        `${row.teamLeader.firstName} ${row.teamLeader.lastName}` || '-',
      cell: (props) =>
        props.row.original.teamLeader.firstName
          ? userLabelText({
              firstName: props.row.original.teamLeader.firstName,
              lastName: props.row.original.teamLeader.lastName,
            })
          : '-',
      header: 'Team Leader',
    },
    {
      id: 'memberCount',
      cell: (props) => props.row.original.teamMembers.length,
      header: 'Members',
    },
    {
      accessorKey: 'teamSize',
      cell: (props) => props.getValue(),
      header: 'Seats',
    },
  ];

  useEffect(() => {
    fetchTeams();

    return () => {
      // on unmount reset the search
      resetSearch();
    };
  }, []); // eslint-disable-line

  return (
    <AdminContainer>
      <div className="admin-team__title">
        <h1>Admin Teams</h1>
        <Button onClick={() => openM(modal)}>Create New Team</Button>
      </div>

      <FilterTable
        defaultData={adminTeams.teams}
        defaultColumns={tableHead2}
        showPagination={true}
        placeholder="Search teams..."
        loading={loading.loading}
      />
    </AdminContainer>
  );
};

AdminTeams.propTypes = {
  adminTeams: object,
  fetchTeams: func,
  resetSearch: func,
  openM: func,
};

const mapStateToProps = ({ adminTeams, loading }) => ({
  adminTeams,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeams: () => dispatch(adminTeamsFetch()),
  resetSearch: () => dispatch(adminFuzzy('')),
  openM: (data) => dispatch(openModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTeams);
