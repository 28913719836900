import constants from '../constants';

const { LOADING_REQUEST, LOADING_FINISHED, LOGOUT, SUB_LOADING } = constants;

const initState = {
  loading: true,
  subLoading: false,
};

export const loading = (state = initState, action) => {
  switch (action.type) {
    case LOADING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOADING_FINISHED:
      return {
        ...state,
        loading: false,
      };

    case SUB_LOADING:
      return {
        ...state,
        subLoading: action.subLoading,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
