import constants from '../constants';

const { SORT_TABLE, SET_PAGINATION, LOGOUT } = constants;

const initState = {
  target: '',
  aToZ: true,
  paginationActive: 0,
};

export const sortTable = (state = initState, action) => {
  switch (action.type) {
    case SORT_TABLE:
      return {
        ...state,
        target: action.target,
        aToZ: action.aToZ,
      };

    case SET_PAGINATION:
      return {
        ...state,
        paginationActive: action.paginationActive,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
