import constants from '../constants';

const {
  BULK_EDIT_MENU,
  BULK_EDIT_ITEMS,
  BULK_EDIT_EDIT,
  BULK_EDIT_TAGS,
  LOGOUT,
} = constants;

const initState = {
  open: false,
  items: [],
  priority: '',
  status: '',
  assignee: '',
  dueDate: '',
  location: '',
};

export const bulkEdit = (state = initState, action) => {
  switch (action.type) {
    case BULK_EDIT_MENU:
      return {
        ...initState,
        open: !state.open,
      };

    case BULK_EDIT_ITEMS:
      return {
        ...state,
        items: action.items,
        sharedTags: action.sharedTags,
        baseTags: action.baseTags,
      };

    case BULK_EDIT_EDIT:
      return {
        ...state,
        [action.target]: action.value,
      };

    case BULK_EDIT_TAGS:
      return {
        ...state,
        sharedTags: action.sharedTags,
        removeTags: action.removeTags,
        addTags: action.addTags,
      };

    case LOGOUT:
      return initState;

    default:
      return state;
  }
};
