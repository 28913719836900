import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { object, func } from 'prop-types';
import Button, { ButtonGroup } from '../../components/Button/Button';

import { updateAcccount } from '../../actions/registerActions';
import {
  fetchCurrentUser,
  fetchCurrentUserSubscriptions,
} from '../../actions/loginActions';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactSelect from 'react-select';

import './ProfileNotifications.scss';

const intervalLabels = (translations) => {
  const {
    notificationsDetailsPage__emailUpdateDay_option,
    notificationsDetailsPage__emailUpdateEightHour_option,
    notificationsDetailsPage__emailUpdateOff_option,
    notificationsDetailsPage__emailUpdateOneHour_option,
    notificationsDetailsPage__emailUpdateWeek_option,
  } = translations.byKeyTranslations;
  return [
    { label: notificationsDetailsPage__emailUpdateOneHour_option, value: 1 },
    { label: notificationsDetailsPage__emailUpdateEightHour_option, value: 8 },
    { label: notificationsDetailsPage__emailUpdateDay_option, value: 24 },
    { label: notificationsDetailsPage__emailUpdateWeek_option, value: 168 },
    { label: notificationsDetailsPage__emailUpdateOff_option, value: 0 },
  ];
};

const reminderIntervalLabels = (translations) => {
  const {
    notificationsDetailsPage__dueDateOff_option,
    notificationsDetailsPage__dueDateOneDay_option,
    notificationsDetailsPage__dueDateThreeDay_option,
    notificationsDetailsPage__dueDateTwoDay_option,
  } = translations.byKeyTranslations;

  return [
    { label: notificationsDetailsPage__dueDateOneDay_option, value: 24 },
    {
      label: notificationsDetailsPage__dueDateTwoDay_option,
      value: 48,
    },
    { label: notificationsDetailsPage__dueDateThreeDay_option, value: 72 },
    { label: notificationsDetailsPage__dueDateOff_option, value: 0 },
  ];
};

// profile subscriptions route /profile#subscriptions
const ProfileNotifications = ({
  updateUser,
  login,
  getUser,
  getSubs,
  translations,
}) => {
  const {
    notificationsDetailsPage__dueDateReminder_input,
    notificationsDetailsPage__noAddedEmail_checkbox,
    notificationsDetailsPage__noMentionEmail_checkbox,
    notificationsDetailsPage__notifications_header,
    notificationsDetailsPage__noWeeeklyReport_checkbox,
    notificationsDetailsPage__projectEmailUpdates_input,
    notificationsDetailsPage__save_button,
  } = translations.byKeyTranslations;

  const {
    _id,
    interval,
    noProjectAddEmail,
    noMetionEmail,
    noWeeklyReminder,
    taskDueInterval,
  } = login.userProfile;

  const { id } = login.user;

  const [settings, setSettings] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    getUser();
    getSubs();
  }, []); // eslint-disable-line

  useEffect(() => {
    setSettings({
      interval: intervalLabels(translations).find(
        (item) => item.value === interval
      ),
      taskDueInterval: reminderIntervalLabels(translations).find(
        (item) => item.value === taskDueInterval
      ),
      noProjectAddEmail: noProjectAddEmail || '',
      noMetionEmail: noMetionEmail || '',
      noWeeklyReminder: noWeeklyReminder || '',
    });
  }, [
    setSettings,
    interval,
    taskDueInterval,
    noProjectAddEmail,
    noMetionEmail,
    noWeeklyReminder,
    translations,
  ]);

  useEffect(() => {
    reset(settings);
  }, [reset, settings]);

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(errors).length === 0) {
        setSubmittingForm(true);
        setTimeout(() => setSubmittingForm(false), 3000);
      }
    }
  }, [getValues, isSubmitting, errors]);

  return (
    <div>
      <h1>{notificationsDetailsPage__notifications_header}</h1>

      <form onSubmit={handleSubmit((data) => updateUser(id, data))}>
        <h5>
          {notificationsDetailsPage__projectEmailUpdates_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="interval"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="interval"
          control={control}
          rules={{ required: ' Project email update interval is required' }}
          render={({ field }) => (
            <ReactSelect
              {...register('interval')}
              {...field}
              options={intervalLabels(translations)}
              isClearable
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        <h5>
          {notificationsDetailsPage__dueDateReminder_input}
          <span className="text-red-600"> *</span>
          <ErrorMessage
            errors={errors}
            name="taskDueInterval"
            render={({ message }) => (
              <span className="text-red-600">{message}</span>
            )}
          />
        </h5>
        <Controller
          name="taskDueInterval"
          control={control}
          rules={{ required: ' Due date reminder is required' }}
          render={({ field }) => (
            <ReactSelect
              {...register('taskDueInterval')}
              {...field}
              options={reminderIntervalLabels(translations)}
              isClearable
              isSearchable={false}
              className="pt-[10px] mb-[10px]"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuList: (base) => ({
                  ...base,
                  maxHeight: '300px',
                }),
                control: (base, state) => ({
                  ...base,
                  background: '#fafbfc',
                  borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  outline: state.isFocused
                    ? '#66ccff solid 2px !important'
                    : 'none',
                  '*': {
                    boxShadow: 'none !important',
                  },
                  '&:hover': {
                    borderColor: state.isFocused ? '#66ccff' : '#dfe1e6',
                  },
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#9a9fa7'
                    : state.isFocused
                    ? '#f1f5f9'
                    : base.backgroundColor,
                  '&:hover': {
                    backgroundColor: state.isSelected ? '#9a9fa7' : '#f1f5f9',
                  },
                }),
              }}
            />
          )}
        />

        {_id && (
          <Fragment>
            <div className="py-2">
              <input
                className="py-[7px] px-[5px] my-[5px] border border-[#DFE1E6] rounded text-bcapp-blue focus:ring-transparent focus:ring-offset-0 cursor-pointer"
                {...register('noProjectAddEmail')}
                type="checkbox"
                defaultChecked={noProjectAddEmail}
              />

              <label className="pl-2">
                {notificationsDetailsPage__noAddedEmail_checkbox}
              </label>
            </div>

            <div className="py-2">
              <input
                className="py-[7px] px-[5px] my-[5px] border border-[#DFE1E6] rounded text-bcapp-blue focus:ring-transparent focus:ring-offset-0 cursor-pointer"
                {...register('noMetionEmail')}
                type="checkbox"
                defaultChecked={noMetionEmail}
              />

              <label className="pl-2">
                {notificationsDetailsPage__noMentionEmail_checkbox}
              </label>
            </div>

            <div className="py-2">
              <input
                className="py-[7px] px-[5px] my-[5px] border border-[#DFE1E6] rounded text-bcapp-blue focus:ring-transparent focus:ring-offset-0 cursor-pointer"
                {...register('noWeeklyReminder')}
                type="checkbox"
                defaultChecked={noWeeklyReminder}
              />

              <label className="pl-2">
                {notificationsDetailsPage__noWeeeklyReport_checkbox}
              </label>
            </div>
          </Fragment>
        )}

        <div className="mt-6">
          <ButtonGroup>
            <Button loading={submittingForm}>
              {notificationsDetailsPage__save_button || ''}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </div>
  );
};

ProfileNotifications.propTypes = {
  register: object,
  getUser: func,
  login: object,
  updateUser: func,
  updateSubs: func,
  getSubs: func,
  translations: object,
};

const mapStateToProps = ({ register, login, translations }) => ({
  register,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (id, data) => dispatch(updateAcccount(id, data)),
  getUser: () => dispatch(fetchCurrentUser()),
  getSubs: () => dispatch(fetchCurrentUserSubscriptions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileNotifications);
