import React, { useState, useEffect } from 'react';
import { object, func } from 'prop-types';
import { generatePdf, generateCsv } from '../../actions/projectActions';
import { connect } from 'react-redux';
import Checkbox from '../../components/CheckBox/CheckBox';
import Button from '../../components/Button/Button';
import template from 'string-template';
import { ExclamationIcon } from '@heroicons/react/solid/';

import './PdfModal.scss';

// pdf and cvs download modal
const PdfModal = ({
  dashboard,
  pdf,
  sortTable,
  csv,
  loading,
  project,
  translations,
}) => {
  const {
    projectNavExport__CSV_header,
    projectNavExport__currentSort_checkbox,
    projectNavExport__downloadCsv_button,
    projectNavExport__downloadPdf_button,
    projectNavExport__exportFilteredOnly_checkbox,
    projectNavExport__exportOpenOnly_checkbox,
    projectNavExport__hidePageNumbers_checkbox,
    projectNavExport__includeCoverPage_checkbox,
    projectNavExport__manyItems_helper,
    projectNavExport__pdf_header,
    projectNavExport__reportCount_helper,
  } = translations.byKeyTranslations;

  const { _id } = dashboard.activeIssue;
  const { _id: projectId, name } = project.activeProject;

  const [filter, setFilter] = useState(false);
  const [sort, setSort] = useState(false);
  const [coverPage, setCoverPage] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const [hidePageNumber, setHidePageNumber] = useState(false);
  const [addAssets, setAddAssets] = useState(false);
  const [experimental, setExperimental] = useState(false);

  const filteredIssues = dashboard.sortedIssues.map((item) => item._id);
  const issues = dashboard.issues.map((item) => item._id);

  const [bodyData, setBodyData] = useState({
    issues: filter ? filteredIssues : issues,
    sort: sort ? sortTable : false,
    coverPage,
    hidePageNumber,
    addAssets,
    name,
    experimental,
  });

  /* eslint-disable */
  useEffect(() => {
    setBodyData({
      issues: openItem ? [_id] : filter ? filteredIssues : issues,
      sort: sort ? sortTable : false,
      coverPage,
      hidePageNumber,
      addAssets,
      name,
      experimental,
    });
  }, [
    filter,
    sort,
    sortTable,
    coverPage,
    openItem,
    hidePageNumber,
    addAssets,
    experimental,
  ]);
  // eslint-enable
  const issuesLength = bodyData.issues.length;

  return (
    <div className="pdf-modal__container grid grid-cols-2 justify-items-center">
      <div className="pdf-modal__row grid grid-cols-1 justify-items-start">
        <div>
          <h3>{projectNavExport__pdf_header}</h3>

          <Checkbox
            label={projectNavExport__includeCoverPage_checkbox}
            onChange={() => setCoverPage(!coverPage)}
            isChecked={coverPage}
          />

          {_id && (
            <Checkbox
              label={projectNavExport__exportOpenOnly_checkbox}
              onChange={() => setOpenItem(!openItem)}
              isChecked={openItem}
            />
          )}

          {!openItem && (
            <Checkbox
              label={projectNavExport__exportFilteredOnly_checkbox}
              onChange={() => setFilter(!filter)}
              isChecked={filter}
            />
          )}

          {!openItem && (
            <Checkbox
              label={projectNavExport__currentSort_checkbox}
              onChange={() => setSort(!sort)}
              isChecked={sort}
            />
          )}

          <Checkbox
            label={'Include attached images.'}
            onChange={() => setAddAssets(!addAssets)}
            isChecked={addAssets}
          />

          <Checkbox
            label={projectNavExport__hidePageNumbers_checkbox}
            onChange={() => setHidePageNumber(!hidePageNumber)}
            isChecked={hidePageNumber}
          />

          <p className="font-bold max-w-[224px]">
            {template(projectNavExport__reportCount_helper, { issuesLength })}
            {issuesLength > 100 && !experimental ? (
              <span>
                {` `}
                {projectNavExport__manyItems_helper}
              </span>
            ) : (
              ''
            )}
          </p>
        </div>
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="relative flex items-start">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm font-medium text-yellow-800 mb-0">
                Use the new PDF engine (beta)
              </div>
            </div>
            <div className="ml-3 flex h-5 items-center">
              <Checkbox
                label=""
                onChange={() => setExperimental(!experimental)}
                isChecked={experimental}
              />
            </div>
          </div>
        </div>

        <Button onClick={() => pdf(bodyData)} loading={loading.loading}>
          {projectNavExport__downloadPdf_button}
        </Button>
      </div>

      <div className="pdf-modal__row">
        <h3>{projectNavExport__CSV_header}</h3>

        <Button onClick={() => csv(projectId, name)} loading={loading.loading}>
          {projectNavExport__downloadCsv_button}
        </Button>
      </div>
    </div>
  );
};

PdfModal.propTypes = {
  dashboard: object,
  loading: object,
  pdf: func,
  csv: func,
  sortTable: object,
  project: object,
  translations: object,
};

const mapStateToProps = ({
  dashboard,
  sortTable,
  loading,
  project,
  translations,
}) => ({
  dashboard,
  sortTable,
  loading,
  project,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  pdf: (data) => dispatch(generatePdf(data)),
  csv: (id, name) => dispatch(generateCsv(id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfModal);
