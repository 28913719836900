import * as msal from '@azure/msal-browser';
import config from '../config';

// Config object to be passed to Msal on creation.
// For a full list of msal.js configuration parameters,
// visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
export const msalConfig = {
  auth: {
    clientId: config.MSAL_AUTH_CLIENTID,
    authority: config.MSAL_AUTH_AUTHORITY,
    redirectUri: config.MSAL_AUTH_REDIRECT,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },

  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (process.env.NODE_ENV === 'development') {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
          }
        }
      },
    },
  },
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

// Add here the scopes that you would like the user to consent during sign-in
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the scopes to request when obtaining an access token for MS Graph API
export const tokenRequest = {
  scopes: ['User.Read'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const appTokenRequest = {
  scopes: ['User.Read'],
};
