import fetchData from '../helpers/fetch';
import constants, { api } from '../constants';
import toast from '../helpers/toast';
import { closeModal } from '../actions/modalActions';

const {
  ADMIN_TOKEN_SUCCESS,
  ADMIN_TOKEN_FAILURE,
  LOADING_REQUEST,
  LOADING_FINISHED,
} = constants;

export const adminTokenFetch = (dontFinishLoading) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminTeamsFetch' });

  const options = {
    url: `${api.TOKEN_API}`,
  };

  try {
    const tokens = await fetchData(options);

    dispatch({ type: ADMIN_TOKEN_SUCCESS, tokens });

    if (!dontFinishLoading) {
      dispatch({ type: LOADING_FINISHED, src: 'adminTokenFetch' });
    }
  } catch (e) {
    dispatch({ type: ADMIN_TOKEN_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminTokenFetch' });
    dispatch(toast('error', e));
  }
};

export const adminTokenCreate =
  (id, { name, user, expire }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_REQUEST, src: 'adminTokenCreate' });

    const body = {
      name,
      user: user.value,
      expire,
    };

    const options = {
      url: id ? `${api.TOKEN_API}/${id}` : api.TOKEN_API,
      type: id ? 'PATCH' : 'POST',
      body,
    };

    try {
      await fetchData(options);
      await dispatch(adminTokenFetch());
      dispatch({ type: LOADING_FINISHED, src: 'adminTokenCreate' });
      dispatch(closeModal());
    } catch (e) {
      dispatch({ type: ADMIN_TOKEN_FAILURE, e });
      dispatch({ type: LOADING_FINISHED, src: 'adminTokenCreate' });
      dispatch(toast('error', e));
    }
  };

export const adminTokenDelete = (id) => async (dispatch) => {
  dispatch({ type: LOADING_REQUEST, src: 'adminTokenDelete' });

  const options = {
    url: `${api.TOKEN_API}/${id}`,
    type: 'DELETE',
  };

  try {
    await fetchData(options);
    await dispatch(adminTokenFetch());
    dispatch({ type: LOADING_FINISHED, src: 'adminTokenDelete' });
    dispatch(closeModal());
  } catch (e) {
    dispatch({ type: ADMIN_TOKEN_FAILURE, e });
    dispatch({ type: LOADING_FINISHED, src: 'adminTokenDelete' });
    dispatch(toast('error', e));
  }
};
