import store from '../store.js';
import { teamMemberRole, teamAdminRole } from '../constants/teamRoles';

// team specific actions and roles
const getTeamPermission = (action, team) => {
  const allRoles = {
    teamAdmin: teamAdminRole,
    teamMember: teamMemberRole,
  };

  const { login, teams } = store.getState();
  const { globalAdmin, _id } = login.userProfile || {};
  const { teamMembers = [], teamLeader = {} } = team || teams.activeTeam || {};

  // global admin can do anything
  if (globalAdmin) {
    return true;
  }

  // team leader can do anything
  if (_id === teamLeader._id) {
    return true;
  }

  const user = teamMembers.find((item) => item.user._id === _id);

  if (!user) {
    return false;
  }

  // get user team level
  const userMemberLevel = user.teamLevel;

  // team admin can do anything
  if (userMemberLevel === 'teamAdmin') {
    return true;
  }

  // check their member level exists within the correct schema
  if (allRoles[userMemberLevel].includes(action)) {
    return true;
  }

  return false;
};

export default getTeamPermission;
