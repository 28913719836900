import React, { useEffect, useState } from 'react';
import { oneOfType, arrayOf, node, bool, object } from 'prop-types';
import logo from '../../assets/logo-invert.svg';
import cx from 'classnames';
import Modal from '../Modal/Modal';

import './ExternalWrapper.scss';

const MAX_BG_NO = 54;

// wrapper for login screene etc
const ExternalWrapper = ({ children, bottom, big }) => {
  const [bg, setBg] = useState(1);

  useEffect(() => {
    // ~~ == easy math floor
    setBg(~~(Math.random() * MAX_BG_NO) + 1);
  }, []);

  return (
    <div
      className="external-wrapper__container"
      style={{
        backgroundImage: `url(/bg/bg_${bg}.jpg)`,
      }}
    >
      <Modal />

      <div
        className={cx('external-wrapper__inner-container', {
          'external-wrapper__inner-container--big': big,
        })}
      >
        <div className="external-wrapper__logo">
          <img src={logo} alt="bcapp logo" />
        </div>
        {children}
      </div>
      {bottom}
    </div>
  );
};

ExternalWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  big: bool,
  bottom: object,
};

export default ExternalWrapper;
