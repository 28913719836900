import React, { useEffect, useState, Fragment, useCallback } from 'react';
import { object, func } from 'prop-types';
import { connect } from 'react-redux';
import { markIssueAsSeen } from '../../actions/dashboardActions';
import findHelper from '../../helpers/findHelper';
import Comments from '../Comments/Comments';
import Description from '../Description/Description';
import IssueDocumentInfo from '../IssueDocumentInfo/IssueDocumentInfo';
import Tags from '../Tags/Tags';
import Assets from '../Assets/Assets';
import Subscribers from '../Subscribers/Subscribers';
import IssueDocumentTop from '../IssueDocumentTop/IssueDocumentTop';
import cx from 'classnames';
import ItemHistory from '../ItemHistory/ItemHistory';
import getPermissions from '../../helpers/getPermissions';
import SubTasks from '../SubTasks/SubTasks';
import HeroIconDocumentOutline from '../HeroIcon/HeroIconDocumentOutline';

import 'react-quill/dist/quill.snow.css';
import './IssueDocument.scss';

// item section wrapper
const IssueDocument = ({
  dashboard = {},
  markAsSeen,
  login,
  translations,
  isMobile,
}) => {
  const [height, setHeight] = useState(0);

  const issueContainer = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const {
    itemView__comments_link,
    itemView__history_link,
    itemView__empty_helper,
  } = translations.byKeyTranslations;

  // const issueContainer = useRef(null)
  const [activeTab, setActiveTab] = useState('comments');

  const { _id, seenBy, isNewItem } = dashboard.activeIssue;

  useEffect(() => {
    if (_id && !findHelper(seenBy, 'user', login.user.id)) {
      markAsSeen(_id);
    }
    const target = document.getElementById('issue__container__target');
    if (target) {
      target.scrollTop = 0;
    }
    setActiveTab('comments');
  }, [_id]); // eslint-disable-line

  const scrollToBottom = () => {
    // time out hack to wait for other animations
    setTimeout(() => {
      const target = document.getElementById('issue__container__target');

      if (target) {
        target.scrollTop = target.scrollHeight;
      }
    }, 200);
  };

  useEffect(() => {
    // this is pretty terrible, but dam iphones add stupid bar
    // could refactor to use refs but annoying
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, []);

  useEffect(() => {
    resizeEvent();
  }, [height]);

  const resizeEvent = () => {
    const windowHeight = window.innerHeight;
    const topNav = document.getElementById('top__nav__target') || {};
    const projectNav = document.getElementById('project__nav__target') || {};
    const target = document.getElementById('issue__container__target');
    const calcHeight =
      windowHeight - topNav.offsetHeight - projectNav.offsetHeight;

    if (target) {
      target.style.height = calcHeight + 'px';
    }
  };

  return _id || isNewItem ? (
    <div
      // className="issue__container custom-scroll pr-2 pb-20 sm:pb-0"

      className={cx(
        'issue__container custom-scroll',
        'bg-white',
        isMobile ? '' : 'w-full'
      )}
      ref={issueContainer}
      id="issue__container__target"
    >
      {isNewItem && <div className="issue__container--click" />}
      <IssueDocumentTop />

      <div className="issue__padding-container">
        <IssueDocumentInfo />
        <Tags />
        <Subscribers />
        <Description />
        <SubTasks />
        <Assets />
        <h5 className="issue__split-title">
          <span
            className={cx('issue__split-title--text', {
              'issue__split-title--active': activeTab === 'comments',
            })}
            onClick={() => setActiveTab('comments')}
          >
            {itemView__comments_link}
          </span>
          {getPermissions('item:history:view') && (
            <Fragment>
              &nbsp; &nbsp; - &nbsp; &nbsp;
              <span
                className={cx('issue__split-title--text', {
                  'issue__split-title--active': activeTab === 'history',
                })}
                onClick={() => setActiveTab('history')}
              >
                {itemView__history_link}
              </span>
            </Fragment>
          )}
        </h5>
        {activeTab === 'comments' ? (
          <Comments scrollToBottom={scrollToBottom} />
        ) : (
          <ItemHistory />
        )}
      </div>
    </div>
  ) : (
    <div className="issue__container">
      <div className="issue__not-open grid place-items-center grid-flow-row auto-rows-max">
        <HeroIconDocumentOutline size={80} />
        <p>{itemView__empty_helper}</p>
      </div>
    </div>
  );
};

IssueDocument.propTypes = {
  dashboard: object,
  markAsSeen: func,
  login: object,
  translations: object,
};

const mapStateToProps = ({ dashboard, login, translations }) => ({
  dashboard,
  login,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  markAsSeen: (id) => dispatch(markIssueAsSeen(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IssueDocument);
