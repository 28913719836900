import constants from '../constants';
import jwtDecode from 'jwt-decode';

const {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  USER_NOTIFICATIONS,
  USER_PROFILE,
  USER_SUBS,
  USER_SIDEBAROPEN,
  USER_SIDEBARWIDTH,
  USER_ISSUEDOCWIDTH,
} = constants;

const token = localStorage.getItem('token') || '';
const user = token ? jwtDecode(token) : {};
const initialState = token
  ? { loggedIn: true, token, user, notifications: 0, userProfile: {} }
  : { loggedIn: false, user, token, notifications: 0, userProfile: {} };

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        token: action.token ? action.token : state.token,
        user: action.user,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loggedIn: false,
        user: {},
        token: '',
      };
    case USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications || 0,
      };
    case USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile,
      };
    case USER_SUBS:
      return {
        ...state,
        subs: action.subs,
      };
    case USER_SIDEBAROPEN:
      let sidebarOpenData = { ...state };
      sidebarOpenData.user.sidebarOpen = action.sidebarOpen;
      return {
        ...sidebarOpenData,
      };
    case USER_SIDEBARWIDTH:
      let sidebarWidthData = { ...state };
      sidebarWidthData.user.sidebarWidth = action.sidebarWidth;
      return {
        ...sidebarWidthData,
      };
    case USER_ISSUEDOCWIDTH:
      let issueDocWidthData = { ...state };
      issueDocWidthData.user.issueDocWidth = action.issueDocWidth;
      return {
        ...issueDocWidthData,
      };
    case LOGOUT:
      return { loggedIn: false, user: {}, token: '', userProfile: {} };
    default:
      return state;
  }
};
