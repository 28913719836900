import { convertFromRaw, EditorState } from 'draft-js';
import parseJson from '../helpers/parseJson';

// turns a draft obeject into plain text
const draft2Text = (data) => {
  const getData = parseJson(data);

  if (!getData || getData.blocks.length === 0) {
    return '';
  }

  const draftObj = convertFromRaw(getData);
  const state = EditorState.createWithContent(draftObj);
  // Note that the function getPlainText will always create a single space between blocks, so you need to send \u0001 as a parameter
  const text = state.getCurrentContent().getPlainText('\u0001');

  return text;
};

export default draft2Text;
