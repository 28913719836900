import constants from '../constants';

const {
  ADMIN_PROJECTS_VIEW_SUCCESS,
  ADMIN_PROJECTS_VIEW_FAILURE,
  ADMIN_PROJECTS_ITEMS_SUCCESS,
  ADMIN_PROJECTS_ITEMS_FAILURE,
  ADMIN_PROJECTS_EVENTS_SUCCESS,
  ADMIN_PROJECTS_EVENTS_FAILURE,
  ADMIN_PROJECTS_STATS_SUCCESS,
  ADMIN_PROJECTS_STATS_FAILURE,
  ADMIN_PROJECTS_VIEW_FUZZY_TERM,
  ADMIN_PROJECTS_VIEW_FUZZY_SEARCH,
  LOGOUT,
} = constants;

const initState = {
  projects: [],
  items: [],
  events: [],
  stats: {},
  error: '',
  searchTerm: '',
  searchResults: null,
};

export const adminProjectsView = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_PROJECTS_VIEW_SUCCESS:
      return {
        ...state,
        projects: action.projects,
      };
    case ADMIN_PROJECTS_VIEW_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_PROJECTS_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case ADMIN_PROJECTS_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_PROJECTS_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.events,
      };
    case ADMIN_PROJECTS_EVENTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_PROJECTS_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats,
      };
    case ADMIN_PROJECTS_STATS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_PROJECTS_VIEW_FUZZY_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case ADMIN_PROJECTS_VIEW_FUZZY_SEARCH:
      return {
        ...state,
        searchResults: action.searchResults,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
