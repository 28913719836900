import React from 'react';
import './NotFound.scss';

// not found page
const NotFound = () => (
  <div className="not-found__container">
    <h1>404 NOT FOUND - WHERE ARE YOU GOING?</h1>
  </div>
);

export default NotFound;
