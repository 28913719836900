import constants from '../constants';

const { REGISTER_SUCCESS, REGISTER_FAILURE, COMPANY_SUCCESS, LOGOUT } =
  constants;

const initState = {
  user: {},
  company: [],
};

export const register = (state = initState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        error: action.error,
        user: {},
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        company: action.company,
      };

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
