import React from 'react';
import { connect } from 'react-redux';
import { object, func, string } from 'prop-types';
import { createNewIssue } from '../../actions/createNewIssuesAction';
import { useRef } from 'react';
import HeroIconCamera from '../../components/HeroIcon/HeroIconCamera';

const QuickUpload = ({ postNew, project, translations }) => {
  const fileInput = useRef(null);

  function handleFileUpload(e) {
    postNew(
      {
        name: 'Quick Issue',
        description: '',
        dueDate: '',
        priority: { value: 'Normal', label: 'Normal' },
        assignee: {},
        status: { value: 'Open', label: 'Open' },
      },
      e.target
    );
  }

  return (
    <>
      <input
        id="file"
        ref={fileInput}
        onChange={handleFileUpload}
        type="file"
        style={{ display: 'none' }}
        multiple={false}
      />
      <label htmlFor="file">
        <span
          role="button"
          className="sm:hidden inline-flex font-medium justify-center items-center text-4xl text-white fixed z-[2] bottom-5 left-[50%] -ml-8 rounded-full w-16 h-16 bg-bcapp-darkblue hover:bg-sky-700"
        >
          <div className="">
            <HeroIconCamera size={32} marginRight={0} marginBottom={1} />{' '}
          </div>
        </span>
      </label>
    </>
  );
};

QuickUpload.propTypes = {
  login: object,
  postNew: func,
  projectId: string,
  project: object,
  translations: object,
};

const mapStateToProps = ({ login, project, translations }) => ({
  login,
  project,
  translations,
});

const mapDispatchToProps = (dispatch) => ({
  postNew: (data, ref) => dispatch(createNewIssue(data, ref)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickUpload);
