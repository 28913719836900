import React from 'react';
import RoundBadge from '../../components/UI/Badge/RoundBadge';
import { projectUrl } from '../../helpers/adminUrls';
import { memberLabels } from '../../constants/labels';
import { get } from 'lodash';
import ReactJson from 'react-json-view';
import Link from '../../components/Link/Link';

export const eventsHead = {
  cells: [
    {
      key: 'createdAt',
      content: 'Date',
      isSortable: true,
      width: 10,
      shouldTruncate: true,
    },
    {
      key: 'table',
      content: 'Table',
      isSortable: true,
      width: 5,
    },
    {
      key: 'method',
      content: 'Method',
      isSortable: true,
      width: 5,
    },
    {
      key: 'target',
      content: 'Target',
      isSortable: false,
      width: 5,
    },
    {
      key: 'user',
      content: 'User',
      isSortable: true,
      width: 15,
    },
    {
      key: 'oldVal',
      content: 'Old Value',
      shouldTruncate: true,
      width: 25,
    },
    {
      key: 'newVal',
      content: 'New Value',
      shouldTruncate: true,
      width: 25,
    },
  ],
};

export const eventsTable = (events = [{}]) =>
  events &&
  events.map(
    ({
      createdAt,
      table,
      method,
      user,
      oldVal,
      newVal,
      itemsId,
      projectsId,
      target,
    }) => {
      const { firstName, lastName } = user;
      const eventTarget =
        get(itemsId, '_id') || get(projectsId, '_id') || target;
      return {
        cells: [
          {
            key: createdAt,
            content: createdAt,
          },
          {
            key: table,
            content: table,
          },
          {
            key: method,
            content: method,
          },
          {
            key: String(eventTarget),
            content: String(eventTarget),
          },
          {
            key: firstName + lastName,
            content: `${firstName} ${lastName}`,
          },
          {
            key: oldVal,
            content: oldVal ? (
              <ReactJson src={oldVal} name={false} indentWidth={2} collapsed />
            ) : (
              String(oldVal)
            ),
          },
          {
            key: newVal,
            content: newVal ? (
              <ReactJson src={newVal} name={false} indentWidth={2} collapsed />
            ) : (
              String(newVal)
            ),
          },
        ],
      };
    }
  );

export const projectsHead = {
  cells: [
    {
      key: 'createdAt',
      content: 'Created',
      isSortable: true,
      width: 5,
    },
    {
      key: 'name',
      content: 'Name',
      isSortable: true,
      width: 15,
      shouldTruncate: true,
    },
    {
      key: 'description',
      content: 'Description',
      isSortable: true,
      width: 25,
      shouldTruncate: true,
    },
    {
      key: 'members',
      content: 'Role',
      width: 10,
    },
  ],
};

// admin route /admin/users/id
export const projectsTable = (projects = [{}], uid) =>
  projects &&
  projects.map((project) => {
    const { _id, name, description, createdAt, creator, members } = project;
    const member = members.find((mem) => mem.user._id === uid);
    const memberLabel = get(
      memberLabels.find((label) => label.value === member.memberLevel),
      'label'
    );
    const role = <RoundBadge value={memberLabel} />;
    return {
      cells: [
        {
          key: createdAt,
          content: createdAt,
        },
        {
          key: name,
          content: (
            <Link to={projectUrl(_id)} dark>
              {name}
            </Link>
          ),
        },
        {
          key: description,
          content: description,
        },
        {
          key: members,
          content:
            creator._id === uid ? (
              <span>
                <RoundBadge value="Creator" /> {role}
              </span>
            ) : (
              role
            ),
        },
      ],
    };
  });

export const tableHeadUserProjects = (URL_ID) => [
  {
    accessorKey: 'createdAt',
    cell: (props) => props.getValue(),
    header: 'Created',
  },
  {
    accessorKey: 'name',
    cell: (props) => (
      <a href={projectUrl(props.row.original._id)}>{props.getValue()}</a>
    ),
    header: 'Name',
  },
  {
    accessorKey: 'description',
    cell: (props) => props.getValue(),
    header: 'Description',
  },
  {
    accessorKey: 'members',
    cell: (props) =>
      props.row.original.creator._id === URL_ID ? (
        <span>
          <RoundBadge value="Creator" />{' '}
          <RoundBadge
            value={get(
              memberLabels.find(
                (label) =>
                  label.value ===
                  props.row.original.members.find(
                    (mem) => mem.user._id === URL_ID
                  ).memberLevel
              ),
              'label'
            )}
          />
        </span>
      ) : (
        <RoundBadge
          value={get(
            memberLabels.find(
              (label) =>
                label.value ===
                props.row.original.members.find(
                  (mem) => mem.user._id === URL_ID
                ).memberLevel
            ),
            'label'
          )}
        />
      ),
    header: 'Role',
  },
];

export const tableHeadUserEvents = () => [
  {
    accessorKey: 'createdAt',
    cell: (props) => props.getValue(),
    header: 'Date',
  },
  {
    accessorKey: 'table',
    cell: (props) => props.getValue(),
    header: 'Table',
  },
  {
    accessorKey: 'method',
    cell: (props) => props.getValue(),
    header: 'Method',
  },
  {
    id: 'target',
    cell: (props) => String(props.getValue()) || '-',
    header: 'Target',
  },
  {
    id: 'user',
    accessorFn: (row) => `${row.user.firstName} ${row.user.lastName}` || '-',
    cell: (props) => props.getValue() || '-',
    header: 'User',
  },
  {
    accessorKey: 'oldVal',
    cell: (props) =>
      props.row.original.oldVal ? (
        <ReactJson
          src={props.getValue()}
          name={false}
          indentWidth={2}
          collapsed
        />
      ) : (
        '-'
      ),
    header: 'Old Value',
    enableSorting: false,
  },
  {
    accessorKey: 'newVal',
    cell: (props) =>
      props.row.original.newVal ? (
        <ReactJson
          src={props.getValue()}
          name={false}
          indentWidth={2}
          collapsed
        />
      ) : (
        '-'
      ),
    header: 'New Value',
    enableSorting: false,
    width: 150,
  },
];
