import constants from '../constants';

const {
  ADMIN_TEAMS_SUCCESS,
  ADMIN_TEAMS_FAILURE,
  ADMIN_TEAMS_FUZZY_TERM,
  ADMIN_TEAMS_FUZZY_SEARCH,
  ADMIN_TEAMS_LABELS,
  ADMIN_TEAMS_LIST,
  LOGOUT,
} = constants;

const initState = {
  teams: [],
  error: '',
  searchTerm: '',
  searchResults: null,
  userLabels: [],
};

export const adminTeams = (state = initState, action) => {
  switch (action.type) {
    case ADMIN_TEAMS_SUCCESS:
      return {
        ...state,
        teams: action.teams,
      };
    case ADMIN_TEAMS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ADMIN_TEAMS_FUZZY_TERM:
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    case ADMIN_TEAMS_FUZZY_SEARCH:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case ADMIN_TEAMS_LIST: {
      return { ...state, teamList: action.userList };
    }
    case ADMIN_TEAMS_LABELS: {
      return { ...state, teamLabels: action.userLabels };
    }

    case LOGOUT:
      return initState;
    default:
      return state;
  }
};
